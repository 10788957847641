import { createContext, useContext, useMemo } from "react";
import { useBoardTasks } from "./use-board-integrations";
import { IntegrationItemsMap } from "./use-board-integrations.types";

interface IntegrationsContextType {
  dataSource: IntegrationItemsMap;
  loadItems: (integrations: { [integrationId: string]: string[] }) => void;
  getItem: (id: string, integrationId: string) => any;
  getBoardIntegrationConfig: (integrationId: string) => any;
  updateColumnValue: (id: string, integrationId: string, columnId: string, value: any) => void;
}

const IntegrationsContext = createContext<IntegrationsContextType>({
  dataSource: {},
  loadItems: () => {},
  getItem: () => {},
  getBoardIntegrationConfig: () => {},
  updateColumnValue: () => {},
});

export const IntegrationsProvider = ({
  documentId,
  children,
}: {
  documentId: string | null | undefined;
  children: React.ReactNode;
}) => {
  const { addItemsToQueue, dataSource, getItem, getBoardIntegrationConfig, updateColumnValue } =
    useBoardTasks(documentId);

  const value = useMemo(
    () => ({
      dataSource,
      loadItems: addItemsToQueue,
      getItem,
      getBoardIntegrationConfig,
      updateColumnValue,
    }),
    [dataSource, addItemsToQueue, getItem, getBoardIntegrationConfig, updateColumnValue]
  );

  return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};

export const useIntegrationsProvider = () => {
  const context = useContext(IntegrationsContext);
  if (!context) {
    throw new Error("useIntegrationsProvider must be used within a IntegrationsProvider");
  }
  return context;
};
