export interface LayoutProps {
  xGap: number;
  yGap: number;
  xPadding: number;
  yPadding: number;
}

interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class SimpleFlexRowLayout {
  protected props: LayoutProps;
  protected items: Rect[];
  protected keys: string[];
  protected x: number;
  protected y: number;

  constructor(protected lineWidth: number, protected lineHeight: number, props?: Partial<LayoutProps>) {
    this.props = {
      xGap: 0,
      yGap: 0,
      xPadding: 0,
      yPadding: 0,
      ...props,
    };
    this.items = [];
    this.keys = [];
    this.x = 0;
    this.y = 0;
  }

  public addItem(width: number, height: number, extraData?: any, key?: string) {
    const fullWidth = width + this.props.xPadding * 2;
    const fullHeight = height + this.props.yPadding * 2;

    // wrap around if we overflow the line - we don't want to do this after adding, only before !
    if (this.x != 0 && this.x + fullWidth > this.lineWidth) {
      this.x = 0;
      this.y += this.lineHeight + this.props.yGap;
    }
    this.items.push({
      x: this.x,
      y: this.y,
      width: fullWidth,
      height: fullHeight,
      ...extraData,
    });
    this.keys.push(key ?? this.keys.length.toString());
    this.x += fullWidth + this.props.xGap;
  }
  public map<T>(callback: (item: Rect, key: string) => T) {
    return this.items.map((value, index) => callback(value, this.keys[index]));
  }

  get top() {
    return 0;
  }
  get bottom() {
    if (this.items.length > 0) return this.top + (this.items[this.items.length - 1].y + this.lineHeight);
    else return this.top;
  }
  get left() {
    return 0;
  }
  get right() {
    return this.left + this.lineWidth;
  }
}
