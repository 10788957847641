import { MondayLogo } from "frontend/icons/monday-logo";
import React from "react";
import { Html } from "react-konva-utils";
import styles from "./connector-popup.module.css";
import { CloseIcon } from "frontend/icons/close";

type ConnectorPopupProps = {
  x?: number;
  y?: number;
  onChange: (value: "monday" | "custom") => void;
  onClose: () => void;
};

export const ConnectorPopup = ({ x, y, onChange, onClose }: ConnectorPopupProps) => {
  if (!x || !y) {
    return null;
  }

  return (
    <Html
      groupProps={{ x: x, y: y }}
      divProps={{
        position: "relative",
      }}
    >
      <div className={styles.closeIcon}>
        <CloseIcon onClick={onClose} />
      </div>

      <div
        className={styles.wrapper}
        onChange={(e) => onChange((e.target as HTMLInputElement).value as "monday" | "custom")}
      >
        <div className={styles.header}>
          <MondayLogo width={18} hanging={10} /> <h3 className={styles.title}>Link issues in Monday</h3>
        </div>
        <div className={styles.option}>
          <input type="radio" name="connectorType" id="monday" value={"monday"} />
          <label className={styles.mondayIcon} htmlFor="monday">
            <LinkIcon />
            Create dependencies in monday.com
          </label>
        </div>
        <div className={styles.option}>
          <input
            type="radio"
            name="connectorType"
            id="custom"
            defaultChecked
            value={"custom"}
            onClick={(e) => {
              if (e.currentTarget.checked) {
                onChange("custom");
              }
            }}
          />
          <label htmlFor="custom">No link in monday.com</label>
        </div>
      </div>
    </Html>
  );
};

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none" viewBox="0 0 11 11">
    <path
      stroke="#113357"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M4.644 6.28c.174.177.383.32.617.421a2.06 2.06 0 0 0 1.497.053c.24-.084.46-.212.646-.377l2.28-1.976c.378-.328.6-.782.617-1.264a1.76 1.76 0 0 0-.526-1.304 2.03 2.03 0 0 0-1.361-.581 2.03 2.03 0 0 0-1.399.483l-.285.247"
    ></path>
    <path
      stroke="#113357"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M6.722 4.583a2 2 0 0 0-.617-.421 2.06 2.06 0 0 0-1.497-.053c-.24.084-.46.212-.645.377L1.682 6.462c-.378.328-.6.782-.617 1.264s.172.95.526 1.304.844.563 1.361.581a2.03 2.03 0 0 0 1.399-.483l.285-.247"
    ></path>
  </svg>
);
