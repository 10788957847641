import { useAtomValue } from "jotai";
import { KonvaEventObject } from "konva/types/Node";
import { useState } from "react";
import { useEvent } from "react-use";
import { stageRefAtom } from "state-atoms";
import { findClosest } from "../find-closest";
import RenderChildrenIf from "../render-children-if";
import { actions } from "../table-actions";
import { DragHandle } from "./drag-handle";
import { TypeTableElement } from "shared/datamodel/schemas/table";
import * as Style from "../table-styling";
import { Button } from "./button-plus";
import { screenToElementMatrix } from "../table-utils";

export function WidgetsInNonActiveState({
  element,
  xs,
  ys,
  dispatch,
  startDragCol,
  startDragRow,
}: {
  element: TypeTableElement;
  xs: number[];
  ys: number[];
  dispatch: any;
  startDragCol: (col: number) => boolean;
  startDragRow: (row: number) => boolean;
}) {
  const stage = useAtomValue(stageRefAtom)?.current;
  const [col, setCol] = useState<null | number>(null);
  const [row, setRow] = useState<null | number>(null);
  const { x, y, scaleX = 1, scaleY = 1 } = element;
  const width = xs[xs.length - 1] * scaleX;
  const height = ys[ys.length - 1] * scaleY;

  useEvent("mousemove", (e) => {
    const matrix = screenToElementMatrix(stage, element);
    const p = matrix.transformPoint({ x: e.clientX, y: e.clientY });
    const col = p.x < 0 ? -1 : p.x > xs[xs.length - 1] ? xs.length - 1 : xs.findIndex((x) => x >= p.x) - 1;
    const row = p.y < 0 ? -1 : p.y > ys[ys.length - 1] ? ys.length - 1 : ys.findIndex((y) => y >= p.y) - 1;
    setCol(col);
    setRow(row);
  });

  function onClick(e: KonvaEventObject<MouseEvent>) {
    const matrix = screenToElementMatrix(e.target.getStage()!, element);
    const p = matrix.transformPoint({ x: e.evt.clientX, y: e.evt.clientY });
    if (p.x < 0 && p.y < 0) {
      // clicked on one of the first buttons (add-col at 0, or add-row at 0)
      // if |x| < |y|, then clicked on add-col button
      if (Math.abs(p.x) < Math.abs(p.y)) {
        dispatch(actions.addCol({ col: -1 }));
      } else {
        dispatch(actions.addRow({ row: -1 }));
      }
    } else if (p.y < 0) {
      // click above the table, on insert-column buttons
      // find the column  p.x is closest to
      const theCol = findClosest(p.x, xs);
      dispatch(actions.addCol({ col: theCol - 1 }));
    } else if (p.x < 0) {
      // click left of table, on insert-row buttons
      const theRow = findClosest(p.y, ys);
      dispatch(actions.addRow({ row: theRow - 1 }));
    }
  }

  //TODO - refactor
  let col1 = null,
    col2 = null,
    distance = Number.MAX_SAFE_INTEGER;
  if (col != null) {
    (col1 = col), (col2 = col + 1);
    col1 = Math.max(0, col1);
    col2 = Math.min(xs.length - 1, col2);
    distance = (xs[col2] - xs[col1]) * scaleX;
  }

  let row1 = null,
    row2 = null,
    distance2 = Number.MAX_SAFE_INTEGER;
  if (row != null) {
    (row1 = row), (row2 = row + 1);
    row1 = Math.max(0, row1);
    row2 = Math.min(ys.length - 1, row2);
    distance2 = (ys[row2] - ys[row1]) * scaleY;
  }

  return (
    <>
      <RenderChildrenIf
        name={`table-widgets-add-button-at-end`}
        x={x}
        y={y}
        shouldRenderTest={(node) => {
          const scale = node.getStage()?.scaleX() || 0;
          return (scale * width) / window.innerWidth > 0.1 || (scale * height) / window.innerHeight > 0.1;
        }}
      >
        <Button
          x={(xs[xs.length - 1] * scaleX) / 2}
          y={ys[ys.length - 1] * scaleY}
          ofsX={0}
          ofsY={Style.WidgetOffsetFromTable}
          distanceToSibling={Number.MAX_SAFE_INTEGER}
          onClick={() => dispatch(actions.addRow({ row: element.rows.length - 1 }))}
        />
        <Button
          x={xs[xs.length - 1] * scaleX}
          y={(ys[ys.length - 1] * scaleY) / 2}
          ofsX={Style.WidgetOffsetFromTable}
          ofsY={0}
          distanceToSibling={Number.MAX_SAFE_INTEGER}
          onClick={() => dispatch(actions.addCol({ col: element.cols.length - 1 }))}
        />
      </RenderChildrenIf>
      <RenderChildrenIf
        name={`table-widgets-on-columns`}
        x={x}
        y={y}
        shouldRenderTest={(node) => {
          const scale = node.getStage()?.scaleX() || 0;
          return (scale * width) / window.innerWidth > 0.1;
        }}
      >
        {/* Handle for selecting and dragging column */}
        {col != null && col >= 0 && col < xs.length - 1 && (
          <DragHandle
            x={((xs[col] + xs[col + 1]) * scaleX) / 2}
            ofsY={-Style.WidgetOffsetFromTable}
            onClick={(e) => dispatch(actions.selectCol({ col, toggle: e.evt.shiftKey }))}
            onDragStart={(e) => {
              dispatch(actions.selectCol({ col, toggle: false }));
              if (!startDragCol(col)) e.target.stopDrag();
            }}
            mode="horizontal"
            trackSize={distance}
          />
        )}

        {/* Buttons above the table, for inserting new columns between */}
        {col1 != null && col1 > 0 && (
          <Button
            key={`plus-col-${col1}`}
            x={xs[col1] * scaleX}
            ofsY={-Style.WidgetOffsetFromTable}
            distanceToSibling={distance}
            onClick={onClick}
          />
        )}
        {col2 != null && col2 != col1 && (
          <Button
            key={`plus-col-${col2}`}
            x={xs[col2] * scaleX}
            ofsY={-Style.WidgetOffsetFromTable}
            distanceToSibling={distance}
            onClick={onClick}
          />
        )}
      </RenderChildrenIf>
      <RenderChildrenIf
        name={`table-widgets-on-rows`}
        x={x}
        y={y}
        shouldRenderTest={(node) => {
          const scale = node.getStage()?.scaleX() || 0;
          return (scale * height) / window.innerHeight > 0.1;
        }}
      >
        {/* Handle for selecting and dragging row */}
        {row != null && row >= 0 && row < ys.length - 1 && (
          <DragHandle
            y={((ys[row] + ys[row + 1]) * scaleY) / 2}
            ofsX={-Style.WidgetOffsetFromTable}
            onClick={(e) => dispatch(actions.selectRow({ row, toggle: e.evt.shiftKey }))}
            onDragStart={(e) => {
              dispatch(actions.selectRow({ row, toggle: false }));
              if (!startDragRow(row)) {
                e.target.stopDrag();
              }
            }}
            mode="vertical"
            trackSize={distance2}
          />
        )}

        {/* Buttons left of the table, for inserting new rows between */}
        {row1 != null && row1 > 0 && (
          <Button
            key={`plus-row-${row1}`}
            y={ys[row1] * scaleY}
            ofsX={-Style.WidgetOffsetFromTable}
            distanceToSibling={distance2}
            onClick={onClick}
          />
        )}
        {row2 != null && row2 != row1 && (
          <Button
            key={`plus-row-${row2}`}
            y={ys[row2] * scaleY}
            ofsX={-Style.WidgetOffsetFromTable}
            distanceToSibling={distance2}
            onClick={onClick}
          />
        )}
      </RenderChildrenIf>
    </>
  );
}
