import { getPathPrefix } from "frontend/utils/getPathPrefix";
import { TipName } from "shared/datamodel/schemas";
import { HomeIcon } from "frontend/icons/home";
import React from "react";
import { MyCanvasesIcon } from "frontend/icons/my-canvases";
import { SharedWithMeIcon } from "frontend/icons/shared-with-me";
import { TeamsIcon } from "frontend/icons/teams";
import { InviteIcon } from "frontend/icons/invite";

export enum WalkthroughTipType {
  Canvas = "canvas",
  HomePage = "home-page",
}

export type WalkthroughTipModel = {
  id: TipName;
  titles: string[];
  texts: string[];
  image?: string;
  buttonTitle?: string;
  video?: string;
  icons?: React.ReactNode[];
  type?: WalkthroughTipType;
};

export const walkthroughTips = {
  [TipName.walkthroughExploreOrgChart]: {
    id: TipName.walkthroughExploreOrgChart,
    titles: ["Get started with org charts"],
    texts: ["Check out this step-by-step guide to create an org chart tailored to your needs"],
    image: getPathPrefix("/images/walkthrough/org-chart.png"),
    video: "UhUeyqMYNok",
  },
  [TipName.walkthroughInviteMembers]: {
    id: TipName.walkthroughInviteMembers,
    titles: ["Invite your teammates"],
    texts: ["Invite team members to your canvas via email or by copying and sharing the link."],
    image: getPathPrefix("/images/walkthrough/invite-teammates.png"),
  },
  [TipName.walkthroughAddTemplate]: {
    id: TipName.walkthroughAddTemplate,
    titles: ["Add a template"],
    texts: ["Explore and choose the perfect template for your workflow, get started in seconds."],
    image: getPathPrefix("/images/walkthrough/add-template.png"),
  },
  [TipName.walkthroughAddElement]: {
    id: TipName.walkthroughAddElement,
    titles: ["Use the tool kit"],
    texts: ["Add  shapes, connectors, text, sticky notes & sketches. Change colors and sizes to fit your needs."],
    image: getPathPrefix("/images/walkthrough/add-elements.png"),
  },
  [TipName.walkthroughDropComment]: {
    id: TipName.walkthroughDropComment,
    titles: ["Drop a comment"],
    texts: [
      "Comment on anything, @mention your teammates and respond to feedback. Manage your comments from the side panel.",
    ],
    image: getPathPrefix("/images/walkthrough/add-comment.png"),
  },
  [TipName.walkthroughCreateFrame]: {
    id: TipName.walkthroughCreateFrame,
    titles: ["Create a frame"],
    texts: [
      "Draw a frame to create structure to your canvas's content and navigate through the frame list. Manage your frames from the side panel.",
    ],
    image: getPathPrefix("/images/walkthrough/add-frame.png"),
  },
  [TipName.walkthroughAddTask]: {
    id: TipName.walkthroughAddTask,
    titles: ["Add a task"],
    texts: ["Expand to edit the title, add a description, tags, assignee and due date."],
    image: getPathPrefix("/images/walkthrough/add-task.png"),
  },
  [TipName.walkthroughUploadImage]: {
    id: TipName.walkthroughUploadImage,
    titles: ["Upload an image"],
    texts: ["Browse your files and upload or drag & drop an image on your canvas."],
    image: getPathPrefix("/images/walkthrough/add-image.png"),
  },
  [TipName.walkthroughPresentationMode]: {
    id: TipName.walkthroughPresentationMode,
    titles: ["Present your work"],
    texts: ["Showcase your work in a slideshow, navigate through frames you created."],
    image: getPathPrefix("/images/walkthrough/presentation.png"),
  },
  [TipName.walkthroughExportCanvas]: {
    id: TipName.walkthroughExportCanvas,
    titles: ["Export your canvas"],
    texts: ["Choose a file format and export your entire canvas as an image."],
    image: getPathPrefix("/images/walkthrough/export.png"),
  },
};

export const newHomePageWalkthroughTips = {
  [TipName.homePageRefactor]: {
    id: TipName.homePageRefactor,
    titles: ["We've updated our homepage!"],
    texts: ["Take a moment to explore the new design and features."],
    image: getPathPrefix("/images/walkthrough/home-page-refactor.png"),
  },
  [TipName.walkthroughHomePageSections]: {
    id: TipName.walkthroughHomePageSections,
    titles: ["Home", "My Canvases", "Shared with me"],
    texts: [
      "Recently opened canvases, including private and shared ones.",
      "All canvases you’ve created.",
      "Canvases shared with you via email or accessed through a public link.",
    ],
    icons: [
      <HomeIcon key={"home"} stroke={"white"} />,
      <MyCanvasesIcon key={"my-canvases"} stroke={"white"} />,
      <SharedWithMeIcon key={"shared-with-me"} stroke={"white"} />,
    ],
    buttonTitle: "Next",
  },
  [TipName.walkthroughTeamsAndProjects]: {
    id: TipName.walkthroughTeamsAndProjects,
    titles: ["Teams & Projects"],
    texts: ["Organize your canvas into a clear hierarchy for easy access management."],
    icons: [<TeamsIcon key={"teams"} stroke={"white"} />],
    buttonTitle: "Next",
  },
  [TipName.walkthroughInviteToAccount]: {
    id: TipName.walkthroughInviteToAccount,
    titles: ["Invite to account"],
    texts: ["Add teammates by inviting them via email."],
    icons: [<TeamsIcon key={"teams"} stroke={"white"} />],
    buttonTitle: "Let's go!",
  },
  [TipName.walkthroughInviteInShareCanvas]: {
    id: TipName.walkthroughInviteInShareCanvas,
    titles: ["Invite people"],
    texts: ["Here, you can invite new users to this specific canvas"],
    icons: [<InviteIcon key={"invite"} stroke={"white"} />],
    buttonTitle: "Got it",
  },
};

export type WalkthroughTipName = keyof typeof walkthroughTips | keyof typeof newHomePageWalkthroughTips;

export const walkthroughTipNames = Object.keys(walkthroughTips) as WalkthroughTipName[];
export const newHomePageWalkthroughTipNames = Object.keys(newHomePageWalkthroughTips) as WalkthroughTipName[];
export const walkthroughTipsOrder: { [tip in WalkthroughTipName]: number } = walkthroughTipNames.reduce(
  (acc, tip, index) => {
    acc[tip] = index;
    return acc;
  },
  {} as { [tip in WalkthroughTipName]: number }
);

export const newHomePageWalkthroughTipsOrder: { [tip in WalkthroughTipName]: number } =
  newHomePageWalkthroughTipNames.reduce((acc, tip, index) => {
    acc[tip] = index;
    return acc;
  }, {} as { [tip in WalkthroughTipName]: number });
