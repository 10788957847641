import { differenceInMilliseconds } from "date-fns";
import type { IGanttController } from "elements/gantt/controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { combineMappingWithData } from "elements/gantt/controllers/controller-utils";
import { useColumnConfiguration } from "elements/gantt/hooks/use-column-configuration";
import { hasDateConfigInMetadata } from "elements/gantt/utils";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import { MondayItem } from "frontend/hooks/use-board-integrations.types";
import { equals, groupBy, isEmpty } from "rambda";
import { useEffect, useRef, useState } from "react";
import { IntegrationItem } from "shared/datamodel/schemas";
import { canvasMetadataPrefix } from "shared/util/utils";
import { useRawCanvasMetadata } from "subscriptions";

export const useGanttSyncMondayItems = ({ controller }: { controller: IGanttController }) => {
  const metadata = useRawCanvasMetadata(controller.context.reflect);
  const columnConfiguration = useColumnConfiguration({
    reflect: controller.context.reflect,
  });
  const [mondayTasks, setMondayTasks] = useState<{ [index: string]: IGanttBaseCellController<IntegrationItem>[] }>({});
  const pusherChannel = controller.context.pusherChannel;
  const { dataSource, loadingBoardIntegrationsState, fetchItems, getItem } = useBoardTasks(
    controller.context.documentId,
    pusherChannel
  );
  const fetchLoadingItemsRef = useRef(new Set<string>());

  useEffect(() => {
    const setTasks = () => {
      const tasks = controller
        .getTaskCells()
        .filter((t) => t.element.type === "integrationItem") as IGanttBaseCellController<IntegrationItem>[];

      const tasksByIntegratinId = groupBy(
        (task: IGanttBaseCellController<IntegrationItem>) => task.element.integrationId
      )(tasks);
      if (!equals(tasksByIntegratinId, mondayTasks) && !isEmpty(tasksByIntegratinId)) {
        setMondayTasks(tasksByIntegratinId);
      }
    };
    controller.subscribe(setTasks);

    return () => controller.unsubscribe(setTasks);
  }, [controller, mondayTasks]);

  useEffect(() => {
    if (!isEmpty(dataSource) && !isEmpty(mondayTasks)) {
      for (const [integrationId, { items, boardId }] of Object.entries(dataSource)) {
        const integrationMetadata = metadata.find(([id]) => id === `${canvasMetadataPrefix}${boardId}`);
        const tasks = mondayTasks[integrationId] ?? [];
        for (const task of tasks) {
          const item = items.find((item) => item.id === task.element.configuration?.itemId);

          // Early returns for cases where we can't proceed
          if (!item) continue;

          // Handle case with item but no integration metadata
          if (!integrationMetadata) {
            patchTitleOnly(controller, task.id, item.name);
            continue;
          }

          const taskConfig = integrationMetadata[1].boardColumnsMapping;
          if (!taskConfig) {
            task.updateIntegrationConfig?.();
            continue;
          }

          const newTaskData = combineMappingWithData(
            { mapping: taskConfig as Record<string, string>, shouldApplyForAll: false },
            item
          );

          // Handle initial loading state
          if (task.isInitialLoading?.()) {
            updateTaskForInitialLoad(controller, task.id, newTaskData);
          } else {
            patchTitleOnly(controller, task.id, newTaskData.title);
          }

          // Handle integration config updates
          updateIntegrationConfiguration(task, taskConfig, newTaskData, item, controller);
        }
      }
      controller.notify();
    }
  }, [
    controller.getTaskCells().length,
    JSON.stringify(dataSource),
    mondayTasks,
    loadingBoardIntegrationsState,
    metadata,
  ]);

  // this function is called when the task is *not* updated by the pusher
  const updateTask = async (taskId: string) => {
    const task = controller.getTaskCells().find((t) => t.id === taskId);
    if (task?.element.type !== "integrationItem") {
      return;
    }
    const newData = await fetchItems({
      [task.element.integrationId]: {
        itemIds: [task.element.configuration.itemId],
      },
    });
    if (!newData) {
      return;
    }
    const item = newData[task.element.integrationId].items?.[0] as MondayItem;
    if (!item) {
      return;
    }
    const config = task.getConfiguration?.();
    if (!config) {
      return;
    }
    const newTaskData = combineMappingWithData(
      {
        mapping: {
          dependency: config.dependencyId,
          end_date: config.endId,
          start_date: config.startId,
          timeline: config.timelineId,
        } as Record<string, string>,
        shouldApplyForAll: false,
      },
      item
    );
    if (!newTaskData) {
      return;
    }

    const currentStartDate = task.getStartDate();
    const currentEndDate = task.getEndDate();
    if (newTaskData && (newTaskData.startDate !== currentStartDate || newTaskData.endDate !== currentEndDate)) {
      controller.context.undoRedoStack.patchElement(taskId, (draft: IntegrationItem) => {
        draft.fieldValues ??= {};
        draft.fieldValues["title"] = newTaskData.title;
        if (!Number.isNaN(newTaskData.startDate)) {
          draft.fieldValues["fromDate"] = newTaskData.startDate;
        }
        if (!Number.isNaN(newTaskData.endDate)) {
          draft.fieldValues["toDate"] = newTaskData.endDate;
        }
      });
    }
    fetchLoadingItemsRef.current.delete(taskId);
  };

  const getIntegrationTaskDates = (task: IntegrationItem) => {
    if (columnConfiguration.has(task.integrationId)) {
      const item = getItem(task.configuration.itemId, task.integrationId);
      const config = columnConfiguration.get(task.integrationId);
      if (!config || !item) {
        return null;
      }

      const newTaskData = combineMappingWithData(
        {
          mapping: {
            dependency: config.dependency,
            end_date: config.end_date,
            start_date: config.start_date,
            timeline: config.timeline,
          } as Record<string, string>,
          shouldApplyForAll: false,
        },
        item
      );
      if (newTaskData) {
        return {
          startDate: newTaskData.startDate,
          endDate: newTaskData.endDate,
        };
      }
    }
    return null;
  };

  return { updateTask, getIntegrationTaskDates };
};

// Helper functions (add these outside the main function)
function patchTitleOnly(controller: IGanttController, taskId: string, title: string) {
  controller.context.undoRedoStack.patchElement(taskId, (draft: IntegrationItem) => {
    draft.fieldValues ??= {};
    draft.fieldValues["title"] = title;
  });
}

function updateTaskForInitialLoad(controller: IGanttController, taskId: string, newTaskData: any) {
  controller.context.undoRedoStack.patchElement(taskId, (draft: IntegrationItem) => {
    const hasJustBeenPlaced = differenceInMilliseconds(draft.fieldValues?.placedAt, Date.now()) < 100;
    draft.fieldValues ??= {};

    if (
      newTaskData.startDate &&
      newTaskData.endDate &&
      newTaskData.startDate <= newTaskData.endDate &&
      !hasJustBeenPlaced
    ) {
      if (!Number.isNaN(newTaskData.startDate)) {
        draft.fieldValues["fromDate"] = newTaskData.startDate;
      }
      if (!Number.isNaN(newTaskData.endDate)) {
        draft.fieldValues["toDate"] = newTaskData.endDate;
      }
    }
    delete draft.fieldValues["placedAt"];
    draft.fieldValues["title"] = newTaskData.title;
  });
}

function updateIntegrationConfiguration(
  task: IGanttBaseCellController<IntegrationItem>,
  taskConfig: any,
  newTaskData: any,
  item: any,
  controller: IGanttController
) {
  const oldConfig = task.getConfiguration?.();
  if (!oldConfig) {
    // task.updateIntegrationConfig?.();
    return;
  }

  const { dependencyId, endId, startId, timelineId } = oldConfig;
  const hasDateConfig = hasDateConfigInMetadata(taskConfig, {
    timeline: controller.context.allFeatureFlags?.["gantt-monday-timeline-column"],
  });

  if (!hasDateConfig) {
    task.updateIntegrationConfig?.();
    return;
  }

  const configChanged =
    dependencyId != taskConfig.dependency ||
    endId != taskConfig.end_date ||
    startId != taskConfig.start_date ||
    timelineId != taskConfig.timeline;

  if (configChanged) {
    task.updateIntegrationConfig?.({
      dependencies: newTaskData.dependencies,
      url: item.url || "",
      dependencyId: taskConfig.dependency,
      endId: taskConfig.end_date,
      startId: taskConfig.start_date,
      timelineId: taskConfig.timeline,
    });

    if (!task.isInitialLoading?.()) {
      task.updateIntegration?.("monday");
    }
  } else {
    task.updateIntegrationConfig?.();
  }
}
