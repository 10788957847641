import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import Konva from "konva";
import { Rect, Text } from "react-konva";
import consts from "shared/consts";
import { TextBlock } from "shared/datamodel/schemas/textBlock";
import { konvaTextDecoration } from "shared/util/utils";
import { FontProperties } from "shared/datamodel/schemas/text-enabled-schema";
import BaseCanvasElement from "./base-canvas-element";
import { Point } from "frontend/utils/math-utils";
import { transformerRefAtom } from "state-atoms/general-atoms";
import { Trait } from "../elements-toolbar/elements-toolbar-types";
import { correctWidthForAspectRatio, FreeSizeTextEditor, getFontSize, calcKonvaTextConfig } from "../text-element";
import { SelectedElementOutlineWidth, SelectedElementOutline, SelectedLockedElementOutline } from "../frontend-consts";
import { isExportingAtom } from "state-atoms";
import { TransformHooksText } from "frontend/hooks/use-transform-hooks";
import { DerivedIntegrationDataHandler, useDerivedData } from "frontend/hooks/use-derived-data";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";

export function TextBlockCanvasElement({
  id,
  element,
  isSelectable,
  drawOutlineAroundElements,
  isEditing,
  isEditingLink,
  onResize,
  changeElement,
}: {
  id: string;
  element: TextBlock;
  isSelectable: boolean;
  drawOutlineAroundElements: boolean;
  isEditing: boolean;
  isEditingLink: boolean;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
  changeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    updateSubMenuData?: any //take the last change and make it the default when selecting this element again from the menu (e.g. make the default color the last color selected)
  ) => void;
}) {
  const transformerRef = useAtomValue(transformerRefAtom);
  const isExporting = useAtomValue(isExportingAtom);
  const isDataLayerEnabled = useFeatureFlag("element-data-layer");

  const textRef = useRef<any>();
  const {
    scaleX = 1,
    scaleY = 1,
    width,
    fontProps,
    fontSize,
    textColor = "black",
    font = consts.DEFAULTS.FONT,
  } = element;

  const {
    values: derivedValues,
    integrations: derivedIntegrations,
    keys: derivedKeys,
  } = useDerivedData(element, isDataLayerEnabled);

  const [integrationText, setIntegrationText] = useState<string | null>(null);
  let text = element.text;
  let placeholder: string | undefined;
  // If the text is derived, we don't want to allow editing
  if (derivedKeys.includes("text")) {
    text = integrationText ?? derivedValues.text ?? "";
    isEditing = false;
    placeholder = "";
  }

  const fontSizePixels = getFontSize(element);
  const mutation = useMemo(() => new TransformHooksText(id, onResize, fontSizePixels), [id, fontSizePixels]);

  let { width: correctedWidth, scaleX: correctedScaleX } = correctWidthForAspectRatio(width, scaleX, scaleY);
  correctedWidth = Math.max(correctedWidth, fontSizePixels);

  useEffect(() => {
    if (textRef.current) {
      const height = textRef.current.height();
      if (height != element.height) {
        changeElement({ height }, { shouldAdd: false });
        transformerRef?.current?.forceUpdate();
      }
      textRef.current.getSelfRect = () => ({ x: 0, y: 0, width: 0, height: 0 });
    }
  }, [text, element.height, correctedWidth, fontSize, fontProps]);

  const memoedElement = useDeepEqualMemo(element);
  const textConfig = useMemo(
    () => calcKonvaTextConfig({ element: { ...element, text }, placeholder }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, memoedElement, placeholder]
  );

  const computeHeight = useCallback(() => {
    const textNode = new Konva.Text(textConfig);
    return textNode.height();
  }, [textConfig]);

  const htmlTextProps: CSSProperties = {
    fontFamily: font,
    lineHeight: consts.LINE_HEIGHT,
    fontWeight: element.fontProps & FontProperties.Bold ? "bold" : "normal",
    color: textColor,
    textDecoration: konvaTextDecoration(fontProps),
    fontStyle: element.fontProps & FontProperties.Italic ? "italic" : "normal",
    textAlign: element.align ?? "center",
    padding: 0,
    width: correctedWidth,
    outline: "none",
  };

  const renderText = () => {
    if (isEditing) {
      return (
        <FreeSizeTextEditor
          absX={element.x}
          absY={element.y}
          scale={scaleY}
          rotation={element.rotate}
          fontSize={fontSizePixels}
          width={correctedWidth}
          initialValue={text}
          updateText={(initial: string, text: string) => {
            changeElement({ text }, { shouldAdd: true, previousProps: { text: initial } });
            setTimeout(() => transformerRef && transformerRef.current && transformerRef.current.forceUpdate());
          }}
          updateTextSize={(_, height: number) => {
            height /= scaleY;
            changeElement({ height }, { shouldAdd: false });
            setTimeout(() => transformerRef && transformerRef.current && transformerRef.current.forceUpdate());
          }}
          textProps={htmlTextProps}
          positionType="top-left"
        />
      );
    }
    return (
      <Text
        ref={textRef}
        listening={false}
        width={correctedWidth}
        scaleX={correctedScaleX}
        opacity={text ? 1 : 0.3}
        {...textConfig}
      />
    );
  };

  return (
    <BaseCanvasElement
      id={id}
      type="textBlock"
      x={element.x}
      y={element.y}
      element={element}
      isSelectable={isSelectable}
      isEditingLink={isEditingLink}
      onResize={onResize}
      mutation={mutation}
      changeElement={changeElement}
    >
      {Object.keys(derivedIntegrations).length > 0 && (
        <DerivedIntegrationDataHandler
          integrations={derivedIntegrations}
          onChangeValue={(values) => {
            if (values.text) {
              setIntegrationText(values.text);
            }
          }}
        />
      )}
      <Rect
        height={element.height ?? computeHeight()}
        width={correctedWidth}
        scaleX={correctedScaleX}
        strokeEnabled={drawOutlineAroundElements && !isExporting && !element.groupId}
        strokeWidth={SelectedElementOutlineWidth}
        stroke={element.lock ? SelectedLockedElementOutline : SelectedElementOutline}
        strokeScaleEnabled={false}
      />
      {renderText()}
    </BaseCanvasElement>
  );
}

export function textEnabledTraits(element: {
  align?: string;
  textColor?: string;
  fontProps?: number;
  font?: string;
  fontSize?: number | string;
}) {
  return {
    align: element.align ?? consts.DEFAULTS.TEXT_ALIGN,
    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: getFontSize(element),
    font: element.font ?? consts.DEFAULTS.FONT,
  };
}

export function textBlockTraits(element: TextBlock) {
  let fs = getFontSize(element);
  if (element.scaleY) fs *= element.scaleY;
  return {
    align: element.align ?? consts.DEFAULTS.TEXT_ALIGN,
    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: fs,
    font: element.font ?? consts.DEFAULTS.FONT,
  };
}

export function textBlockValidateTrait(element: TextBlock, trait: Trait, value: any) {
  switch (trait) {
    case Trait.fontSize: {
      return (value as number) / (element.scaleY ?? 1);
    }
    default: {
      return value;
    }
  }
}
