import { KonvaEventObject } from "konva/types/Node";
import { Button as Style } from "../table-styling";
import { MouseCursor } from "./mouse-cursor-util";

export function onHoverOn(e: KonvaEventObject<MouseEvent>) {
  MouseCursor.setPointer(e);
  e.currentTarget.to({ fill: Style.HoverFill, stroke: Style.HoverStroke, duration: 0.1 });
}

export function onHoverOff(e: KonvaEventObject<MouseEvent>) {
  MouseCursor.unset(e);
  e.currentTarget.to({ fill: Style.Fill, stroke: Style.Stroke, duration: 0.1 });
}
