import { GuiElement, useGuiSystem } from "frontend/canvas-gui-system";
import { useEffect } from "react";

export function RegisterHitAreaForMouseMove({
  id,
  x,
  y,
  width,
  height,
  setMouseOver,
}: {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  setMouseOver: (on: boolean) => void;
}) {
  const gui = useGuiSystem();
  useEffect(() => {
    if (gui) {
      const elem = new GuiElement(id, { x, y, width, height });
      elem.paddingPx = 50;
      elem.onMouseEnter = () => setMouseOver(true);
      elem.onMouseLeave = () => setMouseOver(false);
      const disconnect = gui(elem);
      return disconnect;
    }
  }, [gui, x, y, width, height, setMouseOver]);
  // Return null, because this component exists just to register the table in the gui-system
  return null;
}
