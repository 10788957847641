import { ElementController } from "elements/base/controller";
import { CanvasElementLinkBadge } from "frontend/canvas-designer-new/canvas-element-link-badge";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import { Html } from "react-konva-utils";

export const ElementLink = ({
  controller,
  isEditingLink,
  link,
}: {
  controller?: ElementController<any>;
  isEditingLink: boolean;
  link: string;
}) => {
  if (!controller) {
    return null;
  }
  const { width, height } = controller.getLayoutRect();

  return (
    <>
      {link.length > 0 && (
        <CanvasElementLinkBadge
          url={link}
          scaleX={controller.element.scaleX ?? 1}
          scaleY={controller.element.scaleY ?? 1}
          x={width ?? 0}
          y={height ?? 0}
        />
      )}
      {isEditingLink && (
        <Html>
          <Modal dimBackground={true}>
            <EditElementLinkModal
              element={controller.element}
              onChangeLink={(_, link) =>
                controller.patchElement(controller.id, (draft: any) => {
                  draft.link = link;
                })
              }
            />
          </Modal>
        </Html>
      )}
    </>
  );
};
