import { TasksColors } from "elements/gantt/constants";
import { MondayColumn } from "frontend/hooks/use-board-integrations.types";
import { getDropDownOptions, getStatusesV2 } from "utils/monday-utils";

const SUPPORTED_COLUMNS = ["status"];

type OptionsType = {
  id: string;
  title: string;
  description: string;
  color: string;
}[];

const formatters: Record<string, (c: MondayColumn) => OptionsType> = {
  status: (column: MondayColumn) => {
    return getStatusesV2(column);
  },
  dropdown: (column: MondayColumn) => {
    const settings = getDropDownOptions(column);
    return settings.map((option: any, i) => {
      const wrappedIndex = ((i % TasksColors.length) + TasksColors.length) % TasksColors.length;

      return {
        ...option,
        color: TasksColors[wrappedIndex],
      };
    });
  },
};

export const formatColumnOptions = (columns: MondayColumn) => {
  if (columns.type in formatters) {
    const formatter = formatters[columns.type];
    return formatter(columns);
  }
  return [];
};

export const filterSupportedColumns = (columns: MondayColumn[]) => {
  const columnsSettings = new Map<
    string,
    {
      id: string;
      title: string;
      type: string;
      description: string;
    }
  >();
  columns.forEach((column) => {
    if (SUPPORTED_COLUMNS.includes(column.type)) {
      columnsSettings.set(column.id, {
        id: column.id,
        title: column.title,
        description: column.description ?? "",
        type: "monday",
      });
    }
  });

  return columnsSettings;
};
