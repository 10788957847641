import { Rect } from "react-konva";
import { Frame } from "shared/datamodel/schemas/frame";

interface FrameBackgroundProps {
  frame: Frame;
  uniqueId: string;
}

export default function FrameBackground({ frame, uniqueId }: FrameBackgroundProps) {
  const { x, y, scaleX = 1, scaleY = 1, width, height, fill, visible = true } = frame;

  if (!fill || !visible) {
    return null;
  }

  return (
    <Rect
      element={{ frameId: uniqueId }}
      x={x}
      y={y}
      width={width * scaleX}
      height={height * scaleY}
      fill={fill}
      cornerRadius={4}
    />
  );
}
