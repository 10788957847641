import { BoardContext } from "elements/index";
import type { Reflect } from "@workcanvas/reflect/client";
import type { M } from "shared/datamodel/mutators";

export const createMockReflect = (): Reflect<M> => {
  return {
    subscribe: (() => {}) as unknown as Reflect<M>["subscribe"],
    unsubscribe: () => {},
    get: () => {},
    set: () => {},
    delete: () => {},
    apply: () => {},
    batch: () => {},
  } as any;
};

export const createEmptyContext = ({ reflectOverrides }: { reflectOverrides?: Reflect<M> } = {}): BoardContext => {
  return {
    boardId: 0,
    documentId: "",
    isReadOnly: true,
    reflect: reflectOverrides as Reflect<M>,
    selectedElementIds: [],
    setSelectedIds: () => {},
    setMovingElements: () => {},
    hiddenElementIds: [],
    editingElementId: null,
    editingElementLinkId: null,
    undoRedoStack: {
      addAction: () => {},
      undo: () => {},
      redo: () => {},
      startSequence: () => {},
      endSequence: () => {},
      patchElement: () => {},
    } as any,
  };
};
