import { useEffect, useState } from "react";
import { Context } from "konva/types/Context";
import { Group, Circle, Image } from "react-konva";
import { getReadSignedUrl } from "frontend/api";
import { PictureRadius } from "./orgchart-element";
import { useAtomValue } from "jotai";
import { syncServiceAtom } from "state-atoms";
import { ReadTransaction } from "@workcanvas/reflect";

type ImageState = {
  status: "loading" | "loaded" | "error" | "error getting read-signed-url";
  img?: HTMLImageElement;
};

export function OrgchartPersonPhoto({
  x,
  y,
  photoUrl,
  onClick,
}: {
  x: number;
  y: number;
  photoUrl?: string;
  onClick?: () => void;
}) {
  const [img, setImg] = useState<ImageState>({ status: "loading" });

  const syncService = useAtomValue(syncServiceAtom);
  const reps = syncService?.getReplicache();

  const onerror = () => setImg({ status: "error" });

  // TODO: the read-signed-urls can be cached globally (per-canvas),
  // and refetched only if they expire
  useEffect(() => {
    if (!photoUrl || !reps) return;

    (photoUrl.includes("/")
      ? getReadSignedUrl(photoUrl)
      : reps
          .query(async (tx: ReadTransaction) => tx.get(`cMetadata-File-${photoUrl}`))
          .then((value: { url: string }) => getReadSignedUrl(value.url))
    )
      .then(({ url }: { url: string }) => {
        const img = document.createElement("img");

        const onload = () => setImg({ status: "loaded", img });
        img.addEventListener("load", onload);
        img.addEventListener("error", onerror);
        img.crossOrigin = "anonymous";
        img.width = 40;
        img.height = 40;
        img.alt = "user photo";
        img.src = url;
      })
      .catch((error: unknown) => {
        setImg({ status: "error getting read-signed-url" });
        console.error("failed to load image: can't get read-signed-url", error);
      });
  }, [photoUrl, reps]);

  // remove the image on unload (can images be cached in a better way??)
  useEffect(() => {
    if (img && img.img) return () => img.img?.remove();
  }, [img]);

  return (
    <Group
      name="orgchart-person-photo"
      x={x}
      y={y}
      onMouseDown={(e) => {
        if (!onClick) return;

        e.cancelBubble = true;
        e.evt.preventDefault();
        e.currentTarget.attrs.mousedown = { x: e.evt.offsetX, y: e.evt.offsetY };
      }}
      onMouseUp={(e) => {
        if (!onClick) return;

        e.cancelBubble = true;
        e.evt.preventDefault();
        if (e.currentTarget.attrs.mousedown) {
          const { x, y } = e.currentTarget.attrs.mousedown;
          if ((x - e.evt.offsetX) ** 2 + (y - e.evt.offsetY) ** 2 < 16) {
            onClick();
          }
        }
        e.currentTarget.attrs.mousedown = null;
      }}
      onMouseEnter={(e) => {
        if (!onClick) return;

        const stage = e.target.getStage();
        if (!stage) return;

        stage.container().style.cursor = "pointer";
      }}
      onMouseLeave={(e) => {
        if (!onClick) return;

        const stage = e.target.getStage();
        if (!stage) return;

        stage.container().style.cursor = "default";
      }}
    >
      {/* White ring with shadow */}
      <Circle
        radius={PictureRadius + 2}
        fill="white"
        shadowEnabled={true}
        shadowOffsetX={0}
        shadowOffsetY={4}
        shadowColor="#6F7284"
        shadowOpacity={0.25}
        shadowBlur={4}
        perfectDrawEnabled={false}
      />

      {/* Clipping group for the photo or avatar */}
      <Group
        clipFunc={(context: Context) => {
          context.beginPath();
          context.arc(0, 0, PictureRadius, 0, Math.PI * 2, false);
          context.closePath();
        }}
      >
        {img && img.status === "loaded" ? (
          <Image
            xclip="circle" // used by our pdf-export library
            image={img.img}
            x={-PictureRadius}
            y={-PictureRadius}
            width={PictureRadius * 2}
            height={PictureRadius * 2}
          />
        ) : (
          // Generic avatar
          <>
            <Circle radius={PictureRadius} fill="#F4F7FA" shadowEnabled={false} perfectDrawEnabled={false} />
            <Circle x={0} y={-3.5} radius={8} fill="#DADCE0" shadowEnabled={false} perfectDrawEnabled={false} />
            <Circle x={0} y={19} radius={13} fill="#DADCE0" shadowEnabled={false} perfectDrawEnabled={false} />
          </>
        )}
      </Group>

      {/* Hit area */}
      <Circle radius={PictureRadius + 2} fill="transparent" stroke="transparent" hitStrokeWidth={0} />
    </Group>
  );
}
