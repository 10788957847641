import { BaseElementController } from "elements/base/controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { updateIntegrationItem } from "frontend/api";
import { IntegrationItem } from "shared/datamodel/schemas";
import { cleanDate, extractStartAndEndDate, getRowColor, hasDateConfigInMetadata } from "../utils";
import { getFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { IGanttController } from "elements/gantt/controller";
import ganttConstant, { TaskColor } from "elements/gantt/constants";
import { MultiElementPatch } from "utils/undo-redo/undo-redo-types";
import { uniqWith } from "rambda";

export default class GanttMondayItemCellController
  extends BaseElementController<IntegrationItem>
  implements IGanttBaseCellController<IntegrationItem>
{
  #ganttController: WeakRef<IGanttController> | undefined;

  #startDateColumnId: string | null | undefined = null;
  #dependencyId: string | null | undefined = null;
  #endDateColumnId: string | null | undefined = null;
  #timelineId: string | null | undefined = null;
  #url: string | null | undefined = null;
  #dependencies: string[] | undefined | null = null;
  #isLoading: boolean = false;
  #isInitialLoading: boolean = true;
  private mondayUpdateDebounceTimeout: NodeJS.Timeout | null = null;
  #lastUpdateToMonday: number = Date.now();
  #shouldReflectDependenciesChanges: boolean | undefined;

  #datesChanged: boolean = true;

  async updateIntegrationConfig(config?: {
    dependencyId?: string;
    timelineId?: string;
    startId?: string;
    endId?: string;
    url?: string;
    dependencies?: string[];
    shouldReflectDependenciesChanges?: boolean;
  }) {
    if (!config) {
      this.#isInitialLoading = false;
      this.#startDateColumnId = "";
      this.#endDateColumnId = "";
      this.#dependencyId = "";
      return;
    }
    this.#startDateColumnId = config.startId;
    this.#endDateColumnId = config.endId;
    this.#dependencyId = config.dependencyId;
    this.#dependencies = config.dependencies;
    this.#url = config.url;
    this.#shouldReflectDependenciesChanges = config.shouldReflectDependenciesChanges;
    this.#timelineId = config.timelineId;

    this.handleDependencies(config.dependencies ?? []);
    this.notify();

    this.context.undoRedoStack.subscribe((action) => {
      const newAction = action as { type: "undo" | "redo"; data: MultiElementPatch[] };
      const isThisCellUpdated = newAction && newAction?.data && newAction?.data.find(({ id }) => id === this.id);
      if (isThisCellUpdated) {
        this.#datesChanged = true;
        this.updateMonday(this.element.integrationId, this.element.configuration.itemId);
      }
    });
    this.#isInitialLoading = false;
  }

  updateElement(element: IntegrationItem): void {
    const previousDates = extractStartAndEndDate(this.element);
    const newDates = extractStartAndEndDate(element);
    if (previousDates && newDates) {
      this.#datesChanged = previousDates.fromDate !== newDates.fromDate || previousDates.toDate !== newDates.toDate;
    }

    super.updateElement(element);
    this.notify();

    if (this.#datesChanged) {
      this.updateMonday(this.element.integrationId, this.element.configuration.itemId);
    }
  }

  updateController(ganttController: IGanttController): void {
    this.#ganttController = new WeakRef(ganttController);
    this.notify();
  }

  getLayout() {
    return this.#ganttController?.deref()?.getTaskCellLayout(this.id);
  }

  getTitle() {
    return this.element.fieldValues?.["title"] || "";
  }

  getBackgroundColor() {
    if (this.#ganttController?.deref()?.element.customRows) {
      return this.element.fill as TaskColor;
    }
    return getRowColor(this.element.fill ?? "");
  }

  getGanttStartDate() {
    const cell = this.#ganttController?.deref()?.getDateColumnsLayout()[0];
    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getGanttEndDate() {
    const cell = this.#ganttController
      ?.deref()
      ?.getDateColumnsLayout()
      .filter((cell) => !cell.isHeader)
      .at(-1);

    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getStartDate() {
    return cleanDate(this.element.fieldValues?.["fromDate"] ?? Date.now());
  }

  getEndDate() {
    return cleanDate(this.element.fieldValues?.["toDate"] ?? Date.now());
  }

  changeTitle(newTitle: string) {
    updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      name: newTitle,
    });
  }

  isSelected() {
    return this.#ganttController?.deref()?.context.selectedElementIds.includes(this.id) ?? false;
  }

  getAttachedConnectors() {
    return new Set(
      this.#ganttController
        ?.deref()
        ?.getConnectors()
        .filter((c) => c.to.id === this.id || c.from.id === this.id)
        .flatMap((c) => [c.from.id, c.to.id])
    );
  }

  /**
   * Handles dependencies for the current task, updating connectors in the Gantt chart.
   *
   * This function ensures that connectors in the Gantt chart are consistent with the provided
   * dependencies. It identifies new dependencies that need to be added and old ones that need
   * to be removed, then performs the necessary updates.
   *
   * @param dependencies - An array of item IDs representing the dependencies for the current task.
   */
  async handleDependencies(dependencies: string[]) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }
    // Collect all connectors currently attached to this task
    const connectorsAttached = this.getAttachedConnectors();

    // Maps for converting between item IDs and task IDs
    const idsMappings = this.#ganttController?.deref()?.getIdsMappings();
    if (!idsMappings) {
      return;
    }
    const { itemIdToTaskId, taskIdToItemId } = idsMappings;

    // Identify new and removable connectors
    const dependenciesSet = new Set(dependencies);
    const newConnectors = [...dependenciesSet].filter((itemId) => itemIdToTaskId.has(itemId));
    const removableConnectors = [...connectorsAttached].filter(
      (taskId) => !dependenciesSet.has(taskIdToItemId.get(taskId) || "")
    );

    // Add new connectors
    await Promise.all(
      newConnectors.map(async (itemId) => {
        const taskId = itemIdToTaskId.get(itemId);
        if (taskId && !connectorsAttached.has(taskId)) {
          return this.#ganttController?.deref()?.addConnector(taskId, this.id, "monday");
        }
      })
    );
    this.notify();

    // Remove outdated connectors
    await Promise.all(
      removableConnectors.map((taskId) => {
        const connectors =
          this.#ganttController
            ?.deref()
            ?.getConnectors()
            .filter((c) => c.from.id === taskId && c.to.id === this.id && c.type === "monday") ?? [];
        return Promise.all(connectors.map((c) => this.#ganttController?.deref()?.removeConnector(c.id)));
      })
    );
    this.notify();
  }

  async updateReflectUsingPusher(
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value: string | { linkedPulseIds: { linkedPulseId: number }[] } | { from: string; to: string };
          }[];
          name: string;
        }
      | null
      | undefined
  ) {
    let eventFromDate: string | number | undefined =
      data?.columnValues &&
      data?.columnValues?.find((column) => column.id === this.#startDateColumnId)?.value.toString();
    if (eventFromDate) {
      eventFromDate = new Date(eventFromDate).getTime();
    }

    let eventToDate: string | number | undefined =
      data?.columnValues && data?.columnValues?.find((column) => column.id === this.#endDateColumnId)?.value.toString();
    if (eventToDate) {
      eventToDate = new Date(eventToDate).getTime();
    }

    const eventTimeline: { from: string; to: string } | undefined =
      data?.columnValues &&
      (data?.columnValues?.find((column) => column.id === this.#timelineId)?.value as { from: string; to: string });
    if (eventTimeline) {
      eventFromDate = new Date(eventTimeline.from).getTime();
      eventToDate = new Date(eventTimeline.to).getTime();
    }
    const title = data?.name ?? this.element.fieldValues?.["title"];
    const fromDate = cleanDate(eventFromDate ?? this.element.fieldValues?.["fromDate"]).getTime();
    const toDate = cleanDate(eventToDate ?? this.element.fieldValues?.["toDate"]).getTime();
    const dependenciesDataValue = data?.columnValues?.find((column) => column.id === this.#dependencyId)?.value;

    const dependencies: string[] =
      typeof dependenciesDataValue === "object" && "linkedPulseIds" in dependenciesDataValue
        ? (dependenciesDataValue?.linkedPulseIds ?? []).map((d: { linkedPulseId: number }) =>
            d.linkedPulseId.toString()
          )
        : [];

    if (data) {
      await this.patchElement(this.id, (draft: IntegrationItem) => {
        draft.fieldValues ??= {};
        draft.fieldValues["title"] = title;

        if (fromDate <= toDate) {
          draft.fieldValues["fromDate"] = fromDate;
          draft.fieldValues["toDate"] = toDate;
        }

        dependenciesDataValue && this.handleDependencies(dependencies);
      });
    }
  }

  async updateIntegration(
    updateUsing: "pusher" | "monday",
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value:
              | string
              | {
                  from: string;
                  to: string;
                }
              | { linkedPulseIds: { linkedPulseId: number }[] };
          }[];
          name: string;
        }
      | null
      | undefined
  ): Promise<{
    didUpdate: boolean;
  } | void> {
    this.#isLoading = true;

    if (updateUsing === "pusher") {
      const differenceInSeconds = Math.abs(this.#lastUpdateToMonday - Date.now()) / 1000;
      if (differenceInSeconds > ganttConstant.MondayIntegrationSyncIntervalSeconds) {
        await this.updateReflectUsingPusher(data);
        return { didUpdate: true };
      }
      return { didUpdate: false };
    }

    if (updateUsing === "monday") {
      await this.updateMonday(this.element.integrationId, this.element.configuration.itemId);
      return { didUpdate: true };
    }

    this.#isLoading = false;
    return { didUpdate: false };
  }

  async updateMonday(integrationId: string, itemId: string) {
    const hasDateConfig = hasDateConfigInMetadata(
      {
        dependency: this.#dependencyId,
        start_date: this.#startDateColumnId,
        end_date: this.#endDateColumnId,
        timeline: this.#timelineId,
      },
      {
        timeline: this.context.allFeatureFlags?.["gantt-monday-timeline-column"],
      }
    );
    if (!this.#datesChanged && !this.#isInitialLoading && hasDateConfig) {
      this.#isLoading = false;
      return;
    }

    if (this.#isInitialLoading) {
      this.#isInitialLoading = false;
    }
    const payload: Record<string, any> = {};

    const fromDate = this.element.fieldValues?.["fromDate"];
    const toDate = this.element.fieldValues?.["toDate"];
    if (this.#timelineId && fromDate && toDate) {
      payload[this.#timelineId] = {
        from: new Date(fromDate).toLocaleDateString("en-CA"),
        to: new Date(toDate).toLocaleDateString("en-CA"),
      };
    } else {
      if (fromDate && this.#startDateColumnId) {
        payload[this.#startDateColumnId] = new Date(fromDate).toLocaleDateString("en-CA");
      }

      if (toDate && this.#endDateColumnId) {
        payload[this.#endDateColumnId] = new Date(toDate).toLocaleDateString("en-CA");
      }
    }

    if (Object.keys(payload).length === 0) {
      this.#isLoading = false;
      return;
    }

    if (this.mondayUpdateDebounceTimeout) {
      clearTimeout(this.mondayUpdateDebounceTimeout);
    }

    this.mondayUpdateDebounceTimeout = setTimeout(async () => {
      this.#isLoading = true;
      this.#lastUpdateToMonday = Date.now();
      await updateIntegrationItem(integrationId, itemId, payload);
      this.#isLoading = false;
    }, 1000);
  }

  isLoading(): boolean {
    return this.#isLoading || this.#isInitialLoading;
  }

  isInitialLoading(): boolean {
    return this.#isInitialLoading;
  }

  getLink() {
    return this.#url || "";
  }

  isIntegrated(): boolean {
    return this.#startDateColumnId !== null && this.#endDateColumnId !== null && this.#dependencyId !== null;
  }

  async addMondayIntegrationConnector(itemId: string | number) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }
    if (!this.#dependencyId) {
      return;
    }

    const previousDependencies = this.#dependencies ?? [];
    const dependencies = uniqWith(
      (a, b) => a.linkedPulseId === b.linkedPulseId,
      [
        ...previousDependencies.map((id) => {
          return { linkedPulseId: id };
        }),
        { linkedPulseId: itemId },
      ]
    );

    await updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      [this.#dependencyId]: { linkedPulseIds: dependencies },
    });
    this.notify();
  }

  async removeMondayIntegrationConnector(itemId: string | number) {
    const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
    if (!shouldHandleDependencies) {
      return;
    }

    if (!this.#dependencyId) {
      return;
    }

    const previousDependencies = this.#dependencies ?? [];
    const dependencies = previousDependencies
      .filter((id) => id !== itemId)
      .map((id) => {
        return { linkedPulseId: id };
      });

    await updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      [this.#dependencyId]: { linkedPulseIds: dependencies },
    });
    this.notify();
  }

  getConfiguration() {
    return {
      dependencyId: this.#dependencyId,
      startId: this.#startDateColumnId,
      endId: this.#endDateColumnId,
      timelineId: this.#timelineId,
    };
  }
}
