import React, { CSSProperties, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useThrottle, useUnmount } from "react-use";

import Konva from "konva";
import { Group, Rect, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { useAtomValue, useSetAtom } from "jotai";

import { enablePatches, produceWithPatches } from "immer";
import { useImmerReducer } from "use-immer";

import { replaceColorOpacity } from "frontend/utils/color-utils";
import { noop, unique } from "frontend/utils/fn-utils";
import { clamp, Point } from "frontend/utils/math-utils";
import { defaultTextStyleForTable, TypeTableCell, TypeTableElement } from "shared/datamodel/schemas/table";
import {
  internalSelectionAtom,
  transformerRefAtom,
  layerRefAtom,
  selectedElementIdsAtom,
  documentIdAtom,
  utilsAtom,
  isAnyKindOfExportAtom,
} from "state-atoms";
import { ITraits, Trait } from "../../elements-toolbar/elements-toolbar-types";

import { EVT_ELEMENT_DROP, EVT_ELEMENT_DRAG_START, EVT_ELEMENT_DRAG } from "../card-stack/card-stack-utils";
import consts from "shared/consts";
import { getElementTypeForId, isOfTypes, replaceGroupsAfterCopy } from "../canvas-elements-utils";
import { handleTabInTextArea } from "../../text-element/text-utils";
import {
  useSubscribeCellsData,
  cellPadding,
  cellNanoid,
  useSubscribeContainedElements,
  fullCellKey,
  TableIds,
  computeCoordinatesOfLines,
  splitKey,
} from "./table-utils";
import * as KeyboardHandler from "./table-keyboard-shortcuts";
import { SyncService } from "frontend/services/syncService";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import { PatchAnything } from "frontend/canvas-designer-new";
import { TransformHooks } from "frontend/hooks/use-transform-hooks";
import BoundingBox from "frontend/geometry/bounding-box";
import * as R from "rambda";
import { getTransformParams, measureNodes } from "frontend/utils/node-utils";
import { TableTitle } from "./table-title";
import {
  getColumnsThatAreFullySelected,
  getRowsThatAreFullySelected,
  initialTableState,
  isSelectionEmpty,
  selectionCells,
  tableReducer,
  TableState,
} from "./table-selection";
import { TableCellsInfo } from "./table-info";
import { useBgJob } from "frontend/hooks/use-bg-worker";
import { LockType } from "shared/datamodel/schemas";
import useAnyEvent from "frontend/hooks/use-any-event";
import {
  canvasElementPrefix,
  createElementId,
  dbKey,
  fontPropertiesToString,
  konvaTextDecoration,
} from "shared/util/utils";
import { textEnabledTraits } from "../text-block-element";
import { ActionHandler, actions, TableAction, ActionHandlers } from "./table-actions";
import type { SinglePatch } from "frontend/canvas-designer-new/index";
import { Corners } from "utils/shape-utils";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import RenderChildrenIf from "./render-children-if";
import { RegisterHitAreaForMouseMove } from "./widgets/gui-system-component";
import { TableWidgets } from "./widgets/widgets";
import * as Style from "./table-styling";
import { TrackResizeComponent, TrackResizingState } from "./resizing-cols-rows/track-resizer";
import { TableTrackLines } from "./resizing-cols-rows/widgets-for-track-resize";
import {
  ResizeContainedListener,
  TableResizeContainedListener,
} from "./resizing-contained-elements/resize-contained-listener";
import { defaultTableCell } from "shared/datamodel/table";

enablePatches();

const tableCorners = new Corners(Style.CornerRadius);

/**
 * Get the corner radius that a cell should have, given the cell's position in the table
 * @param col cell column index
 * @param row cell row index
 * @param numCols number of total columns in table
 * @param numRows number of total rows in table
 * @returns corner-radius object fitting for Konva
 */
function getCornerRadius(
  col: number,
  row: number,
  numCols: number,
  numRows: number
): undefined | [number, number, number, number] {
  //prettier-ignore
  const
        top    = row == 0,
        left   = col == 0,
        right  = col == numCols - 1,
        bottom = row == numRows - 1;
  const anySide = +top + +left + +right + +bottom;
  // cells need to be adjacent to 2 edges to have a corner
  if (anySide < 1) return undefined;
  return tableCorners.get(top, left, right, bottom);
}

function onDropElementsOnTable(cellKeyInReflect: string, cellData: any, ids: string[]) {
  const patch = [];
  const [_, cellPatch, cellInversePatch] = produceWithPatches((draft: any) => {
    const curContained = draft.containedIds ?? [];
    draft.containedIds = unique(curContained.concat(ids));
  })(cellData ?? {});
  patch.push({ id: cellKeyInReflect, patch: cellPatch, inversePatch: cellInversePatch });
  return patch;
}

let tableClipboard: any = null;

const isDroppableOnTable = isOfTypes(
  consts.CANVAS_ELEMENTS.STICKY_NOTE,
  consts.CANVAS_ELEMENTS.TEXT_BLOCK,
  consts.CANVAS_ELEMENTS.DRAWING,
  consts.CANVAS_ELEMENTS.SHAPE,
  consts.CANVAS_ELEMENTS.FILE
);

type OverrideSize = null | Record<string, number>;

/**
 * A function to collect the 'containedIds' arrays from the cells object
 * @param cells - the cells object: { cellId: null | { containedIds?: string[] } }
 * @returns an array of all the containedIds
 */
const getAllContainedIdsInTable = (cells: Record<string, { containedIds?: string[] }>) =>
  R.values(cells)
    .flatMap((x) => x.containedIds)
    .filter(Boolean) as string[];

function getCellsForOfTable(tableId: string, allElementsData?: Array<[string, any]>): Record<string, any> {
  if (!allElementsData) return {};

  const uid = new TableIds(tableId).justTableId();
  const cells: Record<string, any> = {};
  for (const [key, element] of allElementsData) {
    if (key.includes(uid) && key.startsWith(consts.CANVAS_ELEMENTS.TABLE_CELL)) {
      cells[canvasElementPrefix + key] = element;
    }
  }
  return cells;
}

// --------------------------------------------------------------------

export const TableElement = React.memo(
  ({
    syncService,
    allElementsData,
    id,
    element,
    isSelected,
    isSelectable,
    isReadOnly,
    patchAnything,
    onResize,
    isEditingLink,
    changeElementLink,
    renderLink,
  }: {
    syncService?: SyncService<RW>;
    allElementsData?: Array<[string, any]>;
    id: string;
    element: TypeTableElement;
    isSelected: boolean;
    isSelectable: boolean;
    isReadOnly: boolean;
    patchAnything: PatchAnything;
    onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
    // for html links
    isEditingLink: boolean;
    changeElementLink: (element: any, newLink?: string) => void;
    renderLink: (element: any) => any;
  }) => {
    const ref = useRef<Konva.Rect>(null);
    const layerRef = useAtomValue(layerRefAtom);
    const transformer = useAtomValue(transformerRefAtom)?.current;
    const canvasUtilsAtom = useAtomValue(utilsAtom(useAtomValue(documentIdAtom).documentId));
    const [mouseOver, setMouseOver] = useState(false);
    const data = useMemo(() => getCellsForOfTable(id, allElementsData), [id, allElementsData]);
    // We should not return replicache elements if allElementData is provided.
    if (allElementsData) syncService = undefined; // Force return default data
    const cells = useSubscribeCellsData(syncService?.getReplicache(), id, data);

    const [drag, setDrag] = useState({ col: -1, row: -1, dx: 0, dy: 0 }); // TODO: unify with overrideSize (track dragged by handles) TODO: allow multiple dragged tracks
    const setSelectedIds = useSetAtom(selectedElementIdsAtom);

    const containedIds = getAllContainedIdsInTable(cells);

    const { scaleX = 1, scaleY = 1 } = element;
    const locked = element.lock || isReadOnly;

    const containedElements = useSubscribeContainedElements(
      syncService?.getReplicache(),
      containedIds,
      allElementsData
    );
    const [state, dispatch] = useImmerReducer(tableReducer, null, initialTableState);
    const setInternalSelection = useSetAtom(internalSelectionAtom);
    const [overrideSize, setoverrideSize] = useState<OverrideSize>(null);
    const [highlightCell, setHighlightCell] = useState<null | {
      col: number;
      row: number;
      spanX: number;
      spanY: number;
    }>(null);
    const [capturableElmsDrag, setCapturableElmsDrag] = useState<null | string[]>(null);
    const [trackResizing, setTrackResizing] = useState<null | TrackResizingState>(null);
    const [listenToTransform, setListenToTransform] = useState<any>(null);
    const resizeContained = useRef<ResizeContainedListener | null>(null);
    const isExporting = useAtomValue(isAnyKindOfExportAtom);

    // when this element selection state is changed we reset our internal state
    useEffect(() => {
      if (!isSelected) dispatch({ type: "reset" });
    }, [isSelected]);

    const selectionEmpty = isSelectionEmpty(state.selection);

    // when we're selected and we have a cell selected, we set an atom to reflect that
    // so canvas-stage won't handle clipboard events (because we do)
    // This can be avoided if we have a system for handling events in a more organized way,
    // like the keyboard shortcuts system.
    useEffect(() => {
      const keys = new TableIds(id);
      if (isSelected && !isSelectionEmpty(state.selection)) {
        // if we're selected and we have selected cells also, place them in the internal-selection
        const info = new TableCellsInfo(element.cols, element.rows, xs, ys);
        setInternalSelection(() =>
          [...selectionCells(state.selection, info)].map((cell) => keys.shortId(cell.col.key, cell.row.key))
        );
      } else {
        // else, clear the internal-selection from our cells
        const isCellFromThisTable = R.startsWith(keys.commonPrefix());
        setInternalSelection((prev) => prev.filter((id) => !isCellFromThisTable(id)));
      }
    }, [id, element.cols.length, element.rows.length, isSelected, state.selection]);

    if (locked) {
      patchAnything = noop;
    }

    const xs = computeCoordinatesOfLines(element.cols, overrideSize);
    const ys = computeCoordinatesOfLines(element.rows, overrideSize);

    //TODO: only needed when dragging a track line, or when contained-elements changed
    // and not even for all rows, just a single track
    const minColSize: number[] = new Array(element.cols.length).fill(10);
    const minRowSize: number[] = new Array(element.rows.length).fill(10);
    for (const cellid in cells) {
      const cell = cells[cellid];
      if (cell.containedIds?.length) {
        const bbox = R.reduce(
          BoundingBox.concat,
          BoundingBox.empty(),
          cell.containedIds
            ?.filter((id: string) => !!containedElements[id as any])
            .map((id: string) =>
              BoundingBox.fromRect(getTransformParams(getElementTypeForId(id), containedElements[id]))
            )
        );
        // The cell's containedIds array can have ids of deleted elements.
        // containedElements won't have them, and then the bounding box is the default
        // one, which isn't valid
        if (!BoundingBox.isValid(bbox)) {
          continue;
        }

        const { col, row } = splitKey(cellid);
        const colN = element.cols.findIndex((c) => c.id == col);
        const rowN = element.rows.findIndex((r) => r.id == row);
        minColSize[colN] = (bbox.right - (xs[colN] * scaleX + element.x)) / scaleX;
        minRowSize[rowN] = (bbox.bottom - (ys[rowN] * scaleY + element.y)) / scaleY;
      }
    }

    const info = new TableCellsInfo(element.cols, element.rows, xs, ys);

    useAnyEvent("transform-start", (ev: CustomEvent<{ nodes: any[] }>) => {
      let nodes = ev.detail.nodes;
      if (!nodes || nodes.length == 0 || nodes.some((node) => node.attrs.id == id)) {
        return;
      }
      nodes = ev.detail.nodes.filter((node) => containedIds.includes(node.id()));

      if (nodes.length) {
        // create a mapping from every node being resized to its containing cell
        const ids = nodes.map((n) => n.id());
        const mapping: Record<string, { col: string; row: string }> = {};
        for (const cellId in cells) {
          const cell = cells[cellId];
          if (cell.containedIds?.length) {
            const shapesBelongingToCell = R.intersection(cell.containedIds, ids);
            for (const id of shapesBelongingToCell) {
              mapping[id] = TableIds.getColAndRow(cellId);
            }
          }
        }

        // set the data in a state that will start a listener to track the resizing
        if (resizeContained.current != null) {
          console.error("Forgot to cleanup resizer");
        }
        resizeContained.current = new ResizeContainedListener(
          id,
          element,
          getContainedElementsInCell,
          patchAnything,
          true,
          10
        );
        setListenToTransform({
          nodes,
          mapping,
        });
      }
    });

    function canICaptureElements(ids: string[]): null | string[] {
      const areWeOnStage = Boolean(ref.current?.getStage());
      if (!areWeOnStage) return null;

      // We check that dragged items are all allowed to be dropped on the table.
      return ids.every(isDroppableOnTable) ? ids : null;
    }

    useAnyEvent(EVT_ELEMENT_DRAG_START, (ev) => {
      if (!ev.detail?.ids?.length) return;
      const capturableItems = canICaptureElements(ev.detail.ids);
      if (!capturableItems?.length) return;
      // Elements that are being dragged and are contained in the table are released here
      window.setTimeout(() => {
        const patches: any = [];
        for (const [cellid, value] of Object.entries(cells)) {
          if (value.containedIds?.some((id: string) => ev.detail.ids.includes(id))) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.containedIds = draft.containedIds.filter((id: string) => !ev.detail.ids.includes(id));
            })(cells[cellid]);
            patches.push({ id: cellid, patch, inversePatch });
          }
        }
        if (patches.length) patchAnything(patches);
      });
      setCapturableElmsDrag(capturableItems);
    });

    useAnyEvent(EVT_ELEMENT_DROP, (ev: CustomEvent<{ x: number; y: number; ids: string[] }>) => {
      // If the drop event comes after drag-and-drop, it's handled elsewhere.
      // This handler is for creation and paste events.
      if (capturableElmsDrag) {
        return;
      }
      if (!ev.detail?.ids?.length) {
        return;
      }
      const capturableItems = canICaptureElements(ev.detail.ids);
      if (!capturableItems?.length) return;

      const { x: mouseX, y: mouseY, ids } = ev.detail;
      const totalWidth = xs[xs.length - 1],
        totalHeight = ys[ys.length - 1];

      const tableLeft = element.x,
        tableTop = element.y,
        tableRight = element.x + totalWidth * scaleX,
        tableBottom = element.y + totalHeight * scaleY;

      const isOverTable = mouseX >= tableLeft && mouseX <= tableRight && mouseY >= tableTop && mouseY <= tableBottom;

      if (isOverTable) {
        // find the cell where the mouse is
        const relX = (mouseX - element.x) / scaleX;
        const relY = (mouseY - element.y) / scaleY;
        let col = xs.findIndex((_x) => _x >= relX) - 1;
        let row = ys.findIndex((_y) => _y >= relY) - 1;

        if (col != -1 && row != -1) {
          // just for compiler. we know it's not -1
          window.setTimeout(() => {
            const keyMaker = new TableIds(id);
            const key = keyMaker.longId(element.cols[col].id, element.rows[row].id);
            let spanX = 1,
              spanY = 1;
            if (cells[key] && cells[key].mergedTo) {
              const mergeRoot = keyMaker.longIdFromMergedTo(cells[key].mergedTo!);
              if (cells[mergeRoot] && cells[mergeRoot].mergedTo) {
                const [colId, rowId] = cells[mergeRoot].mergedTo!.split("-");
                spanX = cells[mergeRoot].spanX ?? 1;
                spanY = cells[mergeRoot].spanY ?? 1;
                col = element.cols.findIndex((c) => c.id == colId);
                row = element.rows.findIndex((r) => r.id == rowId);
              } else {
                console.warn("mergedTo has invalid value");
              }
            }
            const patch = onDropElementsOnTable(key, cells[key], ids);

            // TODO: this doesn't happen in the same operation as the paste/creation, so this makes a new undo point
            const nodes = layerRef.current.find((node: Konva.Node) => ids.includes(node.id())).toArray();
            const bbox = BoundingBox.expandOnAll(measureNodes(nodes));
            const expandNodes =
              bbox.right - element.x > xs[col + spanX] * scaleX || bbox.bottom - element.y > ys[row + spanY] * scaleY;
            if (expandNodes) {
              const dx = Math.max(0, bbox.right - element.x - xs[col + spanX] * scaleX);
              const dy = Math.max(0, bbox.bottom - element.y - ys[row + spanY] * scaleY);
              const dxPerCell = dx / spanX / scaleX;
              const dyPerCell = dy / spanY / scaleY;
              const [newTable, p, ip] = produceWithPatches((draft: any) => {
                for (let i = 0; i < spanX; i++) {
                  draft.cols[col + i].size += dxPerCell;
                }
                for (let i = 0; i < spanY; i++) {
                  draft.rows[row + i].size += dyPerCell;
                }
              })(element);
              patch.push({ id: keyMaker.fullTableId(), patch: p, inversePatch: ip });
              const patches = alignTableContentsAfterChange(id, element, newTable, cells, containedElements);
              patch.push(...patches);
            }
            patchAnything(patch);
          });
        }
      }
    });

    const idMaker = new TableIds(id);

    function* getContainedElementsInCell(col: number, row: number): Generator<[string, any]> {
      const cellid = idMaker.longId(element.cols[col].id, element.rows[row].id);
      const cell = cells[cellid];
      if (cell?.containedIds?.length) {
        for (const id of cell.containedIds) {
          if (containedElements[id]) yield [id, containedElements[id]];
        }
      }
    }

    const handlers: ActionHandlers = {
      click: ({ col, row, event }) => {
        if (isSelected) {
          if (event.evt.metaKey) {
            dispatch({ type: "toggle-cell", col: element.cols[col].id, row: element.rows[row].id, element });
          } else if (event.evt.shiftKey) {
            dispatch({ type: "extend-selection", col: element.cols[col].id, row: element.rows[row].id });
          } else {
            if (state.cursor?.col == element.cols[col].id && state.cursor?.row == element.rows[row].id) {
              dispatch({ type: "start-edit" });
            } else {
              dispatch({ type: "reset-on-cell", col: element.cols[col].id, row: element.rows[row].id });
            }
          }
          // BUG: cancelling bubbling of this event means that canvas-stage remembers the last
          // mouse-down point, and if we switch to another cursor (for example 'shape') the ghost appears
          // to have started at the click point here.
          event.cancelBubble = true;
        }
      },
      dblClick: function ({ col, row }) {
        if (locked) return;
        dispatch({
          type: "reset-on-cell",
          col: element.cols[col].id,
          row: element.rows[row].id,
        });
        dispatch({ type: "start-edit" });
      },
      cellEdited: function ({ colId, rowId, cur, initial }) {
        if (locked) return;
        const patches: any[] = [];
        let cell = cells[idMaker.longId(colId, rowId)] ?? {};
        let baseElement = element;
        // if I have initial state, I want produce to work from that, not current element
        if (initial) {
          baseElement = structuredClone(baseElement);
          const scaleY = element.scaleY ?? 1;
          const theRow = baseElement.rows.find(({ id }) => id == rowId);
          if (theRow) theRow.size = initial.height / scaleY;
          cell = { text: initial.text } as TypeTableCell;
        }
        const [, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.text = cur.text;
        })(cell);
        const cellid_reflect = fullCellKey(id, colId, rowId);
        patches.push({ id: cellid_reflect, patch, inversePatch });

        {
          //if the cell is merged, then its real height is the sum of the heights of the merged cells
          const spanY = cells[idMaker.longId(colId, rowId)].spanY ?? 1;
          const start = element.rows.findIndex(({ id }) => id == rowId);
          const curHeight = ys[start + spanY] - ys[start]; // height of the merged-cells, scaled by scaleY
          if (cur.height > curHeight) {
            // if the text is bigger than the cell, we need to expand the cell
            // but since it could be a merged group, we extend just the first row by amount needed
            const delta = cur.height - curHeight;
            const [newElement, patch, inversePatch] = produceWithPatches((draft) => {
              draft.rows[start].size += delta;
            })(baseElement);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
            alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
          }
        }
        patchAnything(patches);
      },
      delete: function () {
        if (locked) return;
        const patches: any[] = [];
        const deleteElementFn = produceWithPatches((draft: any) => {
          draft.hidden = true;
        });

        for (const cell of selectionCells(state.selection, info)) {
          const theCell = cells[idMaker.longId(cell.col.key, cell.row.key)];
          // delete elements on the cells
          if (theCell && (theCell.containedIds?.length || !!theCell.text)) {
            if (theCell.containedIds?.length) {
              for (const elementId of theCell.containedIds) {
                if (containedElements[elementId]) {
                  const [, patch, inversePatch] = deleteElementFn(containedElements[elementId]);
                  patches.push({ id: "cElement-" + elementId, patch, inversePatch });
                }
              }
            }
            // delete the text in the cell
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.text = "";
              delete draft.containedIds;
            })(theCell);

            if (patch.length) {
              const cellid_reflect = fullCellKey(id, cell.col.key, cell.row.key);
              patches.push({ id: cellid_reflect, patch, inversePatch });
            }
          }
        }
        // if the cells are all empty, delete the cells themselves, assuming selection is complete columns/rows
        if (!patches.length) {
          const selectedCols = getColumnsThatAreFullySelected(state.selection, info);
          const selectedRows = getRowsThatAreFullySelected(state.selection, info);

          if (selectedCols?.length && selectedCols.length != info.numColumns) {
            // Check we're not deleting part of a merged cell
            const dontAllow = selectedCols.some((col) => {
              for (const { id: row } of element.rows) {
                const spanX = cells[idMaker.longId(col, row)]?.spanX ?? 1;
                if (spanX != 1) return true;
              }
            });
            if (dontAllow) return;
            //TODO: allow if we've selected all the columns of the merged cell
            const [, patch, inversePatch] = produceWithPatches((draft: TypeTableElement) => {
              draft.cols = draft.cols.filter(({ id }) => !selectedCols.includes(id));
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
          if (selectedRows?.length && selectedRows.length != info.numRows) {
            // Check we're not deleting part of a merged cell
            const dontAllow = selectedRows.some((row) => {
              for (const { id: col } of element.cols) {
                const spanY = cells[idMaker.longId(col, row)]?.spanY ?? 1;
                if (spanY != 1) return true;
              }
            });
            if (dontAllow) return;

            const [, patch, inversePatch] = produceWithPatches((draft: TypeTableElement) => {
              draft.rows = draft.rows.filter(({ id }) => !selectedRows.includes(id));
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
          moveContainedElementsAfterDelete(element, selectedCols, selectedRows, getContainedElementsInCell, patches);
        }
        patchAnything(patches);
      },
      rowColDragEnd: function ({ item, src, target }) {
        if (locked) return;
        // check that the src row/col isn't dropping in middle of merged cell
        // I choose to prohibit this, though it can be done (add new cells to the merged-cell groups)
        const mainAxis = item == "row" ? "rows" : "cols";
        let hasMergedCellsInTarget = false;

        if (item == "row") {
          // first, if target is before first row or after last row, it's always allowed to place it there
          if (target == 0 || target == element.rows.length - 1) {
            hasMergedCellsInTarget = false;
          } else {
            // check that target row has no merged cells
            // the check is to go over all cells in row, and if any is part of merge-group,
            // check that the one below it is not in the same group
            const rowId = element.rows[target].id;
            // the calculation in the next line might make more sense if target was a fraction:
            // the insert point is between 2 lines after all (it could be 2.5 to indicate dropping
            // between rows 2 and 3)
            const otherRowId = element.rows[target > src ? target + 1 : target - 1].id;
            const keychain = new TableIds(id);
            for (let i = 0; i < element.cols.length; i++) {
              const colId = element.cols[i].id;
              const key = keychain.longId(colId, rowId);
              const cell = cells[key];
              if (!cell) continue;
              if (cell.mergedTo) {
                const cellBeneath = cells[keychain.longId(colId, otherRowId)];
                if (cellBeneath && !!cellBeneath.mergedTo && cellBeneath.mergedTo == cell.mergedTo) {
                  hasMergedCellsInTarget = true;
                  break;
                }
              }
            }
          }
        } else {
          // same as for rows (see above)
          if (target == 0 || target == element.cols.length - 1) {
            hasMergedCellsInTarget = false;
          } else {
            const colId = element.cols[target].id;
            const otherColId = element.cols[target > src ? target + 1 : target - 1].id;
            const keychain = new TableIds(id);
            for (let i = 0; i < element.rows.length; i++) {
              const rowId = element.rows[i].id;
              const key = keychain.longId(colId, rowId);
              const cell = cells[key];
              if (!cell) continue;
              if (cell.mergedTo) {
                const cellBeneath = cells[keychain.longId(otherColId, rowId)];
                if (cellBeneath && !!cellBeneath.mergedTo && cellBeneath.mergedTo == cell.mergedTo) {
                  hasMergedCellsInTarget = true;
                  break;
                }
              }
            }
          }
        }
        if (!hasMergedCellsInTarget) {
          // change the order of columns/rows in element, and take contained elements with us
          const patches: any[] = [];
          const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
            const items = draft[mainAxis];
            const orig = items[src];
            items.splice(src, 1);
            items.splice(target, 0, orig);
          })(element);
          patches.push({ id: "cElement-" + id, patch, inversePatch });
          alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
          patchAnything(patches);
        }
      },
      addRow: function ({ row }) {
        if (locked) return;
        // if we are adding a row in the middle of the table, check we're not splitting a merged cell
        // the only correct way to check is to look at cells at both sides of the insertion point,
        // and see if they have the same mergedTo (which is truthy)
        if (row >= 0 && row < element.rows.length - 1) {
          let allow = true;
          for (const { id: col } of element.cols) {
            const upCell = cells[idMaker.longId(col, element.rows[row].id)];
            const bottomCell = cells[idMaker.longId(col, element.rows[row + 1].id)];
            if (
              upCell?.mergedTo &&
              bottomCell?.mergedTo &&
              upCell.mergedTo === bottomCell.mergedTo &&
              upCell.mergedTo !== ""
            ) {
              allow = false;
              break;
            }
          }
          //TODO: display error
          if (!allow) return;
        }
        const patches: any[] = [];
        const copyPropsFrom = element.rows[Math.max(row, 0)];
        const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.rows.splice(row + 1, 0, { id: cellNanoid(), size: copyPropsFrom.size });
        })(element);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
        // copy styling for the new col from existing col
        if (row >= 0) {
          const idsMaker = new TableIds(id);
          const rowId = element.rows[row].id;
          for (let col = 0; col < element.cols.length; col++) {
            const colId = element.cols[col].id;
            const neighborCell = cells[idsMaker.longId(colId, rowId)];
            let newCell = R.mergeRight(defaultTableCell(), neighborCell);
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              for (const key in newCell) {
                draft[key] = newCell[key as keyof typeof newCell];
              }
              draft.text = "";
            })({});
            patches.push({
              id: fullCellKey(id, newElement.cols[col].id, newElement.rows[row + 1].id),
              patch,
              inversePatch,
            });
          }
        }
        alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        patchAnything(patches);
        // when adding/deleting rows/cols, refresh the transformer if I'm selected
        setTimeout(() => transformer?.forceUpdate());
      },
      addCol: function ({ col }) {
        if (locked) return;
        // if we are adding a column in the middle of the table, check we're not splitting a merged cell
        // the only correct way to check is to look at cells at both sides of the insertion point,
        // and see if they have the same mergedTo (which is truthy)
        if (col >= 0 && col < element.cols.length - 1) {
          let allow = true;
          for (const { id: row } of element.rows) {
            const leftCell = cells[idMaker.longId(element.cols[col].id, row)];
            const rightCell = cells[idMaker.longId(element.cols[col + 1].id, row)];
            if (leftCell?.mergedTo && rightCell?.mergedTo && leftCell.mergedTo === rightCell.mergedTo) {
              allow = false;
              break;
            }
          }
          //TODO: display error
          if (!allow) return;
        }
        const patches: any[] = [];
        const copyPropsFrom = element.cols[Math.max(col, 0)];
        const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.cols.splice(col + 1, 0, { id: cellNanoid(), size: copyPropsFrom.size });
        })(element);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
        // copy styling for the new col from existing col
        // Either the one before it, or the one after it
        const srcCol = Math.max(col, 0);
        const colId = element.cols[srcCol].id;

        const idsMaker = new TableIds(id);
        for (let row = 0; row < element.rows.length; row++) {
          const rowId = element.rows[row].id;
          const neighborCell = cells[idsMaker.longId(colId, rowId)];
          let newCell = R.mergeRight(defaultTableCell(), neighborCell);
          const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
            for (const key in newCell) {
              draft[key] = newCell[key as keyof typeof newCell];
            }
            draft.text = "";
          })({});
          patches.push({
            id: fullCellKey(id, newElement.cols[col + 1].id, newElement.rows[row].id),
            patch,
            inversePatch,
          });
        }
        alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        patchAnything(patches);
        // when adding/deleting rows/cols, refresh the transformer if I'm selected
        setTimeout(() => transformer?.forceUpdate());
      },
      extendSelection: function ({ dx, dy }) {
        if (state.cursor) {
          const { col, row } = state.cursor;
          let x = element.cols.findIndex((c) => c.id == col);
          let y = element.rows.findIndex((r) => r.id == row);
          x = clamp(x + dx, 0, element.cols.length - 1);
          y = clamp(y + dy, 0, element.rows.length - 1);
          dispatch({ type: "extend-selection", col: element.cols[x].id, row: element.rows[y].id });
        }
      },
      selectRow: function ({ row, toggle }) {
        dispatch({ type: "select-row", row: element.rows[row].id, toggle });
        setTimeout(() => setSelectedIds([id])); // move selection to table
      },
      selectCol: function ({ col, toggle }) {
        dispatch({ type: "select-col", col: element.cols[col].id, toggle });
        setTimeout(() => setSelectedIds([id])); // move selection to table
      },
      moveCursor: function ({ dx, dy }) {
        dispatch({ type: "move-cursor", dx, dy, element, cells });
      },
      clipboard: function (event) {
        if (event.target != document.body) return; // we're on input/textarea, let the browser handle it
        if (state.cursor != null && event.clipboardData) {
          const { col, row } = state.cursor;
          const x = element.cols.findIndex((c) => c.id == col);
          const y = element.rows.findIndex((r) => r.id == row);
          const cell_x = xs[x] * (element.scaleX || 1) + element.x,
            cell_y = ys[y] * (element.scaleY || 1) + element.y;
          const cellid = idMaker.longId(col, row);
          if (event.type == "copy" || event.type == "cut") {
            event.clipboardData.clearData();
            const text = cells[cellid]?.text || "";
            tableClipboard = structuredClone(cells[cellid]);
            if (tableClipboard?.containedIds?.length) {
              const ids = tableClipboard.containedIds;
              const elements: Array<{ id: string; type: string; element: any }> = [];
              const bbox = new BoundingBox();
              layerRef.current
                .find((node: Konva.Node) => ids.includes(node.id()))
                .each((node: Konva.Node) => {
                  const el = structuredClone(node.attrs.element);
                  el.lock = LockType.None; // everything is unlocked after copy
                  delete el.frameId; // remove from frame - will be calculated on paste
                  delete el.containerId;
                  el.x -= cell_x;
                  el.y -= cell_y;
                  bbox.expandRect(node.getClientRect({ skipShadow: true, relativeTo: layerRef.current! }));
                  elements.push({ id: node.id(), type: node.attrs.type, element: el });
                });
              tableClipboard.elements = elements;
              tableClipboard.bbox = bbox.moveBy(-cell_x, -cell_y);
            }
            event.clipboardData.setData("text/plain", text); // doesn't copy text styling
            navigator.clipboard.writeText(text).catch(() => {});
            if (event.type == "cut") {
              const cell = cells[cellid] ?? {};
              const [_, patch, inversePatch] = produceWithPatches((draft: any) => void delete draft.text)(cell);
              patchAnything({
                id: fullCellKey(id, col, row),
                patch,
                inversePatch,
              });
            }
          } else {
            if (locked) return;
            if (tableClipboard) {
              const cell = cells[cellid] ?? {};
              let newContainedIds: string[] | undefined;
              // duplicate elements, create new containedIds list
              let expandColumnBy = 0,
                expandRowBy = 0;
              if (tableClipboard.elements?.length) {
                const elements: Array<{ id: string; type: string; element: any }> = [];
                const newIds: Record<string, string> = {};
                const groupIds: Record<string, string> = {};
                tableClipboard.elements.forEach(({ id, type, element }: { id: string; type: string; element: any }) => {
                  newIds[id] = createElementId(); // new id for the element
                  const el = structuredClone(element);
                  replaceGroupsAfterCopy(groupIds, el); // replace group-ids
                  el.x += cell_x;
                  el.y += cell_y;
                  elements.push({ id: newIds[id], type, element: el });
                  newContainedIds ??= [];
                  newContainedIds.push(dbKey(newIds[id], type));
                });
                const bbox = tableClipboard.bbox.moveBy(cell_x, cell_y);
                const cellRight = xs[x + 1] * (element.scaleX || 1) + element.x;
                const cellBottom = ys[y + 1] * (element.scaleY || 1) + element.y;
                if (cellRight < bbox.right) {
                  expandColumnBy = bbox.right - cellRight;
                }
                if (cellBottom < bbox.bottom) {
                  expandRowBy = bbox.bottom - cellBottom;
                }
                canvasUtilsAtom?.onAddElements(elements, true, "copy-paste");
              }

              const patches: SinglePatch[] = [];

              const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                ["text", "fill", "textColor", "fontSize", "align", "valign", "fontProps", "font"].forEach((trait) => {
                  if (tableClipboard[trait] != undefined) {
                    draft[trait] = tableClipboard[trait];
                  }
                });
                draft.containedIds = newContainedIds;
              })(cell);

              patches.push({
                id: fullCellKey(id, col, row),
                patch,
                inversePatch,
              });

              if (expandColumnBy || expandRowBy) {
                const [, patch, inversePatch] = produceWithPatches((draft: any) => {
                  draft.cols[x].size += expandColumnBy;
                  draft.rows[y].size += expandRowBy;
                })(element);
                patches.push({
                  id: "cElement-" + id,
                  patch,
                  inversePatch,
                });
              }
              patchAnything(patches);

              event.preventDefault(); // don't let the browser handle the event
            }
          }
        }
      },
      styleChange: function ({ trait, value }) {
        const patches: any[] = [];
        // if not cells are selected, the table should change
        for (const cell of selectionCells(state.selection, info)) {
          const cellData = cells[idMaker.longId(cell.col.key, cell.row.key)] || { ...element };
          const [_, patch, inversePatch] = produceWithPatches((draft) => {
            if (trait == "fill") {
              if (typeof value == "number") draft.fill = replaceColorOpacity(draft.fill, value);
              else draft.fill = value;
            } else if (trait == "fontProps-toggle") {
              draft.fontProps ^= value;
            } else {
              draft[trait] = value;
            }
          })(cellData);
          patches.push({ id: fullCellKey(id, cell.col.key, cell.row.key), patch, inversePatch });
        }
        patchAnything(patches);
      },
      editTitle: function ({ title }) {
        const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.title = title;
        })(element);
        patchAnything({ id: "cElement-" + id, patch, inversePatch });
      },
    };
    function eventHandler(action: TableAction) {
      if ("payload" in action) {
        (handlers[action.type] as (payload: any) => void)(action.payload);
      } else {
        handlers[action.type](void 0);
      }
    }

    return (
      <>
        <TableView
          ref={ref}
          id={id}
          element={element}
          xs={xs}
          ys={ys}
          onResize={onResize}
          cells={cells}
          info={info}
          containedIds={containedIds}
          isSelectable={isSelectable}
          state={state}
          onDismissEditing={() => dispatch({ type: "end-edit" })}
          drag={drag}
          isEditingLink={isEditingLink}
          changeElementLink={changeElementLink}
          renderLink={renderLink}
          dispatch={eventHandler}
        />
        {!selectionEmpty && (
          <KeyboardHandler.TableKeyboardShortcuts
            moveCursor={(dx, dy) => eventHandler(actions.moveCursor({ dx, dy }))}
            editCell={() => dispatch({ type: "start-edit" })}
            extendSelection={(dx, dy) => {
              eventHandler(actions.extendSelection({ dx, dy }));
            }}
            clearSelection={() => {
              eventHandler(actions.delete());
              return true; // handled the event
            }}
            onClipboardEvent={(event) => eventHandler(actions.clipboard(event))}
          />
        )}
        {/* highlight the cell we're dragging elements over */}
        {highlightCell && !isExporting && (
          <Rect
            fill="#BFDCFF"
            opacity={0.5}
            x={element.x + xs[highlightCell.col] * scaleX}
            y={element.y + ys[highlightCell.row] * scaleY}
            width={(xs[highlightCell.col + highlightCell.spanX] - xs[highlightCell.col]) * scaleX}
            height={(ys[highlightCell.row + highlightCell.spanY] - ys[highlightCell.row]) * scaleY}
          />
        )}
        {capturableElmsDrag && !isExporting && (
          <TableDragListener
            ids={capturableElmsDrag}
            x={element.x}
            y={element.y}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            xs={xs}
            ys={ys}
            onDrag={(override: null | { col: number; row: number; colWidth: number; rowHeight: number }) => {
              // if xs,ys has the ids as well as size, would be much easier
              // this code is horrible for performance when I have several tables
              if (override) {
                const { col, row, colWidth, rowHeight } = override;
                const colId = element.cols[col].id,
                  rowId = element.rows[row].id;
                const idsMaker = new TableIds(id);
                const cell = cells[idsMaker.longId(colId, rowId)];
                let baseCol = col,
                  baseRow = row,
                  spanX = 1,
                  spanY = 1;
                let newSize: Record<string, number> = {};
                if (cell && cell.mergedTo) {
                  // if the cell is merged, we need to find the top-left cell
                  const [col1, row1] = cell.mergedTo!.split("-");
                  baseCol = element.cols.findIndex(({ id }) => id == col1);
                  baseRow = element.rows.findIndex(({ id }) => id == row1);
                  const topLeftOfMergeCell = cells[idsMaker.longId(col1, row1)];
                  spanX = topLeftOfMergeCell.spanX || 1;
                  spanY = topLeftOfMergeCell.spanY || 1;

                  const mergedCellWidth = (xs[baseCol + spanX] - xs[baseCol]) * scaleX;
                  const mergedCellHeight = (ys[baseRow + spanY] - ys[baseRow]) * scaleY;
                  // if the merge-cell is smaller than the size of the dragged items, which columns / rows should change ?
                  // I'll change only the top-left cell.
                  // I'll compute how much it needs to grow so the merge-cell will be big enough.
                  const neededExtraW = Math.max(0, colWidth * 1.1 - mergedCellWidth);
                  const newWidth = (xs[baseCol + 1] - xs[baseCol]) * scaleX + neededExtraW;
                  const neededExtraH = Math.max(0, rowHeight * 1.1 - mergedCellHeight);
                  const newHeight = (ys[baseRow + 1] - ys[baseRow]) * scaleY + neededExtraH;
                  newSize = {
                    [element.cols[baseCol].id]: newWidth / scaleX,
                    [element.rows[baseRow].id]: newHeight / scaleY,
                  };
                } else {
                  newSize = {
                    [element.cols[baseCol].id]: Math.max(colWidth * 1.1, element.cols[baseCol].size * scaleX) / scaleX,
                    [element.rows[baseRow].id]: Math.max(rowHeight * 1.1, element.rows[baseRow].size * scaleY) / scaleY,
                  };
                }

                setHighlightCell({
                  col: baseCol,
                  row: baseRow,
                  spanX: spanX,
                  spanY: spanY,
                });
                setoverrideSize((prev) => (R.equals(prev, newSize) ? prev : newSize));
              } else {
                setHighlightCell(null);
                setoverrideSize(null);
              }
            }}
            onDragEnd={function (col, row, bbox, ids): void {
              if (col != -1 && row != -1) {
                const key = idMaker.longId(element.cols[col].id, element.rows[row].id);
                const patch = onDropElementsOnTable(key, cells[key], ids);
                if (bbox) {
                  const [_, p, ip] = produceWithPatches((draft: any) => {
                    draft.cols[col].size = Math.max(bbox.width * 1.1, draft.cols[col].size * scaleX) / scaleX;
                    draft.rows[row].size = Math.max(bbox.height * 1.1, draft.rows[row].size * scaleY) / scaleY;
                  })(element);
                  patch.push({ id: "cElement-" + id, patch: p, inversePatch: ip });
                }
                patchAnything(patch);
              }
              setoverrideSize(null);
              setHighlightCell(null);
              setCapturableElmsDrag(null);
            }}
          />
        )}
        {/* Handle resizing of contained elements */}
        {listenToTransform && !isExporting && (
          <TableResizeContainedListener
            nodes={listenToTransform.nodes}
            mapping={listenToTransform.mapping}
            setoverrideSize={({ requiredXs, requiredYs }: { requiredXs: any; requiredYs: any }) => {
              if (resizeContained.current) {
                for (let key in requiredXs) {
                  resizeContained.current.updateXline(key, requiredXs[key]);
                }
                for (let key in requiredYs) {
                  resizeContained.current.updateYline(key, requiredYs[key]);
                }
              }
            }}
            onEnd={function (): void {
              setListenToTransform(null);
            }}
          />
        )}
        {trackResizing ? (
          <TrackResizeComponent
            id={id}
            setOverrideSize={(key, size) => {
              setoverrideSize({ [key]: size });
            }}
            endDrag={function (): void {
              setoverrideSize(null);
              setTrackResizing(null);
            }}
            element={element}
            patchAnything={patchAnything}
            initial={trackResizing}
            cells={cells}
            containedElements={containedElements}
          />
        ) : (
          !isExporting && (
            <RenderChildrenIf
              name={`table-track-lines-${id}`}
              x={element.x}
              y={element.y}
              scaleX={scaleX}
              scaleY={scaleY}
              shouldRenderTest={function (node): boolean {
                const scale = node.getStage()?.scaleX() || 0;
                // I don't render the track-lines if the seperation is too small between them.
                // I think ideally it should be done separately for vertical and horizontal lines.
                // but for now, this is good enough.
                // The test is done only for axis with more than 1 column/row.
                // Note: compare length to 2 because single row/col is 2 lines (on both sides of it)
                if (xs.length > 2) {
                  const avgSeperationX = (xs[xs.length - 1] * scaleX * scale) / xs.length;
                  if (avgSeperationX < 30) return false;
                }
                if (ys.length > 21) {
                  const avgSeperationY = (ys[ys.length - 1] * scaleY * scale) / ys.length;
                  if (avgSeperationY < 30) return false;
                }
                return true;
              }}
            >
              <TableTrackLines
                xs={xs}
                ys={ys}
                cells={cells}
                info={info}
                onDragStart={(type, index, x, y) => {
                  setTrackResizing({
                    trackType: type,
                    trackIndex: index,
                    trackKey: element[type][index].id,
                    prevTrackCoordinate: type == "cols" ? xs[index] : ys[index],
                    mouseX: x,
                    mouseY: y,
                  });
                }}
                element={element}
              />
            </RenderChildrenIf>
          )
        )}
        {/* can move Test  inside Widget2, just render it every time, and
        render widgets if no dragging-operation, or resize-table, or capturableElmsDrag
        or trackResizing
        and I think all should not work if table is locked*/}
        <RegisterHitAreaForMouseMove
          id={id}
          x={element.x}
          y={element.y}
          width={xs[xs.length - 1] * scaleX}
          height={ys[ys.length - 1] * scaleY}
          setMouseOver={setMouseOver}
        />
        {!locked && !capturableElmsDrag && !isExporting && (
          <TableWidgets
            mouseOver={mouseOver}
            id={id}
            cells={cells}
            element={element}
            xs={xs}
            ys={ys}
            dispatch={eventHandler}
            setDrag={setDrag}
          />
        )}
      </>
    );
  }
);

TableElement.displayName = "TableElement";

const TableView = React.memo(
  /*eslint-disable react/prop-types*/

  React.forwardRef(
    (
      {
        id,
        element,
        cells,
        containedIds,
        isSelectable,
        state,
        info,
        onDismissEditing,
        xs,
        ys,
        drag,
        dispatch,
        onResize,
        isEditingLink,
        changeElementLink,
        renderLink,
      }: {
        id: string;
        element: TypeTableElement;
        cells: Record<string, TypeTableCell>;
        containedIds: undefined | string[];
        isSelectable: boolean;
        state: TableState;
        info: TableCellsInfo;
        onDismissEditing: () => void;
        xs: number[];
        ys: number[];
        drag: { col: number; row: number; dx: number; dy: number };
        dispatch: ActionHandler;
        onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
        isEditingLink: boolean;
        changeElementLink: (element: any, newLink?: string) => void;
        renderLink: (element: any) => any;
      },
      ref: any
    ) => {
      const mutation = useMemo(() => new TransformHooks(id, onResize), [id]);
      const isExporting = useAtomValue(isAnyKindOfExportAtom);

      function yCoord(yIndex: number) {
        let y = ys[yIndex];
        if (drag && drag.row == yIndex) {
          y += drag.dy;
        }
        return y;
      }
      function xCoord(xIndex: number) {
        let x = xs[xIndex];
        if (drag && drag.col == xIndex) {
          x += drag.dx;
        }
        return x;
      }

      //TODO: if most cases there is no drag operation, and then it's so much simpler and faster to
      // build up the sub-components of the table.
      // and for drag - create a separate function. (code reuse is not that horrible)
      const nodes: Array<{ row: number; col: number; zOrder: number; elm: React.ReactNode }> = [];
      for (let col = 0; col < element.cols.length; col++) {
        for (let row = 0; row < element.rows.length; row++) {
          const colKey = element.cols[col].id;
          const rowKey = element.rows[row].id;
          const cellid = fullCellKey(id, colKey, rowKey);

          const spanX = cells[cellid]?.spanX ?? 1;
          const spanY = cells[cellid]?.spanY ?? 1;
          if (spanX == 0 || spanY == 0) continue;

          const cellWidth = xs[col + spanX] - xs[col];
          const cellHeight = ys[row + spanY] - ys[row];
          const isEditing =
            !element.lock && state.isEditing && state.cursor?.col == colKey && state.cursor?.row == rowKey;

          nodes.push({
            row,
            col,
            zOrder: 0,
            elm: (
              <TableCell
                cellId={cellid.slice(9)}
                tableId={id}
                key={cellid}
                col={col}
                row={row}
                x={xCoord(col)}
                y={yCoord(row)}
                width={cellWidth}
                height={cellHeight}
                element={element}
                cell={cells[cellid]}
                isEditing={isEditing}
                onDismissEditing={onDismissEditing}
                dispatch={dispatch}
                onEdit={(cur, initial) => {
                  dispatch(
                    actions.cellEdited({
                      colId: element.cols[col].id,
                      rowId: element.rows[row].id,
                      cur: {
                        text: cur.text,
                        height: cur.height,
                      },
                      initial,
                    })
                  );
                }}
              />
            ),
          });
        }
      }

      if (state.cursor && !isExporting) {
        let col = element.cols.findIndex(({ id }) => id == state.cursor!.col);
        let row = element.rows.findIndex(({ id }) => id == state.cursor!.row);
        // col/row can be -1 because the cursor was on a column/row that was just deleted
        if (col != -1 && row != -1) {
          let cellid;
          do {
            const colKey = element.cols[col].id;
            const rowKey = element.rows[row].id;
            cellid = fullCellKey(id, colKey, rowKey);

            const cellData = cells[cellid];
            if (cellData?.mergedTo) {
              const [col1, row1] = cellData.mergedTo!.split("-");
              col = element.cols.findIndex(({ id }) => id == col1);
              row = element.rows.findIndex(({ id }) => id == row1);
              cellid = fullCellKey(id, col1, row1);
            }
            break;
            // eslint-disable-next-line no-constant-condition
          } while (true);

          const spanX = cells[cellid]?.spanX ?? 1;
          const spanY = cells[cellid]?.spanY ?? 1;

          const cellWidth = xs[col + spanX] - xs[col];
          const cellHeight = ys[row + spanY] - ys[row];

          nodes.push({
            row,
            col,
            zOrder: 1,
            elm: (
              <CellOutline
                key="cursor highlight"
                x={xCoord(col)}
                y={yCoord(row)}
                width={cellWidth}
                height={cellHeight}
                corners={getCornerRadius(col, row, element.cols.length, element.rows.length)}
              />
            ),
          });
        }
      }
      if (!isSelectionEmpty(state.selection) && !isExporting) {
        for (const cell of selectionCells(state.selection, info)) {
          const x = cell.col.i;
          const y = cell.row.i;
          const cellid = fullCellKey(id, cell.col.key, cell.row.key);

          const spanX = cells[cellid]?.spanX ?? 1;
          const spanY = cells[cellid]?.spanY ?? 1;
          if (spanX == 0 || spanY == 0) continue;
          const cellWidth = xs[x + spanX] - xs[x];
          const cellHeight = ys[y + spanY] - ys[y];

          nodes.push({
            row: y,
            col: x,
            zOrder: 2,
            elm: (
              <CellOutline
                key={"outline" + x + y}
                x={xCoord(x)}
                y={yCoord(y)}
                width={cellWidth}
                height={cellHeight}
                corners={getCornerRadius(x, y, element.cols.length, element.rows.length)}
              />
            ),
          });
        }
      }

      //TODO: if we don't have drag no need to sort, no need to create an array of objects
      // with elements inside it...
      if (drag.col != -1 || drag.row != -1) {
        nodes.sort((a, b) => {
          // non dragged elements are always before dragged elements
          // then decide by zOrder prop
          const aDragged = drag.col == a.col || drag.row == a.row;
          const bDragged = drag.col == b.col || drag.row == b.row;
          if (aDragged == bDragged) {
            return a.zOrder - b.zOrder;
          }
          return +aDragged - +bDragged;
        });

        if (drag.col != -1) {
          nodes.unshift({
            row: 0,
            col: 0,
            zOrder: 3,
            elm: (
              <Rect
                fill="#e5e8ea"
                x={xs[drag.col]}
                y={0}
                width={xs[drag.col + 1] - xs[drag.col]}
                height={ys[ys.length - 1]}
              />
            ),
          });
        } else {
          nodes.unshift({
            row: 0,
            col: 0,
            zOrder: 3,
            elm: (
              <Rect
                fill="#e5e8ea"
                x={0}
                y={ys[drag.row]}
                width={xs[xs.length - 1]}
                height={ys[drag.row + 1] - ys[drag.row]}
              />
            ),
          });
        }
      }
      const elms = nodes.map(({ elm }) => elm);

      return (
        <>
          {!isExporting && <TableTitle id={id} element={element} dispatch={dispatch} />}
          <Group
            ref={ref}
            id={id}
            name={id}
            type={"table"}
            element={element}
            x={element.x}
            y={element.y}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            rotation={(element as any).rotate ?? 0}
            isCanvasElement={true}
            isConnectable={true}
            isConnector={false}
            isDraggable={true}
            isFrame={false}
            isSelectable={isSelectable}
            isTaskConvertible={false}
            attachedConnectors={element.attachedConnectors}
            {...mutation.getCallbacks()}
            // unique to table
            containedIds={containedIds}
            cells={cells}
          >
            <Rect
              fillEnabled={false}
              stroke={element.stroke}
              strokeWidth={1}
              strokeScaleEnabled={false}
              width={xs[xs.length - 1]}
              height={ys[ys.length - 1]}
              listening={false}
              cornerRadius={tableCorners.get(true, true, true, true)}
            />
            {elms}
            {renderLink(element)}
          </Group>
          {isEditingLink && (
            <Html>
              <Modal dimBackground={true}>
                <EditElementLinkModal element={element} onChangeLink={changeElementLink} />
              </Modal>
            </Html>
          )}
        </>
      );
    }
  )
);

function CellOutline({
  x,
  y,
  width,
  height,
  corners,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  corners?: [number, number, number, number];
}) {
  return (
    <Rect
      x={x}
      y={y}
      cornerRadius={corners}
      width={width}
      height={height}
      fillEnabled={false}
      stroke={Style.Cell.SelectedStrokeColor}
      strokeWidth={2}
      strokeScaleEnabled={false}
      listening={false}
    />
  );
}

function TableCell({
  cellId,
  tableId,
  x,
  y,
  col,
  row,
  width,
  height,
  cell,
  element,
  isEditing,
  onDismissEditing,
  onEdit,
  dispatch,
}: {
  cellId: string;
  tableId: string;
  x: number;
  y: number;
  col: number;
  row: number;
  width: number;
  height: number;
  cell?: any;
  element: TypeTableElement;
  isEditing: boolean;
  onEdit: (latest: EditorState, initial?: EditorState) => void;
  onDismissEditing: () => void;
  dispatch: ActionHandler;
}) {
  const { scaleX = 1 } = element;

  // text styling props
  const fontFamily = cell?.font || defaultTextStyleForTable.font;
  const fontSize = cell?.fontSize || defaultTextStyleForTable.fontSize;
  const align = cell?.align || defaultTextStyleForTable.align;
  const verticalAlign = cell?.valign || defaultTextStyleForTable.valign;
  const textColor = cell?.textColor || defaultTextStyleForTable.textColor;

  const fontProps = cell?.fontProps ?? defaultTextStyleForTable.fontProps;
  const textDecoration = konvaTextDecoration(fontProps);
  const fontStyle = fontPropertiesToString(fontProps);

  const backgroundColor = cell?.fill;

  // If font or its size changed, text might have gotten bigger and then we enlarge the row.
  useLayoutEffect(() => {
    if (cell?.text?.length) {
      const text = new Konva.Text({
        fontFamily,
        fontSize,
        align,
        verticalAlign,
        textDecoration,
        fontStyle,
        ellipsis: false,
        wrap: "word",
        perfectDrawEnabled: false,
        width,
        padding: cellPadding,
        text: cell.text,
      });
      if (text.height() > height) {
        onEdit({ text: cell.text, height: text.height() });
      }
    }
  }, [fontFamily, fontSize, align, verticalAlign, textDecoration, fontStyle, cell?.text, scaleX]);

  return (
    <>
      <Rect
        id={cellId}
        x={x}
        y={y}
        cornerRadius={getCornerRadius(col, row, element.cols.length, element.rows.length)}
        width={width}
        height={height}
        fill={backgroundColor}
        element={element}
        cell={cell}
        isTableCell={true}
        tableId={tableId}
        strokeWidth={1}
        strokeScaleEnabled={false}
        stroke={element.stroke}
        listening={true}
        onDblClick={(event) => dispatch(actions.dblClick({ col, row, event }))}
        // the following 2 handlers check for a mouse-click - without dragging the mouse (that's a different operation)
        onMouseDown={(event) => {
          if (event.evt.buttons === 1) {
            event.target.attrs.mouseDown = { x: event.evt.offsetX, y: event.evt.offsetY };
          } else {
            event.target.attrs.mouseDown = undefined;
          }
        }}
        onMouseUp={(event) => {
          try {
            if (event.target.attrs.mouseDown) {
              const { x, y } = event.target.attrs.mouseDown;
              if (Math.max(Math.abs(event.evt.offsetX - x), Math.abs(event.evt.offsetY - y)) < 5)
                dispatch(actions.click({ col, row, event }));
            }
          } catch {
            // Quetly swallow the error
          }
        }}
      />
      {isEditing ? (
        <CellTextEditor
          contentArea={{ x, y, width, height }}
          value={cell?.text}
          placeholder={"Add text"}
          onEdit={onEdit}
          onDismiss={onDismissEditing}
          cssTextArea={
            {
              font: `${fontPropertiesToString(fontProps)} ${fontSize}px/1 ${fontFamily}`,
              textAlign: align,
              color: textColor,
              textDecoration: konvaTextDecoration(fontProps),
            } as CSSProperties
          }
          padding={cellPadding}
        />
      ) : (
        !!cell?.text && (
          <Text
            x={x}
            y={y}
            width={width}
            height={height}
            padding={cellPadding}
            text={cell.text}
            fill={textColor}
            fontFamily={fontFamily}
            fontSize={fontSize}
            align={align}
            verticalAlign={verticalAlign}
            textDecoration={textDecoration}
            fontStyle={fontStyle}
            ellipsis={false}
            wrap="word"
            perfectDrawEnabled={false}
            listening={false}
          />
        )
      )}
    </>
  );
}

interface EditorState {
  text?: string;
  height?: number;
}

function CellTextEditor({
  contentArea,
  value = "",
  placeholder,
  onEdit,
  onDismiss,
  cssTextArea,
  padding,
}: {
  contentArea: { x: number; y: number; width: number; height: number };
  value: string;
  placeholder: string;
  onEdit: (latest: EditorState, initial?: EditorState) => void;
  onDismiss: () => void;
  cssTextArea?: CSSProperties;
  padding: number;
}) {
  // state when component is first created - for undo/redo
  const initial = useRef<Required<EditorState>>({ text: value, height: contentArea.height });

  const [curText, setCurText] = useState(value);
  const throttledText = useThrottle(curText, 500);
  const [height, setHeight] = useState(contentArea.height);

  useEffect(() => {
    onEdit({ text: throttledText });
  }, [throttledText]);

  useEffect(() => {
    onEdit({ text: curText, height });
  }, [height]);

  // final effect on unmount that sends initial state, and this will register an undo point to that state
  useUnmount(() => onEdit({ text: curText, height }, initial.current));

  return (
    <Html groupProps={{ x: contentArea.x, y: contentArea.y }}>
      <div style={{ width: contentArea.width, height: contentArea.height, cursor: "text", padding: padding + "px" }}>
        <textarea
          autoFocus
          onFocus={(e) => e.currentTarget.select()}
          style={{
            height: "100%",
            width: "100%",
            // reset the default styles of a text area
            overflow: "auto",
            outline: "none",
            border: "none",
            background: "unset",
            resize: "none",
            verticalAlign: "top",
            // include the custom css for font and color
            ...cssTextArea,
          }}
          onKeyDown={(e) => {
            handleTabInTextArea(e);
            if (e.key == "Escape") {
              e.stopPropagation();
              onDismiss();
            }
          }}
          onInput={(e) => {
            const input = e.currentTarget;
            input.style.height = "0"; // this forces browser to recalc minimal height needed for the text, without scrollbars
            const minimalHeight = input.scrollHeight;
            input.style.height = input.scrollHeight + "px"; // set the height to the minimal height
            let newHeight = minimalHeight + padding * 2; // calculate height + padding
            newHeight = Math.max(newHeight, initial.current.height); // never go below the initial height
            setCurText(input.value);
            setHeight(newHeight);
          }}
          defaultValue={value}
          placeholder={placeholder}
        />
      </div>
    </Html>
  );
}

export function tableTraits(element: TypeTableElement): ITraits {
  // let { align, textColor, font, fontProps, fontSize } = textEnabledTraits(element);
  // fontSize *= element.scaleX ?? 1;

  return {
    align: undefined,
    textColor: undefined,
    font: undefined,
    fontProps: undefined,
    fontSize: undefined,
    [Trait.tableFillColor]: undefined,
    [Trait.tableStrokeColor]: element.stroke,
    [Trait.tableAddColumn]: "",
    [Trait.tableAddRow]: "",
  };
}

export function tableCellTraits(element: TypeTableCell & { scaleX: number }): ITraits {
  let { align, textColor, font, fontProps, fontSize } = textEnabledTraits({ ...defaultTextStyleForTable, ...element });
  fontSize *= element.scaleX ?? 1;
  return {
    align,
    textColor,
    font,
    fontProps,
    fontSize,
    [Trait.tableFillColor]: element.fill,
  };
}

export function tableValidateTraits(element: TypeTableElement, trait: Trait, value: any) {
  if (trait == Trait.tableAddColumn) {
    // TODO: copy styling for new cells from their left-neighbors.
    // this means I should read from reflect the state of those cells, and write
    // state for new cells.
    //kinda hard to do here with no access to the cell elements, or syncService...
    // if only I could send a patch like this:
    // newCellsKeys.map((id) => ({id, patch:{fill: () => this.get(key-for-left-neighbor)?.fill }}))
    // and the patchAnything will merge this json-merge-patch, run the function with 'this' set to ReadTransaction
    return {
      initialStyle: [{ col: element.cols.length, style: "__copy_previous_col__" }],
      cols: [...element.cols, { id: cellNanoid(), size: element.cols[element.cols.length - 1].size }],
    };
  }
  if (trait == Trait.tableAddRow) {
    return {
      initialStyle: [{ row: element.rows.length, style: "__copy_previous_row__" }],
      rows: [...element.rows, { id: cellNanoid(), size: element.rows[element.rows.length - 1].size }],
    };
  }

  if (trait == Trait.fontSize) {
    value = value / (element.scaleX ?? 1);
  }
  return value;
}

export function tableCellValidateTraits(element: TypeTableCell & { scaleX: number }, trait: Trait, value: any) {
  if (trait == Trait.fontSize) {
    return value / (element.scaleX ?? 1);
  }
  return value;
}

const findCellAtXY = (x: number, y: number, xs: number[], ys: number[]) => {
  const col = xs.findIndex((x_) => x_ >= x) - 1;
  const row = ys.findIndex((y_) => y_ >= y) - 1;
  return { col, row };
};

interface TableDragListenerProps {
  ids: string[];
  x: number;
  y: number;
  xs: number[];
  ys: number[];
  onDrag: (override: { col: number; row: number; colWidth: number; rowHeight: number } | null) => void;
  onDragEnd: (col: number, row: number, bbox: BoundingBox | null, ids: string[]) => void;
  scaleX?: number;
  scaleY?: number;
}

function TableDragListener({ ids, x, y, xs, ys, onDrag, onDragEnd, scaleX = 1, scaleY = 1 }: TableDragListenerProps) {
  const layerRef = useAtomValue(layerRefAtom);
  const bbox = useRef<BoundingBox>(BoundingBox.from(0, 0, 0, 0));
  const { queue } = useBgJob(onDrag, 1);
  // TODO: this element is rendered whenever xs,ys change, which can happen a lot during dragging
  // check how this affects performance

  // start a bg operation to find the dragged konva elements,
  // measure their unified bounding box, get total size
  useEffect(() => {
    setTimeout(() => {
      const nodes = layerRef.current.find((node: Konva.Node) => ids.includes(node.id())).toArray();
      bbox.current = BoundingBox.expandOnAll(measureNodes(nodes, { skipShadow: true }));
    });
  }, [ids]);

  useAnyEvent(EVT_ELEMENT_DRAG, (event: CustomEvent<{ mousePosition: Point; ids: string[] }>) => {
    const { mousePosition } = event.detail;
    const tableWidth = xs[xs.length - 1];
    const tableHeight = ys[ys.length - 1];
    // check if mousePosition is within the table rect
    const relX = (mousePosition.x - x) / scaleX;
    const relY = (mousePosition.y - y) / scaleY;
    const isOverTable = relX > 0 && relY > 0 && relX < tableWidth && relY < tableHeight;

    if (isOverTable) {
      const { col, row } = findCellAtXY(relX, relY, xs, ys);
      const colWidth = bbox.current.width;
      const rowHeight = bbox.current.height;
      queue({ col, row, colWidth, rowHeight });
    } else {
      queue(null);
    }
  });

  useAnyEvent(EVT_ELEMENT_DROP, (ev: CustomEvent<{ x: number; y: number; ids: string[] }>) => {
    const { x: mouseX, y: mouseY } = ev.detail;
    const tableWidth = xs[xs.length - 1];
    const tableHeight = ys[ys.length - 1];
    // check if mousePosition is within the table rect
    const relX = (mouseX - x) / scaleX;
    const relY = (mouseY - y) / scaleY;
    const isOverTable = relX > 0 && relY > 0 && relX < tableWidth && relY < tableHeight;
    queue(null);
    if (isOverTable) {
      const { col, row } = findCellAtXY(relX, relY, xs, ys);
      onDragEnd(col, row, bbox.current, ids);
    } else {
      onDragEnd(-1, -1, bbox.current, ids);
    }
  });

  // Return null, because this component exists just to listen to events and trigger the drag operation
  return null;
}

interface OrderedMap<T> {
  order: string[];
  values: Record<string, T>;
}

function buildOrderedMap(data: { id: string; size: number }[], scale = 1): OrderedMap<number> {
  const order = [];
  const values: Record<string, number> = {};
  for (const { id, size } of data) {
    order.push(id);
    values[id] = size * scale;
  }
  return { order, values };
}

function accumulate(v: OrderedMap<number>, accumulator: (prev: number, cur: number) => number) {
  let prev;
  for (const id of v.order) {
    if (prev) {
      v.values[id] = accumulator(v.values[prev], v.values[id]);
    }
    prev = id;
  }
  return v;
}

function alignTableContentsAfterChange(
  id: string,
  element: TypeTableElement,
  newElement: TypeTableElement,
  cells: Record<string, TypeTableCell>,
  containedElements: Record<string, any>,
  precalculated?: {
    patches?: any[];
    xs?: number[];
    ys?: number[];
  }
) {
  const patches = precalculated?.patches ?? [];

  const xs = accumulate(buildOrderedMap(element.cols, element.scaleX), R.add);
  const ys = accumulate(buildOrderedMap(element.rows, element.scaleY), R.add);

  const new_xs = accumulate(buildOrderedMap(newElement.cols, newElement.scaleX), R.add);
  const new_ys = accumulate(buildOrderedMap(newElement.rows, newElement.scaleY), R.add);

  for (const row_id of new_ys.order) {
    const dy = new_ys.values[row_id] - ys.values[row_id]; // TODO: check old_ys[row_id] exists
    for (const col_id of new_xs.order) {
      const dx = new_xs.values[col_id] - xs.values[col_id]; // TODO: check old_xs[col_id] exists
      if (dx !== 0 || dy !== 0) {
        const cid = fullCellKey(id, col_id, row_id);
        if (cells[cid]?.containedIds?.length) {
          for (const elementId of cells[cid]!.containedIds!) {
            if (!containedElements[elementId]) {
              // it's possible the element was deleted, and the table still has it
              continue;
            }
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.x += dx;
              draft.y += dy;
            })(containedElements[elementId]);

            patches.push({ id: "cElement-" + elementId, patch, inversePatch });
          }
        }
      }
    }
  }
  return patches;
}

function moveContainedElementsAfterDelete(
  element: TypeTableElement,
  deletedCols: null | string[],
  deletedRows: null | string[],
  getContainedElementsInCell: (col: number, row: number) => Generator<[string, any]>,
  patches?: any[]
) {
  patches ??= [];
  const { scaleX = 1, scaleY = 1 } = element;

  if (deletedCols?.length) {
    const sizes = element.cols.map(({ size }) => size * scaleX);
    const newsizes = element.cols.map((col) => (deletedCols.includes(col.id) ? 0 : col.size * scaleX));
    let dx = 0; // accumulated delta-x as we loop over the columns
    for (let c = 0; c < element.cols.length; c++) {
      dx += newsizes[c] - sizes[c];
      if (dx != 0) {
        for (let r = 0; r < element.rows.length; r++) {
          for (const [id, shape] of getContainedElementsInCell(c, r)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.x += dx;
            })(shape);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }
  }

  if (deletedRows?.length) {
    const sizes = element.rows.map(({ size }) => size * scaleY);
    const newsizes = element.rows.map((col) => (deletedRows.includes(col.id) ? 0 : col.size * scaleY));
    let dy = 0; // accumulated delta-x as we loop over the columns
    for (let r = 0; r < element.rows.length; r++) {
      dy += newsizes[r] - sizes[r];
      if (dy != 0) {
        for (let c = 0; c < element.rows.length; c++) {
          for (const [id, shape] of getContainedElementsInCell(c, c)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.y += dy;
            })(shape);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }
  }

  return patches;
}
