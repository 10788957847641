import React, { useState } from "react";
import { Group, Shape } from "react-konva";
import styles from "./table-title.module.css";
import { Html } from "react-konva-utils";
import { useAtom, useAtomValue } from "jotai";
import { editingElementIdAtom, posScaleAtom } from "state-atoms";
import { ActionHandler, actions } from "./table-actions";
import consts from "shared/consts";

export function TableTitle({
  id,
  element,
  dispatch,
}: {
  id: string;
  element: { x: number; y: number; title: string };
  dispatch: ActionHandler;
}) {
  const [hover, setHover] = useState(false);
  const [editingId, setEditingId] = useAtom(editingElementIdAtom);
  const editing = editingId === id;

  return editing ? (
    <TableTitleInEdit
      x={element.x}
      y={element.y}
      title={element.title}
      onChange={(value: string) => {
        dispatch(actions.editTitle({ title: value }));
      }}
      onEndEdit={() => {
        setHover(false);
        setEditingId(null);
      }}
    />
  ) : (
    <Group
      id={id}
      x={element.x}
      y={element.y}
      isCanvasElement={true}
      isFrameTitle={true}
      isTableTitle={true}
      isFrame={false}
      isConnector={false}
      isConnectable={false}
      isSelectable={true}
      isTaskConvertible={false}
      element={element}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Shape
        type={consts.CANVAS_ELEMENTS.TABLE}
        fill={hover ? "#0072FF" : "#848199"}
        sceneFunc={(context, shape) => {
          const stage = shape.getStage();
          if (!stage) return;
          const scale = Math.min(stage.scaleX(), 1);
          if (scale < 0.1) return;
          const fontSize = Math.round(12 / scale);
          context.save();
          context._context.font = `${fontSize}px Poppins`;
          context._context.fillStyle = shape.fill();
          if (
            !shape.attrs.cachedTextWidth ||
            shape.attrs.cachedTextWidth.title != element.title ||
            shape.attrs.cachedTextWidth.fontSize != fontSize
          ) {
            const measure = context.measureText(element.title);
            shape.attrs.cachedTextWidth = { fontSize, title: element.title, width: measure.width };
            shape.getSelfRect = () => ({
              x: 0,
              y: -10 / scale,
              width: shape.attrs.cachedTextWidth.width,
              height: 20 / scale,
            });
          }

          context._context.fillText(element.title, 0, -10 / scale);
          context.restore();
        }}
        hitFunc={(context, shape) => {
          const stage = shape.getStage();
          if (!stage) return;
          const scale = Math.min(stage.scaleX(), 1);
          if (scale < 0.1) return;
          const width = shape.attrs.cachedTextWidth?.width ?? 200;
          const fontSize = Math.round(12 / scale);
          context.beginPath();
          context.rect(0, -10 / scale, width, -fontSize);
          context.fillStrokeShape(shape);
        }}
        listening={true}
      />
    </Group>
  );
}

function TableTitleInEdit({
  x,
  y,
  title,
  onChange,
  onEndEdit,
}: {
  x: number;
  y: number;
  title?: string;
  onChange: (value: string) => void;
  onEndEdit: () => void;
}) {
  const posScale = useAtomValue(posScaleAtom);
  const scale = posScale.scale;
  const s2 = Math.min(scale, 1);
  if (scale < 0.1) {
    return null;
  }

  return (
    <Html
      transform={false}
      divProps={{
        style: {
          position: "fixed",
          top: 0,
          left: 0,
          transform: `translate(${(x - 3) * scale + posScale.x}px, ${(y - 30 / s2) * scale + posScale.y}px)
                    scale(${Math.max(scale, 1)})`,
          transformOrigin: "top left",
        },
      }}
    >
      <label className={styles.responsiveInputContainer}>
        <input
          type="text"
          placeholder="Table name..."
          defaultValue={title}
          autoFocus
          onFocus={(e) => {
            e.currentTarget.select();
            (e.currentTarget!.parentNode! as any).dataset.value = e.currentTarget.value || "Table name...";
          }}
          onInput={(e) =>
            ((e.currentTarget!.parentNode! as any).dataset.value = e.currentTarget.value || "Table name...")
          }
          onKeyUp={(e) => {
            if (e.key == "Enter" || e.key == "Escape") {
              onChange(e.currentTarget.value.trim() || "Table");
              onEndEdit();
            }
          }}
          onBlur={(e) => {
            onChange(e.currentTarget.value.trim() || "Table");
            onEndEdit();
          }}
        />
      </label>
    </Html>
  );
}
