import { ElementProps } from "elements/base/provider";
import { ChartElement, ChartType } from "shared/datamodel/schemas/chart";
import { IChartController } from "./chart-controller";
import SingleBarChartComponent from "./single-bar/single-chart-component";
import type { ISingleBarChartController } from "elements/chart/single-bar/single-chart-controller";

export default function ChartElementComponent({ id, controller: _controller }: ElementProps<ChartElement>) {
  const controller = _controller as IChartController;

  switch (controller.getChartType()) {
    case ChartType.singleBar:
      return (
        <SingleBarChartComponent id={id} controller={controller.getInternalController() as ISingleBarChartController} />
      );
    default:
      return null;
  }
}
