import { Channel } from "pusher-js";
import { useEffect, useRef } from "react";

export function usePusher<T>(
  channel: Channel | null | undefined,
  events: string[],
  callback: (data: T) => void,
  dependencies: unknown[]
) {
  // Properly type the handlers object using Record type
  const handlersRef = useRef<Map<string, (data: T) => void>>(new Map());

  useEffect(() => {
    if (!channel) {
      return;
    }

    // Store reference to our specific handlers with proper typing
    const handlers: Map<string, (data: T) => void> = new Map();

    const handler = (data: T) => {
      if (callback) {
        callback(data);
      }
    };

    // Bind events with specific handlers and store them
    for (const event of events) {
      // Store the handler reference for later unbinding
      handlers.set(event, handler);

      // Bind the event with our specific handler
      channel.bind(event, handler);
    }

    // Save handlers to the ref
    handlersRef.current = handlers;

    // Cleanup function - only unbind our specific handlers
    return () => {
      if (channel) {
        for (const event of events) {
          // Unbind only our specific handler for this event
          if (handlersRef.current.get(event)) {
            channel.unbind(event, handlersRef.current.get(event));
            handlersRef.current.delete(event);
          }
        }
      }
    };
  }, [channel, events, callback, dependencies]);
}
