import Konva from "konva";
import { CSSProperties } from "react";

const setMouseCursor = (cursor: CSSProperties["cursor"]) => (e: any, stage?: any) => {
  // Handle case where stage is passed directly
  if (stage) {
    stage.container().style.cursor = cursor;
    return;
  }

  // Handle Konva event target
  if (e?.target instanceof Konva.Node) {
    const targetStage = e.target.getStage();
    if (targetStage) {
      targetStage.container().style.cursor = cursor;
      return;
    }
  }

  // Handle ref.current
  if (e?.current && e.current.getStage?.()) {
    e.current.getStage().container().style.cursor = cursor!;
    return;
  }

  // Fallback to first stage
  const defaultStage = Konva.stages[0];
  if (defaultStage) {
    defaultStage.container().style.cursor = cursor!;
  }

  e?.stopPropagation?.();
  e?.preventDefault?.();
};

export const setMouseColResize = setMouseCursor("col-resize");
export const setMouseRowResize = setMouseCursor("row-resize");
export const setMouseDrag = setMouseCursor("grab");
export const unsetMouse = setMouseCursor("inherit");
export const resetPointer = setMouseCursor("default");
export const setMousePointer = setMouseCursor("pointer");
export const setMouseText = setMouseCursor("text");
