import {
  createBoardIntegration,
  createIntegrationItems,
  createMondaySolution,
  getAccountIntegration,
  getBoardIntegrations,
  getIntegrationItemIds,
  getIntegrationItems,
  updateIntegrationItem,
  updateBoardIntegration as updateBoardIntegrationApi,
} from "frontend/api";
import { atom, useAtom, useSetAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Channel } from "pusher-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { smartOrgChartsIsFullyLoadedAtom } from "state-atoms";
import { MondayIntegrationConfiguration } from "shared/datamodel/schemas/integration-item";
import { mapMondayItemDataForHook } from "shared/integrations/mapper";
import { IntegrationType, MondayIntegrationType } from "shared/integrations/integration";
import { useDebounce } from "./debounce";
import { useThrottle } from "react-use";
import { createPusherInstance } from "frontend/services/pusherService";
import { nanoid } from "nanoid";
import consts from "shared/consts";
import tracking from "frontend/tracking";
import { generateRedirectURL } from "frontend/utils/url-utils";
import {
  BoardIntegration,
  IntegrationItemsMap,
  BoardIntegrationLoadingState,
  MondayItem,
  MondayColumn,
} from "frontend/hooks/use-board-integrations.types";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import { EMPTY_DEFAULT_COLUMN } from "frontend/canvas-designer-new/elements-toolbar/widgets/live-integration/liveIntegrationFiltersPicker";
import { usePusher } from "./use-pusher";
import { ColumnFilter, MondayColumnValue } from "shared/datamodel/live-integration";

const accountIntegrationsAtom = atom<{ id: string; type: IntegrationType }[] | null>(null);
const boardIntegrationsAtom = atomFamily((_documentId: string | null | undefined) =>
  atom<BoardIntegration[] | null>(null)
);
const boardTasks = atomFamily((_documentId: string | null | undefined) => atom<IntegrationItemsMap>({}));

const accountIntegrationLoadingState = atom<BoardIntegrationLoadingState>(BoardIntegrationLoadingState.notLoaded);
const isLoadingBoardIntegrationsAtom = atomFamily((_documentId: string | null | undefined) =>
  atom<BoardIntegrationLoadingState>(BoardIntegrationLoadingState.notLoaded)
);
const integrationLoadingStateAtom = atomFamily((_documentId: string | null | undefined) =>
  atom<{ [id: string]: BoardIntegrationLoadingState }>({})
);
const didBindToPusherAtom = atomFamily((_documentId: string | null | undefined) => atom<boolean>(false));

export function openMondayIntegrationAuthorization() {
  const redirectURL = generateRedirectURL(process.env.MONDAY_INTEGRATION_REDIRECT_URL);
  window.open(redirectURL, "_self");
}

export function useBoardIntegrations(documentId?: string | null) {
  const [accountIntegrations, setAccountIntegrations] = useAtom(accountIntegrationsAtom);
  const [boardIntegrations, setBoardIntegrations] = useAtom(boardIntegrationsAtom(documentId));

  const [isLoadingAccountIntegrations, setIsLoadingAccountIntegrations] = useAtom(accountIntegrationLoadingState);
  const [loadingBoardIntegrationsState, setLoadingBoardIntegrationsState] = useAtom(
    isLoadingBoardIntegrationsAtom(documentId)
  );

  const didFetchIntegrations = accountIntegrations !== null;
  const didFetchBoardIntegrations = boardIntegrations !== null;

  const mondayIntegration = accountIntegrations && accountIntegrations.find((i) => i.type === IntegrationType.monday);

  useEffect(() => {
    if (didFetchIntegrations || isLoadingAccountIntegrations === BoardIntegrationLoadingState.loading) {
      return;
    }
    async function fetchIntegrations() {
      setIsLoadingAccountIntegrations(BoardIntegrationLoadingState.loading);
      try {
        const integrations = await getAccountIntegration();
        setAccountIntegrations(integrations);
        setIsLoadingAccountIntegrations(BoardIntegrationLoadingState.loaded);
      } catch {
        setIsLoadingAccountIntegrations(BoardIntegrationLoadingState.failed);
      }
    }
    fetchIntegrations();
  }, [didFetchIntegrations]);

  useEffect(() => {
    if (!documentId || didFetchBoardIntegrations) {
      return;
    }
    fetchBoardIntegrations();
  }, [documentId]);

  const fetchBoardIntegrations = useCallback(async () => {
    if (!documentId || loadingBoardIntegrationsState === BoardIntegrationLoadingState.loading) {
      return;
    }
    setLoadingBoardIntegrationsState(BoardIntegrationLoadingState.loading);
    try {
      const integrations = await getBoardIntegrations(documentId); // TODO: must catch errors !
      setBoardIntegrations(integrations);
      setLoadingBoardIntegrationsState(BoardIntegrationLoadingState.loaded);
    } catch {
      setLoadingBoardIntegrationsState(BoardIntegrationLoadingState.failed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, loadingBoardIntegrationsState]);

  async function addBoardIntegration(integrationId: string, config: any) {
    if (!documentId) {
      return;
    }
    const { boardIntegrationId } = await createBoardIntegration(documentId, integrationId, config);
    await fetchBoardIntegrations();
    return boardIntegrationId;
  }

  async function addMondayIntegration(
    boardId: string,
    integrationId: string,
    columns: any[],
    type: MondayIntegrationType = MondayIntegrationType.tasks,
    selectedColumns?: any,
    parentBoardId?: string,
    isSubitemsBoard: boolean = false
  ): Promise<string> {
    const existingIntegration = boardIntegrations?.find(
      (i) => i.configuration.boardId === boardId && i.configuration.type === type && i.isOwner
    );
    if (existingIntegration) {
      return existingIntegration.id;
    }

    const config: MondayIntegrationConfiguration = {
      boardId,
      type,
      columns: selectedColumns ?? getDefaultColumnsMapping(columns, type),
      isSubitemsBoard,
      parentBoardId,
    };
    const createdId = await addBoardIntegration(integrationId, config);
    return createdId;
  }

  function getDefaultColumnsMapping(columns: any[], subType: MondayIntegrationType) {
    const selectedColumns = [];
    switch (subType) {
      case MondayIntegrationType.tasks:
      case MondayIntegrationType.live: {
        const statusColumn = columns.find((c) => c.type === "status");
        const dateColumn = columns.find((c) => c.type === "date");
        const peopleColumn = columns.find((c) => c.type === "multiple-person");
        const selectedColumns = [];
        if (statusColumn) {
          selectedColumns.push(statusColumn);
        }
        if (dateColumn) {
          selectedColumns.push(dateColumn);
        }
        if (peopleColumn) {
          selectedColumns.push(peopleColumn);
        }
        return selectedColumns;
      }
      case MondayIntegrationType.org_chart: {
        {
          const parentColumn = columns.find((c) => c.title === "parent");
          const selfColumn = columns.find((c) => c.title === "self");
          const jobTitleColumn = columns.find((c) => c.title === "position");
          if (parentColumn) {
            selectedColumns.push(parentColumn);
          }
          if (selfColumn) {
            selectedColumns.push(selfColumn);
          }
          if (jobTitleColumn) {
            selectedColumns.push(jobTitleColumn);
          }
        }
        return selectedColumns;
      }
    }
  }

  async function updateBoardIntegration(integrationId: string, config: any) {
    if (!documentId) {
      return;
    }
    try {
      setBoardIntegrations((prev) => {
        if (!prev) {
          return prev;
        }
        const integration = prev.find((i) => i.id === integrationId);
        if (!integration) {
          return prev;
        }
        integration.configuration = config;
        return [...prev];
      });
      await updateBoardIntegrationApi(integrationId, config);
      setLoadingBoardIntegrationsState(BoardIntegrationLoadingState.loaded);
    } catch {
      setLoadingBoardIntegrationsState(BoardIntegrationLoadingState.failed);
    }
    // if we fetch here we can get old data if user deselected 2 columns quickly
    // await fetchBoardIntegrations();
  }

  return {
    accountIntegrations,
    boardIntegrations,
    mondayIntegration,
    addBoardIntegration,
    addMondayIntegration,
    updateBoardIntegration,
    reloadBoardIntegrations: fetchBoardIntegrations,
    isLoadingAccountIntegrations,
    isLoaded: didFetchBoardIntegrations,
    loadingBoardIntegrationsState,
    shouldAddMondayIntegration: !mondayIntegration,
  };
}

export function useBoardTasks(documentId?: string | null, pusherChannel?: Channel | null) {
  const {
    boardIntegrations,
    isLoaded,
    isLoadingAccountIntegrations,
    loadingBoardIntegrationsState,
    addMondayIntegration,
    updateBoardIntegration,
    reloadBoardIntegrations,
    mondayIntegration,
    shouldAddMondayIntegration,
  } = useBoardIntegrations(documentId);

  const [dataSource, setDataSource] = useAtom(boardTasks(documentId));
  const [integrationsLoadingState, setIntegrationsLoadingState] = useAtom(integrationLoadingStateAtom(documentId));
  const [didBindToPusher, setDidBindToPusher] = useAtom(didBindToPusherAtom(documentId));
  const [loadItemsQueue, setLoadItemsQueue] = useState<{ [integrationId: string]: string[] } | null>(null);
  const debouncedQueue = useDebounce(loadItemsQueue, 200);

  const shouldExcludeRestrictedColumns = useFeatureFlag("restricted-columns");

  const tasksIntegrations = boardIntegrations?.filter(
    (i) => i.type === IntegrationType.monday && i.configuration.type === MondayIntegrationType.tasks && i.isOwner
  );

  const tasksBoardIntegration = tasksIntegrations && tasksIntegrations.length > 0;

  useEffect(() => {
    if (!pusherChannel || didBindToPusher) return;

    // Set flag indicating we've bound to Pusher
    setDidBindToPusher(true);
  }, [pusherChannel, didBindToPusher, setDidBindToPusher]);

  // Use the hook to handle the Pusher events
  usePusher(
    pusherChannel,
    ["integration_data_changed", "live_integration_data_changed"],
    (data) => {
      handlePusherEvent(data);
    },
    [pusherChannel]
  );

  useEffect(() => {
    if (debouncedQueue == null) return;
    const fetchItemsMap = Object.entries(debouncedQueue).reduce((acc, [integrationId, itemIds]) => {
      if (itemIds.length > 0) {
        // monday allow max 100 items per request
        acc[integrationId] = { itemIds: itemIds.slice(0, 100) };
      }
      return acc;
    }, {} as { [integrationId: string]: { itemIds: string[] } });
    if (Object.keys(fetchItemsMap).length === 0) {
      setLoadItemsQueue(null);
      return;
    }
    fetchItems(fetchItemsMap).then(() => {
      const restItems = Object.entries(debouncedQueue).reduce((acc, [integrationId, itemIds]) => {
        if (itemIds.length > 100) {
          // if there are more than 100 items, add the rest to the queue
          acc[integrationId] = itemIds.slice(100);
        }
        return acc;
      }, {} as { [integrationId: string]: string[] });
      setLoadItemsQueue(Object.keys(restItems).length > 0 ? restItems : null);
    });
  }, [debouncedQueue]);

  const addItemsToQueue = useCallback(
    (integrations: { [integrationId: string]: string[] }, override = false) => {
      // filter existing ids from `dataSource`
      let shouldReloadIntegrations = false;
      const withoutExisting = override
        ? integrations
        : Object.entries(integrations).reduce((acc, [integrationId, itemIds]) => {
            const integrationData = dataSource?.[integrationId];
            if (!integrationData) {
              shouldReloadIntegrations = true;
              acc[integrationId] = itemIds;
              return acc;
            }
            const existingIds = integrationData.items.map((i) => i.id);
            const newIds = itemIds.filter((id) => !existingIds.includes(id));
            acc[integrationId] = newIds;
            return acc;
          }, {} as { [integrationId: string]: string[] });
      if (shouldReloadIntegrations) {
        reloadBoardIntegrations();
      }
      setLoadItemsQueue((prev) => {
        const newQueue = prev ? { ...prev } : {};
        for (const [integrationId, itemIds] of Object.entries(withoutExisting)) {
          const oldItemIds = newQueue[integrationId] ?? [];
          newQueue[integrationId] = [...oldItemIds, ...itemIds];
        }
        return newQueue;
      });
    },
    [dataSource, reloadBoardIntegrations]
  );

  async function fetchItems(
    integrations: {
      [integrationId: string]: {
        itemIds?: string[];
        pageCursor?: string;
        limit?: number;
        query?: string;
        allItems?: boolean;
      };
    },
    shouldExcludeRestrictedColumns?: boolean,
    mandatoryMappedColumnIds: string[] = [],
    shouldReduceLoadingTime: boolean = false,
    abortController?: AbortController
  ) {
    if (!documentId) {
      return;
    }
    const integrationEntries = Object.entries(integrations);

    function setStateForAllIntegrations(state: BoardIntegrationLoadingState) {
      const newLoadingState = integrationEntries.reduce((acc, [integrationId]) => {
        acc[integrationId] = state;
        return acc;
      }, {} as typeof integrationsLoadingState);
      setIntegrationsLoadingState((prev) => ({ ...prev, ...newLoadingState }));
    }

    setStateForAllIntegrations(BoardIntegrationLoadingState.loading);

    let itemsMap: IntegrationItemsMap;
    try {
      itemsMap = (await getIntegrationItems(documentId, integrations, {
        abortController,
        shouldExcludeRestrictedColumns,
        mandatoryMappedColumnIds,
        shouldReduceLoadingTime,
      })) as IntegrationItemsMap;
      const newStaleState = integrationEntries.reduce((acc, [integrationId]) => {
        const didFail = !!itemsMap[integrationId]?.error;
        if (didFail) {
          acc[integrationId] = BoardIntegrationLoadingState.failed;
          return acc;
        }
        acc[integrationId] = BoardIntegrationLoadingState.stale;
        return acc;
      }, {} as typeof integrationsLoadingState);
      setIntegrationsLoadingState((prev) => ({ ...prev, ...newStaleState }));
    } catch {
      setStateForAllIntegrations(BoardIntegrationLoadingState.failed);
      return;
    }

    // merge itemsMap with existing data
    setDataSource((prev) => {
      const map = prev ? { ...prev } : {};
      for (const [integrationId, data] of Object.entries(itemsMap)) {
        const existingData = map[integrationId] ?? {};
        // filter out new items that already exist
        const newItems = existingData.items
          ? existingData.items.filter((i) => data.items?.find((ni) => ni.id === i.id) === undefined)
          : [];
        newItems.push(...data.items);
        const loadedIntegrationContext = integrations[integrationId];
        const isPageLoad = !loadedIntegrationContext.itemIds && !loadedIntegrationContext.query;
        // add new items
        let newData;
        if (isPageLoad || Object.keys(existingData).length === 0) {
          newData = {
            ...existingData,
            ...data,
            columns: data.columns ?? existingData.columns,
            items: newItems,
            partial: !data.pageCursor,
          };
        } else {
          // if not page load, change nothing
          newData = {
            ...existingData,
            items: newItems,
          };
        }
        map[integrationId] = newData;
      }
      return map;
    });

    return itemsMap;
  }

  function getBoardIntegration(id: string) {
    return boardIntegrations?.find((i) => i.id === id);
  }

  function handlePusherEvent(event: any) {
    const { integrationId, data } = event;
    const { itemId, name, columnValues = [] } = data;
    if (!itemId) {
      return;
    }
    setDataSource((prev) => {
      if (!prev) {
        return prev;
      }
      const integrationData = prev[integrationId];
      if (!integrationData) {
        return prev;
      }
      const index = integrationData.items.findIndex((i) => i.id === itemId.toString());
      if (index === -1) {
        return prev;
      }
      const item = integrationData.items[index];
      const changedColumnValue = columnValues.length > 0 ? columnValues[0] : null;
      const newColumnValues =
        changedColumnValue &&
        item.columnValues.map((c: any) => (c.id === changedColumnValue.id ? { ...c, ...changedColumnValue } : c));
      const newItem = {
        ...item,
        name: name ?? item.name,
        columnValues: newColumnValues ?? item.columnValues,
      };
      try {
        const newNew = mapMondayItemDataForHook(newItem, changedColumnValue, integrationData.configuration);
        integrationData.items[index] = newNew;
      } catch {
        integrationData.items[index] = newItem;
      }
      integrationData.pusherTimestamp = new Date().toLocaleString();
      return {
        ...prev,
        [integrationId]: integrationData,
      };
    });
  }

  const updateItem = useCallback(
    (id: string, integrationId: string, data: any) => {
      const { name, columnValue } = data;
      setDataSource((prev) => {
        if (!prev) {
          return prev;
        }
        const integrationData = prev[integrationId];
        if (!integrationData) {
          return prev;
        }
        const index = integrationData.items.findIndex((i) => i.id === id);
        if (index === -1) {
          return prev;
        }
        const item = integrationData.items[index];
        let newColumnValues: any[] | undefined;
        if (columnValue) {
          const column = integrationData.columns.find((c) => c.id === columnValue.id);
          const oldColumnValueIndex = item.columnValues.findIndex((c: any) => c.id === columnValue.id);
          if (oldColumnValueIndex !== -1) {
            if (columnValue && columnValue.value) {
              newColumnValues = [...item.columnValues];
              newColumnValues[oldColumnValueIndex] = columnValue;
            } else {
              newColumnValues = [...item.columnValues];
              newColumnValues.splice(oldColumnValueIndex, 1);
            }
          } else if (columnValue && column) {
            const newColumnValue = { ...columnValue, type: column.type, title: column.title };
            newColumnValues = [...item.columnValues, newColumnValue];
          }
        }

        const newItem = {
          ...item,
          name: name ?? item.name,
          columnValues: newColumnValues ?? item.columnValues,
        };
        integrationData.items[index] = newItem;
        return {
          ...prev,
          [integrationId]: integrationData,
        };
      });
    },
    [setDataSource]
  );

  const normalizeColumnValue = useCallback(
    (integrationId: string, columnId: string, value: any) => {
      if (columnId === "name") {
        return { name: value };
      }
      const integrationData = dataSource && dataSource[integrationId];
      if (!integrationData) {
        return;
      }
      const column = integrationData.columns.find((column) => column.id === columnId);
      if (!column) {
        return;
      }
      switch (column.type) {
        case "status": {
          const settings = column.settings_str ? JSON.parse(column.settings_str) : {};
          const index = value.index;
          if (!index || index.toString() === "5") {
            return { columnValue: { id: columnId, value: null } };
          } else {
            const text = settings.labels[index];
            const color = settings.labels_colors[index]?.color;
            return { columnValue: { id: columnId, value: { text, color }, type: "status" } };
          }
        }
        case "date": {
          if (Number.isNaN(Date.parse(value.date))) {
            return;
          }
          return { columnValue: { id: columnId, value: value.date, type: "date" } };
        }
        case "text": {
          return { columnValue: { id: columnId, value: value, type: "text" } };
        }
      }
    },
    [dataSource]
  );

  const updateColumnValue = useCallback(
    async (
      integrationId: string,
      itemId: string,
      columnId: string,
      value: any
    ): Promise<{ status: "success" | "faild" | "not-fulfilled"; reason?: string }> => {
      const integrationData = dataSource && dataSource[integrationId];
      if (!integrationData) {
        return {
          status: "not-fulfilled",
        };
      }
      const item = integrationData.items.find((i) => i.id === itemId);
      if (!item) {
        return {
          status: "not-fulfilled",
        };
      }
      const { value: previousValue, type } =
        columnId === "name"
          ? { value: item.name, type: "name" }
          : item.columnValues.find((x: any) => x.id === columnId) ?? {};
      console.log("updateColumnValue", integrationId, itemId, columnId, value);
      const normalized = normalizeColumnValue(integrationId, columnId, value);
      if (!normalized) {
        return {
          status: "not-fulfilled",
        };
      }
      updateItem(itemId, integrationId, normalized);
      try {
        await updateIntegrationItem(integrationId, itemId, { [columnId]: value });
        return {
          status: "success",
        };
      } catch (e: any) {
        console.error(e);
        if (columnId === "name") {
          updateItem(itemId, integrationId, { name: previousValue });
        } else {
          updateItem(itemId, integrationId, { columnValue: { id: columnId, value: previousValue, type } });
        }
        return {
          status: "faild",
          reason: e?.response?.data?.message,
        };
      }
    },
    [dataSource, normalizeColumnValue, updateItem]
  );

  async function createSubitems({
    boardId,
    subitemsBoardId,
    parentItemId,
    columnValues,
    columns,
    titles,
  }: {
    boardId: string;
    subitemsBoardId: string;
    parentItemId: string;
    columnValues: any;
    columns?: any[];
    titles: { [id: string]: string };
  }): Promise<{ integrationId?: string; itemIds?: { [id: string]: string } }> {
    if (!dataSource || !mondayIntegration) {
      return {};
    }

    const integrationData = Object.entries(dataSource).find(([, data]) => data.boardId === subitemsBoardId);
    let integrationId = integrationData?.[0];
    if (!integrationData) {
      integrationId = await addMondayIntegration(
        subitemsBoardId,
        mondayIntegration?.id,
        columns ?? [],
        MondayIntegrationType.tasks,
        undefined,
        boardId,
        true
      );
      addItemsToQueue({ [integrationId!]: [] });
    }
    if (!integrationId) {
      return {};
    }

    const res = await createIntegrationItems(integrationId, titles, undefined, columnValues, parentItemId);
    const ids = Object.values(res) as string[];
    addItemsToQueue({ [integrationId]: ids });
    return { integrationId, itemIds: res };
  }

  async function createItems(
    boardId: string,
    titles: { [id: string]: string },
    groupId: string | undefined,
    columnValues: any,
    columns?: any[]
  ): Promise<{ integrationId?: string; itemIds?: { [id: string]: string } }> {
    if (!dataSource || !mondayIntegration) {
      return {};
    }

    const integrationData = Object.entries(dataSource).find(([, data]) => data.boardId === boardId);
    let integrationId = integrationData?.[0];
    if (!integrationData) {
      integrationId = await addMondayIntegration(
        boardId,
        mondayIntegration?.id,
        columns ?? [],
        MondayIntegrationType.tasks
      );
      addItemsToQueue({ [integrationId!]: [] });
    }
    if (!integrationId) {
      return {};
    }
    const res = await createIntegrationItems(integrationId, titles, groupId, columnValues);
    const ids = Object.values(res) as string[];
    addItemsToQueue({ [integrationId]: ids });
    return { integrationId, itemIds: res };
  }

  function combineItemWithColumns(item: MondayItem, columns: MondayColumn[]) {
    return {
      ...item,
      columns,
    };
  }

  const getBoardIntegrationConfig = useCallback(
    (integrationId: string) => {
      const integration = boardIntegrations?.find((i) => i.id === integrationId);
      if (!integration) {
        return null;
      }
      const data = dataSource?.[integrationId];
      return { config: integration.configuration, columns: data?.columns, boardName: data?.name };
    },
    [boardIntegrations, dataSource]
  );

  const getLoadingState = useCallback(
    (integrationId: string) => {
      return integrationsLoadingState[integrationId] ?? BoardIntegrationLoadingState.notLoaded;
    },
    [integrationsLoadingState]
  );

  const getItem = useCallback(
    (id: string, integrationId: string) => {
      const integrationData = dataSource && dataSource[integrationId];
      if (!integrationData) {
        return null;
      }
      const item = integrationData.items.find((i) => i.id === id);
      if (!item) {
        return null;
      }
      return combineItemWithColumns(item, integrationData.columns);
    },
    [dataSource]
  );

  const getItems = useCallback(
    (integrationId: string) => {
      const integrationData = dataSource && dataSource[integrationId];
      if (!integrationData) {
        return null;
      }
      return integrationData.items.map((item) => combineItemWithColumns(item, integrationData.columns));
    },
    [dataSource]
  );

  async function createMondayBoardForTemplate(
    mondaySolutionId: string,
    mondayColumns: any[],
    templateElements: Record<string, any>
  ) {
    if (!mondayIntegration || !documentId) {
      return templateElements;
    }
    // the creation id is used to identify the specific template creation
    // and when monday is finished (the template creation is async) they call us back
    // to a url containing this creation id, and then we send the created board ids
    // on this specific unique channel
    const creationId = nanoid(12);

    const pusher = createPusherInstance();
    const channelName = `template-creation-${creationId}`;

    const channel = pusher.subscribe(channelName);

    const startTime = new Date().getTime();
    let currentTime = startTime;
    let timeFromPreviousStep = 0;
    let timeFromStart = 0;
    tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "start_create_hybrid_template", startTime);
    // Create listener for the template creation, before sending command to create
    const creationCallback = new Promise<{ boardIds?: string[] }>((resolve, reject) => {
      channel.bind("template_creation_success", (data: any) => {
        timeFromPreviousStep = new Date().getTime() - currentTime;
        currentTime += timeFromPreviousStep;
        timeFromStart += timeFromPreviousStep;
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.INTEGRATIONS,
          "created_boards_for_hybrid_template",
          timeFromStart,
          timeFromPreviousStep
        );
        resolve(data);
      });
      channel.bind("template_creation_failed", () => {
        timeFromPreviousStep = new Date().getTime() - currentTime;
        currentTime += timeFromPreviousStep;
        timeFromStart += timeFromPreviousStep;
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.INTEGRATIONS,
          "failed_to_create_boards_for_hybrid_template",
          timeFromStart,
          timeFromPreviousStep
        );
        reject("Failed to create template");
      });
    });

    //

    // duplicate template board to the user's monday account
    try {
      await createMondaySolution(mondayIntegration.id, mondaySolutionId, creationId);
    } catch (error) {
      console.error("failed to create monday solution", error);
      // assume the user didn't authorize the integration
      // or they have an older oath permissions that doesn't allow creating boards
      openMondayIntegrationAuthorization();
      return;
    }
    timeFromPreviousStep = new Date().getTime() - currentTime;
    currentTime += timeFromPreviousStep;
    timeFromStart += timeFromPreviousStep;
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.INTEGRATIONS,
      "created_solution_for_hybrid_template",
      timeFromStart,
      timeFromPreviousStep
    );

    // the created board ids are sent to our backend with a callback from monday
    // and we send it back here with pusher
    const { boardIds } = await creationCallback;
    pusher.disconnect();

    if (!boardIds || boardIds.length < 1) {
      throw new Error("Failed to create template");
    }

    // now that we have the created monday board id, we'll create an integration to that board
    const integrationId = await addMondayIntegration(boardIds[0], mondayIntegration.id, [], undefined, mondayColumns);
    timeFromPreviousStep = new Date().getTime() - currentTime;
    currentTime += timeFromPreviousStep;
    timeFromStart += timeFromPreviousStep;
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.INTEGRATIONS,
      "created_board_integration_for_hybrid_template",
      timeFromStart,
      timeFromPreviousStep
    );

    const { itemIds, itemAssetIds, itemsByGroups } = await getIntegrationItemIds({
      documentId,
      integrationId,
      filters: [],
      includeAssetIds: true,
      includeGroupIds: true,
      shouldExcludeRestrictedColumns,
    });
    timeFromPreviousStep = new Date().getTime() - currentTime;
    currentTime += timeFromPreviousStep;
    timeFromStart += timeFromPreviousStep;
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.INTEGRATIONS,
      "get_items_for_hybrid_template",
      timeFromStart,
      timeFromPreviousStep
    );

    const mutatedElements = Object.entries(templateElements).reduce((acc, [id, element], index) => {
      if (element.type === consts.CANVAS_ELEMENTS.INTEGRATION) {
        let itemId = itemIds[index % itemIds.length];
        const groupId = element.configuration.groupId;
        if (groupId && itemsByGroups[groupId]) {
          // if we have groups, we'll assign the items to the groups
          itemId = itemsByGroups[groupId][index % itemsByGroups[groupId].length];
        }
        const newConfig = { ...element.configuration, itemId };
        if (newConfig.previewAssetIds) {
          newConfig.previewAssetIds = itemAssetIds[index % itemAssetIds.length];
        }
        acc[id] = { ...element, integrationId, configuration: newConfig };
      } else {
        acc[id] = element;
      }
      return acc;
    }, {} as Record<string, any>);

    timeFromPreviousStep = new Date().getTime() - currentTime;
    timeFromStart += timeFromPreviousStep;
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.INTEGRATIONS,
      "finished_creating_hybrid_template",
      timeFromStart,
      timeFromPreviousStep
    );

    return mutatedElements;
  }

  // TODO: generalize to other integrations
  return {
    shouldAddMondayIntegration,
    shouldAddTasksBoard: !tasksBoardIntegration,
    mondayIntegration,
    tasksIntegrations,
    isLoaded, // if loaded and no tasks board, we're done
    isLoadingAccountIntegrations,
    loadingBoardIntegrationsState,
    dataSource,

    addMondayIntegration,
    addItemsToQueue,
    fetchItems,
    getBoardIntegration,
    getBoardIntegrationConfig,
    getLoadingState,
    updateBoardIntegration,
    getItem,
    getItems,
    updateItem,
    updateColumnValue,
    createItems,
    createSubitems,
    createMondayBoardForTemplate,
  };
}

export function useMondayTask(
  documentId?: string | null,
  integrationId?: string,
  itemId?: string,
  pusherChannel?: Channel | null
) {
  const {
    getItem,
    getBoardIntegrationConfig,
    getLoadingState,
    updateColumnValue,
    isLoadingAccountIntegrations,
    loadingBoardIntegrationsState,
  } = useBoardTasks(documentId, pusherChannel);

  const isFailed = useMemo(
    () => getLoadingState(integrationId ?? "") === BoardIntegrationLoadingState.failed,
    [integrationId, getLoadingState, documentId]
  );

  const item = useMemo(() => {
    if (!integrationId || !itemId) {
      return;
    }
    return getItem(itemId, integrationId);
  }, [integrationId, itemId, getItem, documentId]);

  const isLoading = useMemo(
    () => !item && getLoadingState(integrationId ?? "") === BoardIntegrationLoadingState.loading,
    [integrationId, getLoadingState, item, documentId]
  );

  function updateItemData(
    columnId: string,
    value: any
  ): Promise<{
    status: "success" | "faild" | "not-fulfilled";
    reason?: string;
  }> {
    if (!itemId || !integrationId) {
      return Promise.resolve({
        status: "not-fulfilled",
      });
    }
    return updateColumnValue(integrationId, itemId, columnId, value);
  }

  if (
    !integrationId ||
    !itemId ||
    isLoadingAccountIntegrations != BoardIntegrationLoadingState.loaded ||
    loadingBoardIntegrationsState != BoardIntegrationLoadingState.loaded
  ) {
    return {
      item: null,
      integrationConfig: {},
      allColumns: [],
      updateItemData,
      isLoading,
      isFailed,
      isLoadingAccountIntegrations,
      loadingBoardIntegrationsState,
    };
  }

  const { config = {}, columns = [], boardName } = getBoardIntegrationConfig(integrationId) ?? {};

  return {
    item: { ...item, boardName },
    updateItemData,
    isLoading,
    isFailed,
    integrationConfig: config,
    allColumns: columns,
    isLoadingAccountIntegrations,
    loadingBoardIntegrationsState,
  };
}

export function useMondayOrgChart(
  chartId: string,
  documentId?: string | null,
  integrationId?: string,
  pusherChannel?: Channel | null,
  shouldExcludeRestrictedColumns?: boolean,
  mandatoryMappedColumnIds?: string[],
  shouldReduceLoadingTime?: boolean
) {
  const { getItems, fetchItems, getLoadingState, dataSource } = useBoardTasks(documentId, pusherChannel);
  const isLoading = useMemo(
    () => getLoadingState(integrationId ?? "") === BoardIntegrationLoadingState.notLoaded,
    [integrationId, getLoadingState]
  );

  const setIsChartsFullyLoaded = useSetAtom(smartOrgChartsIsFullyLoadedAtom);

  useEffect(() => {
    if (!integrationId) {
      return;
    }

    if (!shouldReduceLoadingTime) {
      fetchItems({ [integrationId]: { allItems: true } }, shouldExcludeRestrictedColumns);
      return;
    }

    fetchItemsForChart(integrationId);
  }, []);

  async function fetchMinimalData(integrationId: string, abortController: AbortController) {
    await fetchItems(
      { [integrationId]: { allItems: true } },
      shouldExcludeRestrictedColumns,
      mandatoryMappedColumnIds,
      shouldReduceLoadingTime,
      abortController
    );
  }

  async function fetchFullData(integrationId: string, abortController: AbortController) {
    await fetchItems({ [integrationId]: { allItems: true } }, shouldExcludeRestrictedColumns);
    abortController.abort();
    setIsChartsFullyLoaded((prevState) => ({ ...prevState, [chartId]: true }));
  }

  async function fetchItemsForChart(integrationId: string) {
    const abortController = new AbortController();
    setIsChartsFullyLoaded((prevState) => ({ ...prevState, [chartId]: false }));

    fetchMinimalData(integrationId, abortController);
    fetchFullData(integrationId, abortController);
  }

  const items = useMemo(() => {
    if (!integrationId) {
      return null;
    }
    return getItems(integrationId);
  }, [integrationId, getItems]);

  const integrationData = dataSource && integrationId && dataSource[integrationId];
  if (!integrationData) {
    return { items: null, isLoaded: false, pusherTimestamp: "" };
  }

  return { items: items, isLoaded: !isLoading, pusherTimestamp: dataSource[integrationId].pusherTimestamp ?? "" };
}

function filterOutEmptyValues(filters: ColumnFilter[]) {
  return filters.filter(
    (filter: ColumnFilter) => filter.columnId !== EMPTY_DEFAULT_COLUMN.id && filter.columnValues.length > 0
  );
}

export function useFilteredItemIds(
  filters: ColumnFilter[] | undefined,
  integrationId?: string,
  documentId?: string | null,
  pusherChannel?: Channel | null
) {
  const { addItemsToQueue } = useBoardTasks(documentId);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [itemIds, setItemIds] = useState<Set<string>>(new Set());
  const [loadingState, setLoadingState] = useState(BoardIntegrationLoadingState.notLoaded);
  const [reloadData, setReloadData] = useState(false);
  const debouncedReloadData = useThrottle(reloadData, 5000);

  const shouldExcludeRestrictedColumns = useFeatureFlag("restricted-columns");

  const validFilters = filterOutEmptyValues(filters ?? []);
  // used in useEffect to identify changes in filters (filters as an array is not comparable by value)
  const stringifiedFilters = JSON.stringify(validFilters);

  useEffect(() => {
    // reset on filters change so we won't show irrelevant items
    if (documentId && integrationId) {
      getItems(documentId, integrationId, validFilters, undefined, true);
    }
  }, [documentId, integrationId, stringifiedFilters]);

  const handlePusherEvent = useCallback(
    (event: any) => {
      const { data = {} } = event;
      let shouldReload = false;
      const changedColumnValues = data.columnValues !== undefined;
      const changedColumnIsInFilter = data.columnValues?.some((c: MondayColumnValue) => {
        const filter = validFilters.find((validFilter: ColumnFilter) => validFilter.columnId === c.id);
        return filter != null;
      });
      // should reload if column values are undefined or if the column is in the filters
      if (!changedColumnValues || changedColumnIsInFilter) {
        shouldReload = true;
      }
      if (shouldReload) {
        setReloadData(true);
      }
    },
    [integrationId]
  );

  usePusher(
    pusherChannel,
    ["live_integration_data_changed"],
    (data) => {
      handlePusherEvent(data);
    },
    [pusherChannel, handlePusherEvent]
  );

  useEffect(() => {
    if (debouncedReloadData && documentId && integrationId) {
      setReloadData(false);
      getItems(documentId, integrationId, validFilters, undefined, true);
    }
  }, [debouncedReloadData]);

  async function getItems(documentId: string, integrationId: string, filters: any, cursor?: string, override = false) {
    setLoadingState(BoardIntegrationLoadingState.loading);

    try {
      const { itemIds, pageCursor } = await getIntegrationItemIds({
        documentId,
        integrationId,
        filters,
        cursor,
        shouldExcludeRestrictedColumns,
      });
      setCursor(pageCursor || undefined);
      addItemsToQueue({ [integrationId]: itemIds }, override);
      if (override) {
        setItemIds(new Set(itemIds));
      } else {
        setItemIds((current) => {
          const newSet = new Set(current);
          itemIds.forEach((id: string) => newSet.add(id));
          return newSet;
        });
      }
      setLoadingState(BoardIntegrationLoadingState.loaded);
    } catch (error) {
      console.error("failed to get integration items", error);
      setLoadingState(BoardIntegrationLoadingState.failed);
    }
  }

  function fetchNextPage({ force = false } = {}) {
    if (!(documentId && integrationId && (cursor || force))) {
      return;
    }
    getItems(documentId, integrationId, validFilters, cursor, force);
  }

  return {
    itemIds: Array.from(itemIds.values()),
    fetchNextPage,
    isLoading: loadingState === BoardIntegrationLoadingState.loading,
  };
}
