import Konva from "konva";
import { CSSProperties } from "react";
import { is } from "rambda";

export class MouseCursor {
  private static set(stage: Konva.Stage | null, cursor: CSSProperties["cursor"]) {
    if (stage != null) stage.container().style.cursor = cursor || "inherit";
  }

  private static getStage(e: unknown): Konva.Stage | null {
    if (e instanceof Konva.Stage) {
      return e;
    }
    if (is(Object, e)) {
      if ("currentTarget" in e) return e.currentTarget.getStage();
      if ("current" in e) return e.current;
    }
    return null;
  }

  static setColResize = (e: unknown) => MouseCursor.set(MouseCursor.getStage(e), "col-resize");
  static setRowResize = (e: unknown) => MouseCursor.set(MouseCursor.getStage(e), "row-resize");
  static setPointer = (e: unknown) => MouseCursor.set(MouseCursor.getStage(e), "pointer");
  static unset = (e: unknown) => MouseCursor.set(MouseCursor.getStage(e), "inherit");
}
