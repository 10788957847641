import React, { useMemo, useRef, useState } from "react";
import style from "./floaters.module.css";
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  FloatingFocusManager,
  FloatingPortal,
  FloatingOverlay,
  FloatingTree,
  useFloatingNodeId,
  arrow,
  useHover,
} from "@floating-ui/react";
import { mergeRefs } from "react-merge-refs";
import cn from "classnames";

interface FloaterProps {
  floaterDisabled?: boolean;
  side?: Placement;
  children: JSX.Element; // must be jsx element and not a ReactNode, because we need a 'ref' to it
}

interface TooltipProps extends Partial<FloaterProps> {
  label?: string;
  tooltipClassName?: string;
}

interface PopupProps extends Partial<FloaterProps> {
  render: (closePopup: () => void) => React.ReactNode;
  popupClassName?: string;
}

interface DialogProps {
  onClose?: (open: boolean) => void;
  children: React.ReactNode;
}

function getArrowPlacement(elementPlacement: Placement) {
  return elementPlacement.startsWith("top")
    ? "bottom"
    : elementPlacement.startsWith("bottom")
    ? "top"
    : elementPlacement.startsWith("left")
    ? "right"
    : "left";
}

export const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  const { label, floaterDisabled = false, side = "top", children, tooltipClassName } = props;

  const [open, setOpen] = useState(false);
  const arrowRef = useRef<any>(null);

  const {
    x,
    y,
    placement,
    refs,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: side,
    open,
    onOpenChange: setOpen,
    middleware: [offset(15), flip(), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([useHover(context)]);

  // Preserve the consumer's ref
  const ref2 = useMemo(() => mergeRefs([refs.floating, ref, (children as any).ref]), [refs.floating, children, ref]);

  const arrowSide = getArrowPlacement(placement);

  return (
    <>
      {React.cloneElement(children!, getReferenceProps({ ...children!.props, ref: ref2 }))}
      {open && !floaterDisabled && (
        <div
          {...getFloatingProps({
            ref: refs.floating,
            className: cn(tooltipClassName, style.tooltip),
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              opacity: open ? 1 : 0,
            },
          })}
        >
          <div
            ref={arrowRef}
            className={style.arrow}
            style={{
              left: arrowX != null ? `${arrowX}px` : "",
              top: arrowY != null ? `${arrowY}px` : "",
              right: "",
              bottom: "",
              [arrowSide]: "-5px",
            }}
          />
          {label}
        </div>
      )}
    </>
  );
});

Tooltip.displayName = "Tooltip";

export const Popover = ({ render, side, popupClassName, children }: PopupProps) => {
  const [open, setOpen] = useState(false);
  const nodeId = useFloatingNodeId();
  const arrowRef = useRef<any>(null);

  side ||= "bottom";
  const {
    placement,
    refs,
    floatingStyles,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: side,
    open,
    onOpenChange: setOpen,
    nodeId,
    middleware: [offset(15), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);
  const arrowSide = getArrowPlacement(placement);

  return (
    <>
      {children &&
        React.cloneElement(children, {
          ...getReferenceProps(),
          ref: refs.setReference,
        })}
      {open && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps({
            className: popupClassName ?? style.popover,
          })}
        >
          <div
            ref={arrowRef}
            className={style.arrow}
            style={{
              left: arrowX != null ? `${arrowX}px` : "",
              top: arrowY != null ? `${arrowY}px` : "",
              right: "",
              bottom: "",
              [arrowSide]: "-5px",
            }}
          />
          {render(() => setOpen(false))}
        </div>
      )}
    </>
  );
};

export function Dialog({ onClose, children }: DialogProps) {
  const { refs, context } = useFloating({
    open: true,
    onOpenChange: onClose,
  });
  const { getFloatingProps } = useInteractions([useDismiss(context)]);

  return (
    <FloatingPortal>
      <FloatingTree>
        <FloatingOverlay
          lockScroll
          style={{
            display: "grid",
            placeItems: "center",
            background: "rgba(11,38,66,0.6)",
            zIndex: 10000,
          }}
        >
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
              })}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingTree>
    </FloatingPortal>
  );
}
