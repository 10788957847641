import consts, { TypeCanvasElement } from "shared/consts";
import GanttElementProvider from "elements/gantt/provider";
import { elementGraphicsProvidersRegistry, elementProvidersRegistry } from "elements/registry";
import GanttGraphicsProvider from "elements/gantt/graphics";
import type { Reflect } from "@workcanvas/reflect/client";
import type { M } from "shared/datamodel/mutators";
import UndoRedoStack from "utils/undo-redo/undo-redo-stack";
import { CheckoutSources } from "frontend/billing/checkout";
import { MovingElements } from "frontend/canvas-designer-new/canvast-stage.types";
import { Channel } from "pusher-js";
import IconElementProvider from "./icon/provider";
import IconGraphicsProvider from "./icon/graphics";
import { ReactNode } from "react";
import ChartProvider from "./chart/chart-provider";
import ChartGraphicsProvider from "./chart/graphics";
import { FeatureFlagsValues } from "frontend/hooks/use-feature-flag/flags";

export interface BoardContext {
  boardId: number;
  documentId: string;
  isReadOnly: boolean;
  reflect: Reflect<M>;
  selectedElementIds: string[];
  setSelectedIds: (ids: string[], includeGroupsOfElements?: boolean) => void;
  setMovingElements: React.Dispatch<React.SetStateAction<MovingElements | null>>;
  hiddenElementIds: string[];
  editingElementId: string | null;
  editingElementLinkId: string | null;
  undoRedoStack: UndoRedoStack;
  showUpgradeModal?: (reason: CheckoutSources) => void;
  pusherChannel?: Channel;
  showErrorToast?: ((error: ReactNode | null) => void) | undefined;
  allFeatureFlags?: FeatureFlagsValues;
}

export function getElementProvider(type: TypeCanvasElement) {
  return elementProvidersRegistry.getProvider(type);
}

export function getElementGraphicsProvider(type: TypeCanvasElement) {
  return elementGraphicsProvidersRegistry.getProvider(type);
}

// Providers
elementProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.GANTT, new GanttElementProvider());
elementProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.CHART, new ChartProvider());
elementProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.ICON, new IconElementProvider());

// Graphics providers
elementGraphicsProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.GANTT, new GanttGraphicsProvider());
elementGraphicsProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.CHART, new ChartGraphicsProvider());
elementGraphicsProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.ICON, new IconGraphicsProvider());
