import React from "react";
import { Html } from "react-konva-utils";
import { GanttAddTaskIcon } from "elements/gantt/icons/gantt-add-task-icon";
import styles from "./add-actions.module.css";
import { IGanttController } from "elements/gantt/controller";
import { trackGanttEvent } from "elements/gantt/utils";

type AddTaskButtonProps = {
  x: number;
  y: number;
  controller: IGanttController;
};

export const AddActions = ({ x, y, controller }: AddTaskButtonProps) => {
  const actions = [
    // {
    //   title: "Add Row",
    //   onClick: () => {
    //     controller.addSplitLastRow();
    //     trackGanttEvent("gantt_row_added", {
    //       from: "gantt_actions",
    //     });
    //   },
    // },
    // {
    //   title: "Add Column",
    //   onClick: () => {
    //     controller.addSplitColumnRight();
    //     trackGanttEvent("gantt_column_added", {
    //       from: "gantt_actions",
    //     });
    //   },
    // },
    {
      title: "Add Task",
      onClick: async () => {
        const didCreateTask = await controller.createTask();
        if (didCreateTask) {
          trackGanttEvent("gantt_task_created", {
            from: "gantt_actions",
            amount: controller.getTaskCells().length + 1,
          });
        }
      },
    },
  ];
  return (
    <Html
      groupProps={{
        x: x,
        y: y,
      }}
    >
      <div className={styles.wrapper}>
        {actions.map((action) => {
          return (
            <div key={action.title} data-testid={action.title} className={styles.button} onClick={action.onClick}>
              <GanttAddTaskIcon /> {action.title}
            </div>
          );
        })}
      </div>
    </Html>
  );
};
