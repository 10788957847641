import { Stage, Layer } from "react-konva";
import * as MathUtils from "frontend/utils/math-utils";
import BoundingBox from "frontend/geometry/bounding-box";
import consts from "shared/consts";
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { KonvaEventObject } from "konva/types/Node";
import { getTemplateData } from "frontend/api";
import { canvasElementPrefix, canvasMetadataPrefix } from "shared/util/utils";
import CanvasElement from "./canvas-element";
import { getBoundingBox } from "frontend/utils/node-utils";
import { Footer } from "frontend/canvas-controls/footer";
import { ZoomControls } from "frontend/canvas-controls/zoom-controls";
import { Point } from "shared/datamodel/schemas/canvas-element";
import { CanvasProvider } from "./canvas-context";
import useStateValue from "frontend/state/value";
import { miniStageRefAtom } from "state-atoms";
import { useSetAtom } from "jotai";
import { calcPixelRatio, getAreaToExport, getSnapshotBasic } from "frontend/utils/export-utils";
import { getElementTypeForId } from "frontend/canvas-designer-new/elements/canvas-elements-utils";
import { BoardContext, getElementProvider } from "elements/index";
import ElementComponent from "elements/base/konva-component";
import FrameBackground from "./elements/frame/frame-background";

export default function MiniStage({
  width,
  height,
  templateId,
  onLoad,
  templateData,
  showFooter = true,
}: {
  width: number;
  height: number;
  templateId: string;
  onLoad?: (loaded: boolean) => void;
  templateData?: any;
  showFooter?: boolean;
}) {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [templateElements, setTemplateElements] = useState<Array<[string, any]>>([]);
  const [templatesMetadata, setTemplatesMetadata] = useState<Record<string, any>>({});
  const setMiniStageRefAtom = useSetAtom(miniStageRefAtom);

  const ref = useRef<any>(null);
  const layerRef = useRef<any>(null);
  const mouseRef = useRef<any>({ isDown: false });

  const [appState] = useStateValue();

  useEffect(() => {
    async function loadTemplateData() {
      let elements: Array<[string, any]> = [];
      const metadata: Record<string, any> = {};

      templateData ??= templateId ? (await getTemplateData(templateId))?.data : {};

      for (const [id, value] of Object.entries(templateData)) {
        if (id.startsWith(canvasElementPrefix)) {
          const elementId = id.substring(canvasElementPrefix.length);
          elements.push([elementId, value]);
        } else if (id.startsWith(canvasMetadataPrefix)) {
          const elementId = id.substring(canvasMetadataPrefix.length);
          metadata[elementId] = value;
        }
      }

      elements.sort((a, b) => a[1].zIndexLastChangeTime - b[1].zIndexLastChangeTime);
      elements = elements.map(([id, element]) => [id, { ...element, link: null }]);
      elements.length > 0 && setTemplateElements(elements);

      const formattedMetadata: { [key: string]: any } = {};

      for (const key in metadata) {
        if (metadata.hasOwnProperty(key)) {
          const value = metadata[key];
          const newKey = key.split("-")[1] as any;
          formattedMetadata[newKey] = value;
        }
      }

      metadata && setTemplatesMetadata(formattedMetadata);
    }

    loadTemplateData();
  }, [templateId, templateData]);

  useEffect(() => {
    if (templateElements.length > 0) {
      const { x, y, scale } = centerStageAroundElements(templateElements);
      setPosition({ x, y });
      setScale(scale);
      onLoad && onLoad(true);
    }
  }, [templateElements]);

  useEffect(() => {
    if (ref.current) {
      (ref.current as any).getSnapshotImage = getSnapshotFn;
    }
    setMiniStageRefAtom(ref);
  }, [ref.current, layerRef.current]);

  async function getSnapshotFn({
    withWatermark,
    quality,
    elementId,
    scale,
    position,
    maxSize,
    padding = 40,
    whiteBackground = true,
  }: {
    withWatermark: boolean;
    elementId?: string;
    quality: number;
    scale: number;
    position: Point;
    maxSize?: number;
    padding?: number;
    whiteBackground?: boolean;
  }) {
    const canvasLayer = layerRef.current;
    if (canvasLayer == null) return;
    // 1. area is the rect in canvas coordinates that contains all elements, or the frame supplied
    let area = getAreaToExport(canvasLayer, elementId);

    // TODO: if area.width or height == 0, abort; don't export empty canvas
    area = MathUtils.padRect(area, padding);

    // 2. Konva requires us to adjust for the stage's current scale and position when exporting
    const adjustedArea = {
      x: area.x * scale + position.x,
      y: area.y * scale + position.y,
      width: area.width * scale,
      height: area.height * scale,
    };

    // 3. And we also have to choose pixel ratio, which really determines final size and quality.
    //    If we choose window.devicePixelRatio we get a match between screen pixels and canvas pixels.
    //    But maximum value allowed is by quality setting and browser limits.
    const minFinalSize = 512;
    const maxFinalSize = maxSize ?? 32767;
    const pixelRatio = calcPixelRatio(adjustedArea, minFinalSize, maxFinalSize, quality);

    const config = {
      ...adjustedArea,
      pixelRatio,
    };
    return getSnapshotBasic(canvasLayer, area, withWatermark, config, whiteBackground);
  }

  function moveStagePosition(dx: number, dy: number) {
    const dragDistanceScale = 0.75;
    setPosition((pos: any) => {
      return {
        x: pos.x - dragDistanceScale * dx,
        y: pos.y - dragDistanceScale * dy,
      };
    });
  }

  function centerStageAroundElements(elements: Array<[string, any]>) {
    let box = new BoundingBox();
    const stage = ref.current;

    if (!elements) {
      return { x: 0, y: 0, scale: 1 };
    }
    for (const element of elements) {
      const [key, elementData] = element;
      if ((elementData as any).hidden) continue;
      const type = key.split("-", 3)[0]; //TODO: can probably do this without string.split - GC overhead
      const rect = getBoundingBox(type, elementData);
      if (rect) {
        box.expandRect(rect);
      }
    }

    if (box.width > 0 && box.height > 0) {
      box = box.addPadding(200);
      const scale = MathUtils.clamp(
        Math.min(stage.width() / box.width, stage.height() / box.height),
        consts.STAGE_ZOOM.MIN,
        consts.STAGE_ZOOM.MAX
      );
      const x = stage.width() / 2 - (box.x + box.width / 2) * scale;
      const y = stage.height() / 2 - (box.y + box.height / 2) * scale;
      return { x, y, scale };
    } else {
      const x = stage.width() / 2 - box.x;
      const y = stage.height() / 2 - box.y;
      return { x, y, scale: 1 };
    }
  }

  function zoomStage(stage: any, dy: number, mode: "mouse" | "viewport-center") {
    const oldScale = stage.scaleX();
    const newScale = MathUtils.clamp(oldScale - dy * 0.01, consts.STAGE_ZOOM.MIN, consts.STAGE_ZOOM.MAX);
    // This should be canvas element center, but since we stretch the canvas over the window
    // I just take window center
    const point =
      mode == "mouse"
        ? stage.getPointerPosition()
        : {
            x: 0.5 * width,
            y: 0.5 * height,
          };
    const centerX = (point.x - position.x) / scale;
    const centerY = (point.y - position.y) / scale;
    const offsetX = centerX * (newScale - scale);
    const offsetY = centerY * (newScale - scale);
    const newPosition = {
      x: position.x - offsetX,
      y: position.y - offsetY,
    };
    setScale(newScale);
    setPosition(newPosition);
  }

  function handleWheelScroll(e: any) {
    e.evt.preventDefault();
    const stage = ref.current;
    if (!stage) {
      return;
    }
    const physicalWheel = Math.abs(e.evt.wheelDelta % 120) == 0;
    if (physicalWheel || e.evt.ctrlKey || e.evt.metaKey) {
      // The numerical values in the section were chosen to give a nice user experience
      // They are pretty arbitrary
      let dy = e.evt.deltaY;
      if (e.evt.deltaMode === WheelEvent.DOM_DELTA_LINE) dy = dy * 8;
      else if (e.evt.deltaMode === WheelEvent.DOM_DELTA_PAGE) dy = dy * 24;
      dy = MathUtils.clamp(dy, -24, 24);
      const isPinchGesture = e.evt.wheelDelta != e.evt.deltaY * -3;
      const scaleFactor = isPinchGesture ? 5 : 1;
      const finalScaleChange = (scaleFactor * stage.scaleX() * dy) / 5;
      zoomStage(stage, finalScaleChange, "mouse");
    } else {
      moveStagePosition(e.evt.deltaX, e.evt.deltaY);
    }
  }

  function handleMouseDown(e: KonvaEventObject<MouseEvent>) {
    e.target.preventDefault();
    ref.current.container().style.cursor = "grabbing";
    mouseRef.current.isDown = true;
  }

  function handleMouseUp(e: KonvaEventObject<MouseEvent>) {
    e.target.preventDefault();
    ref.current.container().style.cursor = "grab";
    mouseRef.current.isDown = false;
  }

  function handleMouseMove(e: KonvaEventObject<MouseEvent>) {
    if (ref && ref.current && mouseRef.current.isDown) {
      moveStagePosition(-e.evt.movementX, -e.evt.movementY);
    }
  }

  function stageStyle(): CSSProperties {
    const backgroundColor = "white";
    const baseSize = 25;
    if (scale >= 1) {
      const size = baseSize * scale;
      const x = (position.x % size) + size / 2;
      const y = (position.y % size) + size / 2;
      const dotSize = 1.1 * scale;
      return {
        backgroundColor,
        backgroundImage: `radial-gradient(#E5E8EA ${dotSize}px, transparent ${dotSize}px)`,
        backgroundSize: `${size}px ${size}px`,
        backgroundPosition: `${x}px ${y}px`,
      };
    } else if (scale >= 0.5) {
      const size = baseSize * scale * 2;
      const x = (position.x % size) + size / 2;
      const y = (position.y % size) + size / 2;
      return {
        backgroundColor,
        backgroundImage: "radial-gradient(#E5E8EA 1.1px, transparent 1.1px)",
        backgroundSize: `${size}px ${size}px`,
        backgroundPosition: `${x}px ${y}px`,
      };
    } else if (scale >= 0.25) {
      const size = baseSize * scale * 4;
      const x = (position.x % size) + size / 2;
      const y = (position.y % size) + size / 2;
      return {
        backgroundColor,
        backgroundImage: "radial-gradient(#E5E8EA 1.1px, transparent 1.1px)",
        backgroundSize: `${size}px ${size}px`,
        backgroundPosition: `${x}px ${y}px`,
      };
    } else if (scale >= 0.1) {
      const size = baseSize * scale * 8;
      const x = (position.x % size) + size / 2;
      const y = (position.y % size) + size / 2;
      return {
        backgroundColor,
        backgroundImage: "radial-gradient(#E5E8EA 1.1px, transparent 1.1px)",
        backgroundSize: `${size}px ${size}px`,
        backgroundPosition: `${x}px ${y}px`,
      };
    } else {
      return {
        backgroundColor,
      };
    }
  }

  const numbFunc = useCallback(() => {}, []);

  function renderElements(elements: Array<[string, any]>) {
    const context: BoardContext = {
      documentId: "",
      boardId: 0,
      reflect: { subscribe: () => {} } as any,
      isReadOnly: true,
      selectedElementIds: [],
      setSelectedIds: numbFunc,
      setMovingElements: numbFunc,
      hiddenElementIds: [],
      editingElementId: null,
      editingElementLinkId: null,
      undoRedoStack: undefined as any,
    };
    return elements.map(([elementId, elementData], _, allElements) => {
      const elementType = getElementTypeForId(elementId);
      const provider = getElementProvider(elementType);
      if (provider) {
        if (provider.isFFEnabled()) {
          // if provider exists it means it support the new infra
          return (
            <ElementComponent
              key={elementId}
              id={elementId}
              type={elementType}
              context={context}
              elementData={elementData}
              allElementsData={allElements}
              onResize={numbFunc}
            />
          );
        } else {
          return null;
        }
      }

      return (
        <MemoedCanvasElement
          key={elementId}
          uniqueId={elementId}
          elementData={elementData}
          allElementsData={allElements}
          onResize={numbFunc}
          onChangeElement={numbFunc}
          isSelected={false}
          drawOutlineAroundElements={false}
          isEditing={false}
          isEditingLink={false}
          isFrameHighlighted={false}
          onElementsMutationEnded={numbFunc}
          isSelectable={false}
          metaData={templatesMetadata}
          isReadOnly={true}
          patchCanvasElement={numbFunc}
          patchAnything={numbFunc}
        />
      );
    });
  }

  function renderFramesBackgrounds() {
    const frameElements = templateElements.filter(([_id, element]) => element?.type === consts.CANVAS_ELEMENTS.FRAME);

    return frameElements.map(([id, element]) => <FrameBackground key={id} uniqueId={id} frame={element} />);
  }

  function onHover() {
    ref.current.container().style.cursor = "grab";
  }

  function renderStage() {
    return (
      <React.Fragment>
        <div onMouseOver={onHover} onMouseEnter={onHover}>
          <Stage
            ref={ref}
            width={width}
            height={height}
            x={position.x}
            y={position.y}
            scaleX={scale}
            scaleY={scale}
            onWheel={handleWheelScroll}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={stageStyle()}
          >
            <CanvasProvider appState={appState}>
              <Layer name="Elements" ref={layerRef}>
                {renderFramesBackgrounds()}
                {renderElements(templateElements)}
              </Layer>
            </CanvasProvider>
          </Stage>
        </div>
      </React.Fragment>
    );
  }

  function renderFooter() {
    return (
      <Footer
        customStyle={{
          gridArea: "footer",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ZoomControls
          scale={scale}
          position={position}
          onZoom={(scale: number, position: Point) => {
            setScale(scale);
            setPosition(position);
          }}
          viewportWidth={width}
          viewportHeight={height}
          showTooltips={false}
        />
      </Footer>
    );
  }

  return (
    <React.Fragment>
      {renderStage()}
      {showFooter && renderFooter()}
    </React.Fragment>
  );
}

const MemoedCanvasElement = React.memo(CanvasElement);
