import useAnyEvent from "frontend/hooks/use-any-event";
import { useBgJob } from "frontend/hooks/use-bg-worker";
import Konva from "konva";
import { measureNodes } from "utils/node-utils";
import { computeCoordinatesOfLines, TableIds } from "../table-utils";
import { produceWithPatches } from "immer";

export function TableResizeContainedListener({
  nodes,
  mapping,
  setoverrideSize,
  onEnd,
}: {
  nodes: Konva.Node[];
  mapping: Record<string, { col: string; row: string }>;
  setoverrideSize: (value: any) => void;
  onEnd: (requiredSize: any) => void;
}) {
  const { queue, reset } = useBgJob(setoverrideSize, 1);

  function computeRequiredSize() {
    const options = { skipShadow: true, relativeTo: nodes[0].getStage() as any };
    const sizes = measureNodes(nodes, options);
    const requiredXs: Record<string, number> = {};
    const requiredYs: Record<string, number> = {};
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id();
      const box = sizes[i];
      const requiredX = box.x + box.width;
      const requiredY = box.y + box.height;
      const { col, row } = mapping[id];
      requiredXs[col] = Math.max(requiredXs[col] ?? Number.MIN_SAFE_INTEGER, requiredX);
      requiredYs[row] = Math.max(requiredYs[row] ?? Number.MIN_SAFE_INTEGER, requiredY);
    }
    // requiredXs/Ys is the rightmost/bottommost coordinate for cols and rows that are affected by the resize
    // It's in canvas coordinates (not table coordinates)

    return { requiredXs, requiredYs };
  }

  useAnyEvent("transform", () => queue(computeRequiredSize()));
  useAnyEvent("transform-end", () => {
    reset();
    onEnd(computeRequiredSize());
  });

  // Return null, because this component exists just to listen to events and trigger the drag operation
  return null;
}

export class ResizeContainedListener {
  id: string;
  original: any;
  curElement: any;
  getContainedElementsInCell: any;

  patchFn: any;
  scaleX: any;
  scaleY: any;

  originalXs: any[];
  originalYs: any[];
  pendingXs: any;
  pendingYs: any;

  timer = 0;

  constructor(
    id: string,
    element: any,
    getContainedElementsInCell: any,
    patchFn: any,
    private originIsMinimum = false,
    private minimumSize = 10
  ) {
    this.id = TableIds.ensureFullId(id);
    this.curElement = this.original = element;
    this.patchFn = patchFn;
    this.scaleX = element.scaleX || 1;
    this.scaleY = element.scaleY || 1;
    this.originalXs = computeCoordinatesOfLines(element.cols, null);
    this.originalYs = computeCoordinatesOfLines(element.rows, null);
    this.pendingXs = {};
    this.pendingYs = {};
    this.getContainedElementsInCell = getContainedElementsInCell;
    this.updateFn = this.updateFn.bind(this);
  }

  private calcDiff() {
    const patches = [];
    const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
      // Calculate new size for all columns
      let curX = 0;
      for (let i = 0; i < draft.cols.length; i++) {
        const key = draft.cols[i].id;
        if (key in this.pendingXs) {
          const requiredRightSideForThisColumn = (this.pendingXs[key] - this.original.x) / this.scaleX;
          let newSize = requiredRightSideForThisColumn - curX;
          if (this.originIsMinimum) {
            newSize = Math.max(newSize, this.original.cols[i].size);
          }
          newSize = Math.max(newSize, this.minimumSize);
          draft.cols[i].size = newSize;
        }
        curX += draft.cols[i].size;
      }

      // Calculate new size for all rows
      let curY = 0;
      for (let i = 0; i < draft.rows.length; i++) {
        const key = draft.rows[i].id;
        if (key in this.pendingYs) {
          const requiredBottomForThisRow = (this.pendingYs[key] - this.original.y) / this.scaleY;
          let newSize = requiredBottomForThisRow - curY;
          if (this.originIsMinimum) {
            newSize = Math.max(newSize, this.original.rows[i].size);
          }
          newSize = Math.max(newSize, this.minimumSize);
          draft.rows[i].size = newSize;
        }
        curY += draft.rows[i].size;
      }
    })(this.curElement);
    patches.push({ id: this.id, patch, inversePatch });

    let deltaX = new Array(this.original.cols.length).fill(0);
    let deltaY = new Array(this.original.rows.length).fill(0);
    {
      for (let i = 1; i < deltaX.length; i++) {
        deltaX[i] = deltaX[i - 1] + (newElement.cols[i - 1].size - this.curElement.cols[i - 1].size);
      }
      for (let i = 1; i < deltaY.length; i++) {
        deltaY[i] = deltaY[i - 1] + (newElement.rows[i - 1].size - this.curElement.rows[i - 1].size);
      }
      let x = 0;
      for (let col = 0; col < this.original.cols.length; col++) {
        let y = 0;
        for (let row = 0; row < this.original.rows.length; row++) {
          if (deltaX[col] || deltaY[row]) {
            for (const [id, element] of this.getContainedElementsInCell(col, row)) {
              const [, p, invP] = produceWithPatches(
                element,
                (draft: any) => {
                  const offsetX = (draft.x - this.original.x) / this.scaleX - this.originalXs[col];
                  const offsetY = (draft.y - this.original.y) / this.scaleY - this.originalYs[row];
                  draft.x = this.original.x + x * this.scaleX + offsetX;
                  draft.y = this.original.y + y * this.scaleY + offsetY;
                },
                undefined
              );
              patches.push({ id: "cElement-" + id, patch: p, inversePatch: invP });
            }
          }
          y += newElement.rows[row].size;
        }
        x += newElement.cols[col].size;
      }
    }
    this.curElement = newElement;
    return patches;
  }

  private updateFn() {
    this.timer = 0;
    const patches = this.calcDiff();
    this.patchFn(patches, true);
  }

  private updateIn(table: any, key: string, value: number) {
    table[key] = value;
    this.timer ||= window.setTimeout(this.updateFn, 16);
  }

  updateXline(key: string, newX: number) {
    this.updateIn(this.pendingXs, key, newX);
  }

  updateYline(key: string, newY: number) {
    this.updateIn(this.pendingYs, key, newY);
  }

  finish() {
    if (this.timer) window.clearTimeout(this.timer);
    const patches = this.calcDiff();
    this.patchFn(patches);
  }
}
