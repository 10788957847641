import React, { createContext, useCallback, useContext } from "react";
import { IRect, isPointInRect } from "utils/math-utils";

type Point = { x: number; y: number };

type RegisterGuiElement = (element: GuiElement | GuiElement[]) => () => void;

export const GuiSystem = createContext<null | RegisterGuiElement>(null);

export function useGuiSystem() {
  return useContext(GuiSystem);
}

export function GuiSystemProvider({
  widgetsMap,
  children,
}: {
  widgetsMap: Map<string, GuiElement>;
  children: React.ReactNode;
}) {
  const register = useCallback(
    (element: GuiElement | GuiElement[]) => {
      if (Array.isArray(element)) {
        element.forEach((el) => widgetsMap.set(el.id, el));
      } else {
        widgetsMap.set(element.id, element);
      }
      return () => {
        if (Array.isArray(element)) {
          element.forEach((el) => widgetsMap.delete(el.id));
        } else {
          widgetsMap.delete(element.id);
        }
      };
    },
    [widgetsMap]
  );

  return <GuiSystem.Provider value={register}>{children}</GuiSystem.Provider>;
}

/**
 * Another representation for rect:
 * center x,y
 * half width, half height
 */
class Zone implements IRect {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly cx: number;
  readonly cy: number;
  readonly w2: number;
  readonly h2: number;

  private constructor(rect: IRect) {
    this.x = rect.x;
    this.y = rect.y;
    this.width = rect.width;
    this.height = rect.height;
    this.w2 = rect.width / 2;
    this.h2 = rect.height / 2;
    this.cx = rect.x + this.w2;
    this.cy = rect.y + this.h2;
  }

  static fromRect(rect: IRect) {
    return new Zone(rect);
  }
}

export class GuiElement {
  id: string;
  zone: Zone;
  paddingPx = 0; // padding in screen pixels
  children?: GuiElement[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseMove?: (ev: CanvasMouseEvent) => void;

  constructor(id: string, bounds: IRect) {
    this.id = id;
    this.zone = Zone.fromRect(bounds);
  }
  addChild(child: GuiElement) {
    this.children = this.children ?? [];
    this.children.push(child);
  }
}

/**
 * This function will return the manhattan distance of a point from a given rectangle.
 * The distance is 0 if the point is on the edge or inside the rectangle.
 * This is inspired by signed-distance-functions (google that)
 */
function distancePointFromRect({ x, y }: Point, rect: Zone): number {
  const { cx, cy, w2, h2 } = rect;
  x = x - cx;
  y = y - cy;
  const dx = Math.abs(x) - w2;
  const dy = Math.abs(y) - h2;
  const qx = Math.max(dx, 0),
    qy = Math.max(dy, 0);
  // can use euclidean distance or manhanttan distance here
  // I'll go with manhattan distance
  return Math.abs(qx) + Math.abs(qy);
}

function collectElementsAtPoint(
  point: Point,
  element: GuiElement,
  result: GuiElement[],
  stageScale: number
): GuiElement[] {
  let pointInsideElement = isPointInRect(point, element.zone);
  if (!pointInsideElement && element.paddingPx) {
    const d = distancePointFromRect(point, element.zone);
    pointInsideElement ||= d * stageScale <= element.paddingPx;
  }
  if (pointInsideElement) {
    result.push(element);
    if (element.children) {
      for (const child of element.children) {
        collectElementsAtPoint(point, child, result, stageScale);
      }
    }
  }
  return result;
}

type CanvasMouseEvent = {
  type: "mousemove" | "mouseenter" | "mouseleave";
  x: number;
  y: number;
};

let prevFramePath: GuiElement[] = [];

/**
 * This is the main function for the gui system.
 * It accepts a root element and a mouse event, and propagates the event to the elements under the mouse.
 */
export function propagateEvent(roots: Iterable<GuiElement>, event: CanvasMouseEvent, stageScale: number): void {
  let path: any[] = [];
  for (const root of roots) {
    collectElementsAtPoint(event, root, path, stageScale);
  }

  /**
   * For each element that was under the mouse in the previous frame, and not now, call onMouseLeave
   */
  for (const element of prevFramePath) {
    if (!path.find(({ id }) => id == element.id)) {
      element.onMouseLeave?.();
    }
  }
  /**
   * For every element that is under the mouse now, and was not in the previous frame, call onMouseEnter
   */
  for (const element of path) {
    if (!prevFramePath.find(({ id }) => id == element.id)) {
      element.onMouseEnter?.();
    }
  }
  /**
   * And save the current path for the next frame
   */
  prevFramePath = path;

  /**
   * Now trigger mouse-move events, from end to start (assuming that's also z-order of widgets)
   */
  for (let i = path.length - 1; i >= 0; i--) {
    const element = path[i];
    element.onMouseMove?.(event);
    //TODO - I can also mimic the DOM event system, where parent registers handler, and gets
    // in the event structure the actual child the mouse is over.
  }
}
