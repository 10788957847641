import { useAtomValue } from "jotai";
import Konva from "konva";
import { useRef, useMemo, useCallback } from "react";
import { Line } from "react-konva";
import { useEvent, useKey } from "react-use";
import { stageRefAtom } from "state-atoms";
import { fullCellKey, screenToElementMatrix } from "../table-utils";
import type { TypeTableCell, TypeTableElement } from "shared/datamodel/schemas/table";
import { findClosest } from "../find-closest";
import { clamp } from "utils/math-utils";
import { Cell, WidgetOffsetFromTable } from "../table-styling";
import { DragHandle } from "../widgets/drag-handle";

export function RowDragger({
  id,
  row,
  updateDrag,
  xs,
  ys,
  element,
  cells,
}: {
  row: number;
  id: string;
  xs: number[];
  ys: number[];
  element: TypeTableElement;
  cells: Record<string, TypeTableCell>;
  updateDrag: (
    action: { type: "drag"; src: number; dx?: number; dy?: number } | { type: "finish"; src: number; target: number }
  ) => void;
}) {
  const stage = useAtomValue(stageRefAtom)?.current;
  const ref = useRef<any>();
  const lineref = useRef<any>();
  const { scaleX = 1, scaleY = 1 } = element;

  const nodes = useMemo(() => {
    const rowId = element.rows[row].id;
    // get all contained elements in this row
    const childIds = element.cols.flatMap(
      ({ id: colId }: { id: string }) => cells[fullCellKey(id, colId, rowId)]?.containedIds ?? []
    );
    const layer = stage
      .getLayers()
      .toArray()
      .find((x: Konva.Layer) => x.name() == "Elements") as Konva.Layer;
    const nodes = layer!.find((node: any) => childIds.includes(node.id())).toArray();
    return nodes;
  }, []);

  const finishOperation = useCallback(
    (e) => {
      const matrix = screenToElementMatrix(stage, element);
      const point = matrix.transformPoint({ x: e.clientX, y: e.clientY });
      let target = findClosest(point.y, ys);
      if (target > row) target--; // don't ask....
      nodes.forEach((node: any) => node.offset({ x: 0, y: 0 }));
      updateDrag({ type: "finish", src: row, target });
    },
    [nodes]
  );

  useEvent(
    "mousemove",
    useCallback(
      (e) => {
        /**
         * Sometimes when the user moves the mouse fast and lets go of the button, we get
         * "mousemove" event with buttons==0 before the "mouseup". In this case we end the drag.
         */
        if (e.buttons == 0) {
          finishOperation(e);
          return;
        }
        const matrix = screenToElementMatrix(stage, element);
        const point = matrix.transformPoint({ x: e.clientX, y: e.clientY });
        const target = findClosest(point.y, ys);
        const y = clamp(point.y, 0, ys[ys.length - 1]);
        ref.current!.y(element.y + y * scaleY);
        if (target == row || target == row + 1) {
          lineref.current.visible(false);
        } else {
          lineref.current.visible(true);
          lineref.current!.y(element.y + ys[target] * scaleY);
        }
        let dy = y - (ys[row] + ys[row + 1]) / 2; // dx in canvas-pixels
        updateDrag({ type: "drag", src: row, dy });
        nodes.forEach((node: any) => node.offset({ x: 0, y: (-dy * scaleY) / node.scaleY() }));
      },
      [nodes]
    )
  );

  /**
   * On escape cancel this whole shenaningans, and don't apply any changes
   */
  useKey("Escape", () => {
    updateDrag({ type: "finish", src: row, target: row });
    nodes.forEach((node: any) => node.offset({ x: 0, y: 0 }));
  });

  useEvent("mouseup", finishOperation);

  if (!stage) {
    return null; // we should never reach this line - dragging column is impossible unless table is on the stage
  }

  return (
    <>
      <DragHandle
        ref={ref}
        x={element.x}
        y={((ys[row] + ys[row + 1]) * scaleY) / 2}
        ofsX={-WidgetOffsetFromTable}
        mode="vertical"
      />
      <Line
        ref={lineref}
        visible={false}
        x={element.x}
        y={element.y + ((ys[row] + ys[row + 1]) * scaleY) / 2}
        points={[0, 0, xs[xs.length - 1] * scaleX, 0]} // todo: take table height
        strokeWidth={4}
        stroke={Cell.SelectedStrokeColor}
        strokeScaleEnabled={false}
      />
    </>
  );
}
