import { useEffect, useState, useCallback } from "react";
import { IconElement } from "shared/datamodel/schemas/icon";
import { useSetAtom } from "jotai";
import { editingElementIdAtom } from "state-atoms";
import { IIconController } from "./controller";
import { ElementProps } from "elements/base/provider";
import { Rect, Image, Group } from "react-konva";
import { useImage } from "react-konva-utils";
import consts from "shared/consts";
import { EditableText } from "frontend/editableText";
import { ITraits, Trait } from "frontend/canvas-designer-new/elements-toolbar/elements-toolbar-types";
import { getFontSize } from "frontend/canvas-designer-new/text-element";
import { nanoid } from "nanoid";
import useCurrentAndPrev from "frontend/hooks/use-cur-and-prev";
import useObservableController from "elements/hooks/use-observable-controller";

const TEXT_AREA_MARGIN = 10;

export default function IconElementComponent({ controller: _controller }: ElementProps<IconElement>) {
  const setEditingElementId = useSetAtom(editingElementIdAtom);
  const controller = _controller as IIconController;
  useObservableController(controller);

  const textState = useCurrentAndPrev<string>();
  const [sequenceId, setSequenceId] = useState<string | null>(null);
  const [image, imageStatus] = useImage(controller.element.href, "anonymous");

  useEffect(() => {
    if (controller.isEditing()) {
      const newSequenceId = nanoid(8);
      setSequenceId(newSequenceId);
      controller.startActionSequence(newSequenceId);
      textState(controller.element.text ?? "");
    } else {
      const [previous, current] = textState();
      if (previous !== current && current !== null && sequenceId) {
        controller.endActionSequence(sequenceId);
      }
      setSequenceId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller.isEditing()]);

  const onChange = useCallback(
    (newTextValue: string) => {
      textState(newTextValue);
      controller.patchElement(
        controller.id,
        (draft: IconElement) => {
          draft.text = newTextValue;
        },
        sequenceId ?? undefined
      );
    },
    [controller, sequenceId, textState]
  );

  const onStopEditing = useCallback(() => {
    setEditingElementId(null);
  }, [setEditingElementId]);

  const onDblClick = useCallback(() => {
    setEditingElementId(controller.id);
  }, [controller, setEditingElementId]);

  return (
    <>
      <Group>
        {imageStatus === "loaded" && (
          <Image image={image} width={controller.element.width} height={controller.element.height} />
        )}
        {imageStatus !== "loaded" && controller.showPlaceholder() && (
          <Rect
            width={controller.element.width}
            height={controller.element.height}
            x={0}
            y={0}
            fill="#DADCE0"
            cornerRadius={8}
          />
        )}
        <EditableText
          x={-controller.element.width / 2}
          y={controller.element.height + TEXT_AREA_MARGIN}
          scaleX={1 / (controller.element.scaleX ?? 1)}
          scaleY={1 / (controller.element.scaleY ?? 1)}
          width={controller.element.width * 2}
          height={0}
          isFixedHeight={false}
          font={controller.element.font ?? consts.DEFAULTS.FONT}
          fill={controller.element.textColor ?? consts.DEFAULTS.TEXT_COLOR}
          align={controller.element.align ?? consts.DEFAULTS.TEXT_ALIGN}
          fontSize={Number(controller.element.fontSize ?? consts.DEFAULTS.FONTSIZE)}
          fontProps={controller.element.fontProps}
          isEditing={controller.isEditing()}
          text={controller.element.text}
          placeholder=""
          onChange={onChange}
          onStopEditing={onStopEditing}
          onDblClick={onDblClick}
          excludeFromTransformer
          listening
        />
      </Group>
    </>
  );
}

export function iconTraits(element: IconElement): ITraits {
  return {
    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: getFontSize(element),
    font: element.font ?? consts.DEFAULTS.FONT,

    // @ts-expect-error Adding a dummy trait here since I want the text in a sub-bar
    // But that only happens when there is another trait
    dummyTrait: undefined,
  };
}

export function iconValidateTrait(_element: IconElement, trait: Trait, value: unknown) {
  switch (trait) {
    default: {
      return value;
    }
  }
}
