import React from "react";
import consts from "shared/consts";
import { FontProperties, TextAlignment } from "shared/datamodel/schemas/text-enabled-schema";
import { EditableTextInput } from "./editableTextInput";
import { ResizableText } from "./resizableText";

export function EditableText({
  x,
  y,
  width,
  height,
  paddingX = 0,
  paddingY = 0,
  scaleX = 1,
  scaleY = 1,
  isWide = false,
  isFixedHeight,
  isEditing,
  onStopEditing,
  onChange,
  onTextHeightChange,
  text,
  placeholder,
  fontSize,
  fill,
  font,
  align = "left",
  verticalAlign = "top",
  fontProps = FontProperties.None,
  showBorder = false,
  excludeFromTransformer = false,
  listening = false,
  onDblClick,
}: {
  x?: number;
  y?: number;
  width: number;
  height: number;
  paddingX?: number;
  paddingY?: number;
  scaleX?: number;
  scaleY?: number;
  isWide?: boolean;
  isFixedHeight: boolean;
  isEditing: boolean;
  onStopEditing?: () => void;
  onChange: (newValue: string) => void;
  onTextHeightChange?: (height: number) => void;
  text: string;
  placeholder: string;
  fontSize: number;
  fill: string;
  font: string;
  align?: TextAlignment;
  verticalAlign?: "top" | "bottom" | "middle";
  fontProps?: FontProperties;
  showBorder?: boolean;
  excludeFromTransformer?: boolean;
  listening?: boolean;
  onDblClick?: () => void;
}) {
  function handleTextChange(value: string) {
    // This line prevents the user from adding the default text (Add text)
    // we should find a better solution
    const newValue = value === consts.DEFAULTS.TEXT ? "" : value;
    onChange && onChange(newValue);
  }

  function renderEditableText() {
    if (isEditing) {
      return (
        <EditableTextInput
          x={x}
          y={y}
          width={width}
          height={height}
          paddingX={paddingX}
          paddingY={paddingY}
          scaleX={scaleX}
          scaleY={scaleY}
          isWide={isWide}
          value={text}
          placeholder={placeholder}
          isFixedHeight={isFixedHeight}
          fontSize={fontSize}
          fill={fill}
          onStopEditing={onStopEditing}
          onChange={handleTextChange}
          onTextHeightChange={onTextHeightChange}
          align={align}
          verticalAlign={verticalAlign}
          fontProps={fontProps}
          showBorder={showBorder}
          font={font}
        />
      );
    }
    return (
      <ResizableText
        x={x}
        y={y}
        text={text}
        placeholder={placeholder}
        fontSize={fontSize}
        fill={fill}
        width={width}
        height={isFixedHeight ? height : undefined}
        paddingX={paddingX}
        paddingY={paddingY}
        scaleX={scaleX}
        scaleY={scaleY}
        align={align}
        verticalAlign={verticalAlign}
        fontProps={fontProps}
        onHeightChange={onTextHeightChange}
        font={font}
        excludeFromTransformer={excludeFromTransformer}
        onDblClick={onDblClick}
        listening={listening}
      />
    );
  }

  return renderEditableText();
}
