import React from "react";

export const FilterItemTrashIcon = ({ color = "#FEFEFE" }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" fill="none" viewBox="0 0 12 13">
      <path d="M1 3.5H11" stroke={color} strokeLinecap="square" strokeLinejoin="round" />
      <path d="M7.66732 3.5V1H4.33398V3.5" stroke={color} />
      <path d="M1.83464 3.50016L2.66797 11.8335L9.33464 11.8335L10.168 3.50016" stroke={color} />
    </svg>
  );
};
