import z from "zod";
import { customAlphabet } from "nanoid";
import { tableElementSchema, TypeTableCell } from "./schemas/table";
import { Point } from "./schemas";
import { createElementId, getUnixTimestampUTC } from "shared/util/utils";
import consts from "shared/consts";
import { initArray } from "frontend/utils/fn-utils";
import { TableDescription } from "frontend/tableSubMenu";

type TableElementInput = z.input<typeof tableElementSchema>;

export const enum TableDefaults {
  CellWidth = 281,
  CellHeight = 181,
}

export function defaultTableCell(): TypeTableCell {
  return {
    textColor: "#113357",
    fontSize: consts.DEFAULTS.FONTSIZE,
    align: "left",
    valign: "top",
    fontProps: 0,
    font: consts.DEFAULTS.FONT,
    fill: "#ffffff",
    text: "",
  } as TypeTableCell;
}

export function placeTable(point: Point, description: TableDescription, size?: Point) {
  const { cols: numCols, rows: numRows, style } = description;

  const cellNanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 10);

  let colWidth = TableDefaults.CellWidth;
  let rowHeight = TableDefaults.CellHeight;
  let scaleX = 1,
    scaleY = 1;
  if (size) {
    let { x, y } = size;
    if (x == 0) x = colWidth * numCols;
    if (y == 0) y = rowHeight * numRows;
    x = Math.max(x, 10);
    y = Math.max(y, 10);
    // Table's scale must be uniform (scaleX==scaleY), even if the size isn't.
    // so we have to adjust size of columns or rows.
    if (x < y) {
      // table is tall -> scaleX will be calculated first, and scaleY is equal to it, and rowHeight next
      scaleY = scaleX = x / (numCols * colWidth);
      rowHeight = y / (numRows * scaleY);
    } else {
      // table is wide - same as above, just replace x,y
      scaleX = scaleY = y / (numRows * rowHeight);
      colWidth = x / (numCols * scaleX);
    }
  }
  const now = getUnixTimestampUTC();

  const rows = initArray(numRows, () => ({ id: cellNanoid(), size: rowHeight }));
  const cols = initArray(numCols, () => ({ id: cellNanoid(), size: colWidth }));
  const tableId = createElementId();

  // I'm building an array of cells, that will hold all the cells in the table, column-major order.
  // cells[0] - col 0, row 0
  // cells[1] - col 0, row 1
  // cells[2] - col 0, row 2 ... etc
  // to access cell at (row,col) use cells[col * numRows + row]
  const cells = initArray(numCols * numRows, defaultTableCell);

  for (const rule of style.rules) {
    if (typeof rule.row === "number" && typeof rule.col === "number") {
      cells[rule.col * numRows + rule.row].fill = rule.style.fill;
    } else if (typeof rule.row === "number") {
      for (let col = 0; col < numCols; col++) {
        cells[col * numRows + rule.row].fill = rule.style.fill;
      }
    } else if (typeof rule.col === "number") {
      for (let row = 0; row < numRows; row++) {
        cells[rule.col * numRows + row].fill = rule.style.fill;
      }
    }
  }

  const cellElements = cells.map((cell, index) => {
    const col = Math.floor(index / numRows);
    const row = index % numRows;
    return {
      id: tableId + "/" + cols[col].id + "-" + rows[row].id,
      type: consts.CANVAS_ELEMENTS.TABLE_CELL,
      element: cell,
    };
  });

  const base: TableElementInput = {
    type: "table",
    title: "New Table",
    x: point.x,
    y: point.y,
    scaleX,
    scaleY,
    stroke: style.stroke || "#c8c8c8",
    cols,
    rows,
    zIndexLastChangeTime: now,
  };

  try {
    return [
      {
        id: tableId,
        type: consts.CANVAS_ELEMENTS.TABLE,
        element: tableElementSchema.parse(base), // parse will fill defaults
      },
      ...cellElements,
    ];
  } catch (e) {
    console.error("New table failed zod.parse", base);
    throw e;
  }
}
