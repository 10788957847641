import { canvasElementPrefix } from "shared/util/utils";
import { TypeTableCell, TypeTableElement } from "shared/datamodel/schemas/table";
import { useMemo, useRef } from "react";
import { useSubscribe } from "replicache-react";
import { customAlphabet } from "nanoid";
import { deepEqual } from "frontend/utils/fn-utils";
import { ReadTransaction } from "@workcanvas/reflect";
import { Reflect } from "@workcanvas/reflect/client";
import { M } from "shared/datamodel/mutators";
import consts from "shared/consts";
import * as R from "rambda";
import Konva from "konva";

export const cellPadding = 25; // maybe make this function of font-size?
// TODO: duplicated from table.ts
// customAlphabet is chosen to match Regexp \w character class, changing it means changing the regular expressions!
export const cellNanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 10);

const tableCellsPrefix = (tableId: string) => "cElement-tableCell-" + tableId;

export function fullCellKey(tableId: string, col: string, row: string): string {
  // check and extract just the nanoid, without the type
  if (tableId.startsWith(consts.CANVAS_ELEMENTS.TABLE))
    tableId = tableId.slice(consts.CANVAS_ELEMENTS.TABLE.length + 1);
  // return the full key
  return tableCellsPrefix(tableId) + "/" + col + "-" + row;
}

export class TableCellKey {
  static regexp = new RegExp(`^(?:${canvasElementPrefix})?${consts.CANVAS_ELEMENTS.TABLE_CELL}-(\\w+)\/(\\w+)-(\\w+)$`);

  constructor(public readonly tableId: string, public readonly col: string, public readonly row: string) {}

  static from(key: string) {
    const m = TableCellKey.regexp.exec(key);
    return m ? new TableCellKey(m[1], m[2], m[3]) : null;
  }
  colAndRow() {
    return this.col + "-" + this.row;
  }
}

export class TableIds {
  static prefixRegexp = new RegExp(`^(${canvasElementPrefix})?${consts.CANVAS_ELEMENTS.TABLE}-`);

  constructor(public readonly tableId: string) {
    this.tableId = this.tableId.replace(TableIds.prefixRegexp, ""); // take just the nanoid part of the key
  }

  static fromCellId(cellId: string) {
    const match = new RegExp(`^(${canvasElementPrefix})?${consts.CANVAS_ELEMENTS.TABLE_CELL}-(.*)\/`).exec(cellId);
    if (match) {
      return new TableIds(match![2]);
    }
    return null;
  }

  justTableId() {
    return this.tableId;
  }
  fullTableId() {
    return `${canvasElementPrefix}${consts.CANVAS_ELEMENTS.TABLE}-${this.tableId}`;
  }
  commonPrefix() {
    return `tableCell-${this.tableId}`;
  }
  commonPrefixInReflect() {
    return canvasElementPrefix + this.commonPrefix();
  }
  shortId(col: string, row: string) {
    return `${this.commonPrefix()}/${col}-${row}`;
  }
  longId(col: string, row: string) {
    return canvasElementPrefix + this.shortId(col, row);
  }
  longIdFromMergedTo(mergedTo: string) {
    return `${canvasElementPrefix}${this.commonPrefix()}/${mergedTo}`;
  }
  static ensureFullId(id: string) {
    return id.startsWith(canvasElementPrefix) ? id : canvasElementPrefix + id;
  }
  static getColAndRow(id: string): { col: string; row: string } {
    const match = /\/(\w+)-(\w+)$/.exec(id);
    if (!match) {
      throw new Error("invalid id");
    }
    return { col: match[1], row: match[2] };
  }
}

function fastRound(n: number) {
  return n > 0 ? (n + 0.5) << 0 : (n - 0.5) << 0;
}

export function computeCoordinatesOfLines(
  lines: { id: string; size: number }[],
  overrideSize: null | Record<string, number>
) {
  const ps = new Array(lines.length + 1);
  ps[0] = 0;
  for (let i = 0; i < lines.length; i++) {
    ps[i + 1] = lines[i].size;
  }
  if (overrideSize) {
    for (let i = 0; i < lines.length; i++) {
      if (overrideSize[lines[i].id]) {
        ps[i + 1] = overrideSize[lines[i].id];
      }
    }
  }
  for (let i = 1; i < ps.length; i++) {
    ps[i] = fastRound(ps[i - 1] + ps[i]);
  }
  return ps;
}

const regexpToMatchKey = /^(?:cElement-)?tableCell-(\w+)\/(\w+)-(\w+)/;
export function splitKey(key: string) {
  const match = regexpToMatchKey.exec(key);
  if (!match) return { tableId: "", col: "", row: "" };
  return {
    tableId: match[1],
    col: match[2],
    row: match[3],
  };
}

export function useSubscribeCellsData(
  rep: Reflect<M> | undefined,
  id: string,
  defaultValue: Record<string, TypeTableCell>
): Record<string, TypeTableCell> {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const prefix = new TableIds(id).commonPrefixInReflect();
      return Object.fromEntries((await tx.scan({ prefix }).entries().toArray()) as [string, TypeTableCell][]);
    },
    defaultValue,
    [id]
  );
}

export function useSubscribeContainedElements(
  rep: Reflect<M> | undefined,
  ids: string[],
  allElements?: Array<[string, any]>
) {
  const queryRef = useRef(ids);
  const memoizedIds = deepEqual(queryRef.current, ids) ? queryRef.current : (queryRef.current = ids);

  const data = useMemo(() => {
    if (!allElements) return {};
    return memoizedIds.reduce((acc, id) => {
      const theElementEntry = allElements.find(([key]) => key == id);
      acc["cElement-" + id] = theElementEntry ? theElementEntry[1] : undefined;
      return acc;
    }, {} as Record<string, any>);
  }, [memoizedIds]);

  const subscription = useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const values = await Promise.all(ids.map((id) => tx.get("cElement-" + id)));
      return values.reduce((acc, v, index) => {
        if (typeof v === "object" && !Array.isArray(v) && v !== null && !(v as any).hidden) {
          acc[ids[index]] = v;
        }
        return acc;
      }, {} as Record<string, any>);
    },
    data,
    [memoizedIds]
  );

  if (allElements) return data;
  return subscription;
}

export function useTableData(
  rep: Reflect<M> | undefined,
  id: string
): { element: TypeTableElement; cells: Record<string, TypeTableCell> } {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const ids = new TableIds(id);

      const element = ((await tx.get(ids.fullTableId())) as TypeTableElement) ?? {};

      const allCells = (await tx.scan({ prefix: ids.commonPrefixInReflect() }).entries().toArray()) as [
        string,
        TypeTableCell
      ][];

      const cells = R.fromPairs(allCells);

      return { element, cells };
    },
    { element: {} as any, cells: {} },
    [id]
  );
}

export function screenToElementMatrix(stage: Konva.Stage, element: any): DOMMatrix {
  const screenToElement = new DOMMatrix();
  if (stage) {
    const { x, y, scaleX = 1, scaleY = 1 } = element;
    const q = stage.position();
    const s = stage.scaleX();
    // the order of operations is inverted with matrix multiplication, so we need to reverse the order
    // the natural order is screen -> stage -> element
    screenToElement.scaleSelf(1 / scaleX, 1 / scaleY);
    screenToElement.translateSelf(-x, -y);
    screenToElement.scaleSelf(1 / s);
    screenToElement.translateSelf(-q.x, -q.y);
  }
  return screenToElement;
}
