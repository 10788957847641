import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";
import { Group, Path } from "react-konva";

const MondayLogo = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const width = props.size ?? 30;
  const height = (props.size ?? 30) * 0.6;
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 30 18"
      ref={ref}
      style={{ verticalAlign: "middle" }}
      renderPath={() => {
        return (
          <>
            <path
              d="M3.72237 17.4766C2.36798 17.4754 1.12094 16.7701 0.46544 15.6346C-0.190063 14.4991 -0.150777 13.1122 0.568046 12.0125L7.27498 1.75378C7.96387 0.636753 9.23113 -0.0342089 10.5848 0.00134812C11.9386 0.0369052 13.1655 0.773382 13.7895 1.9249C14.4135 3.07641 14.3362 4.46163 13.5876 5.54283L6.88467 15.8015C6.2029 16.8453 5.00756 17.4785 3.72237 17.4766Z"
              fill="#FB275D"
            />
            <path
              d="M15.1479 17.4766C13.7957 17.4754 12.5507 16.7718 11.8963 15.639C11.2419 14.5062 11.2811 13.1226 11.9988 12.0254L18.6927 1.79113C19.3708 0.657811 20.6425 -0.0292145 22.0064 0.000956281C23.3703 0.0311271 24.6075 0.773652 25.2302 1.93575C25.8528 3.09785 25.7611 4.49304 24.9911 5.57118L18.2971 15.8055C17.6179 16.8445 16.4282 17.4758 15.1479 17.4766Z"
              fill="#FFCC00"
            />
            <path
              d="M26.291 17.4973C28.3394 17.4973 30 15.8397 30 13.7948C30 11.75 28.3394 10.0923 26.291 10.0923C24.2426 10.0923 22.582 11.75 22.582 13.7948C22.582 15.8397 24.2426 17.4973 26.291 17.4973Z"
              fill="#00CA72"
            />
          </>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

MondayLogo.displayName = "MondayLogo";

function MondayLogoKonva(props: { width?: number; height?: number; x?: number; y?: number }) {
  const { width = 30, height = 18, ...otherProps } = props;
  const scaleX = width / 30;
  const scaleY = height / 18;
  return (
    <Group scaleX={scaleX} scaleY={scaleY} {...otherProps}>
      <Path
        data="M3.72237 17.4766C2.36798 17.4754 1.12094 16.7701 0.46544 15.6346C-0.190063 14.4991 -0.150777 13.1122 0.568046 12.0125L7.27498 1.75378C7.96387 0.636753 9.23113 -0.0342089 10.5848 0.00134812C11.9386 0.0369052 13.1655 0.773382 13.7895 1.9249C14.4135 3.07641 14.3362 4.46163 13.5876 5.54283L6.88467 15.8015C6.2029 16.8453 5.00756 17.4785 3.72237 17.4766Z"
        fill="#FB275D"
      />
      <Path
        data="M15.1479 17.4766C13.7957 17.4754 12.5507 16.7718 11.8963 15.639C11.2419 14.5062 11.2811 13.1226 11.9988 12.0254L18.6927 1.79113C19.3708 0.657811 20.6425 -0.0292145 22.0064 0.000956281C23.3703 0.0311271 24.6075 0.773652 25.2302 1.93575C25.8528 3.09785 25.7611 4.49304 24.9911 5.57118L18.2971 15.8055C17.6179 16.8445 16.4282 17.4758 15.1479 17.4766Z"
        fill="#FFCC00"
      />
      <Path
        data="M26.291 17.4973C28.3394 17.4973 30 15.8397 30 13.7948C30 11.75 28.3394 10.0923 26.291 10.0923C24.2426 10.0923 22.582 11.75 22.582 13.7948C22.582 15.8397 24.2426 17.4973 26.291 17.4973Z"
        fill="#00CA72"
      />
    </Group>
  );
}

export { MondayLogo, MondayLogoKonva };
