import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const DataLayer = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <g transform="scale(0.9, 0.9)">
            <path
              d="M1 18.0722L0.999999 2.9278C0.999999 1.8631 5.029 1 10 1C14.971 0.999999 19 1.8631 19 2.9278L19 18.0722C19 19.1369 14.971 20 10 20C5.029 20 1 19.1369 1 18.0722Z"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M1 3C1 4.10555 5.029 5 10 5C14.971 5 19 4.10555 19 3"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M1 8C1 9.10555 5.029 10 10 10C14.971 10 19 9.10555 19 8"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M1 13C1 14.1055 5.029 15 10 15C14.971 15 19 14.1055 19 13"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
          </g>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

DataLayer.displayName = "DataLayer";

export { DataLayer };
