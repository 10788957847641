import Konva from "konva";
import React from "react";
import consts from "shared/consts";
import { TextEnabledElement } from "shared/datamodel/schemas";
import { fontPropertiesToString, fontSizeToPixels, konvaTextDecoration, sizeToPixel } from "shared/util/utils";

export function getFontSize(element: { fontSize?: string | number }): number {
  if (typeof element.fontSize == "number") return element.fontSize;
  if (typeof element.fontSize == "string") return fontSizeToPixels(element.fontSize) ?? consts.DEFAULTS.FONTSIZE;
  return consts.DEFAULTS.FONTSIZE;
}

export function computeFitWidth(text: string, renderProps: any) {
  const lines = text.split(/\r?\n/);
  const textElement = new Konva.Text(renderProps);
  const linesMeasurements = lines.map((line) => textElement.measureSize(line).width as number);
  const longestLine = Math.max.apply(null, linesMeasurements);
  return { longestLine, numLines: lines.length };
}

// Text block elements can be stretched horizontally, which changes their scale.x
// This warps the text: text must be rendered width scale.x == scale.y
// To correct, we set scale.x to 1/scale.x and apply scale.y, and take the remainder into the width
export function correctWidthForAspectRatio(width: number, scaleX: number, scaleY: number) {
  return { width: (width * scaleX) / scaleY, scaleX: scaleY / scaleX };
}

export function calcKonvaTextConfig({
  element,
  scaleFont = 1,
  verticalAlign = "top",
  placeholder = consts.DEFAULTS.TEXT,
}: {
  element: TextEnabledElement;
  scaleFont?: number;
  verticalAlign?: "top" | "middle";
  placeholder?: string;
}) {
  const fontSize = element.fontSize ?? placeholder;
  let fontSizePixels =
    (typeof fontSize == "number" ? +fontSize : sizeToPixel(fontSize, "font")) || consts.DEFAULTS.FONTSIZE;
  fontSizePixels *= scaleFont;
  return {
    text: element.text ?? placeholder,
    fontFamily: element.font ?? consts.DEFAULTS.FONT,
    lineHeight: consts.LINE_HEIGHT,
    fontSize: fontSizePixels,
    fill: element.text ? element.textColor : consts.DEFAULTS.TEXT_PLACEHOLDER_COLOR,
    fontStyle: element.text ? fontPropertiesToString(element.fontProps) : "300",
    textDecoration: konvaTextDecoration(element.fontProps),
    ellipsis: false,
    wrap: "word",
    perfectDrawEnabled: false,
    align: element.align ?? consts.DEFAULTS.TEXT_ALIGN,
    verticalAlign,
  };
}

export const handleTabInTextArea = (e: React.KeyboardEvent) => {
  if (e.key == "Tab") {
    e.preventDefault();
    const textarea = e.target as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    // set textarea value to: text before caret + tab + text after caret
    textarea.value = textarea.value.substring(0, start) + "\t" + textarea.value.substring(end);
    // put caret at right position again
    textarea.selectionStart = textarea.selectionEnd = start + 1;
  }
};
export const handleTabInContentEditable = (e: React.KeyboardEvent) => {
  if (e.key == "Tab") {
    const sel = window.getSelection && window.getSelection();
    if (sel) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode("\t"));
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      e.preventDefault();
    }
  }
};

export const fitTextIntoElement = (
  text: string,
  element: { font: string; fontSizeInPixels: number; width: number; height: number; scaleX: number; scaleY: number }
) => {
  const textNode = new Konva.Text({
    text,
    fontSize: 30, // Initial font size
    width: element.width,
    align: "center",
    wrap: "word",
    scaleX: element.scaleX,
    lineHeight: consts.LINE_HEIGHT,
    scaleY: element.scaleX,
  });

  const { width, height } = element;
  let fontSize = textNode.fontSize();

  textNode.width(width);
  textNode.wrap("word");
  textNode.align("center");

  while (textNode.height() > height && fontSize > 1) {
    fontSize -= 1;
    textNode.fontSize(fontSize);
  }

  return fontSize;
};
