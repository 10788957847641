import { SinglePatch } from "frontend/canvas-designer-new";
import { produce, Patch } from "immer";
import { useAtomValue } from "jotai";
import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { useEvent } from "react-use";
import { TypeTableElement, TypeTableCell } from "shared/datamodel/schemas";
import { stageRefAtom } from "state-atoms";
import { measureNode } from "utils/node-utils";
import { screenToElementMatrix, TableIds } from "../table-utils";
import { MouseCursor } from "../widgets/mouse-cursor-util";

export interface TrackResizingState {
  trackKey: string;
  trackType: "cols" | "rows";
  prevTrackCoordinate: number;
  trackIndex: number;
  mouseX: number;
  mouseY: number;
}

const ensureFullId = (id: string) => (id.startsWith("cElement-") ? id : "cElement-" + id);
const defaultStateForTrackingSize = { dx: 0, dy: 0, newSize: 0 };

export function TrackResizeComponent({
  id,
  setOverrideSize,
  endDrag,
  patchAnything,
  element,
  initial,
  cells,
  containedElements,
}: {
  id: string;
  setOverrideSize: (key: string, size: number) => void;
  endDrag: () => void;
  element: TypeTableElement;
  patchAnything: any;
  initial: TrackResizingState;
  cells: Record<string, TypeTableCell>;
  containedElements: Record<string, any>;
}) {
  const stage = useAtomValue(stageRefAtom)?.current;
  const idMaker = new TableIds(id);
  const fullElementId = idMaker.fullTableId();
  const [initialValueSavedForUndo] = useState(() => element);

  const lastUpdate = useRef(defaultStateForTrackingSize);

  // Send updates to replicache every 100 ms, and final update when component
  // unmounts (mark undo point only on unmount)
  useEffect(() => {
    function sendUpdateToReplicache(finishNow: boolean) {
      const { dx, dy, newSize } = lastUpdate.current;
      lastUpdate.current = defaultStateForTrackingSize;
      if (dx != 0 || dy != 0) {
        const patches: SinglePatch[] = [];
        const patchAndSave = (id: string, base: any, recipe: any): void =>
          produce(base, recipe, (_patches: Patch[], _inversePatches: Patch[]) => {
            patches.push({ id: ensureFullId(id), patch: _patches, inversePatch: _inversePatches });
          });

        for (const { id, startX, startY } of elementsToPush) {
          patchAndSave(id, { x: startX, y: startY }, (draft: any) => {
            draft.x = startX + dx;
            draft.y = startY + dy;
          });
        }
        patchAndSave(fullElementId, initialValueSavedForUndo, (draft: TypeTableElement) => {
          draft[initial.trackType][initial.trackIndex].size = newSize;
        });
        patchAnything(patches, finishNow ? false : true); // add undo when finishing operation
      }
    }
    // throttle updates every 33 msec
    let id = window.setInterval(() => sendUpdateToReplicache(false), 33);
    return () => {
      window.clearInterval(id);
      sendUpdateToReplicache(true);
    };
  }, []);

  // Getting the contained-elements of the table that will affect and be affected by this drag operation.
  // If we're resizing col N, then elements in N set minimum width, and all elements in X>N will be moved.
  // I think this is an expensive operation.
  // Ideally, do this in the background, and once the results come in they affect the operation.
  // (How to do this? what if the operation is over too quickly?)
  // The useMemo has no depenencies, because the props change all the time while this component is running.
  // This isn't good - I should find some way to separate what changes from what doesn't, and add dependencies.
  const { minimumSize, elementsToPush } = useMemo(() => {
    let minimumSize = 0;
    const elementsToPush: Array<{ id: string; element: any; startX: number; startY: number; node: any }> = [];
    const layer = stage.getLayers()[0];
    const { scaleX = 1, scaleY = 1 } = element;

    if (initial.trackType == "cols") {
      const col = initial.trackIndex;
      let colStartX = 0;
      for (let i = 0; i < col; i++) {
        colStartX += element.cols[i].size;
      }
      colStartX = colStartX * scaleX + element.x;
      let leftBoundary = colStartX + 10;
      for (const { id: row } of element.rows) {
        const cellId = idMaker.longId(element.cols[col].id, row);
        if (cells[cellId]?.containedIds?.length) {
          for (const id of cells[cellId].containedIds) {
            const element = containedElements[id];
            if (element) {
              const node = layer.findOne("." + id);
              const rect = measureNode(node);
              leftBoundary = Math.max(leftBoundary, rect.x + rect.width + 10);
            }
          }
        }
      }
      minimumSize = (leftBoundary - colStartX) / scaleX;
    } else {
      const row = initial.trackIndex;
      let rowStartY = 0;
      for (let i = 0; i < row; i++) {
        rowStartY += element.rows[i].size;
      }
      rowStartY = element.y + rowStartY * scaleY;
      let topBoundary = rowStartY + 10;
      for (const { id: col } of element.cols) {
        const cellId = idMaker.longId(col, element.rows[row].id);
        if (cells[cellId]?.containedIds?.length) {
          for (const id of cells[cellId].containedIds) {
            const element = containedElements[id];
            if (element) {
              const node = layer.findOne("." + id);
              const rect = measureNode(node);
              topBoundary = Math.max(topBoundary, rect.y + rect.height + 10);
            }
          }
        }
      }
      minimumSize = (topBoundary - rowStartY) / scaleY;
    }

    function collectElementsInCell(col: string, row: string) {
      const cellId = idMaker.longId(col, row);
      if (cellId in cells) {
        if (cells[cellId].containedIds?.length) {
          for (const id of cells[cellId].containedIds) {
            const element = containedElements[id];
            if (element) {
              const node = layer.findOne("." + id);
              elementsToPush.push({ id, element, node, startX: element.x, startY: element.y });
            }
          }
        }
      }
    }
    if (initial.trackType == "cols") {
      const col = initial.trackIndex;
      for (let col2 = col + 1; col2 < element.cols.length; col2++) {
        for (const { id: row } of element.rows) {
          collectElementsInCell(element.cols[col2].id, row);
        }
      }
    } else {
      const row = initial.trackIndex;
      for (const { id: col } of element.cols) {
        for (let i = row + 1; i < element.rows.length; i++) {
          collectElementsInCell(col, element.rows[i].id);
        }
      }
    }
    return { minimumSize, elementsToPush };
  }, []);

  function handleMouseEvent(mousex: number, mousey: number) {
    const matrix = screenToElementMatrix(stage, element);
    const { x, y } = matrix.transformPoint({ x: mousex, y: mousey });

    const newSize =
      initial.trackType == "cols"
        ? Math.max(minimumSize, x - initial.prevTrackCoordinate)
        : Math.max(minimumSize, y - initial.prevTrackCoordinate);
    setOverrideSize(initial.trackKey, newSize);

    if (newSize > minimumSize) {
      const dx = initial.trackType == "cols" ? (mousex - initial.mouseX) / stage.getScaleX() : 0;
      const dy = initial.trackType == "cols" ? 0 : (mousey - initial.mouseY) / stage.getScaleX();
      for (const { node, startX, startY } of elementsToPush) {
        dx != 0 && node.x(startX + dx);
        dy != 0 && node.y(startY + dy);
      }
      // send the patch to replicache
      lastUpdate.current = { dx: dx, dy: dy, newSize };
    }
  }

  // Set mouse cursor while this component is alive and running
  useEffect(() => {
    if (stage) {
      initial.trackType == "cols" ? MouseCursor.setColResize(stage) : MouseCursor.setRowResize(stage);
      return () => MouseCursor.unset(stage);
    }
  }, [stage]);

  //TODO - add hook for catching esc key, and then abort drag completely (reset to initial, no undo)

  useEvent(
    "mousemove",
    useCallback(
      (e) => {
        if (e.buttons == 0) {
          endDrag();
          return;
        }
        handleMouseEvent(e.clientX, e.clientY);
      },
      [elementsToPush, minimumSize]
    )
  );

  useEvent(
    "mouseup",
    useCallback(
      (e) => {
        handleMouseEvent(e.clientX, e.clientY);
        endDrag();
      },
      [elementsToPush, minimumSize]
    )
  );
  // Return null, because this component exists just to listen to events and trigger the drag operation
  return null;
}
