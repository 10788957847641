import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { useSubscribe } from "replicache-react";
import { getElement } from "shared/datamodel/canvas-element";
import { CanvasElement } from "shared/datamodel/schemas";
import { syncServiceAtom } from "state-atoms";
import { useIntegrationsProvider } from "./use-integrations-provider";
import { useDeepEqualMemo } from "./use-deep-memoize";

interface DerivedElementData {
  id: string;
  keyPath: string;
}

interface DerivedIntegrationData {
  elementId: string;
  integrationId: string;
  itemId: string;
  columnId: string;
}

type DerivedData = DerivedElementData | DerivedIntegrationData;

export function useDerivedData(element: CanvasElement | null | undefined, isDataLayerEnabled: boolean) {
  let reflect = useAtomValue(syncServiceAtom)?.getReplicache();

  // If the data layer is disabled, that's the easiest way to disable the derived data
  if (!reflect || !isDataLayerEnabled) {
    reflect = null;
    element = null;
  }

  const extractDerivedValue = useCallback((value: string): DerivedData | null => {
    // check regex for ${derivedElementId:derivedElementPropKeyPath}
    const regex = /\${(.*?):(.*?)}/g;
    const matches = value.matchAll(regex);
    for (const match of matches) {
      const derivedElementId = match[1];
      const derivedElementPropertyKeyPath = match[2];
      const [key, integrationId, itemId, columnId] = derivedElementPropertyKeyPath.split(":");
      if (key === "integration") {
        return { elementId: derivedElementId, integrationId, itemId, columnId };
      }
      // TODO: support formatted text like formula,
      // now we stop on the first match
      return { id: derivedElementId, keyPath: derivedElementPropertyKeyPath };
    }
    return null;
  }, []);

  const derivedElements = useMemo(() => {
    if (!element) return {};
    const propertyToDerived: Record<string, DerivedData> = {};
    for (const [key, value] of Object.entries(element)) {
      if (typeof value !== "string") continue;
      const derivedValue = extractDerivedValue(value);
      if (derivedValue) {
        propertyToDerived[key] = derivedValue;
      }
    }
    return propertyToDerived;
  }, [element, extractDerivedValue]);

  const getKeyPathValue = (element: any, keyPath: string) => {
    const keyPathParts = keyPath.split(".");
    let value = element;
    for (const part of keyPathParts) {
      value = value[part];
      if (value === undefined) return null;
    }
    return value;
  };

  const values = useSubscribe(
    reflect,
    async (tx) => {
      const values: Record<string, any> = {};
      for (const [key, derivedElement] of Object.entries(derivedElements)) {
        if (!("keyPath" in derivedElement)) continue;
        // eslint-disable-next-line no-await-in-loop
        const element = await getElement(tx, derivedElement.id);
        if (!element) continue;
        const value = getKeyPathValue(element, derivedElement.keyPath);
        if (value) {
          values[key] = value;
        }
      }
      return values;
    },
    {},
    [derivedElements]
  );

  const integrations = useSubscribe(
    reflect,
    async (tx) => {
      const integrations: Record<string, { integrationId: string; itemId: string; columnId: string }> = {};
      for (const [key, value] of Object.entries(derivedElements)) {
        if (!("integrationId" in value)) continue;
        // eslint-disable-next-line no-await-in-loop
        const element = await getElement(tx, value.elementId);
        if (!element) continue;
        const elementIntegrationItemIds = Object.keys(element.dataLayer?.integrations?.[value.integrationId] ?? {});
        const itemId = elementIntegrationItemIds.includes(value.itemId) ? value.itemId : elementIntegrationItemIds[0];
        if (!itemId) continue;
        integrations[key] = { integrationId: value.integrationId, itemId, columnId: value.columnId };
      }
      return integrations;
    },
    {},
    [derivedElements]
  );

  return { values, integrations, keys: Object.keys(derivedElements) };
}

export function DerivedIntegrationDataHandler({
  integrations,
  onChangeValue,
}: {
  integrations: Record<string, { itemId: string; integrationId: string; columnId: string }>;
  onChangeValue: (values: Record<string, string>) => void;
}) {
  const { getItem, loadItems } = useIntegrationsProvider();

  const memoedIntegrations = useDeepEqualMemo(integrations);
  useEffect(() => {
    const integrationsToLoad = Object.values(memoedIntegrations).reduce((acc, integration) => {
      acc[integration.integrationId] ??= [];
      acc[integration.integrationId].push(integration.itemId);
      return acc;
    }, {} as Record<string, string[]>);
    loadItems(integrationsToLoad);
  }, [memoedIntegrations]);

  const values = useMemo(() => {
    const values: Record<string, string> = {};
    for (const [key, integration] of Object.entries(integrations)) {
      const item = getItem(integration.itemId, integration.integrationId);

      if (!item) continue;
      if (integration.columnId === "name") {
        values[key] = item.name;
        continue;
      }
      const columnValue = item.columnValues.find((column: { id: string }) => column.id === integration.columnId);
      if (!columnValue) {
        values[key] = "";
        continue;
      }
      const value = columnValue.value;
      if (typeof value === "string") {
        values[key] = value;
      }
      if (typeof value === "object") {
        values[key] = value.text;
      }
    }
    return values;
  }, [getItem, integrations]);

  const memoedValues = useDeepEqualMemo(values);
  useEffect(() => {
    onChangeValue(memoedValues);
  }, [memoedValues]);

  return null;
}
