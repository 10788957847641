import { KonvaEventObject } from "konva/types/Node";
import { Shape } from "react-konva";
import { Button as Style } from "../table-styling";
import { onHoverOff, onHoverOn } from "./hover-effect";

export type MouseCb = (e: KonvaEventObject<MouseEvent>) => void;

export function Button({
  x = 0,
  y = 0,
  ofsX = 0,
  ofsY = 0,
  distanceToSibling,
  onClick,
}: {
  x?: number;
  y?: number;
  ofsX?: number;
  ofsY?: number;
  distanceToSibling: number;
  onClick: MouseCb;
}) {
  return (
    <Shape
      x={x}
      y={y}
      name="table-widget-anchor"
      listening={true}
      fill={Style.Fill}
      stroke={Style.Stroke}
      strokeWidth={1.5}
      strokeScaleEnabled={false}
      onMouseEnter={onHoverOn}
      onMouseLeave={onHoverOff}
      onClick={onClick}
      sceneFunc={(context, shape) => {
        const stage = shape.getStage();
        if (!stage) return; // should never happen
        const scale = stage.scaleX(); // button is fixed-size, regardless of stage scale
        if (!Number.isFinite(distanceToSibling)) return;
        const distanceOnScreen = distanceToSibling * scale;
        if (distanceOnScreen <= 50) return; //don't draw buttons when they are too close

        context.translate(ofsX / scale, ofsY / scale);
        context.beginPath();
        context.arc(0, 0, 11 / scale, 0, 2 * Math.PI, false);
        context.fillShape(shape);

        context.beginPath();
        context.moveTo(-4 / scale, 0);
        context.lineTo(4 / scale, 0);
        context.moveTo(0, -4 / scale);
        context.lineTo(0, 4 / scale);
        context.strokeShape(shape);
      }}
      // Custom hit func, drawing a slightly larger circle than the button (makes it real easy to click)
      hitFunc={(context, shape) => {
        const stage = shape.getStage();
        if (!stage) return;
        const scale = stage.scaleX();
        if (!Number.isFinite(distanceToSibling)) return;
        const distanceOnScreen = distanceToSibling * scale;
        if (distanceOnScreen <= 50) return; //don't draw buttons when they are too close

        context.beginPath();
        context.translate(ofsX / scale, ofsY / scale);
        context.arc(0, 0, 20 / scale, 0, 2 * Math.PI, false);
        context.fillShape(shape);
      }}
    />
  );
}
