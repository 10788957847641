import { useAtomValue } from "jotai";
import { Node, NodeConfig } from "konva/types/Node";
import { useEffect, useRef } from "react";
import { accountAtom, layerRefAtom } from "state-atoms";

const TEST_ACCOUNTS = [
  "18617988", // TestyBesty
];
const ENABLED_ACCOUNTS = new Set<string>(TEST_ACCOUNTS);

export function useTrackRenderCountForTests(elementId: string) {
  const account = useAtomValue(accountAtom);
  const layerRef = useAtomValue(layerRefAtom);
  const node = useRef<Node<NodeConfig> | null>(null);
  const renderCount = useRef(0);

  useEffect(() => {
    if (!ENABLED_ACCOUNTS.has(String(account?.id))) return;

    if (!node.current) {
      node.current = layerRef?.current?.findOne(`.${elementId}`) ?? null;
    }

    renderCount.current += 1;
    node.current?.setAttr("renderCount", renderCount.current);
  });

  return renderCount.current;
}
