/**
 * Finds the element in an array that is closest to a given point.
 *
 * @param p - The reference point to find the closest element to
 * @param arr - The array to search in
 * @returns The element from the array that is closest to the reference point
 * @throws {Error} If the array is empty
 */
export function findClosest(p: number, arr: number[]): number;

/**
 * Finds the element in an array that is closest to a given point using a custom distance function.
 *
 * @param p - The reference point to find the closest element to
 * @param arr - The array to search in
 * @param distanceFunction - A function that calculates the distance between two elements
 * @returns The element from the array that is closest to the reference point
 * @throws {Error} If the array is empty
 */
export function findClosest<T>(p: T, arr: T[], distanceFunction: (a: T, b: T) => number): number;

export function findClosest<T>(p: T, arr: T[], distanceFunction?: (a: T, b: T) => number): number {
  if (arr.length === 0) {
    throw new Error("Cannot find closest element in an empty array");
  }

  // For number type without distance function
  if (
    typeof p === "number" &&
    // arr.every(x => typeof x === "number") &&
    !distanceFunction
  ) {
    const numArr = arr as number[];
    let bestIndex = 0,
      bestDistance = Math.abs(p - numArr[0]);
    for (let i = 1; i < numArr.length; i++) {
      const d = Math.abs(p - numArr[i]);
      if (d < bestDistance) {
        bestDistance = d;
        bestIndex = i;
      }
    }
    return bestIndex;
  }

  // For generic type with distance function
  if (!distanceFunction) {
    throw new Error("Distance function is required for non-number types");
  }

  let bestIndex = 0,
    bestDistance = distanceFunction(p, arr[0]);
  for (let i = 1; i < arr.length; i++) {
    const d = distanceFunction(p, arr[i]);
    if (d < bestDistance) {
      bestDistance = d;
      bestIndex = i;
    }
  }
  return bestIndex;
}
