import { IconElement } from "shared/datamodel/schemas/icon";
import { BaseElementController, ElementController } from "elements/base/controller";
import { BoardContext } from "..";
import { equals } from "rambda";

export interface IIconController extends ElementController<IconElement> {
  isEditing: () => boolean;
  showPlaceholder: () => boolean;
}

export default class IconController extends BaseElementController<IconElement> implements IIconController {
  #showPlaceholder: boolean = true;

  constructor(id: string, element: IconElement, context: BoardContext, showPlaceholder = true) {
    super(id, element, context);
    this.#showPlaceholder = showPlaceholder;
  }

  public isEditing(): boolean {
    return this.context.editingElementId === this.id;
  }

  public showPlaceholder(): boolean {
    return this.#showPlaceholder;
  }

  public isEditingLink(): boolean {
    return this.context.editingElementLinkId === this.id;
  }

  updateContext(context: BoardContext): void {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const makeProps = () => {
      return [this.isEditing(), this.isEditingLink()];
    };

    const oldProps = makeProps();
    super.updateContext(context);
    const newProps = makeProps();

    if (!equals(oldProps, newProps)) {
      this.notify();
    }
  }

  updateElement(element: IconElement): void {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const makeElement = () => {
      return {
        href: this.element.href,
        text: this.element.text,
      };
    };

    const oldElement = makeElement();
    super.updateElement(element);
    const newElement = makeElement();

    if (!equals(oldElement, newElement)) {
      this.notify();
    }
  }

  getLayoutRect(): { x: number; y: number; width: number; height: number } {
    return { x: this.element.x, y: this.element.y, width: this.element.width, height: this.element.height };
  }
}
