import React from "react";

export const TeamsIcon = ({ stroke = "#113357" }: { stroke?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
      <path
        d="M9.34375 3.24083C9.34375 3.83513 9.57984 4.40509 10.0001 4.82533C10.4203 5.24557 10.9903 5.48165 11.5846 5.48165C12.1789 5.48165 12.7488 5.24557 13.1691 4.82533C13.5893 4.40509 13.8254 3.83513 13.8254 3.24083C13.8254 2.64652 13.5893 2.07656 13.1691 1.65632C12.7488 1.23609 12.1789 1 11.5846 1C10.9903 1 10.4203 1.23609 10.0001 1.65632C9.57984 2.07656 9.34375 2.64652 9.34375 3.24083Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75879 4.35693C2.75879 4.85097 2.95505 5.32478 3.30439 5.67412C3.65373 6.02346 4.12753 6.21972 4.62158 6.21972C5.11562 6.21972 5.58943 6.02346 5.93877 5.67412C6.28811 5.32478 6.48436 4.85097 6.48436 4.35693C6.48436 3.86289 6.28811 3.38908 5.93877 3.03974C5.58943 2.6904 5.11562 2.49414 4.62158 2.49414C4.12753 2.49414 3.65373 2.6904 3.30439 3.03974C2.95505 3.38908 2.75879 3.86289 2.75879 4.35693Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22852 9.8507C7.41339 9.23538 7.79169 8.69606 8.30728 8.31272C8.82288 7.92939 9.44831 7.72247 10.0908 7.72266H13.0786C13.7219 7.72243 14.3481 7.92986 14.864 8.31407C15.38 8.69829 15.7582 9.23879 15.9423 9.85518"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.85106C1.15369 9.33955 1.46816 8.89121 1.89677 8.57255C2.32539 8.25389 2.84531 8.08187 3.3794 8.08203H5.86312"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
