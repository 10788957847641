import { ElementProps } from "elements/base/provider";
import { SingleBarChartElement } from "shared/datamodel/schemas/chart";
import { Group, Rect } from "react-konva";
import type { ISingleBarChartController } from "elements/chart/single-bar/single-chart-controller";
import useObservableController from "elements/hooks/use-observable-controller";
import { useEffect } from "react";
import { useIntegrationsProvider } from "frontend/hooks/use-integrations-provider";

export default function SingleBarChartComponent({ controller: _controller }: ElementProps<SingleBarChartElement>) {
  const controller = _controller as ISingleBarChartController;
  useObservableController(controller);

  const { dataSource, loadItems } = useIntegrationsProvider();
  useEffect(() => {
    controller.integrationsController.setAddItemFunc(loadItems);
    controller.loadIntegrationItems();
  }, []);

  useEffect(() => {
    controller.integrationsController.setDataSource(dataSource);
  }, [dataSource]);

  const minValue = controller.getMinValue();
  const maxValue = controller.getMaxValue();
  const value = Math.min(Math.max(controller.getValue(), minValue), maxValue);
  const width = controller.getWidth();
  const height = controller.getHeight();
  const fillWidth = ((value - minValue) / (maxValue - minValue)) * width;

  const cornerRadius = 5; // You can adjust the corner radius as needed

  return (
    <Group>
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={controller.getBackgroundColor()}
        cornerRadius={cornerRadius}
      />
      <Rect x={0} y={0} width={fillWidth} height={height} fill={controller.getFill()} cornerRadius={cornerRadius} />
    </Group>
  );
}
