import { TypeCanvasElement } from "shared/consts";
import type { BoardContext } from "../index";
import useCanvasElement from "elements/hooks/use-canvas-element";
import React from "react";
import BaseElementComponent from "elements/base/base-element-component";
import { Point } from "shared/datamodel/schemas";
import { equals } from "rambda";
import { ElementLink } from "elements/base/link-element/element-link";

interface ElementComponentProps {
  id: string;
  type: TypeCanvasElement;
  context: BoardContext;
  elementData?: any;
  allElementsData?: [string, unknown][];
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}

function ElementComponent({
  id,
  type,
  context,
  elementData = null,
  allElementsData = [],
  onResize,
}: ElementComponentProps) {
  const {
    element = elementData,
    controller,
    provider,
  } = useCanvasElement(id, type, context, elementData, allElementsData);

  if (!element || !controller || !provider) {
    return null;
  }

  const Component = provider.getElementRenderer();

  return (
    <BaseElementComponent
      id={id}
      key={id}
      type={type}
      element={element}
      controller={controller}
      isDraggable={true}
      isSelectable={!context.hiddenElementIds.includes(id)}
      isConnectable={provider.isConnectorsEnabled()}
      onResize={onResize}
    >
      <Component id={id} controller={controller} />
      <ElementLink
        controller={controller}
        isEditingLink={context.editingElementLinkId === id}
        link={element.link ?? ""}
      />
    </BaseElementComponent>
  );
}

function getElementFromAllElementsData(allElementsData: [string, unknown][] | undefined, id: string) {
  return allElementsData?.find(([elementId]) => elementId === id)?.[1];
}
function arePropsEqual(previousProps: ElementComponentProps, nextProps: ElementComponentProps) {
  const idChanged = previousProps.id !== nextProps.id;
  const typeChanged = previousProps.type !== nextProps.type;
  const elementDataChanged = !equals(previousProps.elementData, nextProps.elementData);
  const readOnlyChanged = previousProps.context.isReadOnly !== nextProps.context.isReadOnly;

  const selectedChanged = !equals(previousProps.context.selectedElementIds, nextProps.context.selectedElementIds);
  const isInMultiSelectChanged =
    (previousProps.context.selectedElementIds.includes(previousProps.id) &&
      previousProps.context.selectedElementIds.length > 1) !==
    (nextProps.context.selectedElementIds.includes(nextProps.id) && nextProps.context.selectedElementIds.length > 1);

  const selectableChanged =
    previousProps.context.hiddenElementIds.includes(previousProps.id) !==
    nextProps.context.hiddenElementIds.includes(nextProps.id);

  const editingChanged =
    (previousProps.context.editingElementId === previousProps.id) !==
    (nextProps.context.editingElementId === nextProps.id);

  const editingLinkChanged =
    (previousProps.context.editingElementLinkId === previousProps.id) !==
    (nextProps.context.editingElementLinkId === nextProps.id);

  const allElementsDataChanged =
    getElementFromAllElementsData(previousProps.allElementsData, previousProps.id) !==
    getElementFromAllElementsData(nextProps.allElementsData, nextProps.id);

  return !(
    idChanged ||
    typeChanged ||
    elementDataChanged ||
    readOnlyChanged ||
    selectedChanged ||
    isInMultiSelectChanged ||
    editingChanged ||
    editingLinkChanged ||
    selectableChanged ||
    allElementsDataChanged
  );
}

const MemoedElementComponent = React.memo(ElementComponent, arePropsEqual);
export default MemoedElementComponent;
