import consts, { TypeCanvasElement } from "shared/consts";
import { ElementRuleValueType } from "shared/datamodel/schemas/element-data-layer";

export function getPropertiesForElement(type: TypeCanvasElement) {
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      return ["fill", "border", "scale"];
    }
    case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
      return ["fill"];
    }

    default: {
      return [];
    }
  }
}

export function getPropertyTitleForElement(property: string) {
  switch (property) {
    case "fill": {
      return "Fill Color";
    }
    case "border": {
      return "Stroke Color";
    }
    case "icon": {
      return "Icon";
    }
    case "scale": {
      return "Scale";
    }
    default: {
      return "";
    }
  }
}

export function getPropertyTypeForElement(property: string): ElementRuleValueType {
  switch (property) {
    case "fill":
    case "border": {
      return ElementRuleValueType.color;
    }
    case "icon": {
      return ElementRuleValueType.icon;
    }
    default: {
      return ElementRuleValueType.text;
    }
  }
}

export function getPropertyOptions(type: TypeCanvasElement, property: string) {
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      switch (property) {
        case "fill":
        case "border": {
          return consts.COLOR_PALETTE_INCLUDING_WHITE_TRANSPARENT;
        }
        default: {
          return [];
        }
      }
    }
    case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
      switch (property) {
        case "fill": {
          return consts.STICKY_NOTE_COLORS;
        }
        default: {
          return [];
        }
      }
    }

    default: {
      return [];
    }
  }
}

export function changedPropertiesForElement(elementType: TypeCanvasElement, property: string, newValue: any) {
  if (!newValue) {
    return {};
  }
  switch (elementType) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      switch (property) {
        case "scale": {
          const numberValue = Number.parseFloat(newValue);
          if (Number.isNaN(numberValue)) {
            return {};
          }
          return { scaleX: numberValue, scaleY: numberValue };
        }
      }
    }
  }
  return { [property]: newValue };
}
