import { Shape } from "react-konva";
import type { TypeTableElement } from "shared/datamodel/schemas/table";
import type { TableCellsInfo } from "../table-info";
import { screenToElementMatrix, TableIds } from "../table-utils";
import { MouseCursor } from "../widgets/mouse-cursor-util";

export interface TableTrackLinesProps {
  element: TypeTableElement;
  xs: number[];
  ys: number[];
  cells: Record<string, any>;
  info: TableCellsInfo;
  onDragStart: (type: "rows" | "cols", trackIndex: number, mouseX: number, mouseY: number) => void;
}

export function TableTrackLines({ element, xs, ys, cells, info, onDragStart }: TableTrackLinesProps) {
  // vertical lines
  let hlines: Array<[number, number][]> = [];
  let vlines: Array<[number, number][]> = [];

  // Let's build lists of vertical and horizontal table lines
  hlines.push([[0, 0]]);
  for (let i = 1; i < ys.length; i++) {
    hlines.push([[0, xs.length - 1]]); // [first-x, last-x]
  }
  vlines.push([[0, 0]]);
  for (let i = 1; i < xs.length; i++) {
    vlines.push([[0, ys.length - 1]]); // [first-y, last-y]
  }

  // Now go over cells where spanX!=1 || spanY!=1, and break apart
  // the lines inside those "merged" cells.
  for (const [key, cell] of Object.entries(cells)) {
    const spanX = cell?.spanX ?? 1;
    const spanY = cell?.spanY ?? 1;
    if (spanX > 1 || spanY > 1) {
      const { col, row } = TableIds.getColAndRow(key);
      const minx = info.colIndex(col);
      const miny = info.rowIndex(row);
      const maxx = minx + spanX;
      const maxy = miny + spanY;

      for (let i = minx + 1; i < maxx; i++) {
        const line = vlines[i];
        vlines[i] = line.flatMap((segment) => {
          const [segmentStart, segmentEnd] = segment;
          if (segmentStart <= miny && segmentEnd >= maxy) {
            return [
              [Math.min(miny, segmentStart), miny],
              [maxy, Math.max(maxy, segmentEnd)],
            ];
          } else {
            return [segment];
          }
        });
      }
      for (let i = miny + 1; i < maxy; i++) {
        const line = hlines[i];
        hlines[i] = line.flatMap((segment) => {
          const [segmentStart, segmentEnd] = segment;
          if (segmentStart <= minx && segmentEnd >= maxx) {
            return [
              [Math.min(minx, segmentStart), minx],
              [maxx, Math.max(maxx, segmentEnd)],
            ];
          } else {
            return [segment];
          }
        });
      }
    }
  }

  return (
    <>
      <Shape
        name="table hline anchor"
        fillEnabled={false}
        stroke="transparent"
        strokeWidth={5}
        draggable={true}
        strokeScaleEnabled={false}
        strokeEnabled={true}
        onMouseEnter={MouseCursor.setRowResize}
        onMouseLeave={MouseCursor.unset}
        onDragStart={(e) => {
          const stage = e.target.getStage();
          if (!stage) {
            console.warn("event handler on detached node");
            return;
          }
          const matrix = screenToElementMatrix(stage, element);
          const x = e.evt.clientX,
            y = e.evt.clientY;
          const point = matrix.transformPoint({ x, y });
          let index = 0,
            best = Number.MAX_SAFE_INTEGER;
          for (let i = 0; i < ys.length; i++) {
            const distance = Math.abs(point.y - ys[i]);
            if (distance < best) {
              best = distance;
              index = i;
            }
          }
          if (index == -1) {
            console.warn("could not find row to resize");
          } else {
            onDragStart("rows", index - 1, e.evt.clientX, e.evt.clientY);
          }
        }}
        hitFunc={(context, shape) => {
          context.beginPath();
          let index = 0;
          for (const line of hlines) {
            let y = ys[index++];
            for (const segment of line) {
              const x1 = xs[segment[0]],
                x2 = xs[segment[1]];
              if (x1 == x2) continue;
              context.moveTo(x1, y);
              context.lineTo(x2, y);
            }
          }
          context.fillStrokeShape(shape);
        }}
      />
      <Shape
        name="table vline anchor"
        fillEnabled={false}
        stroke="transparent"
        strokeWidth={5}
        draggable={true}
        strokeScaleEnabled={false}
        strokeEnabled={true}
        onMouseEnter={MouseCursor.setColResize}
        onMouseLeave={MouseCursor.unset}
        onDragStart={(e) => {
          const stage = e.target.getStage();
          if (!stage) {
            console.warn("event handler on detached node");
            return;
          }
          const matrix = screenToElementMatrix(stage, element);
          const x = e.evt.clientX,
            y = e.evt.clientY;
          const point = matrix.transformPoint({ x, y });
          let index = 0,
            best = Number.MAX_SAFE_INTEGER;
          for (let i = 0; i < xs.length; i++) {
            const distance = Math.abs(point.x - xs[i]);
            if (distance < best) {
              best = distance;
              index = i;
            }
          }
          if (index == -1) {
            console.warn("could not find col to resize");
          } else {
            onDragStart("cols", index - 1, e.evt.clientX, e.evt.clientY);
          }
        }}
        hitFunc={(context, shape) => {
          context.beginPath();
          let index = 0;
          for (const line of vlines) {
            const x = xs[index++];
            for (const segment of line) {
              const y1 = ys[segment[0]],
                y2 = ys[segment[1]];
              context.moveTo(x, y1);
              context.lineTo(x, y2);
            }
          }
          context.fillStrokeShape(shape);
        }}
      />
    </>
  );
}
