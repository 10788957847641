import { Rect, Text } from "react-konva";
import { MindmapNodeElement } from "shared/datamodel/schemas/mindmap";
import BaseCanvasElement from "./base-canvas-element";
import { FontProperties, Point } from "shared/datamodel/schemas";
import consts from "shared/consts";
import { FreeSizeTextEditor, calcKonvaTextConfig, getFontSize } from "../text-element";
import { konvaTextDecoration } from "shared/util/utils";
import { transformerRefAtom } from "state-atoms";
import { useAtomValue } from "jotai";
import { CSSProperties, useEffect, useRef } from "react";

export default function MindmapNodeCanvasElement({
  id,
  node,
  absolutePosition,
  size,
  isEditing,
  isEditingLink,
  childrenCount,
  changeElement,
  onChangeSize,
  onFinishedEditing,
}: {
  id: string;
  node: MindmapNodeElement;
  absolutePosition: Point;
  size: { width: number; height: number };
  childrenCount: number;
  isEditing: boolean;
  isEditingLink: boolean;
  changeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    updateSubMenuData?: any, //take the last change and make it the default when selecting this element again from the menu (e.g. make the default color the last color selected)
    elementId?: string
  ) => void;
  onChangeSize: (newSize: { width: number; height: number }) => void;
  onFinishedEditing: (cancelled: boolean) => void;
}) {
  const element = { ...node, absolutePosition };
  const { parentId, color, font, fontSize, textColor, fontProps } = element;
  const { width, height } = size;

  const textRef = useRef<any>(null);
  const transformerRef = useAtomValue(transformerRefAtom);
  const textConfig = calcKonvaTextConfig({ element, scaleFont: 1, verticalAlign: "middle" });
  const fontSizePixels = getFontSize(element);

  const padding = consts.DEFAULTS.MINDMAP_NODE_VERTICAL_PADDING;
  const minNodeSize = element.parentId ? consts.DEFAULTS.MINDMAP_NODE_SIZE : consts.DEFAULTS.MINDMAP_ROOT_SIZE;

  useEffect(() => {
    if (textRef.current) {
      const { width: originalWidth, height: originalHeight } = textRef.current.size();
      const { width, height } = adjustSizeForTextSize(textRef.current.size());
      if (height != element.height || width != element.width) {
        changeElement({ height, width }, { shouldAdd: false }, undefined, id);
      }
      const yPadding = (height - originalHeight) / 2;
      const xPadding = (width - originalWidth) / 2;
      textRef.current.position({ x: xPadding, y: yPadding });
    }
  }, [textRef.current, element.text, width, height, font, fontSize, fontProps]);

  function renderBorderIfNeeded() {
    if (parentId) {
      // regular node - no border
      return <Rect width={width} height={height} />;
    }
    return (
      <Rect
        width={width}
        height={height}
        cornerRadius={height / 2}
        stroke={color ?? consts.DEFAULTS.MINDMAP_ROOT_STROKE_COLOR}
        strokeWidth={2}
      />
    );
  }

  function adjustSizeForTextSize({ width, height }: { width: number; height: number }) {
    const newWidth = Math.round(Math.max(minNodeSize.width, width + padding * 2));
    const newHeight = Math.round(Math.max(minNodeSize.height, height + padding * 2));
    return { height: newHeight, width: newWidth };
  }

  function onEnter() {
    onFinishedEditing(false);
    return true;
  }

  function onEscape() {
    onFinishedEditing(true);
    return true;
  }

  function renderTextEditor() {
    const htmlTextProps: CSSProperties = {
      fontFamily: font,
      lineHeight: consts.LINE_HEIGHT,
      fontWeight: element.fontProps & FontProperties.Bold ? "bold" : "normal",
      color: textColor,
      textDecoration: konvaTextDecoration(fontProps),
      fontStyle: element.fontProps & FontProperties.Italic ? "italic" : "normal",
      textAlign: element.align ?? "center",
      padding: 0,
      width,
      outline: "none",
    };
    const minWidth = parentId
      ? consts.DEFAULTS.MINDMAP_NODE_SIZE.width
      : consts.DEFAULTS.MINDMAP_ROOT_SIZE.width - consts.DEFAULTS.MINDMAP_NODE_VERTICAL_PADDING * 2;
    return (
      <FreeSizeTextEditor
        absX={absolutePosition.x + padding}
        absY={absolutePosition.y + padding}
        fontSize={fontSizePixels}
        initialValue={element.text}
        placeholder={consts.DEFAULTS.MINDMAP_PLACEHOLDER}
        minWidth={minWidth}
        updateText={(initial: string, text: string) => {
          changeElement({ text }, { shouldAdd: true, previousProps: { text: initial } }, undefined, id);
          setTimeout(() => transformerRef && transformerRef.current && transformerRef.current.forceUpdate());
        }}
        updateTextSize={(width: number, height: number) => {
          onChangeSize(adjustSizeForTextSize({ height, width }));
          setTimeout(() => transformerRef && transformerRef.current && transformerRef.current.forceUpdate());
        }}
        textProps={htmlTextProps}
        positionType="top-left"
        onEnter={onEnter}
        onEscape={onEscape}
      />
    );
  }

  function renderText() {
    if (isEditing) {
      return renderTextEditor();
    }
    return (
      <Text
        ref={textRef}
        {...textConfig}
        x={padding}
        y={padding}
        text={element.text || consts.DEFAULTS.MINDMAP_PLACEHOLDER}
      />
    );
  }

  // for debugging
  // function renderXY() {
  //   return <Text width={width} height={height} text={`${element.x}, ${element.y}`} fontSize={10} fill={"#113255"} />;
  // }

  return (
    <BaseCanvasElement
      id={id}
      type="mindmap"
      x={element.x}
      y={element.y}
      element={element}
      isMindmap={true}
      isSelectable={true}
      isEditingLink={isEditingLink}
      isDraggable={id === element.rootId} // only the root node is draggable
      onResize={() => {}}
      changeElement={changeElement}
      childrenCount={childrenCount}
    >
      {renderBorderIfNeeded()}
      {renderText()}
      {/* {renderXY()} */}
    </BaseCanvasElement>
  );
}
