import Konva from "konva";
import { CursorType } from "frontend/canvas-designer-new/cursor-type";
import * as MathUtils from "../../../utils/math-utils";
import BoundingBox from "../../../geometry/bounding-box";
import { lerp, Point } from "../../../utils/math-utils";
import { NewElementData } from "shared/datamodel/canvas-element";
import * as bezierIntersect from "bezier-intersect";
import { NewConnectorData } from "frontend/canvas-designer-new/elements/connector/connector-builder";
import type { Connector, InnerPointSchema } from "shared/datamodel/schemas";
import { getTransformParams, getBoundingBox } from "frontend/utils/node-utils";
import Transform, { Degrees, PointAndDirection } from "frontend/utils/transform";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { distance } from "../../../geometry/utils";
import { segmentIntersectSegmentFast as intersectSegments } from "../../../geometry/intersections";
import { isMac } from "../../../utils/keyboard-shortcuts";
import { IRect } from "konva/types/types";
import { DATA_NOT_LOADED } from "./connector-constants";
import { getElementTypeForId } from "../canvas-elements-utils";

type Point2 = readonly [number, number];
type ReturnPoint = (t: number) => Point2;

// TODO: should maybe move to some more general file
export type OnChangeElementFn = (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;

export type ConnectorEndpoint = {
  x: number;
  y: number;
  rotation?: Degrees;
};

export enum AnchorIndex {
  Start,
  End,
}

export type AnchorDragState = {
  x: number;
  y: number;
  index: number;
  snapToPoint?: PointAndDirection;
  snapToShape?: string;
};

export type AnchorFrameData = null | {
  frameId: string;
  pos: Point;
};

export type ConnectorEditPoint = InnerPointSchema & {
  real: boolean;
  addAt?: number;
};

export function shouldDisableConnectorSnapping(e: MouseEvent | KeyboardEvent) {
  return (isMac() ? e.metaKey : e.ctrlKey) || e.shiftKey;
}

export function attachConnector(
  syncService: SyncService<RW>,
  id: string,
  elementType: any,
  connectorId: string,
  attachedConnector: any,
  anchorIndex: number
) {
  syncService.mutate.addAttachedConnector({
    id,
    connectorId,
    attachedConnector,
  });
  return () => detachConnector(syncService, id, elementType, connectorId, attachedConnector, anchorIndex);
}

function detachConnector(
  syncService: SyncService<RW>,
  id: string,
  elementType: string,
  connectorId: string,
  attachedConnector: any,
  anchorIndex: number
) {
  syncService.mutate.removeAttachedConnector({
    id,
    connectorId,
    anchorIndex,
  });
  return () => attachConnector(syncService, id, elementType, connectorId, attachedConnector, anchorIndex);
}

export function areNodesAttached(node1: Konva.Node, node2: Konva.Node, filterFn?: (id: string) => boolean) {
  const getAttachedConnectors = (node: Konva.Node) => {
    const attachedConnectors = node.attrs.attachedConnectors ?? node.attrs.element.attachedConnectors;
    return Object.keys(attachedConnectors ?? {});
  };

  let commonConnections = getAttachedConnectors(node1).filter((id) => getAttachedConnectors(node2).includes(id));
  if (filterFn) commonConnections = commonConnections.filter(filterFn);

  return commonConnections.length > 0;
}

export function getNewConnector(
  start: { pos: Point; shapeId?: string; side?: string; t?: number },
  end: { pos: Point; shapeId?: string; side?: string; t?: number },
  createElements: any,
  newElementData: NewConnectorData
) {
  const newElements = createElements(start.pos, CursorType.connector);
  if (!newElements || newElements.length !== 1) return null;
  const newElement = newElements[0];
  const connector = newElement.element as Connector;
  connector.anchorMode = start.side;
  connector.anchorsMode = [start.side ?? "n/a", end.side ?? "n/a"];
  if (start.side == "outline" && start.t != undefined) connector.point1_t = start.t;
  if (end.side == "outline" && end.t != undefined) connector.point2_t = end.t;

  connector.connectedShapes = [
    { id: start.shapeId ?? "", type: "" },
    { id: end.shapeId ?? "", type: "" },
  ];
  connector.anchorOrientation = "buttom";
  connector.activeAnchorIndex = 1;
  connector.lineType = newElementData.lineType;
  connector.pointerStyles = [newElementData.endArrow, newElementData.startArrow];
  connector.dash = newElementData.dash;
  connector.stroke = newElementData.stroke;
  connector.strokeWidth = newElementData.strokeWidth;
  connector.textLocation = 0.5;

  connector.points = [
    { x: 0, y: 0 },
    { x: end.pos.x - start.pos.x, y: end.pos.y - start.pos.y },
  ];

  return newElement;
}

export function createConnector(
  start: { pos: Point; shapeId?: string; side?: string; t?: number },
  end: { pos: Point; shapeId?: string; side?: string; t?: number },
  syncService: any,
  createElements: any,
  onAddElements: (elements: NewElementData[]) => Promise<string[]>,
  newElementData: NewConnectorData
) {
  const newElement = getNewConnector(start, end, createElements, newElementData);

  // Note to future maintainers:
  // I originally tried creating the element in position {0,0}, and have points = [start,end]
  // That did not go well. Scaling 2 connectors or more would mess them up.
  // The connector's origin has to be start/end, and the points adjusted accordingly.

  return onAddElements([newElement]).then((newIds: string[]) => {
    if (start.shapeId) {
      const attachedConnector = {
        lineId: newIds[0],
      };
      attachConnector(syncService, start.shapeId, "", newIds[0], attachedConnector, 0);
    }
    if (end.shapeId) {
      const attachedConnector = {
        lineId: newIds[0],
      };
      attachConnector(syncService, end.shapeId, "", newIds[0], attachedConnector, 1);
    }
    return newIds;
  });
}

/**
 * Patch to resolve a bug with old canvases where anchorsMode was accidentally set to an integer
 * @param mode Connector anchorsMode attribute
 * @returns Correc anchorsMode
 */
export function fixAnchorsMode(mode: string | null | undefined) {
  // we had a bug that inserted numbers to some elements :-(
  if (mode == null || mode == undefined) return "n/a";
  if (typeof mode == "number") {
    mode = ["left", "right", "top", "buttom"][+mode] ?? "n/a";
  }
  return mode;
}

/**
 * Returns the bounding box of a shape connected to a conntetor, in the coordinate system of the connector
 */
export function getConnectedShapeBbox(
  element: Connector,
  endpoint: ConnectorEndpoint,
  shapeId: string | undefined,
  shape: any | null
): IRect {
  let result: IRect | null = null;
  if (shapeId && shape && shape !== DATA_NOT_LOADED) {
    result = getBoundingBox(getElementTypeForId(shapeId), shape);
  }

  if (result) {
    const { scaleX = 1, scaleY = 1 } = element;
    const transformedPoint = canvasPointToElementPoint(element, { x: result.x, y: result.y });
    result = {
      x: transformedPoint.x,
      y: transformedPoint.y,
      width: result.width / scaleX,
      height: result.height / scaleY,
    };
  } else {
    const x = isNaN(endpoint.x) ? 0 : endpoint.x;
    const y = isNaN(endpoint.y) ? 0 : endpoint.y;
    result = { x, y, width: 0, height: 0 };
  }
  return result;
}

export interface IConnectorDrawingContext extends Omit<CanvasPath, "arcTo" | "arc" | "ellipse" | "rect" | "roundRect"> {
  beginPath: () => void;
}

type LimitedCanvasPath = Omit<CanvasPath, "roundRect">;

export class LinesRectIntersector implements IConnectorDrawingContext {
  private x = 0;
  private y = 0;
  public intersected = false;
  constructor(private boxCorners: [Point, Point, Point, Point, Point]) {}
  beginPath() {}
  closePath(): void {}
  quadraticCurveTo(_cpx: number, _cpy: number, x: number, y: number) {
    this.lineTo(x, y);
  }
  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  lineTo(x: number, y: number) {
    if (this.intersected) return; // no need to check - a line already intersected
    for (let i = 0; i < this.boxCorners.length - 1; i++) {
      const p1 = this.boxCorners[i],
        p2 = this.boxCorners[i + 1],
        intersects = intersectSegments(this.x, this.y, x, y, p1.x, p1.y, p2.x, p2.y);
      if (intersects) {
        this.intersected = true;
        return;
      }
    }
    this.x = x;
    this.y = y;
  }
  bezierCurveTo(_a1: number, _a2: number, _a3: number, _a4: number, _a5: number, _a6: number) {} //TODO
}

export interface ICurveSegment {
  bbox: () => BoundingBox;
  preFinalPoint: () => Point;
  secondPoint: () => Point;
  point: (f: number) => Point2;
  doesIntersectBox: (corners: [Point, Point, Point, Point, Point]) => boolean;
  drawOnCanvas: (context: LimitedCanvasPath) => void;
  length: () => number;
  getPointAlongSegment: (distance: number) => Point2;
}

class Line implements ICurveSegment {
  constructor(protected x0: number, protected y0: number, protected x1: number, protected y1: number) {}
  bbox() {
    const bbox = new BoundingBox();
    return bbox.expandPoint(this.x0, this.y0).expandPoint(this.x1, this.y1);
  }
  preFinalPoint() {
    return { x: this.x0, y: this.y0 };
  }
  secondPoint() {
    return { x: this.x1, y: this.y1 };
  }
  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return [lerp(this.x0, this.x1, f), lerp(this.y0, this.y1, f)] as const;
  }
  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (intersectSegments(this.x0, this.y0, this.x1, this.y1, p1.x, p1.y, p2.x, p2.y)) {
        return true;
      }
    }
    return false;
  }
  drawOnCanvas(context: LimitedCanvasPath) {
    context.lineTo(this.x1, this.y1);
  }
  length() {
    return distance(this.x0, this.y0, this.x1, this.y1);
  }
  getPointAlongSegment(distance: number) {
    const len = this.length();
    if (distance <= 0) return [this.x0, this.y0] as const;
    if (distance >= len) return [this.x1, this.y1] as const;
    return [lerp(this.x0, this.x1, distance / len), lerp(this.y0, this.y1, distance / len)] as const;
  }
}

class RoundCornerLine extends Line {
  private radius = 6;
  constructor(x0: number, y0: number, x1: number, y1: number, private x2: number, private y2: number) {
    super(x0, y0, x1, y1);
  }

  maxCurveRadius() {
    const l1 = Math.max(Math.abs(this.x0 - this.x1), Math.abs(this.y0 - this.y1));
    const l2 = Math.max(Math.abs(this.x1 - this.x2), Math.abs(this.y1 - this.y2));
    const safedistance = Math.min(l1, l2);
    return Math.min(safedistance / 2, this.radius);
  }

  drawOnCanvas(context: LimitedCanvasPath) {
    context.arcTo(this.x1, this.y1, this.x2, this.y2, this.maxCurveRadius());
  }
}

export class QuadraticCurve implements ICurveSegment {
  private evaluator: (t: number) => readonly [number, number];

  constructor(
    private x0: number,
    private y0: number,
    private cpx: number,
    private cpy: number,
    private x1: number,
    private y1: number
  ) {
    this.evaluator = getQuadraticCurveEvaluator(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1);
  }
  bbox() {
    return bboxOfQuadraticBezierCurve(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1);
  }
  preFinalPoint() {
    return { x: this.cpx, y: this.cpy };
  }
  secondPoint() {
    return { x: this.cpx, y: this.cpy };
  }
  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return this.evaluator(f);
  }
  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (
        bezierIntersect.quadBezierLine(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1, p1.x, p1.y, p2.x, p2.y)
      )
        return true;
    }
    return false;
  }
  drawOnCanvas(context: LimitedCanvasPath) {
    context.quadraticCurveTo(this.cpx, this.cpy, this.x1, this.y1);
  }
  length() {
    let prev: readonly [number, number] = [this.x0, this.y0];
    let len = 0;
    for (let i = 0.01; i <= 1; i += 0.01) {
      const cur = this.evaluator(i);
      len += distance(prev[0], prev[1], cur[0], cur[1]);
      prev = cur;
    }
    return len;
  }
  getPointAlongSegment(dist: number) {
    if (dist <= 0) return [this.x0, this.y0] as const;
    let prev;
    for (let i = 0; i < 1; i += 0.01) {
      const cur = this.evaluator(i);
      if (prev) {
        const segmentLength = distance(prev[0], prev[1], cur[0], cur[1]);
        if (dist <= segmentLength) {
          return [lerp(prev[0], cur[0], dist / segmentLength), lerp(prev[1], cur[1], dist / segmentLength)] as const;
        }
        dist -= segmentLength;
      }
      prev = cur;
    }
    return [this.x1, this.y1] as const;
  }
}

export class CubicCurve implements ICurveSegment {
  private evaluator: (f: number) => readonly [number, number];

  constructor(
    private x0: number,
    private y0: number,
    private cp1x: number,
    private cp1y: number,
    private cp2x: number,
    private cp2y: number,
    private x1: number,
    private y1: number
  ) {
    this.evaluator = getCubicCurveEvaluator(
      this.x0,
      this.y0,
      this.cp1x,
      this.cp1y,
      this.cp2x,
      this.cp2y,
      this.x1,
      this.y1
    );
  }

  bbox() {
    return bboxOfCubicBezierCurve(this.x0, this.y0, this.cp1x, this.cp1y, this.cp2x, this.cp2y, this.x1, this.y1);
  }
  preFinalPoint() {
    return { x: this.cp2x, y: this.cp2y };
  }
  secondPoint() {
    return { x: this.cp1x, y: this.cp1y };
  }
  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return this.evaluator(f);
  }
  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (
        bezierIntersect.cubicBezierLine(
          this.x0,
          this.y0,
          this.cp1x,
          this.cp1y,
          this.cp2x,
          this.cp2y,
          this.x1,
          this.y1,
          p1.x,
          p1.y,
          p2.x,
          p2.y
        )
      )
        return true;
    }
    return false;
  }
  drawOnCanvas(context: LimitedCanvasPath) {
    context.bezierCurveTo(this.cp1x, this.cp1y, this.cp2x, this.cp2y, this.x1, this.y1);
  }
  length() {
    let prev = this.evaluator(0.01);
    let len = 0;
    for (let i = 0.01; i <= 1; i += 0.01) {
      const cur = this.evaluator(i);
      len += distance(prev[0], prev[1], cur[0], cur[1]);
      prev = cur;
    }
    return len;
  }
  getPointAlongSegment(dist: number) {
    if (dist <= 0) return [this.x0, this.y0] as const;
    let prev;
    for (let i = 0; i < 1; i += 0.01) {
      const cur = this.evaluator(i);
      if (prev) {
        const segmentLength = distance(prev[0], prev[1], cur[0], cur[1]);
        if (dist <= segmentLength) {
          return [lerp(prev[0], cur[0], dist / segmentLength), lerp(prev[1], cur[1], dist / segmentLength)] as const;
        }
        dist -= segmentLength;
      }
      prev = cur;
    }
    return [this.x1, this.y1] as const;
  }
}

function evalInLine(f: number, x0: number, y0: number, x1: number, y1: number) {
  return [lerp(x0, x1, f), lerp(y0, y1, f)] as const;
}

// for fast evaluation of a quadratic curve at t
// p(t) = (p0-2p1+p2)t^2 + 2t(p1-p2) + p0

function getQuadraticCurveEvaluator(x0: number, y0: number, cpx: number, cpy: number, x1: number, y1: number) {
  function getCoefficients(p0: number, p1: number, p2: number) {
    const a = p0 - 2 * p1 + p2;
    const b = 2 * (p1 - p2);
    const c = p0;
    return { a, b, c };
  }
  const x = getCoefficients(x0, cpx, x1);
  const y = getCoefficients(y0, cpy, y1);
  return (t: number) => {
    const xx = x.c + t * (x.b + t * x.a);
    const yy = y.c + t * (y.b + t * y.a);
    return [xx, yy] as const;
  };
}

function evalInQuadraticCurve(f: number, x0: number, y0: number, cpx: number, cpy: number, x1: number, y1: number) {
  const x = lerp(lerp(x0, cpx, f), lerp(cpx, x1, f), f);
  const y = lerp(lerp(y0, cpy, f), lerp(cpy, y1, f), f);
  return [x, y] as const;
}

// for fast evaluation of bezier curve at t (0 <= t <= 1)
// P = at^3 + bt^2 + ct + d  ==  d + t(c+t(b+ta))
// a=(-p0+3p1-3p2+p3)
// b=(3p0-6p1+3p2)
// c=(-3p0+3p1)
// d=(p0)
function getCubicCurveEvaluator(
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function getCoefficients(p0: number, p1: number, p2: number, p3: number) {
    const a = -p0 + 3 * p1 - 3 * p2 + p3;
    const b = 3 * p0 - 6 * p1 + 3 * p2;
    const c = -3 * p0 + 3 * p1;
    const d = p0;
    return { a, b, c, d };
  }
  const x = getCoefficients(x0, cp1x, cp2x, x1);
  const y = getCoefficients(y0, cp1y, cp2y, y1);
  return (t: number) => {
    const xx = x.d + t * (x.c + t * (x.b + t * x.a));
    const yy = y.d + t * (y.c + t * (y.b + t * y.a));
    return [xx, yy] as const;
  };
}

function evalInCubicCurve(
  f: number,
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function lerpCubic(p0: number, p1: number, p2: number, p3: number, f: number) {
    const q1 = lerp(p0, p1, f),
      q2 = lerp(p1, p2, f),
      q3 = lerp(p2, p3, f),
      r1 = lerp(q1, q2, f),
      r2 = lerp(q2, q3, f),
      s = lerp(r1, r2, f);
    return s;
  }
  return [lerpCubic(x0, cp1x, cp2x, x1, f), lerpCubic(y0, cp1y, cp2y, y1, f)] as const;
}

export function evaluatePointInCurve(drawCmds: ICurveSegment[]): (f: number) => Point2;
export function evaluatePointInCurve(drawCmds: ICurveSegment[], f?: number): Point2;
export function evaluatePointInCurve(drawCmds: ICurveSegment[], f?: number) {
  if (f == undefined) {
    return evaluatePointInCurve.bind(null, drawCmds) as ReturnPoint;
  }
  const innerPointsPerCurve = drawCmds.map((s) => evalManyPointsOnCurve(s));
  let curvesLen = [],
    total = 0;
  for (let i = 0; i < innerPointsPerCurve.length; i++) {
    let subtotal = 0;
    for (let j = 0; j < innerPointsPerCurve[i].length - 1; j++) {
      const cur = innerPointsPerCurve[i][j];
      const next = innerPointsPerCurve[i][j + 1];
      subtotal += distance(cur[0], cur[1], next[0], next[1]);
    }
    curvesLen.push(subtotal);
    total += subtotal;
  }

  f = MathUtils.clamp(f, 0, 1);
  let distOnCurve = f * total;
  let curveIndex = 0;

  for (curveIndex = 0; curveIndex < curvesLen.length; curveIndex++) {
    if (curvesLen[curveIndex] - distOnCurve > 0) break;
    distOnCurve -= curvesLen[curveIndex];
  }
  // due to floating point errors we can finish the loop with distOnCurve = 1e-14, meaning
  // it's still positive but 0 for all practical purposes
  if (curveIndex == curvesLen.length) curveIndex = curvesLen.length - 1;
  const curve = innerPointsPerCurve[curveIndex];
  for (let j = 0; j < curve.length - 1; j++) {
    const l = distance(curve[j][0], curve[j][1], curve[j + 1][0], curve[j + 1][1]);
    if (distOnCurve >= l) {
      distOnCurve -= l;
    } else {
      return evalInLine(distOnCurve / l, curve[j][0], curve[j][1], curve[j + 1][0], curve[j + 1][1]);
    }
  }
  return curve[curve.length - 1];
}

function evalManyPointsOnCurve(curve: ICurveSegment, N = 100): (readonly [number, number])[] {
  const result = [];
  const step = 1 / N;
  for (let i = 0; i <= 1; i += step) {
    result.push(curve.point(i));
  }
  return result;
}

// We're finding a tight bounding box for quadratic curve here.
// This is done by looking at extremum points of the curve, which can be found where first derivate = 0
// For lots of good explanations:
// https://floris.briolas.nl/floris/2009/10/bounding-box-of-cubic-bezier/
// https://pomax.github.io/bezierinfo/
function bboxOfCubicBezierCurve(
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function extremumPoints(p0: number, p1: number, p2: number, p3: number) {
    const a = 3 * (-p0 + 3 * p1 - 3 * p2 + p3);
    const b = 6 * (p0 - 2 * p1 + p2);
    const c = 3 * (p1 - p0);
    if (a == 0) return [];
    const discriminant = b * b - 4 * a * c;
    if (discriminant < 0) {
      return [];
    } else if (discriminant == 0) {
      return [-b / (2 * a)];
    } else {
      const d = Math.sqrt(discriminant);
      return [(-b + d) / (2 * a), (-b - d) / (2 * a)];
    }
  }

  const x_solutions = extremumPoints(x0, cp1x, cp2x, x1);
  const y_solutions = extremumPoints(y0, cp1y, cp2y, y1);
  const box = new BoundingBox();
  box.expandPoint(x0, y0);
  box.expandPoint(x1, y1);
  for (let i = 0; i < x_solutions.length; i++) {
    if (x_solutions[i] <= 0 || x_solutions[i] >= 1) {
      continue;
    }
    const p = evalInCubicCurve(x_solutions[i], x0, y0, cp1x, cp1y, cp2x, cp2y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  for (let i = 0; i < y_solutions.length; i++) {
    if (y_solutions[i] <= 0 || y_solutions[i] >= 1) {
      continue;
    }
    const p = evalInCubicCurve(y_solutions[i], x0, y0, cp1x, cp1y, cp2x, cp2y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  return box;
}

function bboxOfQuadraticBezierCurve(x0: number, y0: number, cp1x: number, cp1y: number, x1: number, y1: number) {
  const extremumX = (cp1x - x0) / (2 * cp1x - x0 - x1);
  const extremumY = (cp1y - y0) / (2 * cp1y - y0 - y1);
  const box = new BoundingBox();
  box.expandPoint(x0, y0).expandPoint(x1, y1);
  if (!Number.isNaN(extremumX) && extremumX > 0 && extremumX < 1) {
    const p = evalInQuadraticCurve(extremumX, x0, y0, cp1x, cp1y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  if (!Number.isNaN(extremumY) && extremumY > 0 && extremumY < 1) {
    const p = evalInQuadraticCurve(extremumY, x0, y0, cp1x, cp1y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }

  return box;
}

export function elementPointToCanvasPoint(element: any, p: Point, out?: Point) {
  const tr = getTransformParams("connector", element);
  return Transform.Point(tr, p, out);
}

export function canvasPointToElementPoint(element: any, p: Point, out?: Point) {
  const tr = getTransformParams("connector", element);
  return Transform.InvPoint(tr, p, out);
}

interface IConnectorBuilder {
  moveTo: (x: number, y: number) => void;
  lineTo: (x: number, y: number) => void;
  bezierCurve: (cp1x: number, cp1y: number, cp2x: number, cp2y: number, x2: number, y2: number) => void;
  // quadraticCurve: (cp1x: number, cp1y: number, x2: number, y2: number) => void;
  // arcTo: (x1: number, y1: number, x2: number, y2: number, radius: number) => void;
}

// this class can hold a list of drawing commands, and draw to a canvas context
// we can also compute some basic stuff like the direction at start and end and bounding box
export class SimpleConnectorData implements IConnectorBuilder {
  public segments: ICurveSegment[] = [];
  private x = 0;
  private y = 0;
  constructor() {}
  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  lineTo(x: number, y: number) {
    this.segments.push(new Line(this.x, this.y, x, y));
    this.x = x;
    this.y = y;
  }
  arcTo(x1: number, y1: number, x2: number, y2: number) {
    this.segments.push(new RoundCornerLine(this.x, this.y, x1, y1, x2, y2));
    this.x = x1;
    this.y = y1;
  }
  bezierCurve(cp1x: number, cp1y: number, cp2x: number, cp2y: number, x2: number, y2: number) {
    this.segments.push(new CubicCurve(this.x, this.y, cp1x, cp1y, cp2x, cp2y, x2, y2));
    this.x = x2;
    this.y = y2;
  }
  drawOnCanvas(ctx: LimitedCanvasPath) {
    let first = true;
    for (const segment of this.segments) {
      if (first) {
        const start = segment.point(0);
        ctx.moveTo(start[0], start[1]);
        first = false;
      }
      segment.drawOnCanvas(ctx);
    }
  }
  firstPoint() {
    const [x, y] = this.segments[0].point(0);
    return { x, y };
  }
  finalPoint() {
    const [x, y] = this.segments[this.segments.length - 1].point(1);
    return { x, y };
  }
  preFinalPoint() {
    return this.segments[this.segments.length - 1].preFinalPoint();
  }
  secondPoint() {
    return this.segments[0].secondPoint();
  }
  getSelfRect() {
    return this.segments.reduce((acc, cur) => acc.expandRect(cur.bbox()), new BoundingBox());
  }
  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    return this.segments.length > 0 && this.segments.some((x) => x.doesIntersectBox(corners));
  }
}

// metrics:
// given a list of segments for the curve (bezier, linear or elbow)
// compute N points along each segment (N is fixed at start, can be dynamic)
// allow callers to:
//  find point closest to another point (reduce over points allows it)
//    this is per segment really, not necessarily reduce over points
//  get the T value for that point in global terms (not segment terms)
//  compute the point given the T value
//
// that means converting T := [0..1] to T in segments and vice versa
// best way is to linearize the entire curve into 1 segment in [0..1] range
// or to calculate the transformation between [0..total_length] and [0..1]
//
// linear segments can answer messages like getLength and getPoint and getT exactly
// curves needs approximate solutions

export class PathMetrics {
  private readonly segmentLengths: number[] = [];
  private readonly totalLength: number;

  constructor(private readonly path: SimpleConnectorData) {
    if (path.segments.length == 0) {
      console.warn("empty path given");
    }
    this.segmentLengths = path.segments.map((x) => x.length());
    this.totalLength = this.segmentLengths.reduce((a, b) => a + b, 0);
  }

  pathLength() {
    return this.totalLength;
  }

  //TODO: this function was good for getting a single point, not so good for getting multitude
  // t is normalized distance: 0..1 along the path
  getPointAlongPath(t: number): readonly [number, number] {
    if (this.totalLength == 0) return [0, 0]; // wrong answer, but at least we don't throw exception
    if (t <= 0) return this.path.segments[0].point(0);
    if (t >= 1) return this.path.segments[this.path.segments.length - 1].point(1);

    let targetLen = t * this.totalLength;
    for (let i = 0; i < this.segmentLengths.length; i++) {
      if (targetLen <= this.segmentLengths[i]) {
        return this.path.segments[i].getPointAlongSegment(targetLen);
      }
      targetLen -= this.segmentLengths[i];
    }
    console.warn("should not reach here");
    return this.path.segments[0].point(0);
  }
}
