import { useState } from "react";
import type { TypeTableCell, TypeTableElement } from "shared/datamodel/schemas";
import { ColumnDragger } from "../dragging-cols-rows/col-dragger";
import { RowDragger } from "../dragging-cols-rows/row-dragger";
import { actions } from "../table-actions";
import { fullCellKey } from "../table-utils";
import { WidgetsInNonActiveState } from "./widgets-internal";

export function TableWidgets({
  id,
  cells,
  element,
  xs,
  ys,
  dispatch,
  setDrag,
  mouseOver,
}: {
  id: string;
  cells: Record<string, TypeTableCell>;
  element: TypeTableElement;
  xs: number[];
  ys: number[];
  dispatch: any;
  setDrag: any;
  mouseOver: boolean;
}) {
  const [draggingCol, setDraggingCol] = useState(-1);
  const [draggingRow, setDraggingRow] = useState(-1);

  function checkColCanBeDragged(col: number): boolean {
    let allowDragCol = true;
    const colKey = element.cols[col].id;
    for (let i = 0; i < element.rows.length; ) {
      const cellid1 = fullCellKey(id, colKey, element.rows[i].id);
      const { spanX = 1, spanY = 1 } = cells[cellid1] ?? {};
      if (spanX != 1) {
        allowDragCol = false;
        break;
      }
      i += spanY; // advance single line, or multiple lines if it's merge-cell
    }
    return allowDragCol;
  }

  function checkRowCanBeDragged(row: number): boolean {
    let allowDragRow = true;
    const rowKey = element.rows[row].id;
    for (let i = 0; i < element.cols.length; ) {
      const cellid1 = fullCellKey(id, element.cols[i].id, rowKey);
      const { spanX = 1, spanY = 1 } = cells[cellid1] ?? {};
      if (spanY != 1) {
        allowDragRow = false;
        break;
      }
      i += spanX; // advance single line, or multiple lines if it's merge-cell
    }
    return allowDragRow;
  }

  if (draggingCol == -1 && draggingRow == -1) {
    return mouseOver ? (
      <WidgetsInNonActiveState
        element={element}
        xs={xs}
        ys={ys}
        dispatch={dispatch}
        startDragCol={(col: number) => {
          if (checkColCanBeDragged(col)) {
            setDraggingCol(col);
            return true;
          } else {
            return false;
          }
        }}
        startDragRow={(row: number) => {
          if (checkRowCanBeDragged(row)) {
            setDraggingRow(row);
            return true;
          } else {
            return false;
          }
        }}
      />
    ) : null;
  } else if (draggingCol != -1) {
    return (
      <ColumnDragger
        id={id}
        col={draggingCol}
        xs={xs}
        ys={ys}
        element={element}
        cells={cells}
        updateDrag={(action) => {
          if (action.type == "drag") {
            setDrag({ col: action.src, row: -1, dx: action.dx, dy: 0 });
          } else if (action.type == "finish") {
            setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
            setDraggingCol(-1);
            if (action.src != action.target)
              dispatch(actions.rowColDragEnd({ item: "col", src: action.src, target: action.target }));
          } else {
            console.error("unhandled action type", action);
          }
        }}
      />
    );
  } else if (draggingRow != -1) {
    return (
      <RowDragger
        id={id}
        row={draggingRow}
        xs={xs}
        ys={ys}
        element={element}
        cells={cells}
        updateDrag={(action) => {
          if (action.type == "drag") {
            setDrag({ row: action.src, col: -1, dy: action.dy, dx: 0 });
          } else if (action.type == "finish") {
            setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
            setDraggingRow(-1);
            if (action.src != action.target)
              dispatch(actions.rowColDragEnd({ item: "row", src: action.src, target: action.target }));
          } else {
            console.error("unhandled action type", action);
          }
        }}
      />
    );
  } else {
    console.error("unhandled case");
    return null;
  }
}
