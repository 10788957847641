import React from "react";
import styles from "./remove-monday-connector-popup.module.css";
import { CloseIcon } from "frontend/icons/close";
import { RemoveMondayConnectorPopupIllustration } from "elements/gantt/components/remove-monday-connector-popup/remove-monday-connector-popup-illustration";

type RemoveMondayConnectorPopupProps = {
  onClose: () => void;
  onSelection: (shouldDelete: boolean) => void;
};

export const RemoveMondayConnectorPopup = ({ onClose, onSelection }: RemoveMondayConnectorPopupProps) => {
  return (
    <div className={styles.wrapper}>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      <div className={styles.illustration}>
        <RemoveMondayConnectorPopupIllustration />
      </div>
      <h2 className={styles.title}>Remove dependencies between tasks?</h2>
      <p className={styles.message}>
        <div>This will remove the dependency between these items in both monday and workcanvas.</div>
        <div>Are you sure you want to unlink these issues?</div>
      </p>
      <div className={styles.actions}>
        <button className={styles.no} onClick={() => onSelection(false)}>
          No
        </button>
        <button className={styles.yes} onClick={() => onSelection(true)}>
          Yes
        </button>
      </div>
    </div>
  );
};
