import { useAtomValue } from "jotai";
import { nanoid } from "nanoid";
import {
  dataLayerFieldSchema,
  elementDataLayerSchema,
  ElementDataLayerValue,
  ElementRule,
} from "shared/datamodel/schemas/element-data-layer";
import { syncServiceAtom, undoRedoStackAtom } from "state-atoms";
import { useCallback, useEffect, useMemo } from "react";
import { mapColumnValueToText } from "shared/integrations/mapper";
import { useCanvasElementById } from "subscriptions";
import { getElementTypeForId } from "../elements/canvas-elements-utils";
import consts from "shared/consts";
import { useIntegrationsProvider } from "frontend/hooks/use-integrations-provider";
import { CanvasElement } from "shared/datamodel/schemas";

function useMondayTasks(integrations: { [integrationId: string]: string[] }) {
  const { getItem, getBoardIntegrationConfig, loadItems, updateColumnValue } = useIntegrationsProvider();

  const integrationsJSON = JSON.stringify(integrations);

  useEffect(() => {
    const integrationsToLoad = Object.entries(integrations).reduce((acc, [integrationId, itemIds]) => {
      acc[integrationId] = itemIds;
      return acc;
    }, {} as Record<string, string[]>);
    loadItems(integrationsToLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationsJSON]);

  const tasks = useMemo(
    () =>
      Object.entries(integrations).reduce((acc, [integrationId, itemIds]) => {
        acc[integrationId] = itemIds.reduce((taskAcc, itemId) => {
          const task = getItem(itemId, integrationId);
          if (task) {
            taskAcc[itemId] = task;
          }
          return taskAcc;
        }, {} as Record<string, any>);
        return acc;
      }, {} as Record<string, Record<string, any>>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getItem, integrationsJSON]
  );

  const configurations = useMemo(
    () =>
      Object.keys(integrations).reduce((acc, integrationId) => {
        const config = getBoardIntegrationConfig(integrationId);
        if (config) {
          acc[integrationId] = config;
        }
        return acc;
      }, {} as Record<string, any>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getBoardIntegrationConfig, integrationsJSON]
  );

  const values = useMemo(() => {
    return Object.entries(tasks).reduce((acc, [integrationId, tasks]) => {
      acc[integrationId] = Object.entries(tasks).reduce((acc: Record<string, any>, [itemId, task]: any) => {
        acc[itemId] = task.columnValues.reduce(
          (acc: Record<string, any>, column: any) => {
            acc[column.id] = mapColumnValueToText(column.value, column);
            return acc;
          },
          { name: task.name }
        );
        return acc;
      }, {} as Record<string, Record<string, Record<string, any>>>);
      return acc;
    }, {} as Record<string, Record<string, Record<string, any>>>);
  }, [tasks]);

  return {
    tasks,
    configurations,
    values,
    updateColumnValue,
  };
}

export default function useDynamicData(elementId: string) {
  const reflect = useAtomValue(syncServiceAtom)?.getReplicache();
  const element = useCanvasElementById(reflect, elementId, null, [elementId]) as CanvasElement | null;
  const data = element?.dataLayer;
  const undoRedoStack = useAtomValue(undoRedoStackAtom);
  const elementType = getElementTypeForId(elementId);
  const supportCustomFields = consts.ELEMENTS_SUPPORTING_CUSTOM_FIELDS.includes(elementType);

  const integrationItemIds = useMemo(
    () =>
      Object.entries(data?.integrations ?? {}).reduce((acc, [integrationId, items]) => {
        acc[integrationId] = Object.keys(items);
        return acc;
      }, {} as Record<string, string[]>),
    [JSON.stringify(data?.integrations)]
  );

  const {
    configurations: integrationsConfig,
    values: integrationValues,
    updateColumnValue,
  } = useMondayTasks(integrationItemIds);

  function createFieldId() {
    return nanoid(10);
  }

  function addField(title: string, type: string) {
    const newId = createFieldId();
    undoRedoStack?.patchElement(elementId, (element) => {
      element.dataLayer ??= elementDataLayerSchema.parse({});
      element.dataLayer.fields[newId] = dataLayerFieldSchema.parse({ type, title });
      element.dataLayer.fieldsOrder.push(newId);
    });
  }

  function removeField(fieldId: string) {
    undoRedoStack?.patchElement(elementId, (draft) => {
      if (!draft.dataLayer) {
        return;
      }
      const index = draft.dataLayer.fieldsOrder.indexOf(fieldId);
      if (index !== -1) {
        draft.dataLayer.fieldsOrder.splice(index, 1);
      }
      delete draft.dataLayer.fields[fieldId];
      delete draft.dataLayer.values[fieldId];
      draft.dataLayer.rules = draft.dataLayer.rules.filter((rule) => rule.fieldId !== fieldId);
    });
  }

  function changeField(fieldId: string, title: string) {
    if (!data || !data.fields[fieldId]) {
      return;
    }
    undoRedoStack?.patchElement(elementId, (element) => {
      element.dataLayer!.fields[fieldId].title = title;
    });
  }

  function changeFieldValue(fieldId: string, value?: ElementDataLayerValue) {
    const [key, integrationId, itemId, columnId] = fieldId.split(":");
    if (key === "integration") {
      return updateColumnValue(integrationId, itemId, columnId, value);
    }
    if (!data || !data.fields[fieldId]) {
      return;
    }
    undoRedoStack?.patchElement(elementId, (element) => {
      if (value) {
        element.dataLayer!.values[fieldId] = value;
      } else {
        delete element.dataLayer!.values[fieldId];
      }
    });
  }

  function reorderField(id: string, index: number) {
    undoRedoStack?.patchElement(id, (element) => {
      const fieldOrder = element.dataLayer?.fieldsOrder ?? [];
      const currentIndex = fieldOrder.indexOf(id);
      if (currentIndex === -1 || currentIndex === index) {
        return;
      }
      fieldOrder.splice(currentIndex, 1);
      fieldOrder.splice(index, 0, id);
    });
  }

  const getField = useCallback(
    (fieldId: string) => {
      const [key, integrationId, _, columnId] = fieldId.split(":");
      if (key === "integration" && integrationsConfig[integrationId]) {
        const { columns } = integrationsConfig[integrationId];
        const column = columns.find((column: { id: string }) => column.id === columnId);
        if (column) {
          return dataLayerFieldSchema.parse(column);
        }
        return undefined;
      }
      return data?.fields[fieldId];
    },
    [data?.fields, integrationsConfig]
  );

  const getIntegrationFieldValue = useCallback(
    (integrationId: string, itemId: string, columnId: string) => {
      const columnValues = integrationValues[integrationId]?.[itemId];
      if (columnValues) {
        const column = columnValues[columnId];
        if (column) {
          return column;
        }
      }
    },
    [integrationValues]
  );

  const getFieldValue = useCallback(
    (fieldId: string) => {
      const [key, integrationId, itemId, columnId] = fieldId.split(":");
      if (key === "integration") {
        return getIntegrationFieldValue(integrationId, itemId, columnId);
      }
      const value = data?.values[fieldId];
      if (typeof value === "object") {
        const { integrationId, itemId, columnId } = value;
        return getIntegrationFieldValue(integrationId, itemId, columnId);
      }
      return value;
    },
    [data, getIntegrationFieldValue]
  );

  function getIntegrationData(fieldId: string) {
    const [key, integrationId, itemId, columnId] = fieldId.split(":");
    if (key === "integration") {
      return { integrationId, columnId, itemId };
    }
    return undefined;
  }

  const getFieldTextValue = useCallback(
    (fieldId: string): string | undefined => {
      const rawValue = getFieldValue(fieldId);
      if (!rawValue) {
        return undefined;
      }
      switch (typeof rawValue) {
        case "string":
          return rawValue;
        default:
          return undefined;
      }
    },
    [getFieldValue]
  );

  function changeRule(rule: ElementRule) {
    undoRedoStack?.patchElement(elementId, (element) => {
      element.dataLayer ??= elementDataLayerSchema.parse({});
      const index = element.dataLayer?.rules.findIndex((r) => r.id === rule.id);
      if (index !== undefined && index !== -1) {
        element.dataLayer!.rules[index] = rule;
      } else {
        element.dataLayer!.rules.push(rule);
      }
    });
  }

  function deleteRule(rule: ElementRule) {
    undoRedoStack?.patchElement(elementId, (element) => {
      element.dataLayer!.rules = element.dataLayer!.rules.filter((r) => r.id !== rule.id);
    });
  }

  function duplicateRule(rule: ElementRule) {
    undoRedoStack?.patchElement(elementId, (element) => {
      const newRule = { ...rule, id: nanoid(10) };
      element.dataLayer!.rules.push(newRule);
    });
  }

  function getFieldIntegrationConfig(fieldId: string) {
    const value = data?.values[fieldId];
    if (typeof value === "object") {
      return value;
    } else {
      return undefined;
    }
  }

  function addIntegrationItem(
    integrationId: string,
    itemId: string,
    columnIds: string[] | "all" = "all",
    replace: boolean = false
  ) {
    undoRedoStack?.patchElement(elementId, (element) => {
      element.dataLayer ??= elementDataLayerSchema.parse({});
      element.dataLayer.integrations ??= {};
      if (replace) {
        element.dataLayer.integrations[integrationId] = { [itemId]: { columnIds } };
      } else {
        element.dataLayer.integrations[integrationId] ??= {};
        element.dataLayer.integrations[integrationId][itemId] = { columnIds };
      }
    });
  }

  function removeIntegrationItem(integrationId: string, itemId: string) {
    undoRedoStack?.patchElement(elementId, (element) => {
      if (!element.dataLayer) {
        return;
      }
      const integration = element.dataLayer.integrations?.[integrationId];
      if (integration) {
        if (integration[itemId]) {
          delete integration[itemId];
        }
        if (Object.keys(integration).length === 0) {
          delete element.dataLayer.integrations![integrationId];
        }
      }
    });
  }

  function getIntegrationItemConfiguration(integrationId: string, itemId: string) {
    return data?.integrations?.[integrationId]?.[itemId];
  }

  const activeRules = useMemo(() => {
    return data?.rules.filter((rule) => rule.active) ?? [];
  }, [JSON.stringify(data?.rules)]);

  return {
    fieldIds: data?.fieldsOrder ?? [],
    addField,
    removeField,
    changeFieldValue,
    changeField,
    reorderField,
    getField,
    getFieldValue,
    getFieldTextValue,
    // rules
    rules: data?.rules ?? [],
    activeRules,
    changeRule,
    deleteRule,
    duplicateRule,
    // integrations
    isIntegrationActive: Object.keys(integrationItemIds).length > 0,
    integrationItemIds: integrationItemIds,
    getFieldIntegrationConfig,
    getIntegrationItemConfiguration,
    integrationsConfig,
    getIntegrationFieldValue,
    supportCustomFields,
    getIntegrationData,
    addIntegrationItem,
    removeIntegrationItem,
  };
}
