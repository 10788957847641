import { Node, NodeConfig } from "konva/types/Node";
import consts, { TypeCanvasElement } from "shared/consts";
import { ElementDrawProps } from "shared/datamodel/canvas-element";
import { defaultShapeDimensions, Shape } from "shared/datamodel/schemas/shape";
import { StickyNote } from "shared/datamodel/schemas/sticky-note";
import { TextBlock } from "shared/datamodel/schemas/textBlock";
import { TextEnabledElement } from "shared/datamodel/schemas/textEnabled";
import { StickyNoteMarginX, StickyNoteMarginY } from "./sticky-note-element";
import { CanvasElement } from "shared/datamodel/schemas/canvas-element";
import { Drawing } from "shared/datamodel/schemas/drawing";
import { isOn } from "frontend/utils/fn-utils";
import Konva from "konva";
import { MindmapNodeElement } from "shared/datamodel/schemas/mindmap";
import { MindmapOrgChartNodeElement } from "shared/datamodel/schemas/mindmap-org-chart";
import ShapesData from "frontend/data/shapes/shapes-visuals";
import { createElementId } from "shared/util/utils";
import { getElementGraphicsProvider, getElementProvider } from "elements/index";
import { ResizeType, TransformerConfig } from "elements/base/types";
import { getSpecialTransformerConfig } from "elements/bridge";
import { TypeTableElement } from "shared/datamodel/schemas/table";
import { getTransformParams } from "frontend/utils/node-utils";
import * as R from "rambda";
import { numberOfNodes, parseNodeId, transformerAnchorId } from "./org-chart/orgchart-utils";
import { KonvaDefaultBorderStroke, SelectedLockedElementOutline } from "frontend/canvas-designer-new/frontend-consts";
import { count } from "rambda";
export const DistanceToSelectLines_pixels = 35; // measured in pixels

const baseTransformerConfig: TransformerConfig = {
  shouldOverdrawWholeArea: false,
  keepRatio: true,
  lockAspectRatio: false,
  showSnapGuidelines: true,
  snapToGuidelines: true,
  flipEnabled: false,
  replaceAnchorsWithEdgeResize: false,
  borderStroke: KonvaDefaultBorderStroke,
  borderStrokeWidth: 2,
  anchorStrokeWidth: 2,
  borderEnabled: true,
  rotateEnabled: false,
  // disable this for now, add it on shift+rotate
  // rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
  rotationSnapTolerance: 5,
};

function resizeTypeToAnchors(rsz: ResizeType) {
  const keepRatio = rsz == ResizeType.Corners || rsz == getResizeTypeForElement(consts.CANVAS_ELEMENTS.TEXT_BLOCK);
  let enabledAnchors: string[] = [];

  if (isOn(rsz, ResizeType.Corners)) enabledAnchors = ["top-left", "top-right", "bottom-left", "bottom-right"];
  if (isOn(rsz, ResizeType.Sides)) enabledAnchors = [...enabledAnchors, "middle-left", "middle-right"];
  if (isOn(rsz, ResizeType.TopBottom)) enabledAnchors = [...enabledAnchors, "top-center", "bottom-center"];
  if (isOn(rsz, ResizeType.Right)) enabledAnchors = [...enabledAnchors, "middle-right"];
  return { enabledAnchors, keepRatio, lockAspectRatio: keepRatio };
}

const limitedTransformerConfig = {
  ...baseTransformerConfig,
  enabledAnchors: [],
  borderEnabled: true,
};

const lockedTransformerConfig = {
  ...limitedTransformerConfig,
  borderStroke: SelectedLockedElementOutline,
};

const hideTransformConfig = {
  ...baseTransformerConfig,
  enabledAnchors: [],
  rotateEnabled: false,
  borderEnabled: false,
};

function isElementRotatable(type: TypeCanvasElement): boolean {
  const provider = getElementProvider(type);
  if (provider) {
    return provider.isRotatableEnabled();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.FILE:
      return true;
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
      return false;
    case "tableCell":
      return false;
    default:
      console.warn(`Unknown element type: ${type}`);
      return false;
  }
}

function getResizeTypeForElement(type: TypeCanvasElement, restrict = false): ResizeType {
  if (restrict) return ResizeType.None; // Allow disabling transformations, e.g. scale rules

  const provider = getElementGraphicsProvider(type);
  if (provider) {
    return provider.getResizeType();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.FRAME:
      return ResizeType.All;
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
      return ResizeType.Corners | ResizeType.Sides;
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
      return ResizeType.Corners;
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
      return ResizeType.TopBottom;
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.TABLE_CELL:
      return ResizeType.None;
    default:
      return ResizeType.None;
  }
}

// element type to config...
const singleElementTransformerConfigs = Object.fromEntries(
  Object.values(consts.CANVAS_ELEMENTS).map((type) => {
    return [
      type,
      {
        ...baseTransformerConfig,
        ...resizeTypeToAnchors(getResizeTypeForElement(type)),
        rotateEnabled: isElementRotatable(type),
        ...getSpecialTransformerConfig(type),
      },
    ];
  })
);

function getTransformerConfigForSingleElement(node: Konva.Node) {
  if (node.attrs.element.lock) {
    return lockedTransformerConfig;
  }
  const elementType: TypeCanvasElement = node.attrs.type,
    element: any = node.attrs.element;

  if (node.name().startsWith(transformerAnchorId())) {
    return {
      ...baseTransformerConfig,
      ...resizeTypeToAnchors(ResizeType.Corners),
    };
  }

  if (elementType == consts.CANVAS_ELEMENTS.FRAME && element.visible === false) {
    return hideTransformConfig;
  }
  if (elementType == consts.CANVAS_ELEMENTS.CONNECTOR) {
    return hideTransformConfig;
  }
  if (elementType == consts.CANVAS_ELEMENTS.ORG_CHART_NODE || elementType == consts.CANVAS_ELEMENTS.ORG_CHART) {
    return hideTransformConfig;
  }

  if (
    (elementType == consts.CANVAS_ELEMENTS.INTEGRATION || elementType == consts.CANVAS_ELEMENTS.TASK_CARD) &&
    (element?.containerId ?? "").includes("gantt")
  ) {
    return hideTransformConfig;
  }

  if (isLimitedTransformer(node)) {
    return limitedTransformerConfig;
  }

  // Exception for data layer: Elements with scale rule cannot be resized
  if (consts.ELEMENTS_SUPPORTING_DATA_LAYER.includes(elementType) && elementHasScaleRule(node)) {
    return {
      ...baseTransformerConfig,
      ...resizeTypeToAnchors(ResizeType.None),
      rotateEnabled: isElementRotatable(elementType),
      ...getSpecialTransformerConfig(elementType),
    };
  }

  return singleElementTransformerConfigs[elementType];
}

export function isIdOfType(id: string, type: TypeCanvasElement) {
  return id.startsWith(type + "-");
}

export function isOfTypes(...types: TypeCanvasElement[]) {
  const re = new RegExp(`^(cElement-)?${types.map((t) => t + "-").join("|")}`);
  return (id: string) => re.test(id);
}

export function getElementTypeForId(id: string) {
  return id.substring(0, id.indexOf("-")) as TypeCanvasElement;
}

export function getElementTypeForNode(node: Konva.Node) {
  return getElementTypeForId(node.attrs.id);
}

export function linkBadgePosition(element: CanvasElement, type: TypeCanvasElement): { x: number; y: number } {
  const provider = getElementGraphicsProvider(type);
  if (provider) {
    // This doesn't do anything since the new framework doesn't render links.
    return provider.getLinkBadgePosition(element);
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
      const stickyNote = element as StickyNote;
      return {
        x: stickyNote.width + StickyNoteMarginX,
        y: stickyNote.height + StickyNoteMarginY,
      };
    }
    case consts.CANVAS_ELEMENTS.MINDMAP: {
      const node = element as MindmapNodeElement;
      return {
        x: node.width,
        y: node.height,
      };
    }
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART: {
      const node = element as MindmapOrgChartNodeElement;
      return {
        x: node.width,
        y: node.height,
      };
    }
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK: {
      const textBlock = element as TextBlock;
      return { x: textBlock.width, y: 0 };
    }
    case consts.CANVAS_ELEMENTS.FILE: {
      return { x: 50, y: 50 };
    }
    case consts.CANVAS_ELEMENTS.SHAPE: {
      return linkBadgePositionForShape(element as Shape);
    }
    case consts.CANVAS_ELEMENTS.TASK_CARD: {
      return { x: consts.DEFAULTS.CARD_WIDTH, y: 0 };
    }
    case consts.CANVAS_ELEMENTS.DRAWING: {
      const drawing = element as Drawing;
      const maxX = drawing.points
        .filter((_, index) => index % 2 === 0)
        .reduce((max, point) => Math.max(max, point), -Number.MAX_SAFE_INTEGER);
      const maxY = drawing.points
        .filter((_, index) => index % 2 !== 0)
        .reduce((max, point) => Math.max(max, point), -Number.MAX_SAFE_INTEGER);
      return { x: maxX, y: maxY };
    }
    case consts.CANVAS_ELEMENTS.TABLE: {
      const table = element as TypeTableElement;
      const { width, height } = getTransformParams(consts.CANVAS_ELEMENTS.TABLE, table);
      const { scaleX = 1, scaleY = 1 } = table;
      return { x: (width + 50) / scaleX, y: (height + 50) / scaleY };
    }
  }
  return { x: 0, y: 0 };
}

export function linkBadgePositionForShape(shape: Shape): { x: number; y: number } {
  if (shape.type == consts.CANVAS_ELEMENTS.SHAPE) {
    const subtype = shape.subtype;
    const data = subtype ? (ShapesData as any)[subtype] : undefined;
    if (!data) return { x: 0, y: 0 };
    const x = data.viewbox[0] + data.viewbox[2] / 2;
    const y = data.viewbox[1] + data.viewbox[3] / 2;
    return { x, y };
  }

  const { width, height, radius } = { ...defaultShapeDimensions, ...shape };
  let position;
  switch (shape.type) {
    case consts.SHAPES.RECT:
    case consts.SHAPES.RECT_ROUNDED: {
      position = {
        x: width,
        y: height,
      };
      break;
    }
    case consts.SHAPES.CIRCLE:
    case consts.SHAPES.HEXAGON: {
      position = {
        x: radius,
        y: radius,
      };
      break;
    }
    case consts.SHAPES.TRIANGLE: {
      const cos30 = 0.86602540378;
      const sin30 = 0.5;
      const width = radius * cos30;
      const apothem = radius * sin30;
      position = {
        x: width,
        y: apothem,
      };
      break;
    }
    case consts.SHAPES.DIAMOND: {
      position = {
        x: radius,
        y: radius,
      };
      break;
    }
    default: {
      position = {
        x: width,
        y: height,
      };
      break;
    }
  }
  return position;
}

/**
 * Legacy function to get content area for rendering text on shapes and sticky notes.
 * @deprecated Only used for sticky-notes and shapes.
 * To implement text rendering for new elements, write the logic within the element component.
 */
export function contentAreaRectForElement(
  element: TextEnabledElement,
  type: string
): { x: number; y: number; width: number; height: number; paddingX?: number; paddingY?: number } {
  switch (type) {
    case consts.CANVAS_ELEMENTS.CONNECTOR: {
      return { x: 0, y: 0, width: 0, height: 0 };
    }
    case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
      const stickyNote = element as StickyNote;
      return {
        x: 0,
        y: 0,
        width: stickyNote.width + StickyNoteMarginX,
        height: stickyNote.height + StickyNoteMarginY,
        paddingX: StickyNoteMarginX,
        paddingY: StickyNoteMarginY,
      };
    }
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK: {
      const textBlock = element as TextBlock;
      return { x: 0, y: 0, width: textBlock.width, height: textBlock.height };
    }
    case consts.CANVAS_ELEMENTS.SHAPE: {
      return contentAreaRectForShape(element as Shape);
    }
    case consts.CANVAS_ELEMENTS.MINDMAP: {
      const node = element as MindmapNodeElement;
      return { x: 0, y: 0, width: node.width, height: node.height };
    }
  }
  return { x: 0, y: 0, width: 0, height: 0 };
}

export function contentAreaRectForShape(shape: Shape) {
  if (shape.type == consts.CANVAS_ELEMENTS.SHAPE) {
    const subtype = shape.subtype;
    if (!subtype) return { x: 0, y: 0, width: 0, height: 0 };
    const data = subtype ? (ShapesData as any)[subtype] : undefined;
    const textarea = data?.textarea;
    if (!textarea) return { x: 0, y: 0, width: 0, height: 0 };
    let txt = textarea;
    if (typeof textarea == "function") {
      txt = textarea(shape.scaleX, shape.scaleY);
    }
    const centerX = data!.viewbox[0] + data!.viewbox[2] / 2;
    const centerY = data!.viewbox[1] + data!.viewbox[3] / 2;
    return { x: txt[0] - centerX, y: txt[1] - centerY, width: txt[2], height: txt[3] };
  }

  const { width, height, radius } = { ...defaultShapeDimensions, ...shape };
  let contentRect;
  const padding = 10;
  if (shape.type == consts.CANVAS_ELEMENTS.SHAPE) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }
  switch (shape.type) {
    case consts.SHAPES.RECT:
    case consts.SHAPES.RECT_ROUNDED: {
      contentRect = {
        x: 0,
        y: 0,
        width: width,
        height: height,
        paddingX: padding,
        paddingY: padding,
      };
      break;
    }

    case consts.SHAPES.CIRCLE: {
      const x = radius / Math.sqrt(2);
      const y = radius / Math.sqrt(2);
      const paddingX = radius - x;
      const paddingY = radius - y;
      contentRect = {
        x: -x,
        y: -y,
        width: (radius - paddingX) * 2,
        height: (radius - paddingY) * 2,
      };
      break;
    }
    case consts.SHAPES.HEXAGON: {
      const sideLength = radius;
      const apothem = (sideLength * Math.sqrt(3)) / 2; // length from center to middle of side
      const b = Math.sqrt(Math.pow(radius, 2) - Math.pow(apothem, 2));
      contentRect = {
        x: -apothem,
        y: -b,
        width: apothem * 2,
        height: (radius - b) * 2,
        paddingX: padding,
        paddinyY: 0,
      };
      break;
    }
    case consts.SHAPES.TRIANGLE: {
      const cos30 = 0.86602540378;
      const sin30 = 0.5;
      const width = radius * cos30;
      const yOffset = radius * 0.5 * sin30;
      const apothem = radius * sin30;
      contentRect = {
        x: -width / 2,
        y: -yOffset,
        width,
        height: apothem + yOffset,
      };
      break;
    }
    case consts.SHAPES.DIAMOND: {
      contentRect = {
        x: -radius / 2,
        y: -radius / 2,
        width: radius,
        height: radius,
      };
      break;
    }
    default: {
      contentRect = {
        x: 0,
        y: 0,
        width: width,
        height: height,
      };
      break;
    }
  }
  return contentRect;
}

//A node here can be either a canvas element or an attached connector of a canvas element.
//So, if the node is a connector, it can be either a stand-alone or attached to another shape.
//In the case it is attached, we need to save its points and anchorOrientation for undo/redo (since we didn't move the connector, we moved it's anchors)
export function drawPropsForNode(node: Node): ElementDrawProps {
  const { x, y } = node.getPosition();
  const nodeType = getElementTypeForId(node.attrs.id);
  const baseProps = {
    x,
    y,
    scaleX: node.scaleX(),
    scaleY: node.scaleY(),
    rotate: node.rotation(),
    zIndexLastChangeTime: node.attrs.element.zIndexLastChangeTime,
    groupId: node.attrs.element.groupId,
    frameId: node.attrs.element.frameId,
    containerId: node.attrs.element.containerId,
  } as ElementDrawProps;
  const props =
    nodeType === consts.CANVAS_ELEMENTS.CONNECTOR
      ? {
          ...baseProps,
          points: node.attrs.element.points,
          anchorOrientation: node.attrs.element.anchorOrientation,
        }
      : {
          ...baseProps,
          attachedConnectors: node.attrs.attachedConnectors,
        };
  if (nodeType === consts.CANVAS_ELEMENTS.MINDMAP) {
    // Mindmap nodes are not drawn at their x,y position, but at the position of their parent node.
    const mainNode = node.getParent();
    props.x = mainNode.x();
    props.y = mainNode.y();
  }
  return props as ElementDrawProps;
}

export function drawUndoPropsForNode(node: Node): ElementDrawProps {
  const undoProperties = node.attrs.undoProperties || drawPropsForNode(node); // current value as fallback
  node.attrs.undoProperties = null;
  return undoProperties;
}

function propsForNodes(
  nodes: Iterable<Node>,
  extractor: (node: Node) => ElementDrawProps
): Map<string, ElementDrawProps> {
  const info = new Map<string, ElementDrawProps>();
  for (const node of nodes) {
    const props = extractor(node);
    info.set(node.attrs.id, props);
  }
  return info;
}

export function drawPropsForNodes(nodes: Iterable<Node>): Map<string, ElementDrawProps> {
  return propsForNodes(nodes, drawPropsForNode);
}

export function drawUndoPropsForNodes(nodes: Iterable<Node>): Map<string, ElementDrawProps> {
  return propsForNodes(nodes, drawUndoPropsForNode);
}

function isConnectorAttachedFully(element: any) {
  return (
    element.connectedShapes &&
    element.connectedShapes.length == 2 &&
    !!element.connectedShapes[0]?.id &&
    !!element.connectedShapes[1]?.id
  );
}

function isLimitedTransformer(node: any) {
  return (
    isConnectorAttachedFully(node.attrs.element) ||
    node.attrs.element.containerId ||
    (node.attrs.type == consts.CANVAS_ELEMENTS.INTEGRATION && node.attrs.isDirty)
  );
}

const isOrgChartRoot = (node: Node) => node.attrs.id.startsWith(consts.CANVAS_ELEMENTS.ORG_CHART);
const orgchartRootOfNode = (node: Node) => parseNodeId(node.attrs.id).rootId;
const isGanttChart = (node: Node) => node.attrs.type == consts.CANVAS_ELEMENTS.GANTT;

/**
 * This function is a middleware that gets a chance to change the selected nodes list before
 * we pass them to the transformer
 * @param selectedNodes - the nodes that are selected
 * @returns
 */
export function prepareNodeForTransformerConfig(selectedNodes: Node[]): { remove: Node[]; add: string[] } {
  const add = [],
    remove = [];
  // Phase 1: if an entire orgchart is selected, replace its nodes with a single resizeable rect
  if (selectedNodes.some(isOrgChartRoot)) {
    const orgcharts = selectedNodes.filter(isOrgChartRoot);
    const grouped = R.groupBy(orgchartRootOfNode, selectedNodes);
    for (const root of orgcharts) {
      const rootId = parseNodeId(root.attrs.id).rootId;
      const nodes = grouped[rootId];
      const isAllSelected = nodes.length == numberOfNodes(root.attrs.element);
      if (isAllSelected) {
        // we have a full orgchart! remove it and ask caller to insert new node instead
        add.push(transformerAnchorId(rootId));
        remove.push(...nodes);
      }
    }
  }
  // Phase 2: if a gantt chart is selected, we remove all its children from the selection
  // they will be resized by the gantt itself
  const gantts = selectedNodes.filter(isGanttChart);
  if (gantts.length > 0) {
    // if we have gantt elements selected, we filter out (reject) the nodes that are contained by the gantts.
    // They will be transformed by the gantt itself when it's resized/moved.
    // If we leave them in the list, their transformer type (ResizeType.None) will not allow us to transform anything.
    const ganttsIds = new Set(gantts.map((n) => n.id()));
    const containedInGantts = selectedNodes.filter((node) => ganttsIds.has(node.attrs.element.containerId));
    remove.push(...containedInGantts);
  }
  return { remove, add };
}

export function getTransformerConfigByElementType(selectedNodes: Node[]) {
  const numberLocked = count((node: Node) => node.attrs.element.lock, selectedNodes);
  if (numberLocked == selectedNodes.length) {
    return lockedTransformerConfig;
  }
  if (numberLocked > 0) {
    return limitedTransformerConfig;
  }

  const nameOfTransformerAnchor = transformerAnchorId();
  if (selectedNodes.length == 1) {
    return getTransformerConfigForSingleElement(selectedNodes[0]);
  }

  let allowedRotate = true;
  let resizeType = ResizeType.All;
  for (const node of selectedNodes) {
    if (node.name().startsWith(nameOfTransformerAnchor)) {
      resizeType &= ResizeType.Corners;
      allowedRotate = false;
    } else {
      const hasScaleRule = elementHasScaleRule(node);
      const type = getElementTypeForNode(node);
      const typeResize = getResizeTypeForElement(type, hasScaleRule);
      resizeType &= typeResize;
      allowedRotate = allowedRotate && isElementRotatable(type);
    }
  }

  if (selectedNodes.every(isLimitedTransformer) || (resizeType == ResizeType.None && !allowedRotate)) {
    return limitedTransformerConfig;
  }

  const resizeConfig = resizeTypeToAnchors(resizeType);
  return Object.assign(Object.create(null), baseTransformerConfig, resizeConfig, { rotateEnabled: allowedRotate });
}

export function getConnectedConnectors(node: any) {
  if (node && node.attrs && node.attrs.attachedConnectors) {
    return Object.keys(node.attrs.attachedConnectors);
  }
  return [];
}

export function newGroupId() {
  return createElementId();
}

export function replaceGroupsAfterCopy(prevReplacements: { [key: string]: string }, element: any) {
  if (element.groupId) {
    const newId = (prevReplacements[element.groupId] ||= newGroupId());
    const isDerived = element.groupId.startsWith("derived:");
    element.groupId = isDerived ? `derived:${newId}` : newId;
  }
  if (element.groupHistory) {
    element.groupHistory = element.groupHistory.map((id: string) => {
      const newId = (prevReplacements[id] ||= newGroupId());
      const isDerived = id.startsWith("derived:");
      return isDerived ? `derived:${newId}` : newId;
    });
  }
}

export function elementHasScaleRule(node: Node<NodeConfig>) {
  const element = node.attrs.element;
  return element?.dataLayer?.rules?.find((rule: any) => rule.action?.property === "scale") ?? false;
}

const DEFAULT_FRAME_TITLE_REGEX = /^Frame\s*\d*$/;

export function getCopiedFrameTitle(title: string, nextFrameNumber: number) {
  if (DEFAULT_FRAME_TITLE_REGEX.test(title)) {
    return `Frame ${nextFrameNumber}`;
  } else {
    return title.startsWith("Copy of") ? title : `Copy of ${title}`;
  }
}
