import React from "react";

export const SharedWithMeIcon = ({ stroke = "#113357" }: { stroke?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M3.666 5.833a3.333 3.333 0 1 0 6.667 0 3.333 3.333 0 0 0-6.667 0M2 17.5v-1.667A3.333 3.333 0 0 1 5.333 12.5h2.5"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M11.857 13.476A3.33 3.33 0 0 0 9.5 12.5H7M14.5 14.167l-4.167 4.167M10.333 16.112v2.222h2.222"
      ></path>
    </svg>
  );
};
