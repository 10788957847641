import type { IconElement } from "./schema";
import { BaseElementProvider, ElementProps, ToolbarProps } from "elements/base/provider";
import type { ElementController } from "elements/base/controller";
import React from "react";
import consts, { TypeCanvasElement } from "shared/consts";
import type { BoardContext } from "elements/index";
import IconController from "./controller";
import IconElementComponent from "./icon-component";
import IconToolbar from "./toolbar/icon-toolbar";

export default class IconElementProvider extends BaseElementProvider<IconElement> {
  getType(): TypeCanvasElement {
    return consts.CANVAS_ELEMENTS.ICON;
  }

  getElementRenderer(): React.FC<ElementProps<IconElement>> {
    return IconElementComponent;
  }

  getToolbarRenderer(): React.FC<ToolbarProps<IconElement>> {
    return IconToolbar;
  }

  createController(
    id: string,
    element: IconElement,
    context: BoardContext,
    _allElementsData: [string, any][] = []
  ): ElementController<IconElement> {
    return new IconController(id, element, context);
  }

  isConnectorsEnabled(): boolean {
    return true;
  }

  isVotingEnabled(): boolean {
    return true;
  }

  isFFEnabled(): boolean {
    return true;
  }
}
