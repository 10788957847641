import { BaseGraphicsProvider } from "elements/base/graphics";
import { ResizeType } from "elements/base/types";
import { StandardAnchorPoints } from "frontend/canvas-designer-new/elements/connector/anchor-point-utils";
import { IconElement } from "shared/datamodel/schemas/icon";
import { Degrees } from "utils/transform";

export default class IconGraphicsProvider extends BaseGraphicsProvider<IconElement> {
  getResizeType(): ResizeType {
    return ResizeType.Corners;
  }

  getConnectorAnchorPoints(element: IconElement): StandardAnchorPoints | null {
    const { x, y, width = 0, height = 0, scaleX = 1, scaleY = 1 } = element;
    const scaledWidth = width * scaleX;
    const scaledHeight = height * scaleY;

    const anchors = {
      top: { x: x + scaledWidth / 2, y, rotation: 270 as Degrees, onBoundingBox: true },
      right: { x: x + scaledWidth, y: y + scaledHeight / 2, rotation: 0 as Degrees, onBoundingBox: true },
      buttom: { x: x + scaledWidth / 2, y: y + scaledHeight, rotation: 90 as Degrees, onBoundingBox: true },
      left: { x, y: y + scaledHeight / 2, rotation: 180 as Degrees, onBoundingBox: true },
    };
    return anchors;
  }

  getLinkBadgePosition(element: IconElement) {
    const { width = 0, height = 0 } = element;
    return { x: width, y: height };
  }
}
