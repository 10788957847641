import { fitTextIntoElement } from "frontend/canvas-designer-new/text-element/text-utils";
import { EditableText } from "frontend/editableText";
import useCurrentAndPrev from "frontend/hooks/use-cur-and-prev";
import { useEffect, useLayoutEffect, useState } from "react";
import consts from "shared/consts";
import { TextAlignment } from "shared/datamodel/schemas/text-enabled-schema";
import { TextEnabledElement } from "shared/datamodel/schemas/textEnabled";
import { toNumber } from "shared/util/utils";

export default function ElementTextComponent({
  id,
  element,
  onChangeElement,
  contentArea,
  scaleX = 1,
  scaleY = 1,
  isWide = false,
  onTextHeightChange,
  isEditing,
  onStopEditing,
  align = "center",
  verticalAlign = "top",
  placeholder = "",
  layerRef,
}: {
  id: string;
  element: TextEnabledElement;
  onChangeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    submenuData?: any,
    elementId?: string
  ) => void;
  contentArea: { x: number; y: number; width: number; height: number; paddingX?: number; paddingY?: number };
  scaleX?: number;
  scaleY?: number;
  isWide?: boolean;
  onTextHeightChange?: (height: number) => void;
  isEditing: boolean;
  onStopEditing: () => void;
  align?: TextAlignment;
  verticalAlign?: "top" | "bottom" | "middle";
  placeholder: string;
  layerRef?: any;
}) {
  const { text, fontSize, font = consts.DEFAULTS.FONT } = element;
  const fontSizeInPixels = toNumber(fontSize, consts.DEFAULTS.FONTSIZE);
  const [calculatedFontSize, setCalculatedFontSize] = useState<number | null>(null);

  const textState = useCurrentAndPrev<string>();
  useLayoutEffect(() => {
    if (isEditing) {
      // when going into edit mode, remember current text
      textState(element.text ?? "");
    } else {
      // when going out of edit mode, reset it and mark an undo point
      const [prev, cur] = textState();
      if (prev != cur && cur != null) {
        onChangeElement({ text: cur }, { shouldAdd: true, previousProps: { text: prev } }, undefined, id);
      }
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && layerRef && layerRef.current) {
      layerRef.current.batchDraw(); //force re-rendering as a workaround to fix resizableText not disappearing bug
    }
  }, [isEditing]);

  function onChangeText(text: string) {
    textState(text);
    onChangeElement({ text }, { shouldAdd: false }, undefined, id);
  }

  useEffect(() => {
    if (!text || !contentArea.width || !contentArea.height) {
      return;
    }

    if (fontSize === "auto") {
      const calculated = fitTextIntoElement(text, {
        font,
        fontSizeInPixels,
        width: contentArea.width,
        height: Math.min(
          // @ts-expect-error not all elements
          element?.height - (contentArea?.paddingY ?? 0) * 2,
          contentArea.height - (contentArea?.paddingY ?? 0) * 2
        ),
        scaleX,
        scaleY,
      });
      setCalculatedFontSize(calculated);
      onChangeElement({ fontSize: calculated }, { shouldAdd: false }, undefined, id);
    }
  }, [
    text,
    contentArea.width,
    contentArea.height,
    contentArea?.paddingY,
    font,
    fontSizeInPixels,
    scaleX,
    scaleY,
    // @ts-expect-error not all elements
    element?.height,
  ]);

  // If the font size is auto, don't render the text until it is changed to a number value
  if (fontSize === "auto") {
    return null;
  }

  return (
    <EditableText
      x={contentArea.x}
      y={contentArea.y}
      width={contentArea.width}
      height={contentArea.height}
      paddingX={contentArea.paddingX}
      paddingY={contentArea.paddingY}
      scaleX={scaleX}
      scaleY={scaleY}
      isWide={isWide}
      isFixedHeight={onTextHeightChange == undefined}
      onChange={onChangeText}
      onTextHeightChange={onTextHeightChange}
      text={text}
      placeholder={placeholder}
      fill={element.textColor ?? consts.DEFAULTS.TEXT_COLOR}
      fontSize={fontSize === "auto" ? calculatedFontSize ?? 12 : fontSizeInPixels}
      isEditing={isEditing}
      onStopEditing={onStopEditing}
      align={align}
      verticalAlign={verticalAlign}
      fontProps={element.fontProps}
      font={font}
    />
  );
}
