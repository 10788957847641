import { BaseElementProvider, ElementProps } from "elements/base/provider";
import consts, { TypeCanvasElement } from "shared/consts";
import { ChartElement } from "shared/datamodel/schemas/chart";
import { BoardContext } from "..";
import ChartElementComponent from "./chart-component";
import { ElementController } from "elements/base/controller";
import ChartController from "./chart-controller";

export default class ChartProvider extends BaseElementProvider<ChartElement> {
  getType(): TypeCanvasElement {
    return consts.CANVAS_ELEMENTS.CHART;
  }

  getElementRenderer(): React.FC<ElementProps<ChartElement>> {
    return ChartElementComponent;
  }

  createController(id: string, element: ChartElement, context: BoardContext): ElementController<ChartElement> {
    return new ChartController(id, element, context);
  }

  isRotatableEnabled(): boolean {
    return true;
  }

  isFFEnabled(): boolean {
    return true;
  }
}
