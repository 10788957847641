import Modal from "frontend/modal/modal";
import { PortalID } from "frontend/modal/portal-types";
import tracking from "frontend/tracking";
import { Floater } from "frontend/ui-components/floaters/floater";
import { DOMPlacement, RelativePlacement } from "frontend/ui-components/floaters/position-utils";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import consts from "shared/consts";
import { TipName } from "shared/datamodel/schemas";
import useWalkthroughTip from "./use-walkthrough-tip";
import WalkthroughTip from "./walkthrough-tip";
import {
  newHomePageWalkthroughTips,
  WalkthroughTipName,
  walkthroughTips,
  WalkthroughTipModel,
  WalkthroughTipType,
} from "./walkthrough-tip-model";
import { useWindowSize } from "react-use";
import { useBoardValue } from "frontend/state/board-state";

export default function WalkthroughTipPresenter({
  tipName,
  side = "bottom",
  attachToRef,
  onShowAndHide,
  showSkipButton = true,
  headerTitle,
  onActionClick,
  showArrow = true,
  showTipOnModal = false,
  type = WalkthroughTipType.Canvas,
}: {
  tipName: WalkthroughTipName;
  side?: RelativePlacement;
  attachToRef: React.RefObject<HTMLElement>;
  onShowAndHide?: (visible: boolean) => void;
  showSkipButton?: boolean;
  headerTitle?: string;
  onActionClick?: () => void;
  showArrow?: boolean;
  showTipOnModal?: boolean;
  type?: WalkthroughTipType;
}) {
  const boardValue = useBoardValue();
  const board = boardValue[0]?.board ?? null;
  const { showTip, markTipSeen } = useWalkthroughTip(tipName, board ?? null, type);
  const tip =
    type === WalkthroughTipType.HomePage
      ? (newHomePageWalkthroughTips as Record<WalkthroughTipName, WalkthroughTipModel>)[tipName]
      : (walkthroughTips as Record<WalkthroughTipName, WalkthroughTipModel>)[tipName];
  const [animateShow, setAnimateShow] = useState(false);
  const sentShownEvent = useRef(false);
  const size = useWindowSize();
  const minScreenHeightForWalkThrough = 400;

  useLayoutEffect(() => {
    setAnimateShow(!showTip);
    setTimeout(() => setAnimateShow(showTip), 100);
    if (showTip && !sentShownEvent.current) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.WALKTHROUGH, "tip_shown", tipName);
      sentShownEvent.current = true;
    }
  }, [showTip]);

  const visible = showTip && tip && size.height >= minScreenHeightForWalkThrough;

  useEffect(() => {
    if (onShowAndHide && visible) {
      onShowAndHide(true);
      return () => onShowAndHide(false);
    }
  }, [visible]);

  function onDismiss(skip: boolean) {
    if (skip) {
      // set the whole canvas walkthrough as seen
      type === WalkthroughTipType.HomePage
        ? markTipSeen(TipName.homePageWalkthrough)
        : markTipSeen(TipName.canvasWalkthrough);
      tracking.trackAnalyticsEvent("walkthrough_ended");
      tracking.trackEvent(consts.TRACKING_CATEGORY.WALKTHROUGH, "skip_clicked", tipName);
    } else {
      markTipSeen(tipName);
      tracking.trackEvent(consts.TRACKING_CATEGORY.WALKTHROUGH, "tip_dismissed", tipName);
    }
  }

  if (!visible) {
    return null;
  }

  return (
    <Modal
      theme="light"
      portalID={PortalID.WalkthroughTips}
      zIndex={1001}
      dimBackground={false}
      type={"walkthrough-tip"}
      customStyle={{ position: showTipOnModal ? "unset" : "fixed" }}
    >
      <Floater
        relativeTo={attachToRef}
        side={side}
        boundary="stage-container"
        boundaryPadding={40}
        extraStyle={{
          zIndex: Number.MAX_SAFE_INTEGER,
          transform: animateShow ? "scale(1)" : "scale(0.8)",
          opacity: animateShow ? 1 : 0,
          transition: "opacity 0.1s ease-out, transform 0.1s ease-out",
          marginLeft: "8px",
        }}
      >
        {(placement: DOMPlacement) => (
          <WalkthroughTip
            tip={tip}
            onDismiss={onDismiss}
            placement={placement}
            onActionClick={onActionClick}
            showArrow={showArrow}
            headerTitle={headerTitle}
            showSkipButton={showSkipButton}
            key={tipName}
          />
        )}
      </Floater>
    </Modal>
  );
}
