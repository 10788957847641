import type Konva from "konva";
import { Rect } from "react-konva";
import * as utils from "../orgchart-utils";
import { OrgChartElement } from "shared/datamodel/schemas/org-chart";
import { Point } from "shared/datamodel/schemas/canvas-element";

interface OrgChartTransformerProps {
  element: OrgChartElement;
  scaleX: number;
  scaleY: number;
  uniqueId: string;
  rootId: string;
  transformerOffsetX: number;
  transformerOffsetY: number;
  transformerWidth: number;
  transformerHeight: number;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}

export function OrgChartTransformer({
  element,
  scaleX,
  scaleY,
  uniqueId,
  rootId,
  onResize,
  transformerOffsetX,
  transformerOffsetY,
  transformerWidth,
  transformerHeight,
}: OrgChartTransformerProps) {
  return (
    <Rect
      x={element.x}
      y={element.y}
      scaleX={scaleX}
      scaleY={scaleY}
      masterId={uniqueId}
      name={utils.transformerAnchorId(rootId)}
      element={element}
      isCanvasElement={false}
      isSelectable={false}
      isConnectable={false}
      isConnector={false}
      isDraggable={true}
      isFrame={false}
      offsetX={transformerOffsetX}
      offsetY={transformerOffsetY}
      width={transformerWidth}
      height={transformerHeight}
      fillEnabled={false}
      opacity={0.2}
      strokeEnabled={false}
      listening={false}
      onTransformStart={(e: Konva.KonvaEventObject<Event>) => {
        const node = e.currentTarget;
        node.attrs.undoProperties = { scaleX, scaleY, x: element.x, y: element.y };
      }}
      onTransform={(e: Konva.KonvaEventObject<Event>) => {
        const node = e.currentTarget;
        const scale = node.scale();
        scale.x = Math.max(scale.x, 0.001);
        scale.y = Math.max(scale.y, 0.001);
        const rotation = node.rotation();
        // resizing rotated shapes causes them to skew in Konva's transformer.
        // I reset it because it looks so bad.
        // Miro does something else: it applies the change in scale to the shapes before the rotation
        node.skewX(0);
        node.skewY(0);
        onResize(uniqueId, node.getPosition(), scale.x, scale.y, rotation);
      }}
      onTransformEnd={(e: Konva.KonvaEventObject<Event>) => {
        const node = e.currentTarget;
        node.attrs.undoProperties = undefined;
      }}
    />
  );
}
