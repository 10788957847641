import { BaseElementController, ElementController } from "elements/base/controller";
import { ChartElement, ChartType, SingleBarChartElement } from "shared/datamodel/schemas/chart";
import SingleBarChartController from "./single-bar/single-chart-controller";
import { BoardContext } from "..";

export interface IChartController extends ElementController<ChartElement> {
  getInternalController(): ElementController<ChartElement>;
  getChartType(): ChartType;
}

export default class ChartController extends BaseElementController<ChartElement> implements IChartController {
  private internalController: ElementController<ChartElement>;

  constructor(id: string, element: ChartElement, context: any) {
    super(id, element, context);

    switch (this.element.chartType) {
      case ChartType.singleBar:
        this.internalController = new SingleBarChartController(
          this.id,
          this.element as SingleBarChartElement,
          this.context
        );
    }
  }

  getInternalController() {
    return this.internalController;
  }

  getChartType() {
    return this.element.chartType;
  }

  updateContext(context: BoardContext): void {
    super.updateContext(context);
    this.internalController.updateContext(context);
  }

  updateElement(element: ChartElement): void {
    super.updateElement(element);
    this.internalController.updateElement(element);
  }

  destroy(): void {
    super.destroy();
    this.internalController.destroy();
  }
}
