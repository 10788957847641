import { Circle, Group, Path, Rect, Text } from "react-konva";
import type { IGanttController } from "elements/gantt/controller";
import GanttHeaderDateCell from "elements/gantt/components/date-cell";
import Constants from "elements/gantt/constants";
import type { GanttElement } from "elements/gantt/schema";
import type { ElementProps } from "elements/base/provider";
import { useCallback, useEffect, useRef, useState } from "react";
import Konva from "konva";
import {
  getCornerRadius,
  hasDateConfigInMetadata,
  isTargetAnchor,
  isTargetConnector,
  isTargetSplitCell,
  isTargetTaskCell,
  trackGanttEvent,
} from "elements/gantt/utils";
import GanttTaskComponent, { measureTextTitle } from "elements/gantt/components/task-cell";
import { useEvent } from "react-use";
import { EVT_ELEMENT_DRAG, EVT_ELEMENT_DROP } from "../../canvas-designer-new/elements/card-stack/card-stack-utils";
import { isPointInRect } from "utils/math-utils";
import useObservableController from "elements/hooks/use-observable-controller";
import consts from "shared/consts";
import { resetPointer, setMouseColResize, setMouseDrag, setMousePointer, unsetMouse } from "utils/mouse-cursor-utils";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  focusedElementIdAtom,
  internalSelectionAtom,
  selectedElementIdsAtom,
  mondayIntegrationPopupAtom,
  transformerRefAtom,
  mouseStateAtom,
  stageRefAtom,
  userAtom,
} from "state-atoms";
import { Degrees } from "utils/transform";
import { EmptyStateTasks } from "elements/gantt/components/empty-state-tasks";
import { AddActions } from "elements/gantt/components/add-actions/add-actions";
import { KonvaTooltip } from "frontend/canvas-designer-new/utility-elements/konva-tooltip";
import { cleanDate, formatRelatedDates } from "shared/util/date-utils";
import GanttSplitColumn from "elements/gantt/components/split-column";
import { CanvasKeyboardShortcut, useKeyboardShortcuts } from "utils/keyboard-shortcuts";
import SimpleConnector from "frontend/canvas-designer-new/elements/connector/simple-connector";
import AddButton from "frontend/canvas-designer-new/utility-elements/add-button";
import { IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import { usePusher } from "frontend/hooks/use-pusher";
import { getFeatureFlag, useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import useDebounceCallback from "frontend/hooks/use-debounce-callback";
import { useGanttMaxTasks } from "elements/gantt/hooks/gantt-max-tasks";
import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { IntegrationItem } from "shared/datamodel/schemas";
import { GanttConnectorWrapper } from "elements/gantt/components/gantt-connector/gantt-connector-wrapper";
import { useGanttCustomRows } from "elements/gantt/hooks/gantt-use-custom-rows";
import { useGanttSyncMondayItems } from "elements/gantt/hooks/gantt-sync-monday-items";
import { Html } from "react-konva-utils";
import { RemoveMondayConnectorPopup } from "elements/gantt/components/remove-monday-connector-popup/remove-monday-connector-popup";
import Modal from "frontend/modal/modal";
import { ConnectorPopup } from "elements/gantt/components/connector-popup/connector-popup";
import { useIsPresenting } from "frontend/hooks/use-is-presenting";
import { GuestUserError } from "elements/gantt/components/guest-user-error";
import { addDays } from "date-fns";
import { useColumnConfiguration } from "elements/gantt/hooks/use-column-configuration";

export default function GanttElementComponent({ controller: _controller }: ElementProps<GanttElement>) {
  // we cast the controller to GanttController to satisfy the type checker
  const controller = _controller as IGanttController;
  const transformerRef = useAtomValue(transformerRefAtom);
  const stage = useAtomValue(stageRefAtom);
  const setInternalTransformer = useSetAtom(internalSelectionAtom);
  const [mondayIntegrationPopup, setMondayIntegrationPopup] = useAtom(mondayIntegrationPopupAtom);
  const mouseState = useAtomValue(mouseStateAtom);
  const maxAllowedTasksInPlan = useGanttMaxTasks();
  const isPresenting = useIsPresenting();

  const ref = useRef<Konva.Group>(null);
  // it's a string because creating an object of {splitId: string, rowId: string} is expensive
  const [isHoveringSplitRow, setIsHoveringSplitRow] = useState<string | null>(null);
  const [isHoveringTask, setIsHoveringTask] = useState<string | null>(null);
  const [taskEditId, setTaskEditId] = useState<string | null>(null);
  const [ghostConnector, setGhostConnector] = useState<{
    from: { taskId?: string; x: number; y: number };
    to: { taskId?: string; x: number; y: number };
  } | null>(null);
  const [connectorPopup, setConnectorPopup] = useState<{
    x: number;
    y: number;
    changeToMondayConnector: () => void;
  } | null>(null);
  const [hoveringConnector, setHoveringConnector] = useState<"left" | "right" | null>(null);
  const [isDraggingTask, setIsDraggingTask] = useState(false);
  const shouldShowUpgradeModal = useRef(false);
  const pusherChannel = controller.context.pusherChannel;
  const setFocusedElementId = useSetAtom(focusedElementIdAtom);
  const setSelectedElementIds = useSetAtom(selectedElementIdsAtom);
  const user = useAtomValue(userAtom);
  const { updateItem, updateColumnValue, getBoardIntegration } = useBoardTasks(
    controller.context.documentId,
    pusherChannel
  );

  const isSubitemsSupportEnabled = useFeatureFlag("gantt-monday-sub-items-support");
  const isGanttTimelineColumnEnabled = useFeatureFlag("gantt-monday-timeline-column");

  const columnConfiguration = useColumnConfiguration({
    reflect: controller.context.reflect,
  });
  const [showMondayDependenciesPopup, setShowMondayDependenciesPopup] = useState(false);
  const taskItemsToRemoveDependency = useRef(new Map<string, IGanttBaseCellController<IntegrationItem>>());
  const fetchLoadingItemsRef = useRef(new Set<string>());

  const { updateTask, getIntegrationTaskDates } = useGanttSyncMondayItems({
    controller,
  });

  const { changeTaskColumnValue } = useGanttCustomRows({
    controller: controller,
  });

  // makes it so the component re-renders when the controller changes
  useObservableController(controller, () => {
    if (controller.isSelected()) {
      transformerRef?.current?.forceUpdate();
    }
  });

  const reportTaskDateChanged = useDebounceCallback(() => {
    trackGanttEvent("gantt_task_edited", {
      from: "gantt",
      field: "date",
    });
  }, 700);

  const updateTaskDateChanged = useDebounceCallback((taskId: string) => {
    const task = controller
      .getTaskCells()
      .find((task) => task.id === taskId && task.element.type === consts.CANVAS_ELEMENTS.INTEGRATION) as
      | IGanttBaseCellController<IntegrationItem>
      | undefined;
    if (task) {
      addItemsToQueue({ [task.element.integrationId]: [task.element.configuration.itemId] });
    }
  }, 700);

  const { addItemsToQueue } = useBoardTasks(controller.context.documentId, pusherChannel);
  useEvent(EVT_ELEMENT_DRAG, (event) => {
    if (!user) {
      controller.context.showErrorToast?.(<GuestUserError />);
      return;
    }
    const taskIdsOnly = event.detail.ids.filter(
      (id: string) =>
        id.startsWith(consts.CANVAS_ELEMENTS.TASK_CARD) ||
        (id.startsWith(consts.CANVAS_ELEMENTS.INTEGRATION) && getFeatureFlag("gantt-monday-integration-items"))
    );
    if (taskIdsOnly.length !== 1) {
      return;
    }
    const ganttBounds = controller.getLayoutRect();
    const internalPoint = {
      x: (event.detail.mousePosition.x - ganttBounds.x) / (controller.element.scaleX ?? 1),
      y: (event.detail.mousePosition.y - ganttBounds.y) / (controller.element.scaleY ?? 1),
    };
    handleDraggedTask(taskIdsOnly[0], internalPoint, event);
  });

  useEvent(EVT_ELEMENT_DROP, (event) => {
    if (!user) {
      controller.context.showErrorToast?.(<GuestUserError />);
      return;
    }
    const taskIdsOnly = (event.detail.ids ?? []).filter(
      (id: string) =>
        id.startsWith(consts.CANVAS_ELEMENTS.TASK_CARD) ||
        (id.startsWith(consts.CANVAS_ELEMENTS.INTEGRATION) && getFeatureFlag("gantt-monday-integration-items"))
    );
    if (taskIdsOnly.length === 0) {
      return;
    }
    const ganttBounds = controller.getLayoutRect();
    const isDroppedHere = isPointInRect(event.detail, ganttBounds);
    if (!isDroppedHere) {
      return;
    }

    const internalPoint = {
      x: (event.detail.x - ganttBounds.x) / (controller.element.scaleX ?? 1),
      y: (event.detail.y - ganttBounds.y) / (controller.element.scaleY ?? 1),
    };

    handleDraggedTask(taskIdsOnly[0], internalPoint, event);
  });
  const shouldShowMondayIntegrationPopup = controller.getShowMondayIntegrationPopup();

  useEffect(() => {
    if (shouldShowMondayIntegrationPopup.isOpen) {
      if (shouldShowMondayIntegrationPopup.integrationId && mouseState === "up") {
        setMondayIntegrationPopup({
          isOpen: true,
          integrationId: shouldShowMondayIntegrationPopup.integrationId,
        });
      }
    }
  }, [shouldShowMondayIntegrationPopup, mouseState]);

  useEffect(() => {
    if (!mondayIntegrationPopup.isOpen) {
      controller.showMondayIntegrationPopup({
        isOpen: false,
      });
    }
  }, [mondayIntegrationPopup]);

  useEffect(() => {
    if (maxAllowedTasksInPlan) {
      controller.setMaxAllowedTasksInPlan(maxAllowedTasksInPlan);
    }
  }, [controller, maxAllowedTasksInPlan]);

  useEffect(() => {
    if (
      !controller.isSelected() ||
      (!controller.getSelectedConnectorId() && !controller.getSelectedSplitId() && !controller.getSelectedRowId())
    ) {
      setInternalTransformer((prev) => prev.filter((id) => id !== controller.id));
    } else {
      setInternalTransformer((prev) => [...prev, controller.id]);
    }
  }, [controller.isSelected(), controller.getSelectedConnectorId(), controller.getSelectedSplitId()]);

  usePusher<{
    data: {
      itemId: number;
      columnValues?: {
        id: string;
        type: string;
        value:
          | string
          | {
              from: string;
              to: string;
            };
      }[];
      name: string;
    };
  }>(
    pusherChannel,
    ["integration_data_changed", "live_integration_data_changed"],
    async ({ data }) => {
      for (const task of controller.getTaskCells()) {
        const element = task.element;
        if (
          data &&
          data.itemId &&
          (data.columnValues || data.name) &&
          element.type === "integrationItem" &&
          element.configuration.itemId.toString() === data?.itemId.toString()
        ) {
          const updateResult = await task?.updateIntegration?.("pusher", data);
          if (!updateResult?.didUpdate && !fetchLoadingItemsRef.current.has(task.id)) {
            fetchLoadingItemsRef.current.add(task.id);
            setTimeout(async () => {
              await updateTask(task.id);
              fetchLoadingItemsRef.current.delete(task.id);
            }, Constants.MondayIntegrationSyncIntervalSeconds * 1000);
          }
        }
      }
    },
    [controller]
  );

  useKeyboardShortcuts(
    (shortcut, e) => {
      if (shortcut !== CanvasKeyboardShortcut.delete) {
        return;
      }

      const selectedConnectorId = controller.getSelectedConnectorId();
      if (selectedConnectorId) {
        controller.removeConnector(selectedConnectorId);
        e.stopPropagation();
      } else if (controller.getSelectedSplitId() || controller.getSelectedRowId()) {
        controller.deleteSelectedElement();
        e.stopPropagation();
      }
    },
    [controller.getSelectedConnectorId(), controller.isSelected()],
    { enabled: controller.isSelected(), disablePropagation: false, capture: true }
  );

  const handleMouseMove = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    if (isTargetAnchor(e.target)) {
      // if the target is an anchor, do nothing
      return;
    }

    if (isTargetSplitCell(e.target)) {
      const [_, splitId, rowId] = e.target.attrs.id.split("-");
      return setIsHoveringSplitRow(`${splitId}-${rowId}`);
    }
    setIsHoveringSplitRow(null);

    if (isTargetTaskCell(e.target)) {
      const [_, taskId] = (e.target.attrs.id ?? "").split("task-");
      return setIsHoveringTask(taskId);
    }
    setIsHoveringTask(null);
    setTaskEditId(null);
  }, []);

  function onMouseLeave() {
    setIsHoveringSplitRow(null);
    setIsHoveringTask(null);
  }

  function renderDateColumnHeader() {
    return controller.getDateColumnsLayout().map((column) => <GanttHeaderDateCell key={column.id} {...column} />);
  }

  function renderSplitColumns() {
    return controller
      .getSplitColumns()
      .sort((a, b) => (a.isSelected() ? 1 : b.isSelected() ? -1 : 0))
      .map((controller) => <GanttSplitColumn key={controller.getSplitId()} controller={controller} />);
  }

  function renderGridLayout() {
    const headerPositions = controller
      .getDateColumnsLayout()
      .filter((h) => h.isHeader)
      .slice(1, Infinity);

    const { height } = controller.getLayoutRect();

    const shouldShowGuideLines = controller.element.granularity !== "day";

    return (
      <>
        {controller.getLayoutCells().map((cell) => {
          return (
            <Rect
              key={`grid-${cell.rowId}-${cell.date}${cell.x}${cell.y}`}
              x={cell.x}
              y={cell.y}
              width={cell.width}
              height={cell.height}
              fill={"white"}
              stroke={Constants.LayoutBorderColor}
              strokeWidth={Constants.LayoutBorderWidth}
              cornerRadius={getCornerRadius(cell.cornerRadius)}
            />
          );
        })}

        {shouldShowGuideLines &&
          headerPositions.map((column) => {
            return <Rect key={column.id} x={column.x - 1} y={column.y} width={2} height={height} stroke="#DADCE0" />;
          })}
      </>
    );
  }

  function renderTaskCells() {
    const tasks = controller.getTaskCells();
    if (tasks.length === 0) {
      const cells = controller.getLayoutCells();
      if (cells.length === 0) {
        return null;
      }
      const xPositions = cells.map((c) => c.x);
      const startX = Math.min(...xPositions);
      const endX = Math.max(...xPositions);

      const width = endX - startX + cells[0].width;
      return (
        <Group listening={false} x={startX} y={cells[0].y}>
          <EmptyStateTasks width={width} height={(cells.at(-2)?.y ?? 200) + 10} />
        </Group>
      );
    }
    return tasks.map((controller) => (
      <GanttTaskComponent
        key={controller.id}
        controller={controller}
        isEditing={taskEditId === controller.id && isHoveringTask === controller.id}
      />
    ));
  }

  const updateMondayItemDate = useCallback(
    (taskId: string, startDate?: number, endDate?: number) => {
      controller.context.reflect?.mutate.getElement(taskId).then((task) => {
        if (!task) {
          return;
        }
        const taskElement = task as IntegrationItem;
        // dont allow to drop if no config
        const config = taskElement && columnConfiguration.get(taskElement?.integrationId);
        if (config) {
          if (startDate) {
            updateItem(taskElement?.configuration.itemId, taskElement?.integrationId, {
              columnValue: {
                id: config.start_date,
                value: addDays(cleanDate(new Date(startDate ?? 0)), 1),
                type: "date",
              },
            });
          }
          if (endDate) {
            updateItem(taskElement?.configuration.itemId, taskElement?.integrationId, {
              columnValue: {
                id: config.end_date,
                value: addDays(cleanDate(new Date(endDate ?? 0)), 1),
                type: "date",
              },
            });
          }
        }
      });
    },
    [controller, columnConfiguration, updateItem]
  );

  function getPointForEvent(e: Konva.KonvaEventObject<DragEvent> | Konva.KonvaEventObject<MouseEvent>) {
    const stage = e.currentTarget.getStage();
    if (!stage) {
      return null;
    }
    const layoutRect = controller.getLayoutRect();

    const stagePosition = stage.getAbsolutePosition();
    const scale = stage.scaleX();

    const point = {
      x: (e.evt.offsetX - stagePosition.x) / scale,
      y: (e.evt.offsetY - stagePosition.y) / scale,
    };

    const internalPoint = {
      x: (point.x - layoutRect.x) / (controller.element.scaleX ?? 1),
      y: (point.y - layoutRect.y) / (controller.element.scaleY ?? 1),
    };

    return internalPoint;
  }

  function renderHoveredTaskAnchors() {
    if (!isHoveringTask || controller.isReadOnly()) {
      return null;
    }

    const layout = controller.getTaskCellLayout(isHoveringTask);
    const task = controller.getTaskCells().find((task) => task.id === isHoveringTask);
    if (!layout) {
      return null;
    }

    const padding = 10;
    const lineWidth = 5;
    const y = layout.y + padding;
    const startX = layout.x + padding;
    const endX = layout.x + layout.width - padding - lineWidth;
    const connectorAnchorX = layout.x + layout.width - padding;
    const connectorAnchorY = layout.y + layout.height / 2;

    const onResize = (side: "start" | "end") => (e: Konva.KonvaEventObject<DragEvent>) => {
      if (!user) {
        controller.context.showErrorToast?.(<GuestUserError />);
        return;
      }
      setTaskEditId(null);
      setIsDraggingTask(true);

      e.cancelBubble = true;
      const line = e.currentTarget;
      // reset line to original position
      line.y(y);
      line.x(side === "start" ? startX : endX);

      const point = getPointForEvent(e);
      if (!point) {
        return;
      }

      // get the grid cell that the point is hovering over
      const cell = controller.getHoveredGridCell(point);
      if (cell) {
        const startDate = side === "start" ? cell.date : undefined;
        const endDate = side === "end" ? cell.date : undefined;
        controller.changeDateForTask(isHoveringTask, startDate?.getTime(), endDate?.getTime());
        updateMondayItemDate(isHoveringTask, startDate?.getTime(), endDate?.getTime());
        reportTaskDateChanged();
      }
    };

    const onDragStart = () => {
      if (!user) {
        controller.context.showErrorToast?.(<GuestUserError />);
        return;
      }
      setTaskEditId(null);
      setSelectedElementIds([isHoveringTask]);
    };
    const onDragEnd = () => {
      reportTaskDateChanged();
      updateTaskDateChanged(isHoveringTask);
      setTaskEditId(null);
      setSelectedElementIds([]);
      setIsDraggingTask(false);
    };
    const onDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
      if (!user) {
        controller.context.showErrorToast?.(<GuestUserError />);
        return;
      }
      setIsDraggingTask(true);

      setTaskEditId(null);

      e.cancelBubble = true;
      const rect = e.currentTarget;
      const point = {
        x: rect.x(),
        y: rect.y() + rect.height() / 2,
      };
      handleDraggedTask(isHoveringTask, point, e);
    };

    const onDragConnectorStart =
      (reversed = false) =>
      () => {
        if (!user) {
          controller.context.showErrorToast?.(<GuestUserError />);
          return;
        }
        setTaskEditId(null);
        const from = { taskId: isHoveringTask, x: reversed ? startX : connectorAnchorX, y: connectorAnchorY };
        const to = { x: connectorAnchorX, y: connectorAnchorY };
        setGhostConnector({
          from: reversed ? to : from,
          to: reversed ? from : to,
        });
      };
    const onDragConnector =
      (reversed = false) =>
      (e: Konva.KonvaEventObject<DragEvent>) => {
        setTaskEditId(null);
        e.cancelBubble = true;
        const circle = e.currentTarget;
        // reset line to original position
        circle.y(0);
        circle.x(0);

        const point = getPointForEvent(e);
        if (!point) {
          return;
        }

        const task = controller.getHoveredTaskCell(point);
        if (task && task.elementId !== isHoveringTask) {
          const anchor = reversed
            ? {
                x: task.x + task.width - 10,
                y: task.y + task.height / 2,
              }
            : {
                x: task.x + 10,
                y: task.y + task.height / 2,
              };
          setGhostConnector((ghost) => ({
            ...ghost!,
            [reversed ? "from" : "to"]: { ...anchor, taskId: task.elementId },
          }));
        } else {
          setGhostConnector((ghost) => ({
            ...ghost!,
            [reversed ? "from" : "to"]: point,
          }));
        }
      };
    const onDragConnectorEnd = async (e: any) => {
      setTaskEditId(null);

      if (!ghostConnector?.from?.taskId || !ghostConnector?.to?.taskId) {
        setGhostConnector(null);
        return;
      }
      if (ghostConnector.from.taskId && ghostConnector.to.taskId) {
        trackGanttEvent("gantt_connector_added");
        const shouldHandleDependencies = getFeatureFlag("gantt-monday-dependency-column");
        if (shouldHandleDependencies) {
          const mondayItemsTasksForConnectors = controller
            .getTaskCells()
            .filter(
              (task) =>
                (task.id === ghostConnector.from.taskId &&
                  task.element.type === "integrationItem" &&
                  task.isIntegrated()) ||
                (task.id === ghostConnector.to.taskId && task.element.type === "integrationItem" && task.isIntegrated())
            );

          if (mondayItemsTasksForConnectors.length === 2) {
            const toItem = mondayItemsTasksForConnectors.find((task) => task.id === ghostConnector.to.taskId) as
              | IGanttBaseCellController<IntegrationItem>
              | undefined;
            const fromItem = mondayItemsTasksForConnectors.find((task) => task.id === ghostConnector.from.taskId) as
              | IGanttBaseCellController<IntegrationItem>
              | undefined;
            if (toItem && fromItem) {
              addItemsToQueue({ [toItem.element.integrationId]: [toItem.element.configuration.itemId] });
              setGhostConnector(null);

              const newId = await controller.addConnector(
                ghostConnector.from.taskId,
                ghostConnector.to.taskId,
                "custom"
              );
              if (newId) {
                // make sure the connector is not added if the items are from different boards
                if (toItem.element.integrationId !== fromItem.element.integrationId) {
                  setGhostConnector(null);
                  controller.addConnector(ghostConnector.from.taskId, ghostConnector.to.taskId, "custom");
                } else {
                  const toItemConfig = toItem.getConfiguration?.();
                  if (toItemConfig && !toItemConfig.dependencyId) {
                    controller.context.showErrorToast?.(
                      <div>This monday item is not configured to have a dependency column.</div>
                    );
                    unsetMouse(e);
                    setMondayIntegrationPopup({
                      isOpen: true,
                      selectedResult: "map",
                      integrationId: toItem.element.integrationId,
                    });
                  } else {
                    setGhostConnector(null);
                    setConnectorPopup({
                      x: ghostConnector.to.x,
                      y: ghostConnector.to.y,
                      changeToMondayConnector: () => {
                        controller.changeConnectorType(newId, "monday");
                        toItem.addMondayIntegrationConnector?.(fromItem.element.configuration.itemId);
                      },
                    });
                  }
                }
              }
            }
          } else {
            setGhostConnector(null);
            controller.addConnector(ghostConnector.from.taskId, ghostConnector.to.taskId, "custom");
          }
        } else {
          setGhostConnector(null);
          controller.addConnector(ghostConnector.from.taskId, ghostConnector.to.taskId, "custom");
        }
      }
      setGhostConnector(null);
    };

    const startShift = 0;
    const endShift = 0;

    const { height: textHeight, width: textWidth } = measureTextTitle({
      isEditing: false,
      layout,
      title:
        controller
          .getTaskCells()
          .find((task) => task.id === isHoveringTask)
          ?.getTitle() ?? "",
    });

    const hoverdDate = formatRelatedDates(task?.getStartDate() ?? 0, task?.getEndDate() ?? 0);

    const openTask = () => {
      if (isHoveringTask.includes("integrationItem")) {
        if (task && task.element.type === "integrationItem") {
          window.open(task.getLink?.(), "_blank");
        }
      } else {
        setFocusedElementId(isHoveringTask);
      }
    };

    const textElement = new Konva.Text({
      text: task?.getTitle(),
      fontFamily: consts.DEFAULTS.FONT,
      lineHeight: consts.LINE_HEIGHT,
      fontSize: 12,
      fontStyle: "300",
      textDecoration: "none",
      ellipsis: false,
      align: "left",
      verticalAlign: "top",
    });

    const resizeHitZone = 10;

    return (
      <Group
        name={"anchor"}
        onDblClick={() => {
          setTaskEditId(isHoveringTask);
        }}
        onClick={() => {
          setTaskEditId(null);
        }}
      >
        <Rect
          id={"anchor-drag-task"}
          x={startX + startShift}
          y={y}
          width={endX - startX + 5 + endShift - startShift}
          height={layout.height - padding * 2}
          fill={"transparent"}
          stroke="#00A1FF"
          cornerRadius={[5, 5, 5, 5]}
        />
        <Rect
          id={"anchor-drag-task"}
          x={startX}
          y={y}
          width={endX - startX + 5}
          height={layout.height - padding * 2}
          fill={"transparent"}
          stroke={"transparent"}
          draggable
          onDragMove={onDrag}
          onDragStart={onDragStart}
          hitStrokeWidth={resizeHitZone}
          onDragEnd={onDragEnd}
          onMouseEnter={(e) => {
            setMouseDrag(e);
            setIsDraggingTask(false);
          }}
          onMouseMove={setMouseDrag}
          onMouseLeave={unsetMouse}
          cornerRadius={[5, 5, 5, 5]}
        />

        <Rect
          id={"anchor-resize-right"}
          x={endX + endShift - 2}
          y={y}
          width={lineWidth + 4}
          height={layout.height - padding * 2}
          fill={"transparent"}
          cornerRadius={[0, 5, 5, 0]}
          draggable
          onDragMove={(e) => {
            setMouseColResize(e);
            onResize("end")(e);
          }}
          onDragStart={setMouseColResize}
          onMouseMove={setMouseColResize}
          onMouseEnter={setMouseColResize}
          onMouseLeave={(e) => {
            setIsDraggingTask(false);
            unsetMouse(e);
          }}
        />
        <Rect
          id={"anchor-resize-left"}
          x={startX + startShift - 2}
          y={y}
          width={lineWidth + 4}
          height={layout.height - padding * 2}
          fill={"transparent"}
          cornerRadius={[5, 0, 0, 5]}
          draggable
          onDragMove={(e) => {
            setMouseColResize(e);
            onResize("start")(e);
          }}
          onDragStart={setMouseColResize}
          onMouseMove={setMouseColResize}
          onMouseEnter={setMouseColResize}
          onMouseLeave={(e) => {
            setIsDraggingTask(false);
            unsetMouse(e);
          }}
        />

        {[
          {
            x: startX + startShift,
            y: connectorAnchorY,
            side: "left" as "left" | "right",
          },
          {
            x: connectorAnchorX + endShift,
            y: connectorAnchorY,
            side: "right" as "left" | "right",
          },
        ].map(({ x, y, side }) => {
          if (!ref.current?.getClientRect()) {
            return null;
          }
          const viewportHeight = stage.current.getSize().height;
          const height = layout.height - (padding * 2) / (controller.element.scaleY ?? 1) / stage.current.scaleY();
          if (height / viewportHeight < 0.0002) {
            // If the node is too small compared the the viewport, don't render
            return null;
          }

          return (
            <Group
              x={x}
              y={y}
              scaleX={1 / (controller.element.scaleX ?? 1) / Math.min(1, stage.current.scaleX())}
              scaleY={1 / (controller.element.scaleY ?? 1) / Math.min(1, stage.current.scaleX())}
              key={side}
            >
              <Circle
                id={`anchor-connector-${side}`}
                fill={"#00A1FF"}
                radius={hoveringConnector === side ? 8 : 5}
                draggable
                onDragStart={onDragConnectorStart(side === "left")}
                onDragEnd={(e) => {
                  onDragConnectorEnd(e);
                  unsetMouse(e);
                }}
                onDragMove={(e) => {
                  onDragConnector(side === "left")(e);
                  setMousePointer(e);
                }}
                onMouseEnter={(e) => {
                  setHoveringConnector(side);
                  setMousePointer(e);
                }}
                onMouseLeave={(e) => {
                  setHoveringConnector(null);
                  unsetMouse(e);
                }}
                hitStrokeWidth={resizeHitZone}
              />

              {hoveringConnector === side && (
                <Path x={-5} y={-5} listening={false} stroke="#fff" data="M0 5h10M5 0v10" />
              )}
            </Group>
          );
        })}

        <Group
          x={connectorAnchorX - 15}
          y={connectorAnchorY - 30}
          onClick={() => {
            trackGanttEvent("gantt_task_opend", {
              from: "chevron_click",
            });
            openTask();
          }}
          onMouseEnter={setMousePointer}
          id={`task-${isHoveringTask}`}
        >
          <Rect x={0} y={0} width={10} height={10} fill="transparent" id={`task-${isHoveringTask}`} />
        </Group>

        {isHoveringTask !== taskEditId && (
          <Rect
            id={"anchor-drag-task"}
            x={startX + padding}
            y={y + 4}
            width={textWidth}
            height={textHeight}
            onDblClick={(e) => {
              e.cancelBubble = true;
              setTaskEditId(isHoveringTask);
            }}
            draggable
            onDragMove={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onMouseEnter={setMouseDrag}
            onMouseMove={setMouseDrag}
            onMouseLeave={unsetMouse}
          />
        )}
        {isDraggingTask && (
          <KonvaTooltip y={y - 40} x={startX + lineWidth / 2} parentWidth={endX - startX} text={hoverdDate || ""} />
        )}

        {textElement.getWidth() >= layout.width - Constants.taskCellPadding * 4 - 28 &&
          (task?.getTitle() || "").length > 0 && (
            <KonvaTooltip x={startX} y={y - 35} parentWidth={endX - startX} text={task?.getTitle() || ""} />
          )}
      </Group>
    );
  }

  function renderGhostConnector() {
    if (!ghostConnector) {
      return null;
    }
    return (
      <SimpleConnector
        id={Constants.ConnectorPrefix}
        p1={{ ...ghostConnector.from, rotation: 0 as Degrees }}
        p2={{ ...ghostConnector.to, rotation: 180 as Degrees }}
        lineType={"curve"}
        element={{
          strokeWidth: 1,
          stroke: "#657E9A",
        }}
      />
    );
  }

  const resetSelection = (event?: any) => {
    setSelectedElementIds((prev) => [...prev]);
    setTimeout(() => {
      setSelectedElementIds((prev) => [...prev]);
    }, 50);
    if (event) {
      resetPointer(event);
    }
  };

  async function handleDraggedTask(taskId: string, point: { x: number; y: number }, event: any) {
    const oldTaskLayout = controller.getTaskCellLayout(taskId);
    const task = controller.getTaskCells().find((task) => task.id === taskId);
    const [customRowIntegrationId, columnName] = (controller.element.customRows?.id ?? "").split("_");

    let cell = controller.getHoveredGridCell(point);
    if (!cell && !event.detail?.ganttShouldKeep) {
      shouldShowUpgradeModal.current = true;
      const layout = controller.getLayoutRect();

      const position = {
        x: point.x * (controller.element.scaleX ?? 1) + layout.x - 200,
        y: point.y * (controller.element.scaleY ?? 1) + layout.y - 50,
      };
      controller.dragTasksOut(taskId, position).then((handled) => {
        if (handled) {
          const startDateColumnId = task?.getConfiguration?.()?.startId;
          if (
            task &&
            task.element.type === "integrationItem" &&
            task.element.configuration.itemId &&
            startDateColumnId &&
            task?.getStartDate()
          ) {
            updateColumnValue(task.element.integrationId, task.element.configuration.itemId, startDateColumnId, {
              date: addDays(cleanDate(new Date(task?.getStartDate() ?? 0)), 1),
            });
          }

          resetSelection(event);
        }
      });
      return;
    } else if (event.detail?.ganttShouldKeep) {
      cell = controller.getLayoutCells()[0];
    }
    if (!cell) {
      return;
    }
    setTimeout(() => transformerRef.current?.nodes([]), 0);

    if (!(await controller.canAddNewTask()) && shouldShowUpgradeModal.current) {
      controller.context?.showUpgradeModal?.("gantt");
      return;
    }
    const taskElement = (await controller.context.reflect?.mutate.getElement(taskId)) as IntegrationItem;
    const config = taskElement && columnConfiguration.get(taskElement?.integrationId);
    const hasDateConfig = hasDateConfigInMetadata(config ?? {}, {
      timeline: isGanttTimelineColumnEnabled,
    });
    const isSubitemsTask = getBoardIntegration(taskElement?.integrationId)?.configuration?.isSubitemsBoard;

    // not allow to move task from different integration in custom row mode
    if (
      controller.isCustomRowSplitMode() &&
      customRowIntegrationId !== taskElement?.integrationId &&
      task?.getLayout()?.rowId !== cell?.rowId
    ) {
      const lastSplit = controller.element.splits.at(-1);
      if (lastSplit) {
        const lastRow = lastSplit.rows.at(-1);
        if (lastRow) {
          await controller.dropElements(lastRow.id, { startDate: cell.date }, taskId);
        }
      }
      return;
    }

    // not allow subitems
    if (isSubitemsTask && !isSubitemsSupportEnabled) {
      if (columnName) {
        controller.context.showErrorToast?.(
          `Operation not supported: It looks like '${columnName}' column isn't available on this board.`
        );
      } else {
        controller.context.showErrorToast?.("You cannot move a task when split by a different board column.");
      }
      return;
    }
    // new task that has no config
    if (!task && !hasDateConfig && taskElement.type === "integrationItem") {
      setMondayIntegrationPopup({
        isOpen: true,
        selectedResult: "map",
        integrationId: taskElement?.integrationId,
      });
      controller.context.showErrorToast?.(
        "You must first configure the integration to move the task into the Gantt chart."
      );
      return;
    } else if (task && !hasDateConfig && taskElement.type === "integrationItem") {
      // new task that has config
      await controller.dropElements(cell.rowId, { startDate: cell.date }, taskId);
      setMondayIntegrationPopup({
        isOpen: true,
        selectedResult: "map",
        integrationId: taskElement?.integrationId,
      });
      return;
    }

    let dropResult: any = null;
    if (taskElement.type === "integrationItem") {
      const dates = getIntegrationTaskDates(taskElement);
      if (dates) {
        const diff = dates.endDate - dates.startDate;
        dropResult = await controller.dropElements(
          cell.rowId,
          {
            startDate: cell.date,
            endDate: new Date(new Date(cell.date).getTime() + diff),
          },
          taskId
        );
      }
    } else {
      dropResult = await controller.dropElements(cell.rowId, { startDate: cell.date }, taskId);
    }
    // all ok
    if (dropResult?.didDrop) {
      updateMondayItemDate(
        taskId,
        new Date(task?.getStartDate() ?? 0).getTime(),
        new Date(task?.getEndDate() ?? 0).getTime()
      );
    }

    if (dropResult && !dropResult.didDrop && dropResult?.reason === "duplicate-monday") {
      controller.context.showErrorToast?.("This monday item is already placed in the gantt chart.");
      resetSelection(event);
      return;
    }
    const newTaskColumnValue = controller.element.splits.flatMap((s) => s.rows).find((r) => r.id === cell?.rowId);
    if (newTaskColumnValue && controller.isCustomRowSplitMode() && oldTaskLayout?.rowId !== cell.rowId) {
      changeTaskColumnValue?.[taskId]?.(newTaskColumnValue.title);
    }

    if (cell) {
      shouldShowUpgradeModal.current = false;
    }
  }

  function renderTaskConnectors() {
    const connectors = controller.getConnectors();
    const tasks = controller.getTaskCells();
    return connectors.map((connector) => {
      const relevantTasks = tasks.filter((task) => task.id === connector.from.id || task.id === connector.to.id);
      const someAreLoading = relevantTasks.some((task) => task?.isInitialLoading?.());
      if (someAreLoading) {
        return null;
      }
      const isSelected = controller.getSelectedConnectorId() === connector.id;

      return (
        <GanttConnectorWrapper
          isSelected={isSelected}
          tasks={tasks}
          connector={connector}
          key={connector.id}
          id={`${Constants.ConnectorPrefix}-${connector.id}`}
          p1={{ ...connector.from, rotation: 0 as Degrees }}
          p2={{ ...connector.to, rotation: 180 as Degrees }}
          lineType={"curve"}
          element={{
            strokeWidth: 1,
            stroke: isSelected ? "#00A1FF" : "#657E9A",
            hitStrokeWidth: 10,
          }}
          bboxPosition={{ x: controller.getLayoutRect().x, y: controller.getLayoutRect().y }}
          removeConnector={() => {
            const mondayItemsTasksForConnectors = controller
              .getTaskCells()
              .filter(
                (task) =>
                  (task.id === connector.from.id && task.element.type === "integrationItem" && task.isIntegrated()) ||
                  (task.id === connector.to.id && task.element.type === "integrationItem" && task.isIntegrated())
              );

            if (mondayItemsTasksForConnectors.length === 2) {
              const toItem = mondayItemsTasksForConnectors.find((task) => task.id === connector.to.id) as
                | IGanttBaseCellController<IntegrationItem>
                | undefined;
              const fromItem = mondayItemsTasksForConnectors.find((task) => task.id === connector.from.id) as
                | IGanttBaseCellController<IntegrationItem>
                | undefined;
              if (toItem && fromItem) {
                if (connector.type === "monday") {
                  trackGanttEvent("gantt_connector_monday_popup_opened");
                  setShowMondayDependenciesPopup(true);
                  taskItemsToRemoveDependency.current.set(
                    `${fromItem.element.configuration.itemId}-${connector.id}`,
                    toItem
                  );
                } else {
                  controller.removeConnector(connector.id);
                }
                return;
              }
            }
            controller.removeConnector(connector.id);
          }}
        />
      );
    });
  }

  async function onDblClick(e: Konva.KonvaEventObject<MouseEvent>) {
    if (controller.isReadOnly()) {
      return;
    }
    const point = getPointForEvent(e);

    if (!point) {
      return;
    }

    const gridCell = controller.getHoveredGridCell(point);
    if (!gridCell) {
      return;
    }
    const existingTask = controller.getHoveredTaskCell(point);
    if (existingTask) {
      // if there is an existing task, we should not create a new one
      return;
    }
    const didCreateTask = await controller.createTask(gridCell.rowId, gridCell.date);
    if (didCreateTask) {
      trackGanttEvent("gantt_task_created", {
        from: "db_click",
        amount: controller.getTaskCells().length + 1,
      });
    }
  }

  function onClick(e: Konva.KonvaEventObject<MouseEvent>) {
    if (controller.isReadOnly()) {
      return;
    }

    if (isTargetConnector(e.target)) {
      const [, connectorId] = (e.target?.attrs?.id?.split(`${Constants.ConnectorPrefix}-`) ?? []) as string[];
      controller.setSelectedConnector(connectorId);
    } else {
      controller.setSelectedConnector(null);
    }

    if (!isTargetAnchor(e.target) && !isHoveringSplitRow) {
      controller.setSelectedColumnRow(null, null);
    }
  }

  function renderAddBelowRow(
    splitId: string,
    rowId: string,
    splitCellController: IGanttSplitCellController | undefined
  ) {
    const cellLayout = controller.getCellLayout(splitId, rowId);
    if (!cellLayout || !splitCellController || isPresenting) {
      return null;
    }

    const y = cellLayout.y + cellLayout.height;
    const x = cellLayout.x + cellLayout.width / 2;

    return (
      <Group
        x={x}
        y={y}
        onClick={() => {
          splitCellController.addRow();
          trackGanttEvent("gantt_row_added", {
            from: "circle_button",
          });
        }}
        id={"anchor-add-below-column"}
        key={"anchor-add-below-column"}
      >
        <AddButton width={18} height={18} hitHeight={30} hitWidth={30}>
          <KonvaTooltip y={-45} x={-10} parentWidth={20} text={"Add row"} />
        </AddButton>
      </Group>
    );
  }

  function renderAddRightColumn(
    splitId: string,
    rowId: string,
    splitCellController: IGanttSplitCellController | undefined
  ) {
    const cellLayout = controller.getCellLayout(splitId, rowId);
    if (!cellLayout || !splitCellController) {
      return null;
    }

    const x = cellLayout.x + cellLayout.width;
    const y = cellLayout.y + cellLayout.height / 2;

    return (
      <Group
        x={x}
        y={y}
        onClick={() => {
          splitCellController.addColumn();
          trackGanttEvent("gantt_column_added", {
            from: "circle_button",
          });
        }}
        id={"anchor-add-right-column"}
        key={"anchor-add-right-column"}
      >
        <AddButton width={18} height={18} hitHeight={30} hitWidth={30}>
          <KonvaTooltip y={-45} x={-10} parentWidth={20} text={"Add Column"} />
        </AddButton>
      </Group>
    );
  }

  function renderTitlePlaceholder(splitId: string, rowId: string) {
    const selectedRowId = controller.getSelectedRowId();
    const cellLayout = controller.getCellLayout(splitId, rowId);

    if (!cellLayout || cellLayout?.title || selectedRowId === rowId) {
      return null;
    }

    const x = cellLayout.x + 21;
    const y = cellLayout.y + cellLayout.height / 2 - 7;

    return (
      <Group x={x} y={y} id={"anchor-add-text"} key={"anchor-add-text"}>
        <Text
          text={"Add text"}
          align={"center"}
          verticalAlign={"middle"}
          fontFamily={"Poppins"}
          fontSize={14}
          fill={"#676879"}
          listening={false}
        />
      </Group>
    );
  }

  function renderAddButtons() {
    if (!isHoveringSplitRow) {
      return null;
    }

    const [splitId, rowId] = isHoveringSplitRow.split("-");
    const splitCellController = controller.getSplitCells(splitId).find((cell) => cell.getRowId() === rowId);

    return (
      <Group>
        {controller.canAddSplitRowBelow(splitId) && renderAddBelowRow(splitId, rowId, splitCellController)}
        {controller.canAddSplitColumnRight() && renderAddRightColumn(splitId, rowId, splitCellController)}
        {renderTitlePlaceholder(splitId, rowId)}
      </Group>
    );
  }

  function renderSelectedRow() {
    const selectedSplitId = controller.getSelectedSplitId();
    const selectedRowId = controller.getSelectedRowId();
    if (!selectedSplitId || !selectedRowId) {
      return null;
    }
    const row = controller.getSplitCells(selectedSplitId).find((cell) => cell.getRowId() === selectedRowId);
    if (!row) {
      return null;
    }
    const rect = row.getRect();
    const { width } = controller.getLayoutRect();
    return (
      <Rect
        {...rect}
        width={width - rect.x}
        fill="transparent"
        stroke={Constants.SelectedCellBorderColor}
        strokeWidth={2}
        listening={false}
        cornerRadius={getCornerRadius(rect.cornerRadius)}
      />
    );
  }

  return (
    <Group
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={onMouseLeave}
      listening={true}
      id={"gantt-parent-node"}
      onDblClick={onDblClick}
      onClick={onClick}
    >
      {renderDateColumnHeader()}
      {renderGridLayout()}
      {renderSplitColumns()}
      {renderTaskConnectors()}
      {renderTaskCells()}
      {renderHoveredTaskAnchors()}
      {renderAddButtons()}
      {renderGhostConnector()}
      {renderSelectedRow()}
      {!controller.isReadOnly() && (
        <AddActions x={controller.getDateColumnsLayout().at(0)?.x ?? 0} y={-50} controller={controller} />
      )}
      <ConnectorPopup
        x={connectorPopup?.x}
        y={connectorPopup?.y}
        onChange={(option) => {
          if (option === "custom") {
            setConnectorPopup(null);
            return;
          }
          if (option === "monday") {
            connectorPopup?.changeToMondayConnector();
          }
          setConnectorPopup(null);
        }}
        onClose={() => setConnectorPopup(null)}
      />
      {showMondayDependenciesPopup && (
        <Html>
          <Modal
            dimBackground
            onClickBackground={() => {
              setShowMondayDependenciesPopup(false);
            }}
          >
            <RemoveMondayConnectorPopup
              onClose={() => {
                setShowMondayDependenciesPopup(false);
              }}
              onSelection={(shouldDelete) => {
                trackGanttEvent("gantt_connector_removed_monday_dependency", {
                  shouldDeleteInMonday: shouldDelete,
                });
                setShowMondayDependenciesPopup(false);
                if (shouldDelete) {
                  for (const [id, item] of taskItemsToRemoveDependency.current.entries()) {
                    const [itemId, connectorId] = (id ?? "").split("-");
                    if (itemId && connectorId) {
                      item.removeMondayIntegrationConnector?.(itemId).then(() => {
                        taskItemsToRemoveDependency.current.delete(itemId);
                      });
                      controller.removeConnector(connectorId);
                    }
                  }
                }
              }}
            />
          </Modal>
        </Html>
      )}
    </Group>
  );
}
