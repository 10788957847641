import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";
import { dataLayerFieldSchema } from "./element-data-layer";

export enum ChartType {
  singleBar = "single-bar",
}

const chartSchema = canvasElementBaseSchema.merge(
  z.object({
    type: z.string().default("chart"),
    chartType: z.nativeEnum(ChartType).describe("The type of the chart."),
  })
);

export const singleBarFields = z.record(dataLayerFieldSchema).parse({
  minValue: { type: "number", title: "Min", isSystem: true },
  maxValue: { type: "number", title: "Max", isSystem: true },
  value: { type: "number", title: "Value", isSystem: true },
  backgroundColor: { type: "color", title: "Background Color", isSystem: true },
  fillColor: { type: "color", title: "Fill Color", isSystem: true },
});

export const singleBarDefaultValues = {
  minValue: "0",
  maxValue: "100",
  value: "30",
  backgroundColor: "#DADCE0",
  fillColor: "#00B875",
};

export type SingleBarChartFields = keyof typeof singleBarDefaultValues;

export const singleBarChartSchema = chartSchema.merge(
  z.object({
    width: z.number().describe("The width of the chart."),
    height: z.number().describe("The height of the chart."),
  })
);

export type ChartElement = z.infer<typeof chartSchema>;
export type SingleBarChartElement = z.infer<typeof singleBarChartSchema>;
