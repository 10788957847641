import style from "./checkbox.module.css";
import classNames from "classnames";
import { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import { FormValidator } from "./form-fields/use-form-validator";

type CheckboxProps = {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  isError?: boolean;
  tabIndex?: number;
  id?: string;
  onSubmit?: () => void;
  customStyle?: CSSProperties;
  ovverideStyles?: {
    base?: string;
    checked?: string;
  };
  label?: ReactNode;
};

export default function Checkbox({
  checked,
  onChange,
  isError = false,
  tabIndex,
  id,
  onSubmit,
  customStyle,
  ovverideStyles,
  label,
}: CheckboxProps) {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboard);
    return () => window.removeEventListener("keydown", handleKeyboard);
  }, [focused, checked]);

  const toggle = useCallback(() => onChange && onChange(!checked), [checked, onChange]);

  customStyle ||= {};

  function handleKeyboard(e: any) {
    if (!focused) {
      return;
    }
    if (e.code === "Space" || (e.code === "Enter" && !checked)) {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    } else if (e.code === "Enter" && onSubmit) {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    }
  }

  return (
    <>
      <div
        id={id}
        className={classNames("canvasCheckbox", style.checkboxContainer, ovverideStyles?.base, {
          [ovverideStyles?.checked || style.checked]: checked,
          [style.error]: isError,
        })}
        onClick={toggle}
        tabIndex={tabIndex}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={customStyle}
      />
      {label && <div onClick={toggle}>{label}</div>}
    </>
  );
}

type CheckboxWithValidatorProps<O, K extends keyof O> = {
  id: K;
  validator: FormValidator<O, K>;
  tabIndex?: number;
  onChange?: (checked: boolean) => void;
  onSubmit?: () => void;
};

export function CheckBoxWithValidator<O, K extends keyof O>(props: CheckboxWithValidatorProps<O, K>) {
  const { id, validator, tabIndex, onChange, onSubmit } = props;
  const { values, errors, setValue } = validator;

  const isChecked = values[id as K] === true;
  const isError = !!errors[id as K];

  return (
    <Checkbox
      key={id as string}
      id={id as string}
      checked={isChecked}
      onChange={(val) => {
        setValue(id, val);
        onChange && onChange(val);
      }}
      isError={isError}
      tabIndex={tabIndex}
      onSubmit={onSubmit}
    />
  );
}
