export const CornerRadius = 10;

export const Cell = Object.freeze({
  StrokeWidth: 1,
  SelectedStrokeColor: "#00a1ff",
  SelectedStrokeWidth: 2,
});

export const Button = Object.freeze({
  Fill: "#BFDCFF",
  Stroke: "#0072FF",
  HoverFill: "#6DAFFF",
  HoverStroke: "#fff",
});

export const WidgetOffsetFromTable = 30; //  in pixels
