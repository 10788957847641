import React from "react";

export const HomeIcon = ({ stroke = "#113357" }: { stroke?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path
        d="M3.28516 6.14166V14.2131C3.28516 14.4892 3.50901 14.7131 3.78516 14.7131H14.2137C14.4899 14.7131 14.7137 14.4892 14.7137 14.2131V6.14166"
        stroke={stroke}
        strokeWidth="1.2"
      />
      <path
        d="M11.2852 14.7167L11.2852 11.2167C11.2852 10.9405 11.0613 10.7167 10.7852 10.7167L7.21373 10.7167C6.93759 10.7167 6.71373 10.9405 6.71373 11.2167L6.71373 14.7167"
        stroke={stroke}
        strokeWidth="1.2"
      />
      <path
        d="M1 8.42857L8.93013 1.31033C9.12504 1.13538 9.42194 1.14048 9.61072 1.32204L17 8.42857"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};
