import { ObservableController } from "elements/base/observable";
import type { IntegrationItemsMap } from "frontend/hooks/use-board-integrations.types";

export default class BoardIntegrationsProxy extends ObservableController {
  private dataSource: IntegrationItemsMap = {};
  private addItemToQueue: (integrations: Record<string, string[]>) => void = () => {};

  setDataSource(ds: IntegrationItemsMap) {
    this.dataSource = ds;
    this.notify();
  }

  setAddItemFunc(func: (integrations: { [integrationId: string]: string[] }) => void) {
    this.addItemToQueue = func;
  }

  loadItems(integrations: { [integrationId: string]: string[] }) {
    this.addItemToQueue(integrations);
  }

  getIntegration(id: string) {
    return this.dataSource[id];
  }

  getItem(id: string, integrationId: string) {
    return this.dataSource[integrationId]?.items.find((item) => item.id === id);
  }

  getColumnValue(id: string, integrationId: string, columnId: string) {
    const item = this.getItem(id, integrationId);
    return item?.columnValues.find((column) => column.id === columnId)?.value;
  }
}
