import { CanvasElement } from "shared/datamodel/schemas";
import { useEffect, useState } from "react";
import useDynamicData from "./use-dynamic-data";
import { ElementRule, ElementRuleComparator } from "shared/datamodel/schemas/element-data-layer";
import { changedPropertiesForElement } from "./element-rules-editor/rule-cell/rule-creator-utils";
import { getElementTypeForId } from "../elements/canvas-elements-utils";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";

export function useElementWithRulesProcessing<T extends CanvasElement>({ id, element }: { id: string; element: T }): T {
  const [changedProperties, setChangedProperties] = useState<Record<string, any>>({});
  const { activeRules, getFieldTextValue } = useDynamicData(id);
  const elementType = getElementTypeForId(id);

  const memoedElement = useDeepEqualMemo(element);
  useEffect(() => {
    const propsRules = activeRules.filter((rule) => rule.action.property !== "icon");
    const passingRules = getPassingRules(propsRules, getFieldTextValue);
    const changedProperties = passingRules.reduce((acc, rule) => {
      const changed = changedPropertiesForElement(elementType, rule.action.property, rule.action.value);
      return { ...acc, ...changed };
    }, {} as Record<string, any>);

    if (
      propsRules.some((rule) => rule.action.property === "scale") &&
      (changedProperties.scaleX === undefined || changedProperties.scaleY === undefined)
    ) {
      changedProperties.scaleX = 1;
      changedProperties.scaleY = 1;
    }

    setChangedProperties(changedProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoedElement, getFieldTextValue]);

  if (Object.keys(changedProperties).length === 0) {
    return memoedElement;
  }

  return {
    ...memoedElement,
    ...changedProperties,
  };
}

export function useElementIconsProcessing(id: string): Record<string, string> {
  const [icons, setIcons] = useState<Record<string, string>>({});
  const { activeRules, getFieldTextValue } = useDynamicData(id);

  useEffect(() => {
    const iconRules = activeRules.filter((rule) => rule.action.property === "icon");
    const passingRules = getPassingRules(iconRules, getFieldTextValue);
    const icons = passingRules.reduce((acc, rule) => {
      acc[rule.fieldId] = rule.action.value;
      return acc;
    }, {} as Record<string, string>);
    setIcons(icons);
  }, [activeRules, getFieldTextValue]);

  return icons;
}

function passesComparator(value: string, compareValue: string, comparator: ElementRuleComparator): boolean {
  const numericValue = value.includes("%") ? Number.parseFloat(value.slice(0, -1)) : Number.parseFloat(value);
  const numericCompareValue = compareValue.includes("%")
    ? Number.parseFloat(compareValue.slice(0, -1))
    : Number.parseFloat(compareValue);
  switch (comparator) {
    case "eq": {
      return value == compareValue;
    }
    case "neq": {
      return value != compareValue;
    }
    case "contains": {
      return value.includes(compareValue);
    }
    case "not_contains": {
      return !value.includes(compareValue);
    }
    case "gt": {
      return numericValue > numericCompareValue;
    }
    case "lt": {
      return numericValue < numericCompareValue;
    }
    case "gte": {
      return numericValue >= numericCompareValue;
    }
    case "lte": {
      return numericValue <= numericCompareValue;
    }
    default: {
      return false;
    }
  }
}

function getPassingRules(
  rules: ElementRule[],
  getValueFunction: (fieldId: string) => string | undefined
): ElementRule[] {
  const passingRules: ElementRule[] = [];

  for (const rule of rules) {
    const value = getValueFunction(rule.fieldId);
    if (!value) continue;

    const compareValue = rule.compareValue;
    const comparator = rule.comparator;

    if (passesComparator(value.toLowerCase(), compareValue.toLowerCase(), comparator)) {
      passingRules.push(rule);
    }
  }

  return passingRules;
}
