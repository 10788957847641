import { BaseElementController, ElementController } from "elements/base/controller";
import BoardIntegrationsProxy from "frontend/canvas-designer-new/element-dynamic-data/integrations-proxy";
import { SingleBarChartElement, SingleBarChartFields } from "shared/datamodel/schemas/chart";

export interface ISingleBarChartController extends ElementController<SingleBarChartElement> {
  getWidth(): number;

  getHeight(): number;

  getBackgroundColor(): string;

  getFill(): string;

  getMinValue(): number;

  getMaxValue(): number;

  getValue(): number;

  get integrationsController(): BoardIntegrationsProxy;

  loadIntegrationItems(): void;
}

export default class SingleBarChartController
  extends BaseElementController<SingleBarChartElement>
  implements ISingleBarChartController
{
  integrationsController = new BoardIntegrationsProxy();

  constructor(id: string, element: SingleBarChartElement, context: any) {
    super(id, element, context);
    this.bindToController(this.integrationsController);
  }

  updateElement(element: SingleBarChartElement) {
    super.updateElement(element);
    this.notify();
  }

  getIntegrationFieldValue(integrationId: string, itemId: string, columnId: string): string {
    return this.integrationsController.getColumnValue(itemId, integrationId, columnId) || "";
  }

  loadIntegrationItems(): void {
    const { integrations } = this.element.dataLayer || {};
    if (!integrations) {
      return;
    }
    const integrationsToLoad = Object.entries(integrations).reduce((acc, [integrationId, items]) => {
      if (items) {
        acc[integrationId] = Object.keys(items);
      }
      return acc;
    }, {} as Record<string, string[]>);
    this.integrationsController.loadItems(integrationsToLoad);
  }

  getFieldValue<T extends number | string>(fieldId: SingleBarChartFields, defaultValue: T): T {
    const value = this.element.dataLayer?.values[fieldId];

    if (!value) {
      return defaultValue;
    }

    if (typeof value === "object") {
      const { integrationId, columnId, itemId } = value;
      const integrationValue = this.getIntegrationFieldValue(integrationId, itemId, columnId);
      if (!integrationValue) {
        return defaultValue;
      }
      return integrationValue as T;
    }

    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return value as T;
    }
    return numericValue as T;
  }

  getWidth(): number {
    return this.element.width;
  }

  getHeight(): number {
    return this.element.height;
  }

  getBackgroundColor(): string {
    return this.getFieldValue("backgroundColor", "#DADCE0");
  }

  getFill(): string {
    return this.getFieldValue("fillColor", "#00B875");
  }

  getMinValue(): number {
    return this.getFieldValue("minValue", 0);
  }

  getMaxValue(): number {
    return this.getFieldValue("maxValue", 100);
  }

  getValue(): number {
    return this.getFieldValue("value", 30);
  }
}
