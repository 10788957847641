import { CSSProperties, forwardRef, ReactNode, useEffect } from "react";
import style from "./options-picker.module.css";
import { DropdownPickerStyle } from "./dropdown-picker";
import classNames from "classnames";

type OptionsPickerProps = {
  containerStyle?: CSSProperties;
  className?: string; // Add this line
  scrollToSelected?: string;
  pickerStyle?: DropdownPickerStyle;
  children: ReactNode;
};

const OptionsPicker = forwardRef<HTMLDivElement, OptionsPickerProps>(
  ({ scrollToSelected, containerStyle, pickerStyle, className, children }, ref) => {
    useEffect(() => {
      if (scrollToSelected) {
        const selectedOption = document.getElementById(scrollToSelected);
        selectedOption?.scrollIntoView({ block: "end" });
      }
    }, []);
    return (
      <div
        id={"dropdown-picker"}
        className={classNames(style.container, className, {
          [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
        })}
        style={containerStyle}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

OptionsPicker.displayName = "OptionsPicker";
export default OptionsPicker;
