import { CSSProperties, useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { LiveIntegrationColumn, LiveIntegrationFilter } from "./utils";
import DropdownPicker, { DropdownPickerStyle } from "frontend/ui-components/picker/dropdown-picker";
import { FilterItemTrashIcon } from "frontend/canvas-designer-new/elements-toolbar/widgets/live-integration/icons/filter-item-trash-icon";
import { FilterItemPlusIcon } from "frontend/canvas-designer-new/elements-toolbar/widgets/live-integration/icons/filter-item-plus-icon";
import style from "./liveIntegrationFiltersPicker.module.css";
import Checkbox from "frontend/ui-components/checkbox";
import { getMaxByLength } from "frontend/utils/array-utils";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

export const EMPTY_DEFAULT_COLUMN: LiveIntegrationColumn = {
  id: "empty",
  settings_str: "{}",
  title: "Value",
  type: "status",
};

const DEFAULT_DROPDOWN_STYLES: CSSProperties = {
  top: "100%",
  borderRight: "1px solid white",
  borderLeft: "1px solid white",
  borderBottom: "1px solid white",
  borderBottomRightRadius: "2px",
  borderBottomLeftRadius: "2px",
  width: "150px",
};

const ALL_DEFAULT_STYLES = {
  pickerActiveStyle: { border: "1px solid white" },
  optionsPickerActiveStyle: DEFAULT_DROPDOWN_STYLES,
  searchContainer: style.searchContainer,
  optionListClassName: style.optionsList,
  optionClassName: style.option,
  optionTitleClassName: style.optiontitle,
};

interface DropdownLabel {
  id: number;
  name: string;
}

type SelectedColumn = LiveIntegrationColumn | null;

export function LiveIntegrationFiltersPicker({
  filters,
  columns,
  onSelectFilters,
}: {
  filters: LiveIntegrationFilter[];
  columns: LiveIntegrationColumn[];
  onSelectFilters: (filters: LiveIntegrationFilter[]) => void;
}) {
  const shouldEnableFilterByDropdownColumns = useFeatureFlag("orgchart-filters-column-dropdown");

  function onRemoveFilter(index: number) {
    const newFilters = filters.filter((_, i) => i !== index);
    onSelectFilters(newFilters);
  }

  function onFilterChange(index: number, filter: LiveIntegrationFilter) {
    const newFilters = structuredClone(filters);
    newFilters[index] = filter;
    onSelectFilters(newFilters);
    return;
  }

  const supportedColumnTypes = ["status"];

  if (shouldEnableFilterByDropdownColumns) {
    supportedColumnTypes.push("dropdown");
  }

  const supportedColumns = columns?.filter((column) => supportedColumnTypes.includes(column.type)) ?? [];

  return (
    <div className={style.filtersContainer}>
      {filters.map((filter, i) => {
        const column = columns.find((column) => column.id === filter.columnId);
        const isLast = filters.length - 1 === i;
        return (
          <>
            <FilterItem
              key={i + (column?.id ?? "")}
              columns={supportedColumns}
              supportedColumnTypes={supportedColumnTypes}
              column={column}
              values={filter.columnValues}
              onRemove={() => onRemoveFilter(i)}
              onChange={(f) => onFilterChange(i, f)}
              onNewFilterClick={() => {
                onFilterChange(filters.length, {
                  columnId: EMPTY_DEFAULT_COLUMN.id,
                  columnValues: [],
                });
              }}
              isLast={isLast}
            />
            {!isLast && <AndSeperator />}
          </>
        );
      })}
      {filters.length === 0 && (
        <FilterItem
          columns={supportedColumns}
          supportedColumnTypes={supportedColumnTypes}
          column={EMPTY_DEFAULT_COLUMN}
          values={[]}
          onChange={(f) => onFilterChange(0, f)}
          isLast
        />
      )}
    </div>
  );
}

function FilterItem({
  columns = [],
  supportedColumnTypes,
  column = EMPTY_DEFAULT_COLUMN,
  values = [],
  isLast = false,
  onRemove,
  onChange,
  onNewFilterClick,
}: {
  columns?: { id: string; title: string; type: string; settings_str: string }[];
  supportedColumnTypes: string[];
  column?: LiveIntegrationColumn;
  values?: string[];
  isLast?: boolean;
  onRemove?: () => void;
  onNewFilterClick?: () => void;
  onChange: (filter: LiveIntegrationFilter) => void;
}) {
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn>(column);
  const [selectedValues, setSelectedValue] = useState<string[]>(values);

  const shouldEnableFilterByDropdownColumns = useFeatureFlag("orgchart-filters-column-dropdown");

  function isFilterSupportColumnType(column: SelectedColumn) {
    return column && supportedColumnTypes.includes(column.type);
  }

  const parseColumnSettings = useCallback(
    (column: SelectedColumn) => {
      let labels = {};
      let colors = {};
      if (!column) return { labels, colors };

      const settings = JSON.parse(column.settings_str ?? "{}");

      if (column.type === "status") {
        labels = settings?.labels;
        colors = settings?.labels_colors;
      } else if (shouldEnableFilterByDropdownColumns && column.type === "dropdown") {
        labels = settings?.labels.reduce((acc: Record<string, string>, label: DropdownLabel, index: number) => {
          acc[index] = label.name;
          return acc;
        }, {});
      }

      labels ??= {};
      colors ??= {};

      return { labels, colors } as { labels: any; colors: any };
    },
    [shouldEnableFilterByDropdownColumns]
  );

  const optionRenderer = useCallback(
    (column: SelectedColumn, showCheckbox: boolean) => {
      if (!isFilterSupportColumnType(column)) {
        return undefined;
      }

      const { labels, colors } = parseColumnSettings(column);

      return ({ option: statusId, selected: isSelected }: { option: string; selected: boolean }) => {
        const title = labels[statusId];
        const color = colors[statusId];

        return {
          element: (
            <FilterOption title={title} isSelected={isSelected} showCheckbox={showCheckbox} color={color?.color} />
          ),
          enabled: true,
          wrapWithContainer: false,
        };
      };
    },
    [shouldEnableFilterByDropdownColumns]
  );

  const selectionHeaderRenderer = useCallback(
    (column: LiveIntegrationColumn | null) => {
      if (!isFilterSupportColumnType(column)) {
        return undefined;
      }

      const { labels, colors } = parseColumnSettings(column);

      // eslint-disable-next-line react/display-name
      return ({ selectedOptions }: { selectedOptions: string[] }) => {
        const selectedItem = getMaxByLength(
          Object.keys(labels)
            .map((labelId) => {
              return {
                id: labelId,
                title: labels[labelId],
                color: colors[labelId]?.color,
              };
            })
            .filter((item) => selectedOptions.includes(item.id)),
          "title"
        );

        if (!selectedItem) {
          return null;
        }

        return (
          <div className={style.headerWrapper}>
            <FilterOption
              title={selectedItem.title}
              isSelected={false}
              showCheckbox={false}
              color={selectedItem.color}
              isHeader
            />
            {selectedOptions.length > 1 && <div>, +{selectedOptions.length - 1}</div>}
          </div>
        );
      };
    },
    [shouldEnableFilterByDropdownColumns]
  );

  const ValuesPicker = useCallback(() => {
    const { labels } = parseColumnSettings(selectedColumn);

    const statusIds = Object.keys(labels);
    return (
      <DropdownPicker
        options={statusIds}
        isSelected={(id) => {
          const label = labels[id];
          if (!label) {
            return false;
          }
          return selectedValues.includes(label);
        }}
        optionRenderer={optionRenderer(selectedColumn, true)}
        selectionHeaderRenderer={(props) => {
          return selectionHeaderRenderer(selectedColumn)?.(props);
        }}
        titles={(statusId) => labels[statusId]}
        enabled={true}
        multiValue={true}
        placeholder="Value"
        pickerStyle={DropdownPickerStyle.Dark}
        pickerCustomStyle={{ width: "150px" }}
        styles={ALL_DEFAULT_STYLES}
        enableSearch
        onChange={(statusId) => {
          const label = labels[statusId];
          setSelectedValue((values) => {
            if (values.includes(label)) {
              return values.filter((value) => value !== label);
            }
            return [...values, label];
          });
        }}
      />
    );
  }, [selectedColumn, selectedValues, shouldEnableFilterByDropdownColumns]);

  const onRemoveFilter = () => {
    setSelectedValue([]);
    setSelectedColumn(null);
    onRemove?.();
  };

  useEffect(() => {
    if (selectedColumn && selectedColumn.id !== EMPTY_DEFAULT_COLUMN.id) {
      onChange({
        columnId: selectedColumn?.id || "",
        columnValues: selectedValues,
      });
    }
  }, [selectedColumn, selectedValues]);

  return (
    <div className={style.newFilterItem}>
      <span>Where </span>
      <DropdownPicker
        options={columns}
        isSelected={(column) => column.id === selectedColumn?.id}
        titles={(column) => column.title}
        enabled={true}
        onChange={(v) => {
          setSelectedValue([]);
          setSelectedColumn(v);
        }}
        optionRenderer={(option) => {
          return {
            element: <div className={style.statusOptionTitle}>{option.option.title}</div>,
            handlesSelectedState: true,
            enabled: true,
          };
        }}
        placeholder="Column"
        optionsPickerStyle={{
          fontSize: 12,
        }}
        pickerStyle={DropdownPickerStyle.Dark}
        pickerCustomStyle={{ width: "150px" }}
        styles={ALL_DEFAULT_STYLES}
        enableSearch
      />
      <span> is</span>
      <ValuesPicker />
      <div className={style.filterActions}>
        <div className={style.removeFilterButton} data-isactive={selectedValues.length > 0} onClick={onRemoveFilter}>
          <FilterItemTrashIcon color={selectedValues.length > 0 ? "#FEFEFE" : "#8899AB"} />
        </div>
        {isLast && (
          <div className={style.addFilterButton} onClick={onNewFilterClick}>
            <FilterItemPlusIcon />
          </div>
        )}
      </div>
    </div>
  );
}

function FilterOption({
  title,
  isSelected,
  showCheckbox,
  color,
  isHeader,
}: {
  title: string;
  isSelected: boolean;
  showCheckbox: boolean;
  color?: string;
  isHeader?: boolean;
}) {
  return (
    <div className={cn(style.statusOption, { [style.statusOptionHeader]: isHeader })}>
      {showCheckbox && (
        <Checkbox
          checked={isSelected}
          ovverideStyles={{
            base: style.statusOptionCheckBox,
            checked: style.statusOptionCheckBoxChecked,
          }}
        />
      )}
      {color && <span className={style.statusOptionBox} style={{ "--status-color": color } as CSSProperties}></span>}
      <div className={style.statusOptionTitle}>{title}</div>
    </div>
  );
}

function AndSeperator() {
  return <div className={style.andSeperator}>and</div>;
}
