import { IntegrationType } from "shared/integrations/integration";

export type MondayItem = {
  id: string;
  name: string;
  columnValues: any[];
  url?: string;
};

export type MondayColumn = {
  id: string;
  title: string;
  type: string;
  settings_str: string;
  description?: string;
};

export interface OrgChartItem extends MondayItem {
  columns: MondayColumn[];
}

// TODO: integration data should be generic per integration type
export type IntegrationData = {
  boardId: string;
  subitemsBoard?: { id: string; columns: MondayColumn[] };
  type: IntegrationType;
  items: MondayItem[];
  configuration: any;
  columns: MondayColumn[];
  name: string;
  partial: boolean;
  pageCursor?: string;
  pusherTimestamp?: string;
  error?: string;
  columnMappings?: {
    mapping?: Record<string, string>;
    shouldApplyForAll?: boolean;
  };
};
export type IntegrationItemsMap = { [integrationId: string]: IntegrationData };

export type BoardIntegration = {
  id: string;
  type: IntegrationType;
  configuration: any;
  name: string;
  isOwner: boolean;
  integration_id?: string;
};

export enum BoardIntegrationLoadingState {
  notLoaded,
  loading,
  loaded,
  stale,
  failed,
}
