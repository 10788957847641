import Konva from "konva";
import { SceneCanvas, HitCanvas } from "konva/types/Canvas";
import { KonvaNodeComponent } from "react-konva";

interface RenderChildrenIfProps extends Konva.ContainerConfig {
  shouldRenderTest: (node: RenderChildrenIf) => boolean;
}

class RenderChildrenIf extends Konva.Group {
  public shouldRenderTest: RenderChildrenIfProps["shouldRenderTest"];
  constructor(config: RenderChildrenIfProps) {
    super(config);
    this.shouldRenderTest = config.shouldRenderTest;
  }
  drawScene(can?: SceneCanvas, top?: Konva.Node): this {
    if (this.shouldRenderTest(this)) return super.drawScene(can, top);
    return this;
  }
  drawHit(can?: HitCanvas, top?: Konva.Node): this {
    if (this.shouldRenderTest(this)) return super.drawHit(can, top);
    return this;
  }
}

// @ts-expect-error Konva types are mismatched here.
Konva["RenderChildrenIf"] = RenderChildrenIf;

// @ts-expect-error Konva types are mismatched here.
export default "RenderChildrenIf" as KonvaNodeComponent<Konva.Group, RenderChildrenIfProps>;
