import React from "react";

export const MyCanvasesIcon = ({ stroke = "#113357" }: { stroke?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
      <path
        d="M2.5 1L5.5 1.00007H7.75H13C13.3978 1.00007 13.7794 1.19322 14.0607 1.53704C14.342 1.88085 14.5 2.34716 14.5 2.83339V10.1667C14.5 10.6529 14.342 11.1192 14.0607 11.463C13.7794 11.8068 13.3978 12 13 12H2.5C2.10218 12 1.72064 11.8068 1.43934 11.463C1.15804 11.1192 1 10.6529 1 10.1667V2.83332C1 2.3471 1.15804 1.88078 1.43934 1.53697C1.72064 1.19315 2.10218 1 2.5 1Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.8125 3.44446L3.8125 9.55557" stroke={stroke} strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};
