import Konva from "konva";
import { Shape } from "react-konva";
import { onHoverOff, onHoverOn } from "./hover-effect";
import React from "react";
import { MouseCb } from "./button-plus";
import { Button } from "../table-styling";

export const DragHandle = React.forwardRef(
  (
    {
      x = 0,
      y = 0,
      ofsX = 0,
      ofsY = 0,
      onClick,
      onDragStart,
      mode,
      trackSize = Number.MAX_SAFE_INTEGER,
    }: {
      x?: number;
      y?: number;
      ofsX?: number;
      ofsY?: number;
      onClick?: MouseCb;
      onDragStart?: MouseCb;
      mode: "horizontal" | "vertical";
      trackSize?: number;
    },
    ref: React.LegacyRef<Konva.Shape>
  ) => {
    const width = mode == "horizontal" ? 30 : 10;
    const height = mode == "horizontal" ? 10 : 30;
    const hoverWidth = mode == "horizontal" ? width : width * 2;
    const hoverHeight = mode == "horizontal" ? height * 2 : height;
    return (
      <Shape
        ref={ref}
        x={x}
        y={y}
        name="drag-handle-anchor"
        fill={Button.Fill}
        strokeEnabled={false}
        onMouseEnter={onHoverOn}
        onMouseLeave={onHoverOff}
        onClick={onClick}
        draggable={true}
        onDragStart={onDragStart}
        sceneFunc={(context, shape) => {
          const stage = shape.getStage();
          if (!stage) return; // should never happen
          const scale = stage.scaleX(); // button is fixed-size, regardless of stage scale
          if (!Number.isFinite(trackSize)) return;
          const distanceOnScreen = trackSize * scale;
          if (distanceOnScreen <= 35) return; //don't draw handle when the col/row is too small

          context.beginPath();
          context.translate(ofsX / scale, ofsY / scale);
          context.scale(1 / scale, 1 / scale);
          if (context._context.roundRect !== undefined) {
            context._context.roundRect(-width / 2, -height / 2, width, height, Math.min(width, height) / 2);
          } else {
            context.rect(-width / 2, -height / 2, width, height);
          }
          context.fillStrokeShape(shape);
        }}
        hitFunc={(context, shape) => {
          // custom hit-area, slightly larger than the button, for convenience
          const stage = shape.getStage();
          if (!stage) return;
          const scale = stage.scaleX();
          if (!Number.isFinite(trackSize)) return;
          const distanceOnScreen = trackSize * scale;
          if (distanceOnScreen <= 35) return; //don't draw handle when the col/row is too small
          context.beginPath();
          context.translate(ofsX / scale, ofsY / scale);
          context.scale(1 / scale, 1 / scale);
          context.rect(-hoverWidth / 2, -hoverHeight / 2, hoverWidth, hoverHeight);
          context.fillStrokeShape(shape);
        }}
      />
    );
  }
);
DragHandle.displayName = "DragHandle";
