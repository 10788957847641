import { useRef, useMemo, useEffect } from "react";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";
import { Shape as ShapeElement } from "shared/datamodel/schemas/shape";
import { undoRedoStackAtom, transformerRefAtom } from "state-atoms";
import { useAtomValue } from "jotai";
import { useElementWithRulesProcessing } from "./use-element-rules-processor";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { getTransformerConfigByElementType } from "../elements/canvas-elements-utils";

// Currently supports shapes. Can support more element types.
export function useScaleRule(id: string, element: ShapeElement) {
  const isDataLayerEnabled = useFeatureFlag("element-data-layer");

  const isFirstRender = useRef(true);
  const undoRedoStack = useAtomValue(undoRedoStackAtom);
  const transformerRef = useAtomValue(transformerRefAtom);

  const memoedDataLayer = useDeepEqualMemo(element.dataLayer);
  const scaleControlledByRule = useMemo(() => {
    return memoedDataLayer?.rules?.some((rule) => rule.action.property === "scale") ?? false;
  }, [memoedDataLayer]);

  const {
    scaleX = 1,
    scaleY = 1,
    originalScaleX = 1,
    originalScaleY = 1,
  } = useElementWithRulesProcessing({ id, element });

  const allowTransformerResize = (allow: boolean) => {
    if (!transformerRef.current) return;

    if (allow) {
      const nodes = transformerRef.current.nodes();
      transformerRef.current.setAttrs(getTransformerConfigByElementType(nodes));
    } else {
      transformerRef.current.setAttr("enabledAnchors", []);
    }
    transformerRef.current.update();
  };

  useEffect(() => {
    if (!isDataLayerEnabled) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (scaleControlledByRule) {
      allowTransformerResize(false);
      undoRedoStack?.patchElementWithoutUndo(id, (draft: ShapeElement) => {
        // Save original scale, reset scale to 1
        draft.originalScaleX = element.scaleX;
        draft.originalScaleY = element.scaleY;
      });
    } else {
      allowTransformerResize(true);
      undoRedoStack?.patchElementWithoutUndo(id, (draft: ShapeElement) => {
        // Revert to original scale
        draft.scaleX = originalScaleX;
        draft.scaleY = originalScaleY;
        draft.originalScaleX = 1;
        draft.originalScaleY = 1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleControlledByRule]);

  useEffect(() => {
    if (!isDataLayerEnabled || !scaleControlledByRule) return;

    const newScaleX = scaleX * originalScaleX;
    const newScaleY = scaleY * originalScaleY;
    if (newScaleX !== element.scaleX || newScaleY !== element.scaleY) {
      undoRedoStack?.patchElementWithoutUndo(id, (draft) => {
        draft.scaleX = newScaleX;
        draft.scaleY = newScaleY;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleX, scaleY, originalScaleX, originalScaleY]);

  return { scaleX: element.scaleX, scaleY: element.scaleY };
}
