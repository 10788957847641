import React from "react";

export const FilterItemPlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none" viewBox="0 0 11 11">
      <path fill="#fff" d="M5.5 1v8.919V1z"></path>
      <path stroke="#fff" strokeLinecap="square" strokeLinejoin="round" d="M1 5.46h9M5.5 1v8.919"></path>
    </svg>
  );
};
