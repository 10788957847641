import { isSameDay, isSameMonth, isSameYear, format } from "date-fns";

export function dateDiff(dateA: Date, dateB: Date) {
  const diff = Math.floor(dateA.getTime() - dateB.getTime());
  const day = 1000 * 60 * 60 * 24;
  const days = Math.floor(diff / day);
  const months = Math.floor(days / 31);
  const years = Math.floor(months / 12);
  return { days, months, years };
}

export function addDays(time: any, days: number) {
  /* (Date.prototype as any).addDays = function(days:number) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }*/
  // console.log('addDays before',{time: time.getTime(),days})
  const timeStamp = Math.floor(time.getTime() / 1000) + days * 24 * 60 * 60;
  //const timeStamp = time.setDate(time.getDate() + days);
  // console.log('addDays after',{time: time.getTime(),days})
  return timeStamp;
}

export function removeDays(time: any, days: number) {
  /* (Date.prototype as any).addDays = function(days:number) {
         var date = new Date(this.valueOf());
         date.setDate(date.getDate() + days);
         return date;
     }*/
  //console.log('addDays before',{time: time.getTime(),days})
  const timeStamp = Math.floor(time.getTime() / 1000) - days * 24 * 60 * 60;
  //const timeStamp = time.setDate(time.getDate() + days);
  //console.log('addDays after',{time: time.getTime(),days})
  return timeStamp;
}

export function getFormatedDate(date: Date) {
  return `${date.toLocaleString("default", { month: "long" })} ${date.getUTCDate()}`;
}

/**
 * Converts a date to the local time of client's device.
 * Example: 2024-08-16T00:00:00.000Z -> 2024-08-16T00:00:00.000+01:00
 * Note that JS Date objects are not timezone aware. Using this function for anything
 * other than display purposes could cause unexpected behavior.
 * @param date Date to convert
 * @returns Date in local timezone
 */
export function convertToLocalTime(date: Date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000; //M to ms
  return new Date(date.getTime() + offsetMs);
}

export const formatRelatedDates = (date1: number | Date | undefined, date2: number | Date | undefined) => {
  try {
    if (date1 && !date2) {
      return `${format(date1, "LLL d")} - ${format(date1, "LLL d")}`;
    }

    if (date1 && date2) {
      if (isSameDay(date1, date2)) {
        return format(date1, "d MMM");
      } else if (isSameMonth(date1, date2) && isSameYear(date1, date2)) {
        return `${format(date1, "LLL d")} - ${format(date2, "d")}`;
      } else if (isSameYear(date1, date2)) {
        return `${format(date1, "LLL d")} - ${format(date2, "LLL d")}`;
      } else {
        return `${format(date1, "LLL d yyyy")} - ${format(date2, "LLL d yyyy")}`;
      }
    }

    if (date1) {
      return format(date1, "d MMM");
    }

    if (date2) {
      return format(date2, "d MMM");
    }
  } catch (error) {
    console.error("Error formatting dates", error);
    return "";
  }
};

export const cleanDate = (d: Date | number) => {
  return new Date(new Date(d).toDateString());
};
